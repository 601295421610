<template>
  <v-card>
    <v-card-title>
      <span class="headline me-1 word-wrap" ref="settings-franchise-slot-summary-card-title">
        Franchise Slot Summary
      </span>
      <base-tool-tip
        ref="settings-franchise-slot-summary-card-tool-tip"
        class="ps-4"
        dialog-title="Slots for trucks with a begin or end date in the future will not be reflected until that day has passed."
        :nudge-dialog="this.$vuetify.breakpoint.mobile ? '80' : '150'"
        icon-color="primary"
        tooltip-color="primary"
        tooltip-icon="mdi-information-outline"
        :is-bottom-dialog="true"
      >
      </base-tool-tip>
    </v-card-title>
    <v-card-text>
      <v-data-table ref="settings-franchise-slot-summary-card-days-of-week-table" class="data_table_body" :headers="slotSummaryHeaders" :items="listItems" hide-default-footer></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { DayOfWeek } from "@/enums/DayOfWeek";
import { formatAsDateOnly, todayAsDate } from "@/utils/DateTimeFormatters";
import BaseToolTip from "@/components/BaseToolTip.vue";

export default {
  name: "SettingsFranchiseSlotSummaryCard",
  components: { BaseToolTip },
  data() {
    return {}
  },
  computed: {
    ...mapGetters("Settings", ["getWeeklyAllocations", "getSelectedFranchise", "getFranchisesInOperatingUnit", "getAllTrucksInOperatingUnitSortedByName"]),
    slotSummaryHeaders() {
      return [
        { text: "Day", align: "start", value: "dayOfWeek" },
        { text: "AM Slots", align: "start", value: "amSlots" },
        { text: "PM Slots", align: "start", value: "pmSlots" }
      ]
    },
    listItems() {
      let franchiseSlotSummaries = [
        { dayOfWeek: DayOfWeek.SUNDAY.name, dayOfWeekId: DayOfWeek.SUNDAY.id, amSlots: 0, pmSlots: 0 },
        { dayOfWeek: DayOfWeek.MONDAY.name, dayOfWeekId: DayOfWeek.MONDAY.id, amSlots: 0, pmSlots: 0 },
        { dayOfWeek: DayOfWeek.TUESDAY.name, dayOfWeekId: DayOfWeek.TUESDAY.id, amSlots: 0, pmSlots: 0 },
        { dayOfWeek: DayOfWeek.WEDNESDAY.name, dayOfWeekId: DayOfWeek.WEDNESDAY.id, amSlots: 0, pmSlots: 0 },
        { dayOfWeek: DayOfWeek.THURSDAY.name, dayOfWeekId: DayOfWeek.THURSDAY.id, amSlots: 0, pmSlots: 0 },
        { dayOfWeek: DayOfWeek.FRIDAY.name, dayOfWeekId: DayOfWeek.FRIDAY.id, amSlots: 0, pmSlots: 0 },
        { dayOfWeek: DayOfWeek.SATURDAY.name, dayOfWeekId: DayOfWeek.SATURDAY.id, amSlots: 0, pmSlots: 0 }
      ]
      let franchise = this.getFranchisesInOperatingUnit.find(x => x.franchiseId === this.getSelectedFranchise)
      this.getWeeklyAllocations.forEach(allocation => {
        let index = franchiseSlotSummaries.findIndex(x => x.dayOfWeekId === allocation.dayOfWeekId && allocation.dispatchLocationId === franchise?.addressId)
        if (~index) {
          let truck = this.getAllTrucksInOperatingUnitSortedByName.find(x => x.id === allocation.junkTruckId)
          let truckBeginDateInPast = formatAsDateOnly(truck.beginDateTime) <= todayAsDate()
          let truckEndDateInFuture = truck.endDateTime ? formatAsDateOnly(truck.endDateTime) >= todayAsDate() : true
          if (truckBeginDateInPast && truckEndDateInFuture) {
            franchiseSlotSummaries[index].amSlots += Number.parseInt(allocation.amSlots) ?? 0
            franchiseSlotSummaries[index].pmSlots += Number.parseInt(allocation.pmSlots) ?? 0
          }
        }
      })
      return franchiseSlotSummaries
    }
  }
}
</script>

<style scoped>
.data_table_body td {
  font-size: medium !important;
  height: 0 !important;
  padding: 8px !important;
}
</style>
