<template>
  <span data-cy="number-formatter" :style="{ color: setColor(amount) }">{{ formatAmount(amount) }}</span>
</template>

<script>
import applicationColors from "@/utils/ApplicationColors"

export default {
  name: "AppNumberFormatter",
  props: {
    amount: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      default: "USD"
    },
    numberFormatStyle: {
      type: String,
      default: "decimal"
    },
    currencySign: {
      type: String,
      default: "standard"
    },
    displayNegativeAsRed: {
      type: Boolean,
      default: true
    },
    isPercentage: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatAmount(amount) {
      if (this.isPercentage) return `${amount * 100}%`
      let formatter = new Intl.NumberFormat("en-US", {
        style: this.numberFormatStyle,
        currency: this.currency,
        currencySign: this.currencySign
      })
      return formatter.format(amount)
    },
    setColor(amount) {
      if (amount < 0 && this.displayNegativeAsRed) return applicationColors.tmtRed.light
    }
  }
}
</script>
