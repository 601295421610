import junkApi from "@/api/Junk"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { persistentErrorSnackbarWithPositiveAction } from "@/utils/SnackbarBuilder"
import * as Sentry from "@sentry/vue"

export const state = {
  _jobDataFixDetails: {},
  _jobDataFixPreview: {},
  _jobStatuses: [],
  _cancellationReasons: [],
  _activeFranchiseTaxes: [],
  _isLoadingJobDataFixDetails: false,
  _customerDetails: {}
}

export const getters = {
  getIsLoadingJobDataFixDetails(state) {
    return state._isLoadingJobDataFixDetails
  },
  getJobStatuses(state) {
    return state._jobStatuses
  },
  getCancellationReasons(state) {
    return state._cancellationReasons
  },
  getActiveFranchiseTaxes(state) {
    return state._activeFranchiseTaxes.slice()
      .sort((a,b) => a.taxName < b.taxName ? -1 : 1)
  },
  getCustomerDetails(state) {
    return state._customerDetails
  }
}

export const mutations = {
  SET_JOB_DATA_FIX_DETAILS(state, jobDataFixDetails) {
    state._jobDataFixDetails = Object.assign({}, jobDataFixDetails)
  },
  SET_JOB_DATA_FIX_PREVIEW(state, jobDataFixPreview) {
    state._jobDataFixPreview = Object.assign({}, jobDataFixPreview)
  },
  SET_CANCELLATION_REASONS(state, cancellationReasons) {
    state._cancellationReasons.splice(0, state._cancellationReasons?.length ?? 0, ...cancellationReasons)
  },
  SET_ACTIVE_FRANCHISE_TAXES(state, activeFranchiseTaxes) {
    state._activeFranchiseTaxes.splice(0, state._activeFranchiseTaxes?.length ?? 0, ...activeFranchiseTaxes)
  },
  SET_IS_LOADING_JOB_DETAILS_DATA_FIX(state, isLoading) {
    state._isLoadingJobDataFixDetails = isLoading
  },
  SET_JOB_DATA_FIX_CUSTOMER_DETAILS(state, customerDetails) {
    state._customerDetails = Object.assign({}, customerDetails)
  }
}

export const actions = {
  async clearActiveFranchiseTaxes({ commit }) {
    commit("SET_ACTIVE_FRANCHISE_TAXES", [])
  },
  async resetJobDataFixCustomerDetails({ commit }) {
    commit("SET_JOB_DATA_FIX_CUSTOMER_DETAILS", {})
  },
  async fetchJobDataFixCustomerDetails({ commit }, jobNumber) {
    commit("SET_JOB_DATA_FIX_CUSTOMER_DETAILS", {})
    return await junkApi
      .fetchJobDataFixCustomerDetailsByJobNumber(jobNumber)
      .then(jobDataFixDetails => {
        commit("SET_JOB_DATA_FIX_CUSTOMER_DETAILS", jobDataFixDetails)
        return Promise.resolve(jobDataFixDetails)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("DataFix/fetchJobDataFixCustomerDetails", jobNumber)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
  },
  async fetchJobDataFixDetails({ commit }, jobId) {
    commit("SET_IS_LOADING_JOB_DETAILS_DATA_FIX", true)
    return await junkApi
      .fetchJobDataFixDetailsByJobId(jobId)
      .then(jobDataFixDetails => {
        commit("SET_JOB_DATA_FIX_DETAILS", jobDataFixDetails)
        return Promise.resolve(jobDataFixDetails)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Job Details! ${errorMessage}`, "Retry", () => this.dispatch("DataFix/fetchJobDataFixDetails", jobId)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_DETAILS_DATA_FIX", false)
      })
  },
  async fetchCancellationReasons({ commit }) {
    commit("SET_IS_LOADING_JOB_DETAILS_DATA_FIX", true)
    return await junkApi
      .fetchCancellationReasons()
      .then(data => {
        commit("SET_CANCELLATION_REASONS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Cancellation Reasons! ${errorMessage}`, "Retry", () => this.dispatch("DataFix/fetchCancellationReasons")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_DETAILS_DATA_FIX", false)
      })
  },
  async fetchActiveFranchiseTaxes({ commit }, { franchiseId, date }) {
    commit("SET_IS_LOADING_JOB_DETAILS_DATA_FIX", true)
    return await junkApi
      .fetchActiveFranchiseTaxesByFranchiseIdAndDate(franchiseId, date)
      .then(activeFranchiseTaxes => {
        commit("SET_ACTIVE_FRANCHISE_TAXES", activeFranchiseTaxes)
        return Promise.resolve(activeFranchiseTaxes)
      })
      .catch(error => {
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.dispatch("DataFix/fetchActiveFranchiseTaxes", { franchiseId, date })),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_DETAILS_DATA_FIX", false))
  },
  async postJobDataFixForPreview({ commit }, jobDataFix) {
    commit("SET_IS_LOADING_JOB_DETAILS_DATA_FIX", true)
    commit("SET_JOB_DATA_FIX_PREVIEW", {})
    return await junkApi
      .postJobDataFixPreview(jobDataFix)
      .then(data => {
        commit("SET_JOB_DATA_FIX_PREVIEW", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Posting Job Data Fix! ${errorMessage}`, "Retry", () => this.dispatch("DataFix/postJobDataFixForPreview", jobDataFix)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_DETAILS_DATA_FIX", false)
      })
  },
  async postJobDataFixForApply({ commit }, { jobId, employeeId }) {
    commit("SET_IS_LOADING_JOB_DETAILS_DATA_FIX", true)
    return await junkApi
      .postJobDataFixApply(jobId, employeeId)
      .then(() => {
        commit("SET_JOB_DATA_FIX_DETAILS", {})
        commit("SET_JOB_DATA_FIX_PREVIEW", {})
        commit("SET_CANCELLATION_REASONS", [])
        return Promise.resolve()
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Applying Job Data Fix! ${errorMessage}`, "Retry", () =>
            this.dispatch("DataFix/postJobDataFixForApply", {
              jobId,
              employeeId
            })
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JOB_DETAILS_DATA_FIX", false)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {}
}
