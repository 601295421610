<template>
  <div>
    <v-card class="mx-auto" max-width="550" min-height="200" flat :loading="getIsLoadingAddPayment">
      <v-card-title>
        <v-row>
          <v-col>
            Payments
          </v-col>
          <v-col class="text-end">
            <v-btn color="primary" ref="record-payment-details-button" :disabled="isAddPaymentDisabled" outlined elevation="1" rounded @click="handleAddPayment"
              >Record Payment Details</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-list>
              <template>
                <v-simple-table>
                  <template>
                    <thead>
                      <tr>
                        <th class="text-left">Payment Type</th>
                        <th class="text-right">Amount</th>
                        <th class="text-right">Number</th>
                        <th class="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody v-if="isPaymentsBodyVisible">
                      <tr v-for="(item, i) in getJobReceiptPayments" :key="`item-${i}`">
                        <td>
                          {{ item.paymentTypeName }}
                        </td>
                        <td class="text-right">
                          <app-number-formatter :amount="item.amount" currencySign="accounting" numberFormatStyle="currency" />
                        </td>
                        <td class="text-right">
                          {{ getPaymentDetail(item) }}
                        </td>
                        <td class="text-right">
                          <v-btn
                            ref="job-addresses-card-delete-address-btn"
                            color="error"
                            @click="promptDeletePaymentConfirmationDialog(item)"
                            :disabled="getIsLoadingAddPayment"
                            icon
                          >
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td class="text-center text-h5 pa-8" colspan="4">No payments have been logged.</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="text-left pt-4">
                          <strong>Total: </strong>
                          <span class="text-h4">
                            <app-number-formatter :amount="getCurrentJob.totalPrice" currencySign="accounting" numberFormatStyle="currency" />
                          </span>
                        </td>
                        <td class="text-right"></td>
                        <td class="text-right pt-4" colspan="2">
                          <v-icon class="me-1" icon @click="handleCopyAmount">mdi-content-copy</v-icon>
                          <strong @click="handleCopyAmount">Remaining Balance: </strong>
                          <span @click="handleCopyAmount" class="text-h4">
                            <app-number-formatter :amount="getAmountDue" currencySign="accounting" numberFormatStyle="currency" />
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <job-receipt-add-payment-dialog
      v-if="isJobReceiptAddPaymentDialogVisible"
      :is-dialog-visible="isJobReceiptAddPaymentDialogVisible"
      :amount-due="getAmountDue"
      @cancel-payment="handleCancelAddPayment"
      @save-payment="handleSaveAddPayment"
    ></job-receipt-add-payment-dialog>

    <app-confirmation-dialog
      v-if="isDeletePaymentConfirmationDialogVisible"
      :is-dialog-visible="isDeletePaymentConfirmationDialogVisible"
      :is-loading="getIsLoadingAddPayment"
      title="Delete Payment?"
      :content-text="deletePaymentContextText"
      dialog-image="mdi-account-cancel-outline"
      @closeDialog="dismissDeletePaymentConfirmationDialog"
      @confirmed="deletePayment"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import JobReceiptAddPaymentDialog from "@/components/JobReceiptAddPaymentDialog.vue"
import snackbarBuilder from "@/utils/SnackbarBuilder"
import AppNumberFormatter from "@/components/AppNumberFormatter.vue"
import { consoleLog } from "@/utils/Logging"
import AppConfirmationDialog from "@/components/AppConfirmationDialog.vue"
import { PaymentType } from "@/enums/PaymentTypes"
import { round } from "@/utils/Round"

export default {
  name: "JobReceiptPaymentsCard",
  components: { AppConfirmationDialog, AppNumberFormatter, JobReceiptAddPaymentDialog },
  data() {
    return {
      isJobReceiptAddPaymentDialogVisible: false,
      isDeletePaymentConfirmationDialogVisible: false,
      paymentToDelete: {}
    }
  },
  computed: {
    ...mapGetters("Job", ["getJobReceiptPayments", "getCurrentJob", "getIsLoadingAddPayment"]),
    getAmountDue() {
      const total = this.getCurrentJob.totalPrice
      const payments = this.getJobReceiptPayments
      const paymentsReduced = payments.reduce((acc, payment) => acc + payment.amount, 0)
      const paymentsRounded = round(paymentsReduced)
      const result = total - paymentsRounded
      return round(result)
    },
    isPaymentsBodyVisible() {
      return this.getJobReceiptPayments.length > 0
    },
    deletePaymentContextText() {
      if (this.paymentToDelete.paymentTypeName) {
        return `Are you sure you want to delete this ${this.paymentToDelete.paymentTypeName?.toLowerCase()} payment?`
      } else {
        return "Are you sure you want to delete this payment?"
      }
    },
    isAddPaymentDisabled() {
      return this.getAmountDue <= 0
    }
  },
  methods: {
    ...mapActions(["addSnackbar"]),
    ...mapActions("Job", ["addJobReceiptPayment", "deleteJobReceiptPayment"]),
    promptDeletePaymentConfirmationDialog(item) {
      this.paymentToDelete = Object.assign({}, item)
      consoleLog("paymentToDelete = ", this.paymentToDelete)
      this.isDeletePaymentConfirmationDialogVisible = true
    },
    dismissDeletePaymentConfirmationDialog() {
      this.isDeletePaymentConfirmationDialogVisible = false
      this.paymentToDelete = Object.assign({}, {})
      consoleLog("paymentToDelete = ", this.paymentToDelete)
    },
    async deletePayment() {
      await this.deleteJobReceiptPayment(this.paymentToDelete.jobFranchiseDocumentPaymentId).then(() => {
        this.dismissDeletePaymentConfirmationDialog()
      })
    },
    setIsAddPaymentDialogVisible(isVisible) {
      this.isJobReceiptAddPaymentDialogVisible = isVisible
    },
    getPaymentDetail(item) {
      switch (item.paymentTypeId) {
        case PaymentType.CREDIT_CARD.id:
          return `****-****-****-${item.lastFourCardNumber}`
        case PaymentType.CHECK.id:
          return `${item.checkNumber}`
        case PaymentType.INVOICE.id:
          return `${item.invoiceNumber}`
        default:
          return ""
      }
    },
    handleAddPayment() {
      this.setIsAddPaymentDialogVisible(true)
    },
    handleCancelAddPayment() {
      this.setIsAddPaymentDialogVisible(false)
    },
    async handleSaveAddPayment(payment) {
      await this.addJobReceiptPayment(payment).then(() => {
        this.setIsAddPaymentDialogVisible(false)
      })
    },
    async handleCopyAmount() {
      await navigator.clipboard.writeText(this.getAmountDue.toFixed(2))
      await this.addSnackbar(snackbarBuilder.successSnackbar("Amount copied to clipboard!", 6000))
    }
  }
}
</script>
