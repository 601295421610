import axios from "axios"
import getEnv from "@/utils/Env"
import { app } from "../../appsettings.json"
import apiUtils from "@/api/Utils"

/* This client is used for interacting with the routing-service */
const routingClient = axios.create({
  baseURL: getEnv("VUE_APP_ROUTING_API_URL") || app.VUE_APP_ROUTING_API_URL,
  timeout: 30000,
  json: true
})

export default {
  async execute(method, resource, dataParam, queryParams) {
    return routingClient({
      method,
      url: resource,
      data: dataParam,
      params: queryParams,
      headers: {
        Authorization: await apiUtils.routingHeader(),
        "User-Time-Zone-Id": Intl.DateTimeFormat().resolvedOptions().timeZone,
        "Access-Control-Allow-Origin": "*"
      }
    }).then(req => {
      return req.data
    })
  },
  async fetchAutoSuggestedAddressesByQuery(queryValue, latitude, longitude) {
    return await this.execute("get", `/v1/address/autosuggest?query=${queryValue}&latitude=${latitude}&longitude=${longitude}`)
  },
  async fetchReverseGeocode(latitude, longitude) {
    return await this.execute("get", `/v1/address/reverse-geocode?latitude=${latitude}&longitude=${longitude}`)
  },
  async fetchAddressLookupById(id) {
    return await this.execute("get", `/v1/address/lookup?id=${id}`)
  },
  async calculateRoute(waypoints) {
    return await this.execute("post", `/v1/routing/calculateRoute/waypoints`, {waypoints: waypoints })
  }
}
