<template>
  <base-dialog :is-dialog-visible="isDialogVisible" title="Add Recipients" dialog-image="mdi-account-plus-outline">
    <template v-slot:content>
      <v-form ref="job-receipt-add-recipients-dialog-form" v-model="isValid" v-on:submit.prevent="handleSave">
        <v-row dense>
          <v-col>
            <v-text-field
              ref="job-receipt-add-recipient-dialog-email-text"
              label="Email *"
              v-model="email"
              :rules="emailRules"
              autocomplete="null"
              counter="100"
              clearable
              outlined
              validate-on-blur
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" color="primary" @click="handleCancel" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" color="primary" :disabled="isDisabled" @click="handleSave" text rounded ripple>Save</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog.vue"

export default {
  name: "JobReceiptAddRecipientsDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: "",
      emailRules: [v => /.+@.+\..+/.test((v ?? "").trim()) || "E-mail must be valid"],
      isValid: false
    }
  },
  computed: {
    isDisabled() {
      return this.email?.trim().length <= 0
    }
  },
  methods: {
    handleCancel() {
      this.$emit("cancel")
    },
    handleSave() {
      if (this.$refs["job-receipt-add-recipients-dialog-form"].validate()) {
        this.$emit("save", this.email)
      }
    }
  }
}
</script>
