let CREATED = { id: 1, name: "Created" }
let SCHEDULED = { id: 2, name: "Scheduled" }
let CLAIMED = { id: 3, name: "Claimed" }
let COMPLETED = { id: 4, name: "Completed" }
let CANCELLED = { id: 5, name: "Cancelled" }
let CLOSED = { id: 6, name: "Closed" }
// let REVISED = { id: 7, name: "Revised" }
let ABANDONED = { id: 7, name: "Abandoned" }

export const JobStatus = {
  CREATED: CREATED,
  SCHEDULED: SCHEDULED,
  CLAIMED: CLAIMED,
  COMPLETED: COMPLETED,
  CANCELLED: CANCELLED,
  CLOSED: CLOSED,
  // REVISED: REVISED,
  ABANDONED: ABANDONED
}

export const JobStatuses = [
  CREATED,
  SCHEDULED,
  CLAIMED,
  COMPLETED,
  CANCELLED,
  CLOSED,
  // REVISED,
  ABANDONED
]
