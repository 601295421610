/**
 * Colors are derived from the latest Brand Standards: http://hub.twomen.com/ho/mrkt/Documents/BrandStandards-Aug2021.pdf
 * Brand colors were utilized to generate the Material Palette depicted below. These colors were generated from the following tool: https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 */
const tmtBlue = {
  extraLight: "#e2f8fd",
  light: "#87def9",
  medium: "#00bcfb",
  pastelMedium: "#80defd",
  dark: "#0068a6"
}

const tmtBlueGray = {
  light: "#879ca8",
  medium: "#344955",
  pastelMedium: "#96A5AE",
  dark: "#1d2a31"
}

const tmtGray = {
  extraLight: "#eff1f4",
  light: "#e5e7ea",
  medium: "#555759",
  pastelMedium: "#A6ABB1",
  dark: "#37393a",
  extraDark: "#302F30"
}

const tmtGreen = {
  extraLight: "#e7f5e6",
  light: "#a1d699",
  medium: "#3dae2b",
  pastelMedium: "#9ADC90",
  dark: "#147c06"
}

const tmtOrange = {
  light: "#ffe0b2",
  medium: "#ff9900",
  pastelMedium: "#FFCC80",
  dark: "#FA8100"
}

const tmtRed = {
  light: "#fe969c",
  medium: "#ff0033",
  pastelMedium: "#FF8099",
  dark: "#d30016"
}

const tmtYellow = {
  light: "#ffe17c",
  medium: "#ffb500",
  pastelMedium: "#FFDA80",
  dark: "#ffa100"
}

const junkBlue = {
  fifty: "#e2f8fd",
  oneHundred: "#b6ebfb",
  twoHundred: "#87def9",
  threeHundred: "#57d1f8",
  fourHundred: "#29c7f9",
  fiveHundred: "#00bcfb",
  sixHundred: "#00aeec",
  sevenHundred: "#009ad9",
  eightHundred: "#0089c6",
  nineHundred: "#0068a6"
}

/**
 * This is the material complementary color palette for "Junk Blue".
 */
const junkMaterialComplementary = {
  fifty: "#fae7e5",
  oneHundred: "#fcc6b6",
  twoHundred: "#f9a287",
  threeHundred: "#f57d58",
  fourHundred: "#f36135",
  fiveHundred: "#f0430d",
  pastelFiveHundred: "#fe9e80",
  sixHundred: "#e53e09",
  sevenHundred: "#d83704",
  eightHundred: "#ca3000",
  nineHundred: "#b12200"
}

const junkBrightBlue = {
  fifty: "#0047BA",
  oneHundred: "#bae0ff",
  twoHundred: "#8ccdff",
  threeHundred: "#59b9ff",
  fourHundred: "#28a9ff",
  fiveHundred: "#009aff",
  sixHundred: "#008cff",
  sevenHundred: "#0079eb",
  eightHundred: "#0067d9",
  nineHundred: "#0047BA" // primary
}

const junkDeepBlue = {
  fifty: "#e6e9f2",
  oneHundred: "#c0c9e1",
  twoHundred: "#97a6cc",
  threeHundred: "#6e83b7",
  fourHundred: "#4e69a9",
  fiveHundred: "#2b509c",
  sixHundred: "#244893",
  sevenHundred: "#1a3f87",
  eightHundred: "#11357b",
  nineHundred: "#022465" // primary
}

const junkGray = {
  fifty: "#f7f7f7",
  oneHundred: "#eeeeee",
  twoHundred: "#e3e3e3",
  threeHundred: "#d1d1d1",
  fourHundred: "#acacac",
  fiveHundred: "#8b8b8b",
  sixHundred: "#646464",
  sevenHundred: "#515151",
  eightHundred: "#333333", // primary
  nineHundred: "#131313"
}

const vuetify = {
  light: "#FFFFFF",
  dark: "#1E1E1E"
}

const applicationColors = {
  tmtBlue,
  tmtBlueGray,
  tmtGray,
  tmtGreen,
  tmtOrange,
  tmtRed,
  tmtYellow,
  junkBlue,
  junkDeepBlue,
  junkBrightBlue,
  junkGray,
  junkMaterialComplementary,
  vuetify
}

export default applicationColors
