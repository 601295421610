<template>
  <v-card ref="settings-auth-management-users-card-root" class="elevation-4" :disabled="getIsLoadingRolesForUser">
    <v-card-title ref="settings-auth-management-users-card-title">
      <v-col class="ms-0 mb-4 me-0 mt-0 pa-0 align-self-start d-flex">
        <span id="settings-trucks-card-title-heading" class="headline text-no-wrap align-self-start d-flex pe-1">Users</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="ma-0 pa-0 d-flex flex-fill ms-0 ms-sm-4 ms-md-8 ms-lg-12 ms-xl-6 col-lg-6 col-xl-5" cols="auto">
        <v-text-field
          id="settings-auth-management-users-search-field"
          class="ma-0 mx-4 ps-4 float-end d-flex align-self-end flex-fill"
          v-model="searchText"
          :disabled="isSearchTextFieldDisabled"
          clearable="clearable"
          clear-icon="mdi-trash-can-outline"
          append-icon="mdi-magnify"
          label="Search Users"
        />
      </v-col>
    </v-card-title>
    <v-card-text class="mt-n4">
      <v-row>
        <v-col>
          <v-responsive class="overflow-y-auto" max-height="550" min-height="200">
            <v-data-table
              class="ma-0 pa-0 row-pointer"
              ref="settings-auth-management-users-card-data-table"
              :headers="settingsAuthManagementUserHeaders"
              :items="getUsersInOperatingUnit"
              :loading="getIsLoadingUsers"
              sort-by="displayName"
              mobile-breakpoint="840"
              :search="searchText"
              fixed-header
              disable-pagination
              hide-default-footer
            >
              <template v-slot:no-data>
                <v-col class="ma-0 pa-0 align-self-center d-flex">
                  <app-not-found title="No Results" content="No Users Found in the Selected Operating Unit.">
                    <template v-slot:actions><div></div></template>
                  </app-not-found>
                </v-col>
              </template>
              <template v-slot:item.email_verified="{ value }">
                <v-icon :color="determineVerificationStatus(value).color">{{ determineVerificationStatus(value).icon }}</v-icon>
              </template>
              <template v-slot:item.businessPhones="{ value }">
                <span v-for="phoneNumber in value" :key="phoneNumber">
                  {{ formatPhoneNumber(phoneNumber) }}
                </span>
              </template>
              <template v-slot:item.picture="{ item }">
                <mgt-person :fallback-details="getUserFromCache(item.id)" view="image" />
              </template>
            </v-data-table>
          </v-responsive>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import AppNotFound from "@/components/AppNotFound"
import phoneNumberFormatter from "@/utils/PhoneNumberFormatter"

export default {
  name: "SettingsAuthManagementUsersCard",
  components: { AppNotFound },
  data() {
    return {
      searchText: "",
      settingsAuthManagementUserHeaders: [
        { text: "Details", align: "start", value: "picture", sortable: false },
        { text: "Name", align: "start", value: "displayName" },
        { text: "Email", align: "start", value: "userPrincipalName" },
        { text: "Roles", align: "start", value: "roles" }
      ]
    }
  },
  methods: {
    determineVerificationStatus(status) {
      return status ? { color: "primary", icon: "mdi-check-circle-outline" } : { color: "error", icon: "mdi-close-circle-outline" }
    },
    formatPhoneNumber(phoneNum) {
      return phoneNumberFormatter.formatPhoneNumber(phoneNum)
    },
    getUserFromCache(id) {
      let rez = this.getUsersInOperatingUnit.find(c => c.id === id)
      console.log("user.rez = ", rez)
      return JSON.stringify(rez)
    }
  },
  computed: {
    ...mapGetters("Settings", ["getUsersInOperatingUnit", "getIsLoadingUsers", "getIsLoadingRolesForUser"]),
    isSearchTextFieldDisabled() {
      return this.getUsersInOperatingUnit?.length <= 0 || this.getIsLoadingUsers || this.getIsLoadingRolesForUser
    }
  }
}
</script>

<style scoped>
mgt-people {
    --avatar-size: 50px;
}
</style>
