<template>
  <div class="app-meter">
    <div v-for="i in max" :key="i" :class="{ bar: true, filled: i <= calculatedLevel }"></div>
  </div>
</template>

<script>
export default {
  name: 'AppMeter',
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: 8
    }
  },
  computed: {
    calculatedLevel() {
      return Math.round((this.value / this.max) * this.max);
    }
  }
};
</script>

<style>
.app-meter {
  display: flex;
  border: 1px solid #ccc;
  padding: 2px;
  height: 1rem;
}

.bar {
  flex: 1;
  margin: 1px;
  background-color: #eee;
  transition: background-color 0.3s;
}

.bar.filled {
  background-color: green;
}
</style>
