<template>
  <div data-cy="date-time-locale-formatter">{{ toLocalDateTime(dateTimeValue) }}</div>
</template>

<script>
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"

export default {
  name: "AppDateTimeLocaleFormatter",
  props: {
    dateTimeValue: {
      type: String,
      required: undefined
    },
    textFieldFormat: {
      type: String,
      default: "MMMM D, YYYY"
    }
  },
  methods: {
    toLocalDateTime(date) {
      if (date === undefined || date === null) return date
      dayjs.extend(localizedFormat)
      return dayjs(date).format(this.textFieldFormat)
    }
  }
}
</script>

<style scoped></style>
