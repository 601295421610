let FIRST = { id: 1, name: "First Slot" }
let AM = { id: 2, name: "AM" }
let PM = { id: 3, name: "PM" }
let NONE = { id: 4, name: "No Preference" }

export const PreferredTimeSlot = {
  FIRST: FIRST,
  AM: AM,
  PM: PM,
  NONE: NONE
}

export const PreferredTimeSlots = [
  PreferredTimeSlot.FIRST,
  PreferredTimeSlot.AM,
  PreferredTimeSlot.PM,
  PreferredTimeSlot.NONE
]
