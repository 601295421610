<template>
  <v-container fluid>
    <base-dialog ref="app-new-address-dialog-root" :is-dialog-visible="isDialogVisible" dialog-image="mdi-map-marker-plus-outline" title="Manually Create Address From Marker" @close-dialog="closeDialog">
      <template v-slot:content>
        <v-form ref="app-new-address-dialog-form" v-model="isCreateAddressFormValid">
          <v-layout row>
            <v-text-field
              ref="app-new-address-dialog-address-line-one-text"
              label="Address Line 1"
              v-model="mutableAddressLine1"
              :disabled="isAddressLine1Disabled"
              :rules="isRequiredRule"
              counter="100"
              outlined
              validate-on-blur
            />
          </v-layout>
          <v-layout row>
            <v-text-field ref="app-new-address-dialog-address-line-two-text" label="Address Line 2, Apartment/ Suite Number" counter="100" v-model="mutableAddressLine2" outlined />
          </v-layout>
          <v-layout row>
            <v-text-field
              ref="app-new-address-dialog-complex-text"
              label="Complex"
              v-model.trim="complex"
              counter="100"
              outlined
              validate-on-blur
            />
          </v-layout>
          <v-layout row>
            <v-text-field
              ref="app-new-address-dialog-city-text"
              label="City"
              v-model="mutableCity"
              :disabled="isCityDisabled"
              :rules="isRequiredRule"
              counter="100"
              outlined
              validate-on-blur
            />
          </v-layout>
          <v-layout row>
            <v-select
              ref="app-new-address-dialog-state-text"
              label="State"
              v-model="mutableState"
              :disabled="!isUserValidated"
              :items="getStateCodes"
              :menu-props="{ bottom: true, offsetY: true }"
              :rules="isRequiredRule"
              return-object
              outlined
              validate-on-blur
            />
          </v-layout>
          <v-layout row>
            <v-select
              ref="app-new-address-dialog-country-text"
              label="Country"
              v-model="mutableCountry"
              :disabled="!isUserValidated"
              :items="getCountryCodes"
              :menu-props="{ bottom: true, offsetY: true }"
              :rules="isRequiredRule"
              outlined
              validate-on-blur
            />
          </v-layout>
          <v-layout row>
            <v-text-field
              ref="app-new-address-dialog-postal-code-text"
              label="Postal Code"
              v-model="mutableZip"
              :disabled="isZipDisabled"
              :rules="isRequiredRule"
              counter="10"
              outlined
              validate-on-blur
            />
          </v-layout>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="app-new-address-dialog-cancel-btn" color="secondary" @click="closeDialog" :disabled="false" text rounded ripple>Cancel</v-btn>
        <v-btn class="pa-4" ref="app-new-address-dialog-confirm-btn" color="primary" @click="save" :loading="isLoading" :disabled="isLoading" text rounded ripple>Confirm</v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { createCustomerAddressDto } from "@/api/dtos/JunkDtos"
import { provinceCodes, stateCodes, countryCodes } from "@/enums/StateCodes"

export default {
  name: "AppNewAddressFormDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    addressLine1: {
      type: String,
      required: false
    },
    city: {
      type: String,
      required: false
    },
    state: {
      type: String,
      required: false
    },
    country: {
      type: String,
      required: false
    },
    zip: {
      type: String,
      required: false
    },
    latitude: {
      type: String,
      required: true
    },
    longitude: {
      type: String,
      required: true
    },
    isUserValidated: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isCreateAddressFormValid: false,
      mutableAddressLine1: undefined,
      mutableAddressLine2: undefined,
      mutableCity: undefined,
      mutableState: undefined,
      complex: undefined,
      mutableCountry: undefined,
      mutableZip: undefined,
      isAddressLine1Disabled: false,
      isCityDisabled: false,
      isZipDisabled: false,
      isRequiredRule: [value => (!!value && !!value.trim()) || "Is required."]
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog")
    },
    async save() {
      if (this.$refs["app-new-address-dialog-form"].validate()) {
        // if AddressLine1, City, State, Country, or Postal Code have been manipulated (ie - the props don't match the mutables, then flip isUserValidated = true, before emitting the DTO.
        let dto = createCustomerAddressDto(this.mutableAddressLine1, this.mutableAddressLine2, this.mutableCity, this.mutableState, this.mutableZip, this.mutableCountry, this.latitude, this.longitude, this.isUserValidated, this.complex) // todo: Rename createCustomerAddressDto -> createAddressDto() ???
        this.$emit("createAddress", dto)
      }
    },
    stringIsNotNullUndefinedOrEmpty(str) {
      return str !== null && str !== undefined && str?.trim()?.length > 0
    }
  },
  computed: {
    getCountryCodes() {
      return countryCodes
    },
    getStateCodes() {
      return this.mutableCountry?.toUpperCase() === "USA" ? this.getUnitedStatesStateCodes : this.getCanadaProvinceCodes
    },
    getCanadaProvinceCodes() {
      return provinceCodes
    },
    getUnitedStatesStateCodes() {
      return stateCodes
    }
  },
  created() {
    // if AddressLine1, City, State, Country, or Postal Code have been manipulated (ie - the props don't match the mutables, then flip isUserValidated = true, before emitting the DTO.
    this.isAddressLine1Disabled = this.stringIsNotNullUndefinedOrEmpty(this.addressLine1) // this.addressLine1 !== null && this.addressLine1 !== undefined && this.addressLine1?.trim()?.length > 0
    this.mutableAddressLine1 = this.addressLine1

    this.isCityDisabled = this.stringIsNotNullUndefinedOrEmpty(this.city)
    this.mutableCity = this.city

    this.mutableState = this.state

    let defaultCountryCode = this.country === undefined ? this.getCountryCodes[0] : this.country
    this.mutableCountry = defaultCountryCode

    this.isZipDisabled = this.stringIsNotNullUndefinedOrEmpty(this.zip)
    this.mutableZip = this.zip
  }
}
</script>

<style scoped></style>
