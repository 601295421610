<template>
  <span class="text-md-body-1">{{ getFormattedDecimalValue }}</span>
</template>

<script>
export default {
  name: "AppDecimalFormatter",
  props: {
    amount: {
      type: Number,
      default: 0
    },
    isPercentage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getFormattedDecimalValue() {
      let formatStyle = this.isPercentage ? "percent" : "decimal"
      let formatter = new Intl.NumberFormat("en-US", {
        style: formatStyle,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      return formatter.format(this.amount)
    }
  }
}
</script>
