import junkApi from "@/api/Junk"
import {
  errorSnackbar,
  errorSnackbarWithPositiveAction,
  persistentErrorSnackbarWithPositiveAction, successSnackbar
} from "@/utils/SnackbarBuilder"
import * as Sentry from "@sentry/vue"
import { formatAsDateOnly, isDateOnOrAfterToday, todayAsDate } from "@/utils/DateTimeFormatters"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"

export const state = {
  activeFranchiseCapacities: [],
  isLoadingJunkTrucks: false,
  isLoadingTruckUnavailability: false,
  isLoadingTruckUtilizationRates: false,
  _isLoadingFranchiseSpecialHours: false,
  inactiveFranchiseCapacities: [],
  junkTrucks: [],
  _junkTruckWeeklyAllocations: [],
  selectedFranchise: undefined,
  unavailableTrucks: [],
  vehicleFuelTypes: [],
  franchisesInOperatingUnit: [],
  usersInOperatingUnit: [],
  roles: [],
  selectedUser: {},
  franchiseTaxes: [],
  _isLoadingFranchiseBusinessHours: false,
  _franchiseSpecialHours: [],
  _isLoadingTipConfiguration: false,
  _tipConfiguration: {},
  isLoadingRolesForUser: false,
  isLoadingUsers: false,
  isLoadingRoles: false,
  isLoadingFranchiseTax: false,
  isLoadingFranchiseAddOns: false,
  isLoadingFranchiseDiscounts: false,
  _franchiseBusinessHours: [],
  _selectedFranchiseObject: {},
  _isLoadingFranchiseDistanceCutoff: false,
  _isLoadingOperatingUnitAllocationGroups: false,
  _operatingUnitAllocationGroups: [],
  _nationalDiscounts: [],
  _operatingUnitDiscounts: [],
  _franchiseElectedDiscountsForWebsite: [],
  _isLoadingDiscounts: false,
  _isLoadingFranchiseElectedDiscountsForWebsite: false,
  _isLoadingFranchisesInOperatingUnit: false
}

export const getters = {
  getIsLoadingFranchisesInOperatingUnit(state) {
    return state._isLoadingFranchisesInOperatingUnit
  },
  getIsLoadingOperatingUnitAllocationGroups(state) {
    return state._isLoadingOperatingUnitAllocationGroups
  },
  getOperatingUnitAllocationGroups(state) {
    return state._operatingUnitAllocationGroups
  },
  getIsLoadingFranchiseDistanceCutoff(state) {
    return state._isLoadingFranchiseDistanceCutoff
  },
  getSelectedFranchiseObject(state) {
    return state._selectedFranchiseObject
  },
  getFranchiseTaxes(state) {
    return state.franchiseTaxes?.slice()?.sort((a, b) => a.taxName.localeCompare(b.taxName)) ?? []
  },
  getIsLoadingFranchiseTax(state) {
    return state.isLoadingFranchiseTax
  },
  getIsLoadingFranchiseBusinessHours(state) {
    return state._isLoadingFranchiseBusinessHours
  },
  getFranchiseBusinessHours(state){
    return state._franchiseBusinessHours.slice()
  },
  getActiveAvailableTrucksInOperatingUnit(state, getters) {
    return getters.getActiveTrucksInOperatingUnitSortedByName
      ?.slice()
      .filter(activeOrFutureTruck => !state.unavailableTrucks.some(unavailableTruck => activeOrFutureTruck.id === unavailableTruck.junkTruckId))
  },
  getActiveTrucksInOperatingUnitSortedByName(state) {
    return state.junkTrucks
      .slice()
      .filter(jt =>
        formatAsDateOnly(jt.beginDateTime) <= todayAsDate() &&
        (formatAsDateOnly(jt.endDateTime) ?? todayAsDate()) >= todayAsDate())
      .sort((a, b) => a.name.localeCompare(b.name))
  },
  getActiveAndFutureTrucksInOperatingUnitSortedByName(state) {
    return state.junkTrucks
      .slice()
      .filter(jt => (formatAsDateOnly(jt.endDateTime) ?? todayAsDate()) >= todayAsDate())
      .sort((a, b) => a.name.localeCompare(b.name))
  },
  getAllTrucksInOperatingUnitSortedByName(state) {
    return state.junkTrucks.slice().sort((a, b) => a.name.localeCompare(b.name))
  },
  getPastTrucksInOperatingUnit(state) {
    return state.junkTrucks.slice().filter(jt =>
      formatAsDateOnly(jt.beginDateTime) < todayAsDate() &&
      (formatAsDateOnly(jt.endDateTime) ?? todayAsDate()) < todayAsDate()
    )
  },
  getPastUnavailableTrucks(state) {
    return state.unavailableTrucks.slice().filter(ut =>
      formatAsDateOnly(ut.beginDateTime) < todayAsDate() &&
      (formatAsDateOnly(ut.endDateTime) ?? todayAsDate()) < todayAsDate()
    )
  },
  getActiveAndFutureUnavailableTrucks(state) {
    return state.unavailableTrucks.slice().filter(jt => (formatAsDateOnly(jt.endDateTime) ?? todayAsDate()) >= todayAsDate())
  },
  getAllUnavailableTrucks(state) {
    return state.unavailableTrucks?.slice()
  },
  getAllInactiveFranchiseCapacities(state) {
    return state.inactiveFranchiseCapacities?.slice()
  },
  getAllActiveFranchiseCapacities(state) {
    return state.activeFranchiseCapacities?.slice()
  },
  getFranchisesInOperatingUnit(state) {
    return state.franchisesInOperatingUnit?.slice()
  },
  getSelectedFranchise(state) {
    return state.selectedFranchise
  },
  getJunkTrucksLoadingState(state) {
    return state.isLoadingJunkTrucks
  },
  getTruckUnavailabilityLoadingState(state) {
    return state.isLoadingTruckUnavailability
  },
  getTruckUtilizationRatesLoadingState(state) {
    return state.isLoadingTruckUtilizationRates
  },
  getVehicleFuelTypes(state) {
    return state.vehicleFuelTypes?.slice()
  },
  getUsersInOperatingUnit(state) {
    return state.usersInOperatingUnit?.slice()
  },
  getRoles(state) {
    return state.roles.slice()
  },
  getSelectedUser(state) {
    return state.selectedUser
  },
  getIsLoadingUsers(state) {
    return state.isLoadingUsers
  },
  getIsLoadingRoles(state) {
    return state.isLoadingRoles
  },
  getIsLoadingRolesForUser(state) {
    return state.isLoadingRolesForUser
  },
  getWeeklyAllocations(state) {
    return state._junkTruckWeeklyAllocations.slice()
  },
  getIsLoadingFranchiseSpecialHours(state) {
    return state._isLoadingFranchiseSpecialHours
  },
  getFranchiseSpecialHours(state) {
    return state._franchiseSpecialHours.slice()
  },
  getIsLoadingTipConfiguration(state) {
    return state._isLoadingTipConfiguration
  },
  getTipConfiguration(state) {
    return state._tipConfiguration
  },
  getNationalDiscounts(state) {
    return state._nationalDiscounts
  },
  getActiveAndFutureNationalDiscounts(state) {
    return state._nationalDiscounts.map(item => {
      const activeFranchiseElectedDiscounts = item.franchiseElectedDiscounts.filter(discount =>
        isDateOnOrAfterToday(discount.endDate)
      )
      return { ...item, franchiseElectedDiscounts: activeFranchiseElectedDiscounts }
    })
  },
  getOperatingUnitDiscounts(state) {
    return state._operatingUnitDiscounts
  },
  getActiveAndFutureOperatingUnitDiscounts(state) {
    return state._operatingUnitDiscounts.map(item => {
      const activeFranchiseElectedDiscounts = item.franchiseElectedDiscounts.filter(discount =>
        isDateOnOrAfterToday(discount.endDate)
      )
      return { ...item, franchiseElectedDiscounts: activeFranchiseElectedDiscounts }
    })
  },
  getFranchiseElectedDiscountsForWebsite(state) {
    return state._franchiseElectedDiscountsForWebsite
  },
  getIsLoadingDiscounts(state) {
    return state._isLoadingDiscounts
  },
  getIsLoadingFranchiseElectedDiscountsForWebsite(state) {
    return state._isLoadingFranchiseElectedDiscountsForWebsite
  }
}

export const mutations = {
  SET_NATIONAL_DISCOUNTS(state, discounts) {
    state._nationalDiscounts.splice(0, state._nationalDiscounts?.length ?? 0, ...discounts)
  },
  UPDATE_NATIONAL_DISCOUNT(state, discount) {
    let index = state._nationalDiscounts.findIndex(d => d.nationalDiscountId === discount.nationalDiscountId)
    if (~index) {
      state._nationalDiscounts.splice(index, 1, discount)
    }
  },
  ADD_OPERATING_UNIT_DISCOUNT(state, discount) {
    state._operatingUnitDiscounts.push(discount)
  },
  SET_OPERATING_UNIT_DISCOUNTS(state, discounts) {
    state._operatingUnitDiscounts.splice(0, state._operatingUnitDiscounts?.length ?? 0, ...discounts)
  },
  UPDATE_OPERATING_UNIT_DISCOUNT(state, discount) {
    let index = state._operatingUnitDiscounts.findIndex(d => d.operatingUnitDiscountId === discount.operatingUnitDiscountId)
    if (~index) {
      state._operatingUnitDiscounts.splice(index, 1, discount)
    }
  },
  UPDATE_FRANCHISE_ELECTED_OPERATING_UNIT_DISCOUNT(state, franchiseElectedDiscount) {
    let index = state._operatingUnitDiscounts.findIndex(d => d.operatingUnitDiscountId === franchiseElectedDiscount.discountId)
    if (~index) {
      let franchiseIndex = state._operatingUnitDiscounts[index].franchiseElectedDiscounts.findIndex(f => f.franchiseElectedDiscountId === franchiseElectedDiscount.franchiseElectedDiscountId)
      if (~franchiseIndex) {
        state._operatingUnitDiscounts[index].franchiseElectedDiscounts.splice(franchiseIndex, 1, franchiseElectedDiscount)
      }
    }
  },
  DELETE_FRANCHISE_ELECTED_OPERATING_UNIT_DISCOUNT(state, discount) {
    let index = state._operatingUnitDiscounts.findIndex(d => d.operatingUnitDiscountId === discount.discountId)
    if (~index) {
      let franchiseElectedDiscountIndex = state._operatingUnitDiscounts[index].franchiseElectedDiscounts.findIndex(f => f.franchiseElectedDiscountId === discount.franchiseElectedDiscountId)
      if (~franchiseElectedDiscountIndex) {
        state._operatingUnitDiscounts[index].franchiseElectedDiscounts.splice(franchiseElectedDiscountIndex, 1)
      }
    }
  },
  ADD_FRANCHISE_ELECTED_DISCOUNT_FOR_WEBSITE(state, discount) {
    const index = state._franchiseElectedDiscountsForWebsite.findIndex(d => d.franchiseElectedDiscountId === discount.franchiseElectedDiscountId)
    if (~index) {
      state._franchiseElectedDiscountsForWebsite[index].franchiseElectedDiscountsForWebsite.push(discount)
    }
  },
  SET_FRANCHISE_ELECTED_DISCOUNTS_FOR_WEBSITE(state, discounts) {
    state._franchiseElectedDiscountsForWebsite.splice(0, state._franchiseElectedDiscountsForWebsite?.length ?? 0, ...discounts)
  },
  UPDATE_FRANCHISE_ELECTED_DISCOUNT_FOR_WEBSITE(state, franchiseElectedDiscountForWebsite) {
    let index = state._franchiseElectedDiscountsForWebsite.findIndex(d => d.franchiseElectedDiscountId === franchiseElectedDiscountForWebsite.franchiseElectedDiscountId)
    if (~index) {
      let electedDiscountForWebsiteIndex = state._franchiseElectedDiscountsForWebsite[index].franchiseElectedDiscountsForWebsite.findIndex(f => f.franchiseElectedDiscountForWebsiteId === franchiseElectedDiscountForWebsite.franchiseElectedDiscountForWebsiteId)
      if (~index) {
        state._franchiseElectedDiscountsForWebsite[index].franchiseElectedDiscountsForWebsite.splice(electedDiscountForWebsiteIndex, 1, franchiseElectedDiscountForWebsite)
      }
    }
  },
  REMOVE_FRANCHISE_ELECTED_DISCOUNT_FOR_WEBSITE(state, discount) {
    let index = state._franchiseElectedDiscountsForWebsite.findIndex(d => d.franchiseElectedDiscountId === discount.franchiseElectedDiscountId)
    if (~index) {
      let electedDiscountForWebsiteIndex = state._franchiseElectedDiscountsForWebsite[index].franchiseElectedDiscountsForWebsite.findIndex(f => f.franchiseElectedDiscountForWebsiteId === discount.franchiseElectedDiscountForWebsiteId)
      if (~electedDiscountForWebsiteIndex) {
        state._franchiseElectedDiscountsForWebsite[index].franchiseElectedDiscountsForWebsite.splice(electedDiscountForWebsiteIndex, 1)
      }
    }
  },
  SET_IS_LOADING_FRANCHISE_ELECTED_DISCOUNTS_FOR_WEBSITE(state, isLoading) {
    state._isLoadingFranchiseElectedDiscountsForWebsite = isLoading
  },
  SET_IS_LOADING_DISCOUNTS(state, isLoading) {
    state._isLoadingDiscounts = isLoading
  },
  SET_IS_LOADING_FRANCHISE_DISTANCE_CUTOFF(state, isLoading) {
    state._isLoadingFranchiseDistanceCutoff = isLoading
  },
  SET_FRANCHISE_CUSTOMER_SELF_BOOKING_DISTANCE_CUTOFF(state, cutOffDistance) {
    const franchise = state.franchisesInOperatingUnit.find(f => f.franchiseId === state._selectedFranchiseObject.franchiseId)
    if (franchise) {
      franchise.selfBookingCutoffDistance = cutOffDistance
    }
    state._selectedFranchiseObject = Object.assign(
      {},
      {
        ...state._selectedFranchiseObject,
        selfBookingCutoffDistance: cutOffDistance
      }
    )
  },
  ADD_FRANCHISE_SPECIAL_HOUR(state, specialHour) {
    state._franchiseSpecialHours.push(specialHour)
  },
  ADD_JUNK_TRUCK(state, junkTruck) {
    state.junkTrucks = state.junkTrucks.filter(jt => {
      return jt.id !== junkTruck.id
    })
    state.junkTrucks.push(junkTruck)
  },
  ADD_JUNK_TRUCK_UNAVAILABILITY(state, unavailableJunkTruck) {
    state.unavailableTrucks = state.unavailableTrucks.filter(jt => {
      return jt.id !== unavailableJunkTruck.id
    })
    state.unavailableTrucks.push(unavailableJunkTruck)
  },
  SET_ACTIVE_FRANCHISE_CAPACITIES(state, activeFranchiseCapacities) {
    state.activeFranchiseCapacities = activeFranchiseCapacities
  },
  SET_INACTIVE_FRANCHISE_CAPACITIES(state, inActiveFranchiseCapacities) {
    state.inactiveFranchiseCapacities = inActiveFranchiseCapacities
  },
  SET_WEEKLY_ALLOCATION(state, data) {
    let index = state._junkTruckWeeklyAllocations.findIndex(sa => sa.junkTruckWeeklyAllocationId === data.junkTruckWeeklyAllocationId)
    if (~index) {
      state._junkTruckWeeklyAllocations.splice(index, 1, data)
    } else {
      let idx = state._junkTruckWeeklyAllocations.findIndex(sa => sa.dayOfWeekId === data.dayOfWeekId && sa.junkTruckId === data.junkTruckId)
      state._junkTruckWeeklyAllocations.splice(idx, 1, data)
    }
  },
  ADD_NEW_JUNK_TRUCK_WEEKLY_ALLOCATIONS(state, data) {
    data.forEach(weeklyAllocation => {
      state._junkTruckWeeklyAllocations.push(weeklyAllocation)
    })
  },
  SET_JUNK_TRUCKS_WEEKLY_ALLOCATIONS(state, weeklyAllocations) {
    state._junkTruckWeeklyAllocations = weeklyAllocations
  },
  SET_IS_LOADING_JUNK_TRUCKS(state, isLoading) {
    state.isLoadingJunkTrucks = isLoading
  },
  SET_IS_LOADING_TRUCK_UNAVAILABILITY(state, isLoading) {
    state.isLoadingTruckUnavailability = isLoading
  },
  SET_IS_LOADING_TRUCK_UTILIZATION_RATES(state, isLoading) {
    state.isLoadingTruckUtilizationRates = isLoading
  },
  SET_IS_LOADING_TIP_CONFIGURATION(state, isLoading) {
    state._isLoadingTipConfiguration = isLoading
  },
  SET_TIP_CONFIGURATION(state, tipConfiguration) {
    state._tipConfiguration = Object.assign({}, tipConfiguration)
  },
  SET_JUNK_TRUCKS(state, junkTrucks) {
    state.junkTrucks = junkTrucks
  },
  SET_UNAVAILABLE_TRUCKS(state, unavailableJunkTrucks) {
    state.unavailableTrucks = unavailableJunkTrucks
  },
  REMOVE_UNAVAILABLE_TRUCK(state, truckId) {
    let index = state.unavailableTrucks.findIndex(c => c.id === truckId)
    if (~index) {
      state.unavailableTrucks.splice(index, 1)
    }
  },
  SET_SELECTED_FRANCHISE(state, franchiseId) {
    state.selectedFranchise = franchiseId
  },
  SET_SELECTED_FRANCHISE_OBJECT(state, franchise) {
    state._selectedFranchiseObject = Object.assign({}, franchise)
  },
  SET_FRANCHISES_IN_OPERATING_UNIT(state, franchises) {
    state.franchisesInOperatingUnit = franchises
  },
  SET_VEHICLE_FUEL_TYPES(state, vehicleFuelTypes) {
    state.vehicleFuelTypes = vehicleFuelTypes
  },
  SET_SELECTED_USER(state, user) {
    state.selectedUser = Object.assign({}, user)
  },
  SET_USERS_IN_OPERATING_UNIT(state, usersInOperatingUnit) {
    state.usersInOperatingUnit = usersInOperatingUnit
  },
  SET_ROLES(state, roles) {
    state.roles = roles
  },
  SET_IS_LOADING_USERS(state, isLoading) {
    state.isLoadingUsers = isLoading
  },
  SET_IS_LOADING_ROLES(state, isLoading) {
    state.isLoadingRoles = isLoading
  },
  SET_IS_LOADING_ROLES_FOR_USER(state, isLoading) {
    state.isLoadingRolesForUser = isLoading
  },
  SET_FRANCHISE_TAXES(state, taxes) {
    state.franchiseTaxes = taxes
  },
  SET_IS_LOADING_FRANCHISE_BUSINESS_HOURS(state, isLoading) {
    state._isLoadingFranchiseBusinessHours = isLoading
  },
  SET_FRANCHISE_BUSINESS_HOURS(state, franchiseBusinessHours) {
    state._franchiseBusinessHours = franchiseBusinessHours
  },
  REPLACE_FRANCHISE_BUSINESS_HOUR(state, franchiseBusinessHour) {
    const idx = state._franchiseBusinessHours.findIndex(c => c.franchiseBusinessHourId === franchiseBusinessHour.franchiseBusinessHourId)
    if (~idx) {
      state._franchiseBusinessHours.splice(idx, 1, franchiseBusinessHour)
    }
  },
  REPLACE_FRANCHISE_SPECIAL_HOUR(state, franchiseBusinessHour) {
    const idx = state._franchiseSpecialHours.findIndex(c => c.franchiseSpecialHourId === franchiseBusinessHour.franchiseSpecialHourId)
    if (~idx) {
      state._franchiseSpecialHours.splice(idx, 1, franchiseBusinessHour)
    }
  },
  ADD_FRANCHISE_TAX(state, tax) {
    state.franchiseTaxes.push(tax)
  },
  UPDATE_FRANCHISE_TAX(state, tax) {
    state.franchiseTaxes = state.franchiseTaxes.filter(ft => {
      return ft.taxId !== tax.taxId
    })
    state.franchiseTaxes.push(tax)
  },
  SET_IS_LOADING_FRANCHISE_TAX(state, isLoading) {
    state.isLoadingFranchiseTax = isLoading
  },
  SET_IS_LOADING_FRANCHISE_SPECIAL_HOURS(state, isLoading) {
    state._isLoadingFranchiseSpecialHours = isLoading
  },
  SET_FRANCHISE_SPECIAL_HOURS(state, specialHours) {
    state._franchiseSpecialHours = specialHours
  },
  DELETE_FRANCHISE_SPECIAL_HOUR(state, id) {
    let index = state._franchiseSpecialHours.findIndex(c => c.franchiseSpecialHourId === id)
    if (~index) {
      state._franchiseSpecialHours.splice(index, 1)
    }
  },
  SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS(state, isLoading) {
    state._isLoadingOperatingUnitAllocationGroups = isLoading
  },
  SET_OPERATING_UNIT_ALLOCATION_GROUPS(state, operatingUnitAllocationGroups) {
    state._operatingUnitAllocationGroups = operatingUnitAllocationGroups
  },
  ADD_OPERATING_UNIT_ALLOCATION_GROUP(state, operatingUnitAllocationGroup) {
    state._operatingUnitAllocationGroups.push(operatingUnitAllocationGroup)
  },
  REMOVE_OPERATING_UNIT_ALLOCATION_GROUP(state, id) {
    state._operatingUnitAllocationGroups = state._operatingUnitAllocationGroups.filter(s => {
      return s.operatingUnitAllocationGroupId !== id
    })
  },
  REPLACE_OPERATING_UNIT_ALLOCATION_GROUP(state, operatingUnitAllocationGroup) {
    let index = state._operatingUnitAllocationGroups.findIndex(o => o.operatingUnitAllocationGroupId === operatingUnitAllocationGroup.operatingUnitAllocationGroupId)
    if (~index) {
      state._operatingUnitAllocationGroups.splice(index, 1, operatingUnitAllocationGroup)
    }
  },
  SET_IS_LOADING_FRANCHISES_IN_OPERATING_UNIT(state, isLoading) {
    state._isLoadingFranchisesInOperatingUnit = isLoading
  }
}

export const actions = {
  async fetchElectedDiscountsForWebsiteByFranchiseId({ commit }, franchiseId) { // ForWebsite
    commit("SET_IS_LOADING_FRANCHISE_ELECTED_DISCOUNTS_FOR_WEBSITE", true)
    commit("SET_FRANCHISE_ELECTED_DISCOUNTS_FOR_WEBSITE", [])
    await junkApi
      .fetchFranchiseElectedDiscountsForWebsiteByFranchiseId(franchiseId) // replace with fetch all
      .then(franchiseElectedNationalDiscounts => {
        commit("SET_FRANCHISE_ELECTED_DISCOUNTS_FOR_WEBSITE", franchiseElectedNationalDiscounts)
        return Promise.resolve(franchiseElectedNationalDiscounts)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_FRANCHISE_ELECTED_DISCOUNTS_FOR_WEBSITE", false))
  },
  async fetchNationalDiscountsWithElectedFranchisesByOperatingUnitId({ commit }, operatingUnitId) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    commit("SET_NATIONAL_DISCOUNTS", [])
    await junkApi
      .fetchNationalDiscountsWithElectedFranchisesByOperatingUnitId(operatingUnitId)
      .then(response => {
        commit("SET_NATIONAL_DISCOUNTS", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async associateFranchisesToNationalDiscount({ commit }, dto) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    await junkApi
      .addFranchisesToNationalDiscount(dto)
      .then(response => {
        commit("UPDATE_NATIONAL_DISCOUNT", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async associateFranchisesToOperatingUnitDiscount({ commit }, dto) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    await junkApi
      .addFranchisesToOperatingUnitDiscount(dto)
      .then(response => {
        commit("UPDATE_OPERATING_UNIT_DISCOUNT", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async updateOperatingUnitDiscount({ commit }, dto) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    await junkApi
      .updateOperatingUnitDiscount(dto)
      .then(response => {
        commit("UPDATE_OPERATING_UNIT_DISCOUNT", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async updateOperatingUnitFranchiseElectedDiscount({ commit }, dto) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    await junkApi
      .updateOperatingUnitFranchiseElectedDiscount(dto)
      .then(response => {
        commit("UPDATE_FRANCHISE_ELECTED_OPERATING_UNIT_DISCOUNT", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async deleteOperatingUnitFranchiseElectedDiscount({ commit }, discountId) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    await junkApi
      .deleteOperatingUnitFranchiseElectedDiscountByDiscountId(discountId)
      .then(response => {
        commit("DELETE_FRANCHISE_ELECTED_OPERATING_UNIT_DISCOUNT", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async createOperatingUnitDiscount({ commit }, createDiscountsDto) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    await junkApi
      .createOperatingUnitDiscount(createDiscountsDto)
      .then(response => {
        commit("ADD_OPERATING_UNIT_DISCOUNT", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async fetchOperatingUnitDiscounts({ commit }, operatingUnitId) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    commit("SET_OPERATING_UNIT_DISCOUNTS", [])
    await junkApi
      .fetchOperatingUnitDiscounts(operatingUnitId)
      .then(response => {
        commit("SET_OPERATING_UNIT_DISCOUNTS", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async createElectedDiscountForWebsite({ commit }, createDiscountsDto) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    await junkApi
      .createFranchiseElectedDiscountForWebsite(createDiscountsDto)
      .then(response => {
        commit("ADD_FRANCHISE_ELECTED_DISCOUNT_FOR_WEBSITE", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async updateElectedDiscountForWebsite({ commit }, putDiscountsDto) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    await junkApi.updateFranchiseElectedDiscountForWebsite(putDiscountsDto)
      .then(response => {
        commit("UPDATE_FRANCHISE_ELECTED_DISCOUNT_FOR_WEBSITE", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async deleteElectedDiscountForWebsite({ commit }, discount) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    await junkApi.deleteFranchiseElectedDiscountForWebsiteById(discount.franchiseElectedDiscountForWebsiteId)
      .then(response => {
        commit("REMOVE_FRANCHISE_ELECTED_DISCOUNT_FOR_WEBSITE", discount)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async resetSelectedUser({ commit }) {
    commit("SET_SELECTED_USER", {})
  },
  async resetFranchiseData({ commit }) {
    commit("SET_SELECTED_FRANCHISE", undefined)
    commit("SET_SELECTED_FRANCHISE_OBJECT", {})
    commit("SET_INACTIVE_FRANCHISE_CAPACITIES", [])
    commit("SET_ACTIVE_FRANCHISE_CAPACITIES", [])
    commit("SET_FRANCHISE_TAXES", [])
    commit("SET_FRANCHISE_BUSINESS_HOURS", [])
    commit("SET_FRANCHISE_SPECIAL_HOURS", [])
    commit("SET_FRANCHISE_ELECTED_DISCOUNTS_FOR_WEBSITE", [])
    commit("SET_TIP_CONFIGURATION", {})
  },
  async fetchActiveFranchisesInOperatingUnit({ commit, dispatch }, operatingUnitId) {
    commit("SET_IS_LOADING_FRANCHISES_IN_OPERATING_UNIT", true)
    return await junkApi
      .fetchActiveFranchisesInOperatingUnit(operatingUnitId)
      .then(franchises => {
        commit("SET_FRANCHISES_IN_OPERATING_UNIT", franchises)
        if (franchises.length === 1) {
          const franchiseId = franchises[0].franchiseId
          commit("SET_SELECTED_FRANCHISE", franchiseId)
          commit("SET_SELECTED_FRANCHISE_OBJECT", franchises[0])
          dispatch("fetchFranchiseCapacitiesByFranchiseId", franchiseId)
          dispatch("fetchFranchiseBusinessHoursByFranchiseId", franchiseId)
          dispatch("fetchFranchiseSpecialHoursByFranchiseId", franchiseId)
          dispatch("fetchElectedDiscountsForWebsiteByFranchiseId", franchiseId)
          dispatch("fetchTipConfigurationByFranchiseId", franchiseId)
        }
        return Promise.resolve(franchises)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Franchises in Operating Unit! ${errorMessage}`, "Retry", () =>
            this.dispatch("Settings/fetchActiveFranchisesInOperatingUnit", operatingUnitId)
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_FRANCHISES_IN_OPERATING_UNIT", false))
  },
  async setUsersInOperatingUnits({ commit }, users) {
    commit("SET_USERS_IN_OPERATING_UNIT", users)
  },
  async setAllRoles({ commit }, roles) {
    commit("SET_ROLES", roles)
  },
  async fetchFranchiseBusinessHoursByFranchiseId({ commit }, franchiseId) {
    commit("SET_IS_LOADING_FRANCHISE_BUSINESS_HOURS", true)
    commit("SET_FRANCHISE_BUSINESS_HOURS", [])
    return await junkApi
      .fetchFranchiseBusinessHours(franchiseId)
      .then(data => {
        commit("SET_FRANCHISE_BUSINESS_HOURS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          errorSnackbarWithPositiveAction(`Error: ${errorMessage}`, "Retry", () => this.dispatch("settings/fetchFranchiseBusinessHoursByFranchiseId", franchiseId)),
          { root: true }
        )
        Sentry.captureException(error.response.data)
        return Promise.reject(error.response.data)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_BUSINESS_HOURS", false)
      })
  },
  async updateWeeklyTruckAllocation({ commit }, junkTruckWeeklyAllocationDto) {
    commit("SET_IS_LOADING_JUNK_TRUCKS", true)
    return await junkApi
      .updateWeeklyTruckAllocation(junkTruckWeeklyAllocationDto)
      .then(data => {
        commit("SET_WEEKLY_ALLOCATION", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Truck Allocation! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JUNK_TRUCKS", false)
      })
  },
  async createJunkTruck({ commit }, junkTruckDto) {
    commit("SET_IS_LOADING_JUNK_TRUCKS", true)
    return await junkApi
      .createJunkTruck(junkTruckDto.operatingUnitId, junkTruckDto)
      .then(data => {
        commit("ADD_JUNK_TRUCK", data)
        commit("ADD_NEW_JUNK_TRUCK_WEEKLY_ALLOCATIONS", data.junkTruckWeeklyAllocationDtos)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Creating Truck! ${errorMessage}`), { root: true })
        Sentry.captureException(error.response.data)
        return Promise.reject(error.response.data)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JUNK_TRUCKS", false)
      })
  },
  async createFranchiseSpecialHour({ commit }, franchiseSpecialHourDto) {
    commit("SET_IS_LOADING_FRANCHISE_SPECIAL_HOURS", true)
    return await junkApi
      .createFranchiseSpecialHour(franchiseSpecialHourDto)
      .then(data => {
        commit("ADD_FRANCHISE_SPECIAL_HOUR", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Creating Franchise Special Hour! ${errorMessage}`), { root: true })
        Sentry.captureException(error.response.data)
        return Promise.reject(error.response.data)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_SPECIAL_HOURS", false)
      })
  },
  async fetchFranchiseTaxes({ commit }, operatingUnitId) {
    commit("SET_IS_LOADING_FRANCHISE_TAX", true)
    return await junkApi
      .fetchFranchiseTaxesByOperatingUnitId(operatingUnitId)
      .then(data => {
        commit("SET_FRANCHISE_TAXES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_TAX", false)
      })
  },
  async createFranchiseTax({ commit }, franchiseTaxDto) {
    commit("SET_IS_LOADING_FRANCHISE_TAX", true)
    return await junkApi
      .createFranchiseTax(franchiseTaxDto)
      .then(data => {
        commit("ADD_FRANCHISE_TAX", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_TAX", false)
      })
  },
  async endDateFranchiseTax({ commit }, putFranchiseTaxEndDateDto) {
    commit("SET_IS_LOADING_FRANCHISE_TAX", true)
    return await junkApi
      .putEndDateFranchiseTax(putFranchiseTaxEndDateDto)
      .then(data => {
        commit("UPDATE_FRANCHISE_TAX", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_TAX", false)
      })
  },
  async addFranchiseTaxAssociation({ commit }, associateTaxToFranchisesDto) {
    commit("SET_IS_LOADING_FRANCHISE_TAX", true)
    return await junkApi
      .addFranchiseTaxAssociation(associateTaxToFranchisesDto)
      .then(data => {
        commit("UPDATE_FRANCHISE_TAX", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_TAX", false)
      })
  },
  async fetchJunkTrucksByOperatingUnitIds({ commit }, operatingUnitIds) {
    commit("SET_IS_LOADING_JUNK_TRUCKS", true)
    commit("SET_JUNK_TRUCKS", [])
    commit("SET_JUNK_TRUCKS_WEEKLY_ALLOCATIONS", [])
    return await junkApi
      .fetchJunkTrucksByOperatingUnitIds(operatingUnitIds)
      .then(data => {
        if (data === "") commit("SET_JUNK_TRUCKS", []) // todo: 204
        else {
          commit("SET_JUNK_TRUCKS", data)
          commit("SET_JUNK_TRUCKS_WEEKLY_ALLOCATIONS", data.flatMap(d => d.junkTruckWeeklyAllocationDtos))
        }
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          errorSnackbarWithPositiveAction(`Error Fetching Trucks! ${errorMessage}`, "Retry", () => this.dispatch("settings/fetchJunkTrucksByOperatingUnitIds", operatingUnitIds)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JUNK_TRUCKS", false)
      })
  },
  async updateFranchiseBusinessHours({ commit }, putFranchiseBusinessHourDto){
    commit("SET_IS_LOADING_FRANCHISE_BUSINESS_HOURS", true)
    return await junkApi
      .updateFranchiseBusinessHour(putFranchiseBusinessHourDto)
      .then(data => {
        commit("REPLACE_FRANCHISE_BUSINESS_HOUR", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Business Hours! ${errorMessage}`), { root: true })
        Sentry.captureException(error.response.data)
        return Promise.reject(error.response.data)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_BUSINESS_HOURS", false)
      })
  },
  async updateFranchiseSpecialHour({ commit }, putFranchiseSpecialHourDto){
    commit("SET_IS_LOADING_FRANCHISE_SPECIAL_HOURS", true)
    return await junkApi
      .updateFranchiseSpecialHour(putFranchiseSpecialHourDto)
      .then(data => {
        commit("REPLACE_FRANCHISE_SPECIAL_HOUR", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Special Hours! ${errorMessage}`), { root: true })
        Sentry.captureException(error.response.data)
        return Promise.reject(error.response.data)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_SPECIAL_HOURS", false)
      })
  },
  async deleteFranchiseSpecialHour({ commit }, franchiseSpecialHourId){
    commit("SET_IS_LOADING_FRANCHISE_SPECIAL_HOURS", true)
    return await junkApi
      .deleteFranchiseSpecialHour(franchiseSpecialHourId)
      .then(data => {
        commit("DELETE_FRANCHISE_SPECIAL_HOUR", franchiseSpecialHourId)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Deleting Special Hours! ${errorMessage}`), { root: true })
        Sentry.captureException(error.response.data)
        return Promise.reject(error.response.data)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_SPECIAL_HOURS", false)
      })
  },
  async updateJunkTruck({ commit }, putJunkTruckDto) {
    commit("SET_IS_LOADING_JUNK_TRUCKS", true)
    return await junkApi
      .updateJunkTruck(putJunkTruckDto)
      .then(data => {
        commit("ADD_JUNK_TRUCK", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Updating Truck! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_JUNK_TRUCKS", false)
      })
  },
  async fetchVehicleFuelTypes({ commit }) {
    commit("SET_IS_LOADING", true, { root: true })
    return await junkApi
      .fetchVehicleFuelTypes()
      .then(data => {
        commit("SET_VEHICLE_FUEL_TYPES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Fuel Types! ${errorMessage}`, "Retry", () => this.dispatch("settings/fetchVehicleFuelTypes")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING", false, { root: true })
      })
  },
  async fetchFranchiseSpecialHoursByFranchiseId({ commit }, franchiseId) {
    commit("SET_IS_LOADING_FRANCHISE_SPECIAL_HOURS", true)
    return await junkApi
      .fetchFranchiseSpecialHoursByFranchiseId(franchiseId)
      .then(data => {
        commit("SET_FRANCHISE_SPECIAL_HOURS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          errorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("settings/fetchFranchiseSpecialHoursByFranchiseId", franchiseId)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_SPECIAL_HOURS", false)
      })
  },
  async fetchFranchiseCapacitiesByFranchiseId({ commit }, franchiseId) {
    commit("SET_IS_LOADING_TRUCK_UTILIZATION_RATES", true)
    commit("SET_INACTIVE_FRANCHISE_CAPACITIES", [])
    commit("SET_ACTIVE_FRANCHISE_CAPACITIES", [])
    return await junkApi
      .fetchFranchiseCapacitiesByFranchiseId(franchiseId)
      .then(data => {
        commit("SET_INACTIVE_FRANCHISE_CAPACITIES", data.inactiveFranchiseCapacityDtos)
        commit("SET_ACTIVE_FRANCHISE_CAPACITIES", data.activeFranchiseCapacityDtos)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          errorSnackbarWithPositiveAction(`Error Fetching Capacities! ${errorMessage}`, "Retry", () => this.dispatch("settings/fetchFranchiseCapacitiesByFranchiseId", franchiseId)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_TRUCK_UTILIZATION_RATES", false)
      })
  },
  async upsertFranchiseCapacity({ commit }, franchiseCapacity) {
    commit("SET_IS_LOADING_TRUCK_UTILIZATION_RATES", true)
    return await junkApi
      .upsertFranchiseCapacity(franchiseCapacity)
      .then(data => {
        commit("SET_INACTIVE_FRANCHISE_CAPACITIES", data.inactiveFranchiseCapacityDtos)
        commit("SET_ACTIVE_FRANCHISE_CAPACITIES", data.activeFranchiseCapacityDtos)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error updating franchise capacity! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_TRUCK_UTILIZATION_RATES", false)
      })
  },
  async fetchTipConfigurationByFranchiseId({ commit }, franchiseId) {
    commit("SET_IS_LOADING_TIP_CONFIGURATION", true)
    return await junkApi
      .fetchFranchiseTipConfigurationByFranchiseId(franchiseId)
      .then(data => {
        commit("SET_TIP_CONFIGURATION", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR",
          errorSnackbarWithPositiveAction(`Error Fetching Tip Configuration! ${errorMessage}`, "Retry", () => this.dispatch("settings/fetchTipConfigurationByFranchiseId", franchiseId)),
          { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_TIP_CONFIGURATION", false)
      })
  },
  updateTipConfiguration({ commit, getters }, tipConfig) {
    const updatedTipConfiguration = {...getters.getTipConfiguration, ...tipConfig}
    commit("SET_TIP_CONFIGURATION", updatedTipConfiguration)
  },
  async upsertTipConfiguration({ commit }, tipConfiguration) {
    commit("SET_IS_LOADING_TIP_CONFIGURATION", true)
    return await junkApi
      .upsertFranchiseTipConfiguration(tipConfiguration)
      .then(data => {
        commit("SET_TIP_CONFIGURATION", data)
        commit("ADD_SNACKBAR", successSnackbar("Tip configuration updated!"), { root: true })
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error updating tip configuration! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_TIP_CONFIGURATION", false)
      })
  },
  async fetchUnavailableTrucksByOperatingUnitIds({ commit }, operatingUnitIds) {
    commit("SET_IS_LOADING_TRUCK_UNAVAILABILITY", true)
    commit("SET_UNAVAILABLE_TRUCKS", [])
    return await junkApi
      .fetchUnavailableTrucksByOperatingUnitIds(operatingUnitIds)
      .then(data => {
        if (data === "") commit("SET_UNAVAILABLE_TRUCKS", []) // todo: 204
        else commit("SET_UNAVAILABLE_TRUCKS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          errorSnackbarWithPositiveAction(`Error fetching unavailable trucks! ${errorMessage}`, "Retry", () => this.dispatch("settings/fetchUnavailableTrucksByOperatingUnitIds", operatingUnitIds)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_TRUCK_UNAVAILABILITY", false)
      })
  },
  async createTruckUnavailability({ commit }, postJunkTruckUnavailabilityDto) {
    commit("SET_IS_LOADING_TRUCK_UNAVAILABILITY", true)
    return await junkApi
      .createTruckUnavailability(postJunkTruckUnavailabilityDto)
      .then(data => {
        commit("ADD_JUNK_TRUCK_UNAVAILABILITY", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error adding truck unavailability! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_TRUCK_UNAVAILABILITY", false)
      })
  },
  async updateTruckUnavailability({ commit }, putJunkTruckUnavailabilityDto) {
    commit("SET_IS_LOADING_TRUCK_UNAVAILABILITY", true)
    return await junkApi
      .updateTruckUnavailability(putJunkTruckUnavailabilityDto)
      .then(data => {
        commit("ADD_JUNK_TRUCK_UNAVAILABILITY", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error editing truck unavailability! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_TRUCK_UNAVAILABILITY", false)
      })
  },
  async deleteJunkTruckUnavailability({ commit }, junkTruckUnavailabilityId) {
    commit("SET_IS_LOADING_TRUCK_UNAVAILABILITY", true)
    return await junkApi
      .deleteTruckUnavailabilityById(junkTruckUnavailabilityId)
      .then(data => {
        commit("REMOVE_UNAVAILABLE_TRUCK", junkTruckUnavailabilityId)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error deleting truck unavailability! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_TRUCK_UNAVAILABILITY", false)
      })
  },
  async putFranchiseCustomerSelfBookingDistanceCutoff({ commit }, putFranchiseCustomerSelfBookingCutoffDto) {
    commit("SET_IS_LOADING_FRANCHISE_DISTANCE_CUTOFF", true)
    return await junkApi
      .putFranchiseCustomerSelfBookingDistanceCutoff(putFranchiseCustomerSelfBookingCutoffDto)
      .then(data => {
        commit("SET_FRANCHISE_CUSTOMER_SELF_BOOKING_DISTANCE_CUTOFF", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_FRANCHISE_DISTANCE_CUTOFF", false)
      })
  },
  async fetchOperatingUnitAllocationGroups({ commit }, operatingUnitId) {
    commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", true)
    return await junkApi
      .fetchOperatingUnitAllocationGroups(operatingUnitId)
      .then(data => {
        commit("SET_OPERATING_UNIT_ALLOCATION_GROUPS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", false)
      })
  },
  async createOperatingUnitAllocationGroup({ commit }, { operatingUnitId, postOperatingUnitAllocationGroupDto }) {
    commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", true)
    return await junkApi
      .createOperatingUnitAllocationGroup(operatingUnitId, postOperatingUnitAllocationGroupDto)
      .then(data => {
        commit("ADD_OPERATING_UNIT_ALLOCATION_GROUP", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", false)
      })
  },
  async deleteOperatingUnitAllocationGroup({ commit }, operatingUnitAllocationGroupId) {
    commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", true)
    return await junkApi
      .deleteOperatingUnitAllocationGroup(operatingUnitAllocationGroupId)
      .then(data => {
        commit("REMOVE_OPERATING_UNIT_ALLOCATION_GROUP", operatingUnitAllocationGroupId)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", false)
      })
  },
  async updateOperatingUnitGroupName({ commit }, dto) {
    commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", true)
    return await junkApi
      .updateOperatingUnitGroupName(dto)
      .then(data => {
        commit("REPLACE_OPERATING_UNIT_ALLOCATION_GROUP", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", false)
      })
  },
  async createFranchiseOperatingUnitAllocationGroup({ commit }, dto) {
    commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", true)
    return await junkApi
      .createFranchiseOperatingUnitAllocationGroup(dto)
      .then(data => {
        commit("REPLACE_OPERATING_UNIT_ALLOCATION_GROUP", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", false)
      })
  },
  async deleteFranchiseOperatingUnitAllocationGroup({ commit }, franchiseOperatingUnitAllocationGroupId) {
    commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", true)
    return await junkApi
      .deleteFranchiseOperatingUnitAllocationGroup(franchiseOperatingUnitAllocationGroupId)
      .then(data => {
        commit("REPLACE_OPERATING_UNIT_ALLOCATION_GROUP", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_ALLOCATION_GROUPS", false)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {}
}
