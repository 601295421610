<template>
  <base-dialog
    ref="job-allocation-slot-dialog-root"
    title="Allocate Slots"
    max-dialog-width="900"
    :is-dialog-visible="jobAllocationSlotDialogPropObject.isVisible"
    dialog-image="mdi-counter"
  >
    <template v-slot:content>
      <v-row>
        <v-col>
          <div ref="job-allocation-slot-dialog-headline" class="headline">
            You are attempting to update the <em>Preferred Time of Day</em> from <strong>{{ jobAllocationSlotDialogPropObject.oldPreferredTimeOfDay }}</strong> to
            <strong>{{ jobAllocationSlotDialogPropObject.newPreferredTimeOfDay }}</strong> on a job designated as a multi-slot job having
            <strong>{{ jobAllocationSlotDialogPropObject.numberOfSlots }} slots</strong>.
          </div>
        </v-col>
      </v-row>
      <v-row class="my-8 mb-2">
        <v-col>
          <v-row class="justify-center text-h6">Existing AM</v-row>
          <v-row ref="job-allocation-slot-dialog-existing-am" class="justify-center text-h6">
            {{ jobAllocationSlotDialogPropObject.oldNumberOfAmSlots }}
          </v-row>
        </v-col>
        <v-col>
          <v-row class="justify-center text-h6">Existing PM</v-row>
          <v-row ref="job-allocation-slot-dialog-existing-pm" class="justify-center text-h6">
            {{ jobAllocationSlotDialogPropObject.oldNumberOfPmSlots }}
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <v-row class="my-2">
        <v-col>
          <div class="headline mb-4">Please allocate remaining slots.</div>
          <div ref="job-allocation-slot-dialog-remaining-slots" class="text-h6" :class="{ 'red--text': isOverAllocationRemainingSlots }">
            Remaining Slots to Allocate:
            {{ remainingSlotsToAllocate }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col>
          <v-autocomplete
              class="cursor_pointer"
              ref="job-allocation-slot-dialog-am-slot-selector"
              color="primary"
              label="AM"
              hint="AM slots to allocate"
              :items="slots"
              :value="amSlotCount"
              @change="setAmSlotCount"
              outlined
              hide-details
          />
        </v-col>
        <v-col>
          <v-autocomplete
              class="cursor_pointer"
              ref="job-allocation-slot-dialog-pm-slot-selector"
              color="primary"
              label="PM"
              hint="PM slots to allocate"
              :items="slots"
              :value="pmSlotCount"
              :rules="pmSlotRules"
              @change="setPmSlotCount"
              outlined
              hide-details
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn ref="job-allocation-slot-dialog-cancel-button" class="pa-4" color="primaryText" @click="closeDialog" text rounded ripple>
        Cancel
      </v-btn>
      <v-btn
        ref="job-allocation-slot-dialog-confirm-button"
        class="pa-4"
        color="primary"
        :loading="isLoading"
        :disabled="isSaveDisabledWrapper"
        @click="confirm"
        text
        rounded
        ripple
      >
        Confirm
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog.vue"
import { PreferredTimeSlot } from "@/enums/PreferredTimeSlot"

export default {
  name: "JobAllocationSlotDialog",
  components: { BaseDialog },
  props: {
    jobAllocationSlotDialogPropObject: {
      type: Object
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      amSlotCount: 0,
      pmSlotCount: 0
    }
  },
  computed: {
    remainingSlotsToAllocate() {
      return this.jobAllocationSlotDialogPropObject.numberOfSlots - this.amSlotCount - this.pmSlotCount
    },
    isOverAllocationRemainingSlots() {
      return this.remainingSlotsToAllocate < 0
    },
    slots() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    isSaveDisabledWrapper() {
      return this.$msal.getUserHasElevatedRole()
        ? this.isSaveDisabled
        : this.amSlotCount + this.pmSlotCount !== this.jobAllocationSlotDialogPropObject.numberOfSlots || this.isSaveDisabled
    },
    isSaveDisabled() {
      if (
        this.jobAllocationSlotDialogPropObject.newPreferredTimeOfDayId === PreferredTimeSlot.FIRST.id ||
        this.jobAllocationSlotDialogPropObject.newPreferredTimeOfDayId === PreferredTimeSlot.AM.id
      ) {
        return this.amSlotCount < 1
      }
      return false
    }
  },
  methods: {
    presetSlotCounts() {
      if (
        this.jobAllocationSlotDialogPropObject.newPreferredTimeOfDayId === PreferredTimeSlot.FIRST.id ||
        this.jobAllocationSlotDialogPropObject.newPreferredTimeOfDayId === PreferredTimeSlot.AM.id
      ) {
        this.amSlotCount = 1
      } else if (this.jobAllocationSlotDialogPropObject.newPreferredTimeOfDayId === PreferredTimeSlot.PM.id) {
        this.pmSlotCount = 1
      } else if (this.jobAllocationSlotDialogPropObject.newPreferredTimeOfDayId === PreferredTimeSlot.NONE.id) {
        this.amSlotCount = 0
        this.pmSlotCount = 0
      }
    },
    confirm() {
      let obj = {
        amSlotCount: this.amSlotCount,
        pmSlotCount: this.pmSlotCount,
        timeSlotId: this.jobAllocationSlotDialogPropObject.newPreferredTimeOfDayId
      }
      this.$emit("confirm", obj)
    },
    closeDialog() {
      this.$emit("close-dialog")
    },
    setAmSlotCount(slotCount) {
      this.amSlotCount = slotCount
    },
    setPmSlotCount(slotCount) {
      this.pmSlotCount = slotCount
    }
  },
  created() {
    this.presetSlotCounts()
  }
}
</script>
