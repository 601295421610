<template>
  <v-container fluid>
    <v-card class="flex-fill flex-column flex" flat>
      <v-card-title>Edit Franchise Details</v-card-title>
      <v-card-text>
        <v-form ref="administrator-edit-franchise-form" :disabled="isFranchiseClosed" v-model="isEditFranchiseDetailsFormValid">
          <v-row dense>
            <v-col>
              <v-text-field ref="administrator-edit-franchise-number-text" label="Franchise Number *" outlined disabled readonly v-model="franchise.franchiseNumber"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field ref="administrator-edit-franchise-name-text" v-model="mutableFranchiseName" label="Franchise Name *" :rules="isRequiredRules" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-phone-input class="d-flex" ref="administrator-edit-franchise-primary-phone-text" v-model="mutablePhonePrimary" label="Primary Phone Number *" :rules="isRequiredRules" @input="handleChangeInputPrimary" outlined validate-on-blur />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="ma-0">
              <v-checkbox
                  ref="administrator-enroll-franchise-is-dialpad-enabled-checkbox"
                  label="Enable Texting"
                  v-model="isDialpadEnabled"
                  @change="handleChangeEnableDialpad" />
            </v-col>
            <v-col class="ma-0" v-if="isDialpadEnabled">
              <v-checkbox
                  ref="administrator-enroll-franchise-is-dialpad-primary-phone-checkbox"
                  label="Use Primary Phone"
                  v-model="isDialpadPrimaryPhone"
                  @change="handleChangeIsDialpadPrimary" />
            </v-col>
          </v-row>

          <v-row dense v-if="isDialpadEnabled">
            <v-col class="ma-0">
              <v-phone-input
                  ref="admin-enroll-franchise-dialog-phone-primary-text"
                  label="Dialpad Phone Number"
                  class="d-flex"
                  v-model="mutablePhoneDialpad"
                  outlined
                  :validate-on-blur="!isDialpadPrimaryPhone"
                  :disabled="isDialpadPrimaryPhone" />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-edit-franchise-email-text"
                label="Email Address *"
                v-model="mutableEmailPrimary"
                :rules="emailRules"
                outlined
                counter="50"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-edit-franchise-google-review-url-text"
                label="Google Review URL"
                v-model="mutableGoogleReviewUrl"
                outlined
                counter="256"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field ref="administrator-edit-franchise-address-text" class="cursor_pointer" label="Address *" readonly :value="formatAddress(mutableAddress)" outlined @click="setIsAddressSearchDialogVisible(true)"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mb-4 d-flex">
              <app-date-picker-field-selector
                ref="administrator-edit-franchise-system-access-date-picker"
                :date-picker-value="mutableSystemAccessDate"
                :minimum-date-value="minimumDate"
                :maximum-date-value="mutableFirstDayOfBusiness"
                text-field-format="MMMM D, YYYY"
                @updatedPickerValue="updateSystemAccessDate($event)"
                text-field-label="System Access Date *"
                :text-field-disabled="isSystemAccessDateDisabled"
                :is-clear-enabled="!isSystemAccessDateDisabled"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mb-4 d-flex">
              <app-date-picker-field-selector
                ref="administrator-edit-franchise-start-date-picker"
                text-field-label="First Day of Business *"
                :date-picker-value="mutableFirstDayOfBusiness"
                :minimum-date-value="mutableSystemAccessDate"
                :maximum-date-value="mutableLastDayOfBusiness"
                text-field-format="MMMM D, YYYY"
                @updatedPickerValue="updateFirstDateOfBusiness($event)"
                :text-field-disabled="isBusinessStartDateDisabled"
                :is-clear-enabled="!isBusinessStartDateDisabled"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex">
              <app-date-picker-field-selector
                ref="administrator-edit-franchise-end-date-picker"
                text-field-label="Last Day of Business"
                :date-picker-value="mutableLastDayOfBusiness"
                :minimum-date-value="mutableFirstDayOfBusiness"
                text-field-format="MMMM D, YYYY"
                :text-field-disabled="isFranchiseClosed"
                @updatedPickerValue="updateLastDateOfBusiness($event)"
                :is-clear-enabled="!isFranchiseClosed"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-checkbox v-model="mutableIsMiniMarket" :disabled="isFranchiseClosed" label="Mini Market"></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense v-if="!isFranchiseClosed">
            <v-col>
              <v-btn class="float-end" color="primary" :disabled="getIsLoadingOperatingUnitFranchisesAndSatellites" :loading="getIsLoadingOperatingUnitFranchisesAndSatellites" @click="save" rounded ripple large>Update Franchise Details</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <app-address-search-dialog
      v-if="isAddressSearchDialogVisible"
      :is-dialog-visible="isAddressSearchDialogVisible"
      :initial-camera-position="{ lat: 42.435712, lng: -84.331992 }"
      :is-create-address-from-marker-button-visible="false"
      @createAddressFromSearch="setFranchiseAddress($event)"
      @createAddressFromForm="setFranchiseAddress($event)"
      @closeDialog="setIsAddressSearchDialogVisible(false)"
    ></app-address-search-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { putFranchiseDetailsDto, putFranchiseDetailsWithUpdatedAddressDto } from "@/api/dtos/JunkDtos"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { formatAsDateOnly, todayAsDate } from "@/utils/DateTimeFormatters"
import { successSnackbar } from "@/utils/SnackbarBuilder"
import AppAddressSearchDialog from "@/components/AppAddressSearchDialog"
import { formatHereAddressText } from "@/utils/FormatAddressText"

export default {
  name: "AdministratorEditFranchise",
  components: { AppAddressSearchDialog, AppDatePickerFieldSelector },
  props: {
    franchiseInitial: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      franchise: {},
      isAddressSearchDialogVisible: false,
      isEditFranchiseDetailsFormValid: false,
      minimumDate: todayAsDate(),
      mutableAddress: undefined,
      mutableFranchiseName: "",
      mutablePhonePrimary: "",
      mutablePhoneDialpad: "",
      isDialpadEnabled: true,
      isDialpadPrimaryPhone: false,
      mutableEmailPrimary: "",
      mutableGoogleReviewUrl: "",
      mutableIsMiniMarket: false,
      mutableIsRoyaltyExempt: false,
      mutableSystemAccessDate: null,
      mutableFirstDayOfBusiness: null,
      mutableLastDayOfBusiness: null,
      emailRules: [v => /.+@.+\..+/.test(v.trim()) || "E-mail must be valid"],
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."]
    }
  },
  computed: {
    ...mapGetters("Administrator", ["getIsLoadingOperatingUnitFranchisesAndSatellites"]),
    isSystemAccessDateDisabled() {
      return this.mutableSystemAccessDate <= todayAsDate() || this.isFranchiseClosed
    },
    isBusinessStartDateDisabled() {
      return this.mutableFirstDayOfBusiness <= todayAsDate() || this.isFranchiseClosed
    },
    isFranchiseClosed() {
      return this.franchise.businessEndDate !== undefined && this.franchise.businessEndDate !== null
    },
    isDialpadEmpty() {
      return this.mutablePhoneDialpad === ""
    }
  },
  methods: {
    ...mapActions(["addSnackbar"]),
    ...mapActions("Administrator", ["updateFranchiseDetails"]),
    async save() {
      if (this.$refs["administrator-edit-franchise-form"].validate()) {
        const dto = this.generatePutFranchiseDetailsDto()
        await this.updateFranchiseDetails(dto).then(operatingUnitLeaf => {
          this.addSnackbar(successSnackbar("Franchise Successfully Updated"))
          let updatedFranchise = operatingUnitLeaf.children.find(franchiseLeaf => franchiseLeaf.franchiseId === this.franchise.franchiseId)
          this.setMutableStates(updatedFranchise)
        })
      }
    },
    generatePutFranchiseDetailsDto() {
      return (this.mutableAddress.latitude === this.franchise.addressDto.latitude && this.mutableAddress.longitude === this.franchise.addressDto.longitude)
        ? putFranchiseDetailsDto(this.franchise.franchiseId, this.mutableFranchiseName, this.mutablePhonePrimary, this.mutablePhoneDialpad, this.mutableEmailPrimary, this.mutableIsMiniMarket, this.mutableIsRoyaltyExempt, this.mutableSystemAccessDate, this.mutableFirstDayOfBusiness, this.mutableLastDayOfBusiness, this.$msal.getCurrentUserId(), this.mutableGoogleReviewUrl) :
          putFranchiseDetailsWithUpdatedAddressDto(this.franchise.franchiseId, this.mutableFranchiseName, this.mutablePhonePrimary, this.mutablePhoneDialpad, this.mutableEmailPrimary, this.mutableIsMiniMarket, this.mutableIsRoyaltyExempt, this.mutableSystemAccessDate, this.mutableFirstDayOfBusiness, this.mutableLastDayOfBusiness, this.$msal.getCurrentUserId(), this.mutableGoogleReviewUrl, this.mutableAddress)
    },
    updateSystemAccessDate(date) {
      this.mutableSystemAccessDate = date
    },
    updateFirstDateOfBusiness(date) {
      this.mutableFirstDayOfBusiness = date
    },
    updateLastDateOfBusiness(date) {
      this.mutableLastDayOfBusiness = date
    },
    formatAddress(address) {
      return formatHereAddressText(address)
    },
    setIsAddressSearchDialogVisible(isVisible) {
      this.isAddressSearchDialogVisible = isVisible
    },
    setFranchiseAddress(event) {
      this.mutableAddress = event
      this.isAddressSearchDialogVisible = false
    },
    toMutableAddress(franchiseAddress) {
      return {
        addressLine1: franchiseAddress.addressLine1,
        addressLine2: franchiseAddress.addressLine2,
        city: franchiseAddress.city,
        stateCode: franchiseAddress.stateOrProvince,
        postalCode: franchiseAddress.zipCode,
        countryCode: franchiseAddress.country,
        isUserValidated: franchiseAddress.isUserValidated,
        latitude: franchiseAddress.latitude,
        longitude: franchiseAddress.longitude
      }
    },
    setMutableStates(franchise) {
      this.franchise = franchise
      this.mutableFranchiseName = franchise.franchiseName
      this.mutablePhonePrimary = franchise.phone
      this.mutablePhoneDialpad = franchise.dialpadPhone
      this.mutableIsMiniMarket = franchise.isMiniMarket
      this.mutableIsRoyaltyExempt = franchise.isRoyaltyExempt
      this.mutableEmailPrimary = franchise.email
      this.mutableGoogleReviewUrl = franchise.googleReviewUrl
      this.mutableAddress = this.toMutableAddress(franchise.addressDto)
      this.mutableSystemAccessDate = formatAsDateOnly(franchise.systemAccessDate)
      this.mutableFirstDayOfBusiness = formatAsDateOnly(franchise.businessStartDate)
      this.mutableLastDayOfBusiness = formatAsDateOnly(franchise.businessEndDate)
      this.isDialpadEnabled = !this.isDialpadEmpty
    },
    handleChangeEnableDialpad() {
      if (!this.isDialpadEnabled) {
        this.isDialpadPrimaryPhone = false
        this.mutablePhoneDialpad = ""
      }
    },
    handleChangeIsDialpadPrimary() {
      this.mutablePhoneDialpad = (this.isDialpadPrimaryPhone) ? this.mutablePhonePrimary : ""
    },
    handleChangeInputPrimary() {
      if (this.isDialpadPrimaryPhone) {
        this.mutablePhoneDialpad = this.mutablePhonePrimary
      }
    }
  },
  created() {
    this.setMutableStates(this.franchiseInitial)
  }
}
</script>
