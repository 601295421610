let CASH = { id: 1, name: "Cash" }
let INVOICE = { id: 2, name: "Invoice" }
let CHECK = { id: 3, name: "Check" }
let CREDIT_CARD = { id: 4, name: "Credit Card" }

export const PaymentType = {
  CASH: CASH, INVOICE: INVOICE, CHECK: CHECK, CREDIT_CARD: CREDIT_CARD
}

export const PaymentTypes = [CREDIT_CARD, CASH, CHECK, INVOICE]
