const AM_SLOT = 0
const PM_SLOT = 1
const SCHEDULED_DATE = 2
const PREFERRED_TIME_OF_DAY = 3
const FRANCHISE = 4
const SLOTS_AND_PREFERRED_TIME_OF_DAY = 5
const SCHEDULE_JOB = 6

export const SlotAllocationChangeType = {
  AM_SLOT: AM_SLOT,
  PM_SLOT: PM_SLOT,
  SCHEDULED_DATE: SCHEDULED_DATE,
  PREFERRED_TIME_OF_DAY: PREFERRED_TIME_OF_DAY,
  FRANCHISE: FRANCHISE,
  SLOTS_AND_PREFERRED_TIME_OF_DAY: SLOTS_AND_PREFERRED_TIME_OF_DAY,
  SCHEDULE_JOB: SCHEDULE_JOB
}

export const SlotAllocationChangeTypes = [AM_SLOT, PM_SLOT, SCHEDULED_DATE, PREFERRED_TIME_OF_DAY, FRANCHISE, SLOTS_AND_PREFERRED_TIME_OF_DAY]
