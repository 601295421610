import { msalPluginInstance } from "@/plugins/MsalPlugin"
import getEnv from "@/utils/Env"
import { azureauth } from "../../appsettings.json"

export const routeGuard = async (to, from, next) => {
  await msalPluginInstance.handleRedirectPromise()
  //todo: might have a gotcha here - just want to highlight that there's a forward slash after the guid like so: `api://8635a5d4-38f4-4b2e-832c-3ea06b2c7656/.default`
  const customApiScopes = getEnv("VUE_APP_AZURE_API_SCOPES_GUID") || azureauth.VUE_APP_AZURE_API_SCOPES_GUID
  const azureRedirectUri = getEnv("VUE_APP_AZURE_REDIRECT_URI") || azureauth.VUE_APP_AZURE_REDIRECT_URI
  const loginRequestWithRedirectUri = {
    scopes: ["openid", "profile", "offline_access", `${customApiScopes}/.default`],
    redirectUri: `${azureRedirectUri}/${to.name.toLowerCase()}`
  }

  if (!msalPluginInstance.getIsUserLoggedIn()) {
    await msalPluginInstance.signIn(loginRequestWithRedirectUri)
  } else {
    if (to.name === "Settings") {
      if (msalPluginInstance.getUserHasReadSettingsPermission()) {
        return next()
      } else {
        return next({ name: "Dashboard" })
      }
    } else if (to.name === "AdministratorSettings") {
      if (msalPluginInstance.getUserHasOnboardFranchisePermission()) {
        return next()
      } else {
        return next({ name: "Dashboard" })
      }
    }
    return next()
  }
}
