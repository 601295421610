<template>
  <v-container fluid>
    <transition name="canvas">
      <v-btn
        ref="app-off-canvas-blade-collapsed"
        id="app-off-canvas-card-collapsed"
        v-if="!isExpanded"
        x-large
        :style="{background: buttonBackgroundColor}"
        color="primary"
        elevation="4"
        :disabled="isDisabled"
        @click="openIt"
        icon
        ripple
      >
        <v-badge ref="app-off-canvas-blade-notification" v-if="isNotificationIconVisible" color="red" offset-x="50" class="pe-1">
          <v-icon size="x-large">mdi-file-sign</v-icon>
        </v-badge>
        <v-icon ref="app-off-canvas-blade-icon" v-if="!isNotificationIconVisible" size="x-large">mdi-file-sign</v-icon>
      </v-btn>
      <v-card ref="app-off-canvas-blade-expanded" id="app-off-canvas-card" v-else class="background">
        <v-card-title>
          <slot name="title"></slot>
          <v-spacer></v-spacer>
          <v-icon ref="app-off-canvas-blade-close-button" color="primaryText" @click="closeIt">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <slot name="body"></slot>
        </v-card-text>
      </v-card>
    </transition>
  </v-container>
</template>

<script>
import applicationColors from "@/utils/ApplicationColors"

export default {
  name: "AppOffCanvasBlade",
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    isNotificationIconVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  computed: {
    buttonBackgroundColor() {
      return this.$vuetify.theme.dark ? applicationColors.vuetify.dark : applicationColors.vuetify.light
    }
  },
  methods: {
    openIt() {
      this.isExpanded = true
      this.$emit("open-it")
    },
    closeIt() {
      this.isExpanded = false
      this.$emit("close-it")
    }
  }
}
</script>

<style scoped>
#app-off-canvas-card {
  position: fixed;
  bottom: 40%;
  right: 0;
  z-index: 200;
  border-radius: 16px 0 0 16px !important;
  opacity: 0.96;
  border-color: transparent !important;
  min-width: 400px;
  min-height: 280px;
}

#app-off-canvas-card-collapsed {
  position: fixed;
  bottom: 40%;
  right: 0;
  z-index: 200;
  border-radius: 16px 0 0 16px !important;
  opacity: 0.96;
  border-color: transparent !important;
}
</style>
