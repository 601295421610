let DEVELOP = "dev"
let PRODUCTION = "prd"
let STAGE = "stg"

export const EnvironmentVariant = {
  DEVELOP: DEVELOP,
  PRODUCTION: PRODUCTION,
  STAGE: STAGE
}

export const EnvironmentVariants = [EnvironmentVariant.DEVELOP, EnvironmentVariant.PRODUCTION, EnvironmentVariant.STAGE]
