import applicationColors from "./ApplicationColors"

export const stgTheme = {
  backgroundDisabled: applicationColors.tmtGray.extraLight,
  navigationDrawerBackground: applicationColors.junkGray.eightHundred,
  background: applicationColors.junkBlue.fifty,
  dataTableHoverColor: applicationColors.junkGray.fifty,
  primary: applicationColors.junkMaterialComplementary.twoHundred,
  primaryPastel: applicationColors.tmtOrange.pastelFiveHundred,
  primaryText: applicationColors.junkGray.eightHundred,
  primaryLight: applicationColors.junkMaterialComplementary.twoHundred,
  primaryDark: applicationColors.junkMaterialComplementary.eightHundred,
  secondary: applicationColors.tmtGray.medium,
  secondaryLight: applicationColors.tmtGray.light,
  secondaryDark: applicationColors.tmtGray.dark,
  accent: applicationColors.tmtOrange.medium,
  error: applicationColors.tmtRed.medium,
  warning: applicationColors.tmtOrange.dark,
  info: applicationColors.junkDeepBlue.sixHundred,
  success: applicationColors.tmtGreen.medium
}

export const lightTheme = {
  backgroundDisabled: applicationColors.tmtGray.extraLight,
  navigationDrawerBackground: applicationColors.junkGray.eightHundred,
  background: applicationColors.tmtGray.light,
  dataTableHoverColor: applicationColors.tmtGreen.extraLight,
  primary: applicationColors.tmtGreen.medium,
  primaryPastel: applicationColors.tmtGreen.pastelMedium,
  primaryText: applicationColors.tmtGray.dark,
  primaryLight: applicationColors.tmtGreen.light,
  primaryDark: applicationColors.tmtGreen.dark,
  secondary: applicationColors.tmtGray.medium,
  secondaryLight: applicationColors.tmtGray.light,
  secondaryDark: applicationColors.tmtGray.dark,
  accent: applicationColors.tmtOrange.medium,
  error: applicationColors.tmtRed.medium,
  warning: applicationColors.tmtOrange.dark,
  info: applicationColors.tmtBlue.dark,
  success: applicationColors.tmtGreen.medium
}

export const darkTheme = {
  backgroundDisabled: applicationColors.tmtGray.dark,
  navigationDrawerBackground: applicationColors.vuetify.dark,
  background: applicationColors.tmtGray.dark,
  dataTableHoverColor: applicationColors.tmtGray.medium,
  primary: applicationColors.tmtGreen.medium,
  primaryPastel: applicationColors.tmtGreen.pastelMedium,
  primaryText: applicationColors.tmtGray.light,
  primaryLight: applicationColors.tmtGreen.extraLight,
  primaryDark: applicationColors.tmtGreen.dark,
  secondary: applicationColors.tmtGray.medium,
  secondaryLight: applicationColors.tmtGray.light,
  secondaryDark: applicationColors.tmtGray.dark,
  accent: applicationColors.tmtOrange.medium,
  error: applicationColors.tmtRed.medium,
  warning: applicationColors.tmtOrange.dark,
  info: applicationColors.tmtBlue.medium,
  success: applicationColors.tmtGreen.medium
}
