import junkApi from "@/api/Junk"
import documentApi from "@/api/Document"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { persistentErrorSnackbarWithPositiveAction } from "@/utils/SnackbarBuilder"
import * as Sentry from "@sentry/vue"

export const state = {
  _operatingUnitSlotAllocations: [],
  _isLoadingDashboard: false,
  _isLoadingDashboardSlotAllocation: false,
  _isLoadingDashboardRevenueByDriverReport: false,
  _isLoadingOperatingUnitRevenue: false,
  _revenueByDriverReportData: 0.0,
  _isLoadingDashboardRevenueByTruckReport: false,
  _revenueByTruckReportData: 0.0,
  _isLoadingDashboardScheduledConversionReport: false,
  _scheduledConversionReportData: {},
  _isLoadingDashboardClosedConversionReport: false,
  _averageJobLifecycleTimeInHours: 0,
  _closedConversionReportData: {},
  _operatingUnitRevenueData: [
    {
      dateDisplay: "",
      confirmedRevenue: 0,
      pendingRevenue: 0,
      cancelledCount: 0
    }
  ],
  _confirmedRevenue: 0,
  _pendingRevenue: 0,
  _cancelledCount: 0,
  _isLoadingDashboardSelfScheduledJobs: false,
  _selfScheduledJobs: [],
  _isLoadingDashboardCloseOutReminderJobs: false,
  _closeOutReminderJobs: [],
  _receiptsNotSent: [],
  _isLoadingReceiptsNotSent: false,
  _activeFranchiseIdsInOperatingUnit: [],
  _franchisesInOperatingUnit: []
}

export const getters = {
  getReceiptsNotSent(state) {
    return state._receiptsNotSent
  },
  getIsLoadingReceiptsNotSent(state) {
    return state._isLoadingReceiptsNotSent
  },
  getIsLoadingDashboardRevenueByDriverReport(state) {
    return state._isLoadingDashboardRevenueByDriverReport
  },
  getIsLoadingDashboardRevenueByTruckReport(state) {
    return state._isLoadingDashboardRevenueByTruckReport
  },
  getIsLoadingDashboardClosedConversionReport(state) {
    return state._isLoadingDashboardClosedConversionReport
  },
  getIsLoadingOperatingUnitRevenue(state) {
    return state._isLoadingOperatingUnitRevenue
  },
  getIsLoadingDashboard(state) {
    return state._isLoadingDashboard
  },
  getIsLoadingDashboardScheduledConversionReport(state) {
    return state._isLoadingDashboardScheduledConversionReport
  },
  getIsLoadingDashboardSlotAllocation(state) {
    return state._isLoadingDashboardSlotAllocation
  },
  getScheduledConversionReportData(state) {
    return state._scheduledConversionReportData
  },
  getRevenueByDriverReportData(state) {
    return state._revenueByDriverReportData
  },
  getRevenueByTruckReportData(state) {
    return state._revenueByTruckReportData
  },
  getClosedConversionReportData(state) {
    return state._closedConversionReportData
  },
  getCancelledCount(state) {
    return state._cancelledCount
  },
  getAverageJobLifecycleTimeInHours(state) {
    return state._averageJobLifecycleTimeInHours
  },
  getPendingRevenue(state) {
    return state._pendingRevenue
  },
  getConfirmedRevenue(state) {
    return state._confirmedRevenue
  },
  getOperatingUnitRevenueData(state) {
    return state._operatingUnitRevenueData
  },
  getOperatingUnitSlotAllocations(state) {
    const groupedData = state._operatingUnitSlotAllocations?.slice()?.reduce((result, item) => {
      const scheduledDate = item.scheduledDate
      // const scheduledDate = item.dateDisplay

      if (!result[scheduledDate]) {
        result[scheduledDate] = []
      }

      result[scheduledDate].push(item)
      return result
    }, {})

    return groupedData
  },
  getOperatingUnitSlotAllocationsOperatingUnit(state, getters) {
    let deepClone = JSON.parse(JSON.stringify(getters.getOperatingUnitSlotAllocations))
    let summationsByDate = {}

    Object.keys(deepClone).forEach(key => {
      summationsByDate[key] = deepClone[key].reduce(
        (acc, item) => {
          acc.totalSlotsRemaining += item.totalSlotsRemaining
          acc.firstSlotsScheduled += item.firstSlotsScheduled
          acc.amSlotsScheduled += item.amSlotsScheduled
          acc.pmSlotsScheduled += item.pmSlotsScheduled
          acc.noPreferenceSlotsScheduled += item.noPreferenceSlotsScheduled
          acc.totalSlotsScheduled += item.totalSlotsScheduled
          return acc
        },
        {
          totalSlotsRemaining: 0,
          firstSlotsScheduled: 0,
          amSlotsScheduled: 0,
          pmSlotsScheduled: 0,
          noPreferenceSlotsScheduled: 0,
          totalSlotsScheduled: 0
        }
      )
    })
    return summationsByDate
  },
  getIsLoadingDashboardSelfScheduledJobs(state) {
    return state._isLoadingDashboardSelfScheduledJobs
  },
  getSelfScheduledJobs(state) {
    return state._selfScheduledJobs.slice() ?? []
  },
  getIsLoadingDashboardCloseOutReminderJobs(state) {
    return state._isLoadingDashboardCloseOutReminderJobs
  },
  getCloseOutReminderJobs(state) {
    return state._closeOutReminderJobs.slice() ?? []
  },
  getFranchisesInOperatingUnit(state) {
    return state._franchisesInOperatingUnit.slice() ?? []
  }
}

export const mutations = {
  SET_ACTIVE_FRANCHISE_IDS_IN_OPERATING_UNIT(state, activeFranchiseIds) {
    state._activeFranchiseIdsInOperatingUnit = activeFranchiseIds
  },
  SET_RECEIPTS_NOT_SENT(state, receiptsNotSent) {
    state._receiptsNotSent = receiptsNotSent
  },
  SET_IS_LOADING_RECEIPTS_NOT_SENT(state, isLoading) {
    state._isLoadingReceiptsNotSent = isLoading
  },
  SET_IS_LOADING_DASHBOARD(state, isLoading) {
    state._isLoadingDashboard = isLoading
  },
  SET_IS_LOADING_DASHBOARD_REVENUE_BY_DRIVER_REPORT(state, isLoading) {
    state._isLoadingDashboardRevenueByDriverReport = isLoading
  },
  SET_IS_LOADING_DASHBOARD_SCHEDULED_CONVERSION_REPORT(state, isLoading) {
    state._isLoadingDashboardScheduledConversionReport = isLoading
  },
  SET_IS_LOADING_DASHBOARD_REVENUE_BY_TRUCK_REPORT(state, isLoading) {
    state._isLoadingDashboardRevenueByTruckReport = isLoading
  },
  SET_IS_LOADING_DASHBOARD_CLOSED_CONVERSION_REPORT(state, isLoading) {
    state._isLoadingDashboardClosedConversionReport = isLoading
  },
  SET_IS_LOADING_DASHBOARD_SLOT_ALLOCATION(state, isLoading) {
    state._isLoadingDashboardSlotAllocation = isLoading
  },
  SET_SCHEDULED_CONVERSION_REPORT_DATA(state, data) {
    state._scheduledConversionReportData = Object.assign({}, data)
  },
  SET_REVENUE_BY_DRIVER_REPORT(state, data) {
    state._revenueByDriverReportData = data
  },
  SET_SCHEDULED_CONVERSION_CLOSED_DATA(state, data) {
    state._closedConversionReportData = Object.assign({}, data)
  },
  SET_DASHBOARD_OPERATING_UNIT_REVENUE_DATA(state, data) {
    state._operatingUnitRevenueData = data.revenueChartCardData
    state._confirmedRevenue = data.confirmedRevenue
    state._pendingRevenue = data.pendingRevenue
    state._cancelledCount = data.cancelledCount
    state._averageJobLifecycleTimeInHours = data.averageJobLifecycleTimeInHours
  },
  SET_IS_LOADING_DASHBOARD_OPERATING_UNIT_REVENUE(state, isLoading) {
    state._isLoadingOperatingUnitRevenue = isLoading
  },
  SET_REVENUE_BY_TRUCK_REPORT_DATA(state, data) {
    state._revenueByTruckReportData = data
  },
  SET_OPERATING_UNIT_SLOT_ALLOCATIONS(state, slotAllocations) {
    state._operatingUnitSlotAllocations = slotAllocations
  },
  SET_IS_LOADING_DASHBOARD_SELF_SCHEDULED_JOBS(state, isLoading) {
    state._isLoadingDashboardSelfScheduledJobs = isLoading
  },
  SET_SELF_SCHEDULED_JOBS(state, selfScheduledJobs) {
    state._selfScheduledJobs = selfScheduledJobs
  },
  SET_IS_LOADING_DASHBOARD_CLOSE_OUT_REMINDER_JOBS(state, isLoading) {
    state._isLoadingDashboardCloseOutReminderJobs = isLoading
  },
  SET_CLOSE_OUT_REMINDER_JOBS(state, closeOutReminderJobs) {
    state._closeOutReminderJobs = closeOutReminderJobs
  },
  SET_FRANCHISES_IN_OPERATING_UNIT(state, franchises) {
    state._franchisesInOperatingUnit = franchises
  },
  UPDATE_SELF_SCHEDULED_JOB_LEAD_HANDLED(state, jobLeadDto) {
    state._selfScheduledJobs = state._selfScheduledJobs.map(job =>
      job.jobId === jobLeadDto.jobId
        ? { ...job, isLeadHandled: jobLeadDto.isHandled, leadHandledBy: jobLeadDto.handledBy }
        : job
    )
  }
}

export const actions = {
  async fetchFranchisesInOperatingUnit({ commit, rootGetters }) {
    commit("SET_FRANCHISES_IN_OPERATING_UNIT", [])
    commit("SET_IS_LOADING_RECEIPTS_NOT_SENT", true)
    const selectedOperatingUnitId = rootGetters.getSelectedOperatingUnitId
    return await junkApi
      .fetchActiveFranchisesInOperatingUnit(selectedOperatingUnitId)
      .then(franchises => {
        commit("SET_FRANCHISES_IN_OPERATING_UNIT", franchises)
        return Promise.resolve(franchises)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Franchises in Operating Unit! ${errorMessage}`, "Retry", () =>
            this.dispatch("Dashboard/fetchFranchisesInOperatingUnit", selectedOperatingUnitId)
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_RECEIPTS_NOT_SENT", false)
      })
  },
  async fetchReceiptsNotSent({ commit, getters }) {
    commit("SET_RECEIPTS_NOT_SENT", [])
    commit("SET_IS_LOADING_DASHBOARD", true)
    commit("SET_IS_LOADING_RECEIPTS_NOT_SENT", true)
    let franchiseIds = getters.getFranchisesInOperatingUnit.map(franchise => franchise.franchiseId)
    console.log("franchiseIds = ", franchiseIds)
    return await documentApi
      .fetchReceiptsNotSent(franchiseIds)
      .then(response => {
        commit("SET_RECEIPTS_NOT_SENT", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Dashboard/fetchReceiptsNotSent")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_RECEIPTS_NOT_SENT", false)
        commit("SET_IS_LOADING_DASHBOARD", false)
      })
  },
  async fetchCloseOutReminderJobs({ commit, rootGetters }) {
    commit("SET_CLOSE_OUT_REMINDER_JOBS", [])
    commit("SET_IS_LOADING_DASHBOARD", true)
    commit("SET_IS_LOADING_DASHBOARD_CLOSE_OUT_REMINDER_JOBS", true)
    return await junkApi
      .fetchCloseOutReminderJobs(rootGetters.getSelectedOperatingUnitId)
      .then(response => {
        commit("SET_CLOSE_OUT_REMINDER_JOBS", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Dashboard/fetchCloseOutReminderJobs")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DASHBOARD", false)
        commit("SET_IS_LOADING_DASHBOARD_CLOSE_OUT_REMINDER_JOBS", false)
      })
  },
  async createJobLead({ commit }, postJobLeadDto) {
    commit("SET_IS_LOADING_DASHBOARD_SELF_SCHEDULED_JOBS", true)
    return await junkApi
      .createJobLead(postJobLeadDto)
      .then(response => {
        commit("UPDATE_SELF_SCHEDULED_JOB_LEAD_HANDLED", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Dashboard/createJobLead", postJobLeadDto)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DASHBOARD_SELF_SCHEDULED_JOBS", false)
      })
  },
  async updateJobLead({ commit }, jobLeadPutDto) {
    commit("SET_IS_LOADING_DASHBOARD_SELF_SCHEDULED_JOBS", true)
    return await junkApi
      .updateJobLead(jobLeadPutDto)
      .then(response => {
        commit("UPDATE_SELF_SCHEDULED_JOB_LEAD_HANDLED", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Dashboard/updateJobLead", jobLeadPutDto)),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DASHBOARD_SELF_SCHEDULED_JOBS", false)
      })
  },
  async fetchSelfScheduledJobs({ commit, rootGetters }) {
    commit("SET_SELF_SCHEDULED_JOBS", [])
    commit("SET_IS_LOADING_DASHBOARD", true)
    commit("SET_IS_LOADING_DASHBOARD_SELF_SCHEDULED_JOBS", true)
    return await junkApi
      .fetchSelfScheduledJobs(rootGetters.getSelectedOperatingUnitId)
      .then(response => {
        commit("SET_SELF_SCHEDULED_JOBS", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Dashboard/fetchSelfScheduledJobs")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DASHBOARD", false)
        commit("SET_IS_LOADING_DASHBOARD_SELF_SCHEDULED_JOBS", false)
      })
  },
  async fetchOperatingUnitSlotAllocation({ commit, rootGetters }) {
    commit("SET_IS_LOADING_DASHBOARD", true)
    commit("SET_IS_LOADING_DASHBOARD_SLOT_ALLOCATION", true)
    return await junkApi
      .fetchOperatingUnitSlotAllocation(rootGetters.getSelectedOperatingUnitId)
      .then(slotAllocations => {
        commit("SET_OPERATING_UNIT_SLOT_ALLOCATIONS", slotAllocations)
        // commit("SET_REVENUE_BY_TRUCK_REPORT_DATA", response.truckRevenue)
        return Promise.resolve(slotAllocations)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Dashboard/fetchRevenueForTruckAndDriver")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DASHBOARD_SLOT_ALLOCATION", false)
        commit("SET_IS_LOADING_DASHBOARD", false)
      })
  },
  async fetchRevenueForTruckAndDriver({ commit, rootGetters }) {
    commit("SET_IS_LOADING_DASHBOARD", true)
    commit("SET_IS_LOADING_DASHBOARD_REVENUE_BY_TRUCK_REPORT", true)
    commit("SET_IS_LOADING_DASHBOARD_REVENUE_BY_DRIVER_REPORT", true)
    const selectedOperatingUnitId = rootGetters.getSelectedOperatingUnitId
    return await junkApi
      .fetchRevenueForTruckAndDriver(selectedOperatingUnitId)
      .then(response => {
        commit("SET_REVENUE_BY_DRIVER_REPORT", response.driverRevenue)
        commit("SET_REVENUE_BY_TRUCK_REPORT_DATA", response.truckRevenue)
        return Promise.resolve(response.data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Dashboard/fetchRevenueForTruckAndDriver")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DASHBOARD", false)
        commit("SET_IS_LOADING_DASHBOARD_REVENUE_BY_TRUCK_REPORT", false)
        commit("SET_IS_LOADING_DASHBOARD_REVENUE_BY_DRIVER_REPORT", false)
      })
  },
  async fetchScheduledConversionReport({ commit, rootGetters }) {
    commit("SET_IS_LOADING_DASHBOARD", true)
    commit("SET_IS_LOADING_DASHBOARD_SCHEDULED_CONVERSION_REPORT", true)
    const selectedOperatingUnitId = rootGetters.getSelectedOperatingUnitId
    return await junkApi
      .fetchScheduledConversionReport(selectedOperatingUnitId)
      .then(response => {
        commit("SET_SCHEDULED_CONVERSION_REPORT_DATA", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Dashboard/fetchScheduledConversionReport")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DASHBOARD", false)
        commit("SET_IS_LOADING_DASHBOARD_SCHEDULED_CONVERSION_REPORT", false)
      })
  },
  async fetchClosedConversionReport({ commit, rootGetters }) {
    commit("SET_IS_LOADING_DASHBOARD", true)
    commit("SET_IS_LOADING_DASHBOARD_CLOSED_CONVERSION_REPORT", true)
    const selectedOperatingUnitId = rootGetters.getSelectedOperatingUnitId
    return await junkApi
      .fetchClosedConversionReport(selectedOperatingUnitId)
      .then(response => {
        commit("SET_SCHEDULED_CONVERSION_CLOSED_DATA", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Dashboard/fetchClosedConversionReport")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DASHBOARD", false)
        commit("SET_IS_LOADING_DASHBOARD_CLOSED_CONVERSION_REPORT", false)
      })
  },
  async fetchOperatingUnitRevenue({ commit, rootGetters }) {
    commit("SET_IS_LOADING_DASHBOARD", true)
    commit("SET_IS_LOADING_DASHBOARD_OPERATING_UNIT_REVENUE", true)
    const selectedOperatingUnitId = rootGetters.getSelectedOperatingUnitId
    return await junkApi
      .fetchOperatingUnitRevenueReport(selectedOperatingUnitId)
      .then(response => {
        commit("SET_DASHBOARD_OPERATING_UNIT_REVENUE_DATA", response)
        return Promise.resolve(response)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.dispatch("Dashboard/fetchOperatingUnitRevenue")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DASHBOARD", false)
        commit("SET_IS_LOADING_DASHBOARD_OPERATING_UNIT_REVENUE", false)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {}
}
