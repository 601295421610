<template>
  <base-dialog
    ref="settings-create-franchise-elected-discount-for-website-dialog-root"
    title="Discount for Brand Website"
    :is-dialog-visible="isDialogVisible"
    dialog-image="mdi-cash-plus"
  >
    <template class="flex-fill" v-slot:content>
      <v-form ref="settings-create-franchise-elected-discount-for-website-dialog-form" v-model="isFormValid">
        <v-layout row>
          <v-col cols="6" class="ps-0 d-flex">
            <app-date-picker-field-selector
              ref="settings-create-franchise-elected-discount-for-website-dialog-begin-date-input"
              text-field-format="MM/DD/YYYY"
              text-field-label="Begin Date *"
              :date-picker-value="beginDate"
              :minimum-date-value="getMinimumPickerDate"
              :maximum-date-value="endDate"
              :rules="isRequiredSelectorRules"
              @updatedPickerValue="setBeginDateTime($event)"
            />
          </v-col>
          <v-col cols="6" class="pe-0 d-flex">
            <app-date-picker-field-selector
              ref="settings-create-franchise-elected-discount-for-website-dialog-end-date-input"
              text-field-format="MM/DD/YYYY"
              text-field-label="End Date"
              :date-picker-value="endDate"
              :minimum-date-value="beginDate"
              :maximum-date-value="maximumDate"
              @updatedPickerValue="setEndDateTime($event)"
            />
          </v-col>
        </v-layout>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="settings-create-franchise-elected-discount-for-website-dialog-cancel-button" color="primaryText" @click="closeDialog" text rounded ripple
        >Cancel
      </v-btn>
      <v-btn
        class="pa-4"
        ref="settings-create-franchise-elected-discount-for-website-dialog-confirmation-button"
        color="primary"
        text
        rounded
        ripple
        :loading="getIsLoadingDiscounts"
        :disabled="getIsLoadingDiscounts"
        @click="confirm"
        >Confirm
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { mapGetters } from "vuex"
import { formatAsDateOnly, isDateOnOrAfterToday, todayAsDate } from "@/utils/DateTimeFormatters"
import { createFranchiseElectedDiscountForWebsiteDto } from "@/api/dtos/JunkDtos"

export default {
  name: "SettingsCreateFranchiseElectedDiscountForWebsiteDialog",
  components: { BaseDialog, AppDatePickerFieldSelector },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    selectedDiscount: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isFormValid: false,
      maximumDate: todayAsDate(),
      beginDate:
        formatAsDateOnly(this.selectedDiscount.nationalDiscountBeginDate) > todayAsDate() ? formatAsDateOnly(this.selectedDiscount.nationalDiscountBeginDate) : todayAsDate(),
      endDate: null,
      isRequiredSelectorRules: [value => (!!value && value.length > 0) || "Is required."]
    }
  },
  methods: {
    async confirm() {
      if (this.$refs["settings-create-franchise-elected-discount-for-website-dialog-form"].validate()) {
        const createDiscountDto = createFranchiseElectedDiscountForWebsiteDto(
          this.selectedDiscount.franchiseElectedDiscountId,
          this.beginDate,
          this.endDate,
          this.$msal.getCurrentUserId()
        )
        this.$emit("confirm-discount", createDiscountDto)
      }
    },
    closeDialog() {
      this.$emit("close-dialog")
    },
    setBeginDateTime(dateTime) {
      this.beginDate = dateTime
    },
    setEndDateTime(dateTime) {
      this.endDate = dateTime
    }
  },
  computed: {
    ...mapGetters("Settings", ["getIsLoadingDiscounts"]),
    getMinimumPickerDate() {
      return isDateOnOrAfterToday(this.selectedDiscount.nationalDiscountBeginDate) ? formatAsDateOnly(this.selectedDiscount.nationalDiscountBeginDate) : todayAsDate()
    }
  },
  created() {
    this.setBeginDateTime(this.getMinimumPickerDate)
    this.setEndDateTime(formatAsDateOnly(this.selectedDiscount.nationalDiscountEndDate))
    this.maximumDate = formatAsDateOnly(this.selectedDiscount.nationalDiscountEndDate)
  }
}
</script>
