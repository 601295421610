<template>
  <v-card ref="settings-auth-management-roles-card-root" elevation="4">
    <v-card-title ref="settings-auth-management-roles-card-title">Roles</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-responsive class="overflow-y-auto" max-height="550" min-height="200">
            <v-data-table
              ref="settings-auth-management-roles-card-data-table"
              class="elevation-0"
              :headers="settingsAuthManagementRolesCardHeaders"
              :items="getRoles"
              sort-by="displayName"
              :loading="getIsLoadingRoles"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:no-data>
                <v-col class="ma-0 pa-0 align-self-center d-flex">
                  <app-not-found title="No Results" content="No Roles Found.">
                    <template v-slot:actions><div></div></template>
                  </app-not-found>
                </v-col>
              </template>
              <template v-slot:item.name="{ value }">
                <span class="text-md-body-1">{{ value }}</span>
              </template>
              <template v-slot:item.description="{ value }">
                <span class="text-md-body-1">{{ value }}</span>
              </template>
            </v-data-table>
          </v-responsive>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import AppNotFound from "@/components/AppNotFound"

export default {
  name: "SettingsAuthManagementRolesCard",
  components: { AppNotFound },
  data() {
    return {
      settingsAuthManagementRolesCardHeaders: [
        { text: "Role Name", align: "start", value: "displayName" },
        { text: "Description", align: "start", value: "description" }
      ]
    }
  },
  computed: {
    ...mapGetters("Settings", ["getRoles", "getIsLoadingRoles"])
  }
}
</script>

<style scoped></style>
