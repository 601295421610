import { errorSnackbar, persistentErrorSnackbarWithPositiveAction } from "@/utils/SnackbarBuilder"
import * as Sentry from "@sentry/vue"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import junkApi from "@/api/Junk"
import routingApi from "@/api/Routing"
import { JobStatus } from "@/enums/JobStatus"
import { consoleLog } from "@/utils/Logging"
import { set } from "@/store/indexeddb/KeyVal"
import {
  filterByPreferredTimeSlot,
  filterBySelectedFranchise,
  filterBySelectedStatuses,
  filterBySelectedTruckCapacity,
  filterByTotalDistance
} from "@/utils/filters/JobQueueFilters"

export const state = {
  _scheduledDate: todayAsDate(),
  _selectedJunkTruckId: undefined,
  _activeEmployees: [],
  _activeTrucks: [],
  _jobQueue: [],
  _jobAddressesForNavigation: [],
  _isLoadingClaimJob: false,
  _isLoadingActiveTrucks: false,
  _isLoadingSlotQueues: false,
  _selectedStatuses: [],
  _jobQueueHeaders: [
    { text: "Job Number", align: "start", value: "jobNumber" },
    { text: "Customer & Address", align: "start", value: "customerAndAddress" },
    { text: "Distance From Me", align: "start", value: "totalDistance" },
    { text: "Capacity", align: "start", value: "capacityName" },
    { text: "Preferred Time Slot", align: "center", value: "preferredTimeSlotId" },
    { text: "Status", align: "center", value: "statusName" },
    { text: "Claimed By", align: "center", value: "claimedBy" }
  ],
  _franchisesInOperatingUnit: [],
  _selectedFranchises: [],
  _distanceFromMeFilter: {},
  _preferredTimeSlotFiltersSelected: [],
  _selectedTruckCapacity: {},
  _userCurrentPosition: { lat: undefined, lng: undefined }
}

export const getters = {
  getFilteredJobQueue(state, getters) {
    return getters.getJobQueue
      .slice()
      ?.filter(job => filterByTotalDistance(job, getters.getDistanceFromMeFilter))
      ?.filter(job => filterByPreferredTimeSlot(job, getters.getPreferredTimeSlotFiltersSelected))
      ?.filter(job => filterBySelectedFranchise(job, getters.getSelectedFranchiseFilters))
      ?.filter(job => filterBySelectedStatuses(job, getters.getSelectedStatuses))
      ?.filter(job => filterBySelectedTruckCapacity(job, getters.getSelectedTruckCapacity))
  },
  getUserCurrentPosition(state) {
    return state._userCurrentPosition
  },
  getJobQueueHeaders(state) {
    return state._jobQueueHeaders.slice()
  },
  getDistanceFromMeFilter(state) {
    return state._distanceFromMeFilter
  },
  getDistanceFromMeFilters() {
    return [
      { id: 1, name: "< 10", lower: -1, upper: 10 },
      { id: 2, name: "10-25", lower: 10, upper: 25 },
      { id: 3, name: "25-50", lower: 25, upper: 50 }
    ]
  },
  getSelectedFranchiseFilters(state) {
    return state._selectedFranchises
  },
  getFranchisesInOperatingUnit(state) {
    return state._franchisesInOperatingUnit
  },
  getSelectedStatuses(state) {
    return state._selectedStatuses
  },
  getPreferredTimeSlotFiltersSelected(state) {
    return state._preferredTimeSlotFiltersSelected.slice()
  },
  getJobQueue(state) {
    return state._jobQueue.slice()
  },
  getJobs(state) {
    return state._jobQueue.slice()
  },
  getScheduledDate(state) {
    return state._scheduledDate
  },
  getSelectedTruckId(state) {
    return state._selectedJunkTruckId
  },
  getActiveTrucks(state) {
    return state._activeTrucks.slice()
  },
  getActiveEmployees(state) {
    return state._activeEmployees.slice()
  },
  getSelectedTruckJobQueue(state) {
    return state._jobQueue
      .slice()
      .filter(job => {
        return job.jobTrucks.some(truck => truck.junkTruckId === state._selectedJunkTruckId)
      })
      .sort((a, b) => {
        return a.preferredTimeSlotId < b.preferredTimeSlotId ? -1 : 1
      })
  },
  getIsLoadingActiveTrucks(state) {
    return state._isLoadingActiveTrucks
  },
  getIsLoadingClaimJob(state) {
    return state._isLoadingClaimJob
  },
  getIsLoadingSlotQueues(state) {
    return state._isLoadingSlotQueues
  },
  getAddressesForNavigation(state) {
    return state._jobAddressesForNavigation
  },
  getSelectedTruckCapacity(state) {
    return state._selectedTruckCapacity
  }
}

export const mutations = {
  SET_USER_CURRENT_POSITION(state, userCurrentPosition) {
    state._userCurrentPosition = Object.assign({}, userCurrentPosition)
  },
  ADD_JOB_TO_QUEUE(state, queueDto) {
    let index = state._jobQueue.findIndex(j => j.jobId === queueDto.jobId)
    if (~index) {
      let job = state._jobQueue.find(j => j.jobId === queueDto.jobId)
      queueDto.totalDistance = job.totalDistance
      queueDto.totalDistanceKm = job.totalDistanceKm
      state._jobQueue.splice(index, 1, queueDto)
    } else {
      state._jobQueue.push(queueDto)
    }
  },
  REMOVE_JOB_FROM_QUEUE(state, id) {
    let index = state._jobQueue.findIndex(job => job.jobId === id)
    if (~index) {
      state._jobQueue.splice(index, 1)
    }
  },
  SET_ACTIVE_EMPLOYEES(state, activeEmployees) {
    state._activeEmployees = activeEmployees
  },
  SET_ACTIVE_TRUCKS(state, activeTrucks) {
    state._activeTrucks = activeTrucks
  },
  SET_SCHEDULED_DATE(state, date) {
    state._scheduledDate = date
  },
  SET_SELECTED_JUNK_TRUCK_ID(state, junkTruckId) {
    state._selectedJunkTruckId = junkTruckId
  },
  SET_IS_LOADING_ACTIVE_TRUCKS(state, isLoading) {
    state._isLoadingActiveTrucks = isLoading
  },
  SET_IS_LOADING_CLAIM_JOB(state, isLoading) {
    state._isLoadingClaimJob = isLoading
  },
  SET_IS_LOADING_SLOT_QUEUES(state, isLoading) {
    state._isLoadingSlotQueues = isLoading
  },
  SET_QUEUE_JOBS(state, jobs) {
    state._jobQueue.splice(0, state._jobQueue?.length ?? 0, ...jobs)
  },
  SET_JOB_ADDRESSES_FOR_NAVIGATION(state, jobAddresses) {
    state._jobAddressesForNavigation = jobAddresses
  },
  SET_JOB_DISTANCE_FROM_USER(state, jobDistances) {
    console.log("job distances: ", JSON.stringify(jobDistances, null, 4))
    let decoratedJob = state._jobQueue.find(job => job.jobId === jobDistances.jobId)
    decoratedJob = {
      ...decoratedJob,
      totalDistance: jobDistances.totalDistance,
      totalDistanceKm: jobDistances.totalDistanceKm
    }

    let index = state._jobQueue.findIndex(job => job.jobId === jobDistances.jobId)
    state._jobQueue.splice(index, 1, decoratedJob)
    console.log(JSON.stringify(state._jobQueue, null, 4))
  },
  SET_SELECTED_STATUSES(state, statuses) {
    state._selectedStatuses = statuses
  },
  SET_FRANCHISES_IN_OPERATING_UNIT(state, franchises) {
    state._franchisesInOperatingUnit = franchises
  },
  SET_SELECTED_FRANCHISES(state, franchise) {
    state._selectedFranchises = franchise
  },
  SET_DISTANCE_FROM_ME_FILTER(state, filter) {
    state._distanceFromMeFilter = filter
  },
  SET_PREFERRED_TIME_SLOT_FILTER(state, preferredTimeSlots) {
    state._preferredTimeSlotFiltersSelected = preferredTimeSlots
  },
  SET_SELECTED_TRUCK_CAPACITY(state, truckCapacity) {
    state._selectedTruckCapacity = truckCapacity
  }
}

export const actions = {
  async setUserCurrentPosition({ commit }, userCurrentPosition) {
    commit("SET_USER_CURRENT_POSITION", userCurrentPosition)
  },
  async setDistanceFromMeFilter({ commit }, filter) {
    if (!filter) {
      filter = {}
    }
    commit("SET_DISTANCE_FROM_ME_FILTER", filter)

    await set("jobQueueDistanceFromMeFilter", filter).catch(error => {
      console.log("Error saving to IndexedDb - jobQueueDistanceFromMeFilter: ", error)
      Sentry.captureException(error)
      return Promise.reject(error)
    })
  },
  async setPreferredTimeSlotFilter({ commit }, preferredTimeSlots) {
    if (!preferredTimeSlots) {
      preferredTimeSlots = []
    }
    commit("SET_PREFERRED_TIME_SLOT_FILTER", preferredTimeSlots)

    await set("jobQueuePreferredTimeSlotsFilter", preferredTimeSlots).catch(error => {
      console.log("Error saving to IndexedDb - preferredTimeSlotsFilter: ", error)
      Sentry.captureException(error)
      return Promise.reject(error)
    })
  },
  async setSelectedFranchisesFilter({ commit }, franchises) {
    consoleLog("resetting attributes! - setSelectedFranchisesFilter called!")
    if (!franchises) {
      franchises = []
    }
    commit("SET_SELECTED_FRANCHISES", franchises)

    await set("jobQueueFranchisesFilter", franchises).catch(error => {
      console.log("Error saving to IndexedDb - jobQueueFranchisesFilter: ", error)
      Sentry.captureException(error)
      return Promise.reject(error)
    })
  },
  async setSelectedStatuses({ commit }, statuses) {
    if (!statuses) {
      statuses = []
    }
    commit("SET_SELECTED_STATUSES", statuses)

    await set("jobQueueStatusFilters", statuses).catch(error => {
      console.log("Error saving to IndexedDb - jobQueueStatusFilters: ", error)
      Sentry.captureException(error)
      return Promise.reject(error)
    })
  },
  async setSelectedTruckCapacity({ commit }, truckCapacity) {
    consoleLog("truckCapacity = ", truckCapacity)
    if (!truckCapacity) {
      truckCapacity = {}
    }
    commit("SET_SELECTED_TRUCK_CAPACITY", truckCapacity)

    await set("jobQueueTruckCapacityFilters", truckCapacity).catch(error => {
      console.log("Error saving to IndexedDb - jobQueueTruckCapacityFilters: ", error)
      Sentry.captureException(error)
      return Promise.reject(error)
    })
  },
  async addJobToQueue({ commit }, dto) {
    console.log("New job scheduled, adding to queue....")
    commit("ADD_JOB_TO_QUEUE", dto)
  },
  async removeJobFromQueue({ commit }, id) {
    console.log(`Job ${id} camcelled, removing from queue.`)
    commit("REMOVE_JOB_FROM_QUEUE", id)
  },
  async fetchClaimedJobDistancesFromUser({ getters, commit }, userLocation) {
    console.log("fetchClaimedJobDistancesFromUser.userLocation: ", userLocation)

    getters.getJobs.forEach(j => {
      console.log(JSON.stringify(j.jobAddresses, null, 4))

      let primaryLocation = j.jobAddresses.find(c => !c.isFranchiseLocation) // && c.stopOrder === 2)
      console.log("primaryLocation: ", primaryLocation)
      console.log("Fetching JobId: ", j.jobId)

      if (primaryLocation && (j.jobStatusId === JobStatus.SCHEDULED.id || j.jobStatusId === JobStatus.CLAIMED.id)) {
        console.log("Fetching primary location: ", primaryLocation)
        console.log(JSON.stringify(j, null, 4))

        let waypoints = [
          {
            latitude: userLocation.lat,
            longitude: userLocation.lng
          },
          {
            latitude: primaryLocation.latitude,
            longitude: primaryLocation.longitude
          }
        ]

        routingApi.calculateRoute(waypoints).then(data => {
          console.log("Result: ", JSON.stringify(data, null, 4))
          commit("SET_JOB_DISTANCE_FROM_USER", { jobId: j.jobId, totalDistance: data.totalDistance, totalDistanceKm: data.totalDistanceKm })
        })
      } else if (primaryLocation === undefined) {
        commit("SET_JOB_DISTANCE_FROM_USER", { jobId: j.jobId, totalDistance: -1, totalDistanceKm: -1 })
      }
    })
  },
  async setAddressesForNavigation({ commit }, jobAddresses) {
    commit("SET_JOB_ADDRESSES_FOR_NAVIGATION", jobAddresses)
  },
  async resetAttributes({ commit, dispatch }) {
    consoleLog("resetting attributes!")
    commit("SET_SELECTED_JUNK_TRUCK_ID", undefined)
    commit("SET_QUEUE_JOBS", [])
    commit("SET_FRANCHISES_IN_OPERATING_UNIT", [])
    dispatch("setSelectedFranchisesFilter", [])
  },
  async setScheduledDate({ commit }, date) {
    commit("SET_SCHEDULED_DATE", date)
    // this.dispatch("JobQueue/fetchAllQueuedJobsByScheduledDateAndOperatingUnitId", { date: date, operatingUnitId: operatingUnitId })
    // this.dispatch("JobQueue/fetchActiveJunkTrucksInOperatingUnitByDate", { date: date, operatingUnitId: operatingUnitId })
    // this.dispatch("JobQueue/fetchActiveEmployeesWithJunkActivityTypeByOperatingUnitIdAndDate", { date: date, operatingUnitId: operatingUnitId })
  },
  async setSelectedJunkTruckId({ commit }, junkTruckId) {
    commit("SET_SELECTED_JUNK_TRUCK_ID", junkTruckId)
  },
  async fetchAllQueuedJobsByScheduledDateAndOperatingUnitId({ commit }, { date, operatingUnitId }) {
    commit("SET_IS_LOADING_SLOT_QUEUES", true)
    return await junkApi
      .fetchAllQueuedJobsByScheduledDateAndOperatingUnitId(date, operatingUnitId)
      .then(data => {
        commit("SET_QUEUE_JOBS", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Jobs! ${errorMessage}`, "Retry", () =>
            this.dispatch("JobQueue/fetchAllQueuedJobsByScheduledDateAndOperatingUnitId", {
              date,
              operatingUnitId
            })
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_SLOT_QUEUES", false))
  },
  async fetchActiveJunkTrucksInOperatingUnitByDate({ commit }, { date, operatingUnitId }) {
    commit("SET_IS_LOADING_ACTIVE_TRUCKS", true)
    return await junkApi
      .fetchActiveJunkTrucksInOperatingUnitByDate(date, operatingUnitId)
      .then(data => {
        commit("SET_ACTIVE_TRUCKS", data)
        console.log("SET_ACTIVE_TRUCKS CALLED WITH = ", JSON.stringify(data, null, 4))
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Trucks! ${errorMessage}`, "Retry", () =>
            this.dispatch("JobQueue/fetchActiveJunkTrucksInOperatingUnitByDate", {
              date,
              operatingUnitId
            })
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_ACTIVE_TRUCKS", false))
  },
  async claimJunkJobByJobId({ commit }, { jobId, claimJobDto }) {
    commit("SET_IS_LOADING_CLAIM_JOB", true)
    return await junkApi
      .claimJunkJob(jobId, claimJobDto)
      .then(data => {
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(`Error Claiming Job! ${errorMessage}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_CLAIM_JOB", false)
      })
  },
  async fetchFranchisesInOperatingUnit({ commit }, operatingUnitId) {
    return await junkApi
      .fetchActiveFranchisesInOperatingUnit(operatingUnitId)
      .then(franchises => {
        commit("SET_FRANCHISES_IN_OPERATING_UNIT", franchises)
        if (franchises.length === 1) {
          commit("SET_SELECTED_FRANCHISES", franchises)
        }
        return Promise.resolve(franchises)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Franchises in Operating Unit! ${errorMessage}`, "Retry", () =>
            this.dispatch("JobQueue/fetchFranchisesInOperatingUnit", operatingUnitId)
          ),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
  },
  async setUsersInOperatingUnit({ commit }, users) {
    console.log("Setting users to = ", JSON.stringify(users,null,4))
    commit("SET_ACTIVE_EMPLOYEES", users)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {}
}
