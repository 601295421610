// Represents the tab content for the AppTabNavDrawer
export function JobTab(jobId, jobNumber, customerId, lastName, businessName) {
  this.jobId = jobId
  this.jobNumber = jobNumber
  this.customerId = customerId
  this.customerLastName = lastName
  this.businessName = businessName

  this.displayText = () => {
    if (this.businessName && this.businessName !== "") {
      let bn = this.businessName.toString()
      if (bn.length > 8) {
        bn = bn.substring(0, 8) + "..."
      }
      return `${bn} - ${this.jobNumber}`
    }

    if (this.customerLastName.length > 8) {
      let substringLastName = this.customerLastName.substring(0, 8) + "..."
      return `${substringLastName} - ${this.jobNumber}`
    }

    return `${this.customerLastName} - ${this.jobNumber}`
  }
}
