<template>
  <v-container fluid>
    <v-card class="flex-fill flex-column flex" flat>
      <v-card-title>Edit Operating Unit Details</v-card-title>
      <v-card-text>
        <v-form ref="administrator-edit-operating-unit-form" v-model="isEditOperatingUnitFormValid">
          <v-row dense>
            <v-col>
              <v-text-field :value="operatingUnit.operatingUnitNumber" label="Operating Unit Number *" counter="5" :rules="isRequiredRules" disabled readonly outlined />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field v-model="mutableName" label="Operating Unit Name *" counter="50" :rules="isRequiredRules" outlined />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field v-model="mutableDotNumber" label="Department of Transportation Number" counter="50" type="number" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn class="float-end" color="primary" :disabled="getIsLoadingOperatingUnitFranchisesAndSatellites" :loading="getIsLoadingOperatingUnitFranchisesAndSatellites" @click="save" rounded ripple large>Update Operating Unit</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { putOperatingUnitDto } from "@/api/dtos/JunkDtos"
import { successSnackbar } from "@/utils/SnackbarBuilder"

export default {
  name: "AdministratorEditOperatingUnit",
  props: {
    operatingUnitInitial: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      operatingUnit: {},
      isEditOperatingUnitFormValid: false,
      mutableDotNumber: "",
      mutableName: "",
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."]
    }
  },
  methods: {
    ...mapActions(["addSnackbar"]),
    ...mapActions("Administrator", ["updateOperatingUnit"]),
    async save() {
      if (this.$refs["administrator-edit-operating-unit-form"].validate()) {
        const dto = putOperatingUnitDto(this.operatingUnit.operatingUnitId, this.mutableName, this.mutableDotNumber)
        await this.updateOperatingUnit(dto).then(operatingUnitLeaf => {
          this.addSnackbar(successSnackbar("Operating Unit Successfully Updated"))
          this.setMutableStates(operatingUnitLeaf)
        })
      }
    },
    setMutableStates(operatingUnit) {
      this.operatingUnit = operatingUnit
      this.mutableName = operatingUnit.operatingUnitName
      this.mutableDotNumber = operatingUnit.operatingUnitDotNumber
    }
  },
  computed: {
    ...mapGetters("Administrator", ["getIsLoadingOperatingUnitFranchisesAndSatellites"])
  },
  created() {
    this.setMutableStates(this.operatingUnitInitial)
  }
}
</script>

<style scoped></style>
