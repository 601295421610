<template>
  <v-card class="mx-auto" :loading="getIsLoadingOperatingUnitFranchisesAndSatellites" min-height="300">
    <v-sheet class="pa-4 primary">
      <v-text-field type="search" autocomplete="off" v-model="search" prepend-icon="mdi-magnify" label="Search Company Directory for Existing Operating Units, Franchises, and Satellite Offices" dark flat solo-inverted hide-details clearable clear-icon="mdi-close-circle-outline"></v-text-field>
    </v-sheet>

    <v-card-text>
      <v-row>
        <v-responsive class="overflow-y-auto" :max-height="getMaxHeightForOperatingUnitList">
          <v-col>
            <v-treeview
              :items="getOperatingUnitFranchisesAndSatellites"
              item-key="treeViewKey"
              rounded
              @update:active="setSelectedItems"
              activatable
              return-object
              :search="search"
              :open.sync="open"
              item-text="displayName"
            >
              <template v-slot:prepend="{ item }">
                <v-icon v-text="getIcon(item)"></v-icon>
              </template>
            </v-treeview>
          </v-col>
        </v-responsive>
        <v-divider v-show="isVerticalDividerVisible" vertical></v-divider>
        <v-col>
          <administrator-edit-operating-unit v-if="selectedItem[0].type === 'OperatingUnit'" :key="selectedItem[0].treeViewKey" :operating-unit-initial="selectedItem[0]"></administrator-edit-operating-unit>
          <administrator-edit-franchise v-if="selectedItem[0].type === 'Franchise'" :key="selectedItem[0].treeViewKey" :franchise-initial="selectedItem[0]"></administrator-edit-franchise>
          <administrator-edit-satellite-office v-if="selectedItem[0].type === 'SatelliteOffice'" :key="selectedItem[0].treeViewKey" :satellite-office-initial="selectedItem[0]"></administrator-edit-satellite-office>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AdministratorEditOperatingUnit from "@/components/AdministratorEditOperatingUnit"
import { mapActions, mapGetters } from "vuex"
import AdministratorEditFranchise from "@/components/AdministratorEditFranchise"
import AdministratorEditSatelliteOffice from "@/components/AdministratorEditSatelliteOffice"

export default {
  name: "AdministratorOperatingUnitTree",
  components: { AdministratorEditSatelliteOffice, AdministratorEditFranchise, AdministratorEditOperatingUnit },
  data: () => ({
    selectedItem: [{ type: undefined }],
    open: [],
    search: null
  }),
  computed: {
    ...mapGetters("Administrator", ["getIsLoadingOperatingUnitFranchisesAndSatellites", "getOperatingUnitFranchisesAndSatellites"]),
    getMaxHeightForOperatingUnitList() {
      return this.$vuetify.breakpoint.smAndDown ? "300" : "1000"
    },
    isVerticalDividerVisible() {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  methods: {
    ...mapActions(["setIsLoadingAppFullScreen"]),
    ...mapActions("Administrator", ["fetchAllOperatingUnitFranchisesAndSatellites"]),
    setSelectedItems(item) {
      this.selectedItem = item
    },
    getIcon(item) {
      const itemLowercase = item.type?.toLowerCase() ?? ""
      if (itemLowercase === "operatingunit") return "mdi-domain"
      else if (itemLowercase === "franchise") return "mdi-office-building"
      else if (itemLowercase === "satelliteoffice") return "mdi-satellite-uplink"
      else return "mdi-blank"
    }
  },
  async created() {
    await this.setIsLoadingAppFullScreen({ isLoading: true, loadingText: "Fetching All Operating Units, Franchises, and Satellite Offices" })
    await this.fetchAllOperatingUnitFranchisesAndSatellites().finally(async () => {
      await this.setIsLoadingAppFullScreen({ isLoading: false, loadingText: "" })
    })
  }
}
</script>

<style scoped></style>
