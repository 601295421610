function formatInternationalCurrency(amount, options) {
  let defaultOptions = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    locale: "en-US"
  }

  defaultOptions = {
    ...defaultOptions,
    ...options
  }
  let locale = defaultOptions.locale
  delete defaultOptions.locale

  let formatter = new Intl.NumberFormat(locale, defaultOptions)
  return formatter.format(amount)
}

module.exports = { formatInternationalCurrency }