<template>
  <v-app id="application">
    <app-nav-drawer v-show="isAuthenticated"></app-nav-drawer>
    <v-main>
      <app-environment-banner v-if="isEnvironmentBannerVisible"></app-environment-banner>
      <v-container fluid>
        <app-tab-nav-drawer v-if="getJobTabs.length > 0"></app-tab-nav-drawer>
        <app-full-screen-progress-bar-circular
          v-if="getIsLoadingAppFullScreen.isLoading"
          :loading-text="getIsLoadingAppFullScreen.loadingText"
          :is-enabled="getIsLoadingAppFullScreen.isLoading"
          :is-indeterminate-loading-state="getIsLoadingAppFullScreen.isLoading"
        />
        <transition name="fade" mode="out-in">
          <router-view v-if="getIsSelectedOperatingUnitIdSet" :key="$route.fullPath"></router-view>
        </transition>
        <app-snackbar-presenter></app-snackbar-presenter>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppSnackbarPresenter from "@/components/AppSnackbarPresenter"
import AppNavDrawer from "@/components/AppNavDrawer"
import AppFullScreenProgressBarCircular from "@/components/AppFullScreenProgressBarCircular"
import { mapGetters, mapActions } from "vuex"
import { consoleLog } from "@/utils/Logging"
import socketioService from "@/notifications/socketio-service"
import { formatAsDateOnly } from "@/utils/DateTimeFormatters"
import snackbarBuilder from "@/utils/SnackbarBuilder"
import { stgTheme } from "./utils/Themes"
import { EnvironmentVariant } from "./enums/Environments"
import AppTabNavDrawer from "@/components/AppTabNavDrawer.vue"
import AppEnvironmentBanner from "@/components/AppEnvironmentBanner.vue"

export default {
  components: { AppEnvironmentBanner, AppTabNavDrawer, AppFullScreenProgressBarCircular, AppNavDrawer, AppSnackbarPresenter },
  data() {
    return {
      priorMetadata: undefined // Track what the user's prior subscription is for happy path scenarios; must unsubscribe from prior events.
    }
  },
  computed: {
    ...mapGetters(["getIsLoadingAppFullScreen", "getSelectedOperatingUnitId", "getJobTabs", "getAppEnvironment", "getIsSelectedOperatingUnitIdSet"]),
    ...mapGetters("JobQueue", ["getScheduledDate"]),
    isAuthenticated() {
      consoleLog("this.$msal. = ", this.$msal)
      consoleLog("this.$msal.isAuthenticated = ", this.$msal.isAuthenticated)
      return this.$msal.isAuthenticated ?? false
    },
    isEnvironmentBannerVisible() {
      return this.getAppEnvironment === EnvironmentVariant.STAGE
    }
  },
  methods: {
    ...mapActions(["addSnackbar", "fetchActiveFranchisesForUserByOperatingUnitIds", "setHubConnectionState"]),
    ...mapActions("JobQueue", ["addJobToQueue", "removeJobFromQueue"]),
    setTheme() {
      if (this.getAppEnvironment === EnvironmentVariant.STAGE) {
        this.$vuetify.theme.dark = false
        this.$vuetify.theme.themes["light"] = stgTheme
      } else {
        this.$vuetify.theme.dark = localStorage.getItem("junkIsDarkThemeEnabled")?.toLowerCase() === "true" ?? false
      }
    }
  },
  async created() {
    consoleLog("$msal.getUserHasReadQueueNotifications() = ", this.$msal.getUserHasReadQueueNotifications())
    this.setTheme()

    this.$eventBus.$on("operating-unit-updated", async metadata => {
      console.info("MetadataFilter: operating-unit-updated triggered")
      console.log("MetadataFilter: metadata = ", JSON.stringify(metadata, null, 4))
      // todo: disconnect from our previous connection and recreate a signalR connection.
      await this.fetchActiveFranchisesForUserByOperatingUnitIds()
      if (this.$msal.getUserHasReadQueueNotifications()) {
        socketioService.setupSocketConnection()
        console.log("redis.emit")
        console.log("redis.socket = ", socketioService.socket)
        // socketioService.socket.emit("join-operating-unit", metadata.id)

        socketioService.socket.on("OnJobPreferredTimeSlotChanged", async dto => {
          console.log("Data = ", dto)
          if (dto.operatingUnitId === this.getSelectedOperatingUnitId && this.getScheduledDate === formatAsDateOnly(dto.scheduledDate)) {
            await this.addJobToQueue(dto)
          } else {
            await this.removeJobFromQueue(dto.jobId)
          }
        })

        socketioService.socket.on("OnJobCancelled", async id => {
          await this.removeJobFromQueue(id)
        })

        socketioService.socket.on("OnJobClaimed", async dto => {
          console.log("Data = ", dto)
          if (dto.operatingUnitId === this.getSelectedOperatingUnitId && this.getScheduledDate === formatAsDateOnly(dto.scheduledDate)) {
            await this.addJobToQueue(dto)
          } else {
            await this.removeJobFromQueue(dto.jobId)
          }
        })

        socketioService.socket.on("OnJobClosed", async dto => {
          console.log("Data = ", dto)
          if (dto.operatingUnitId === this.getSelectedOperatingUnitId && this.getScheduledDate === formatAsDateOnly(dto.scheduledDate)) {
            await this.addJobToQueue(dto)
          } else {
            await this.removeJobFromQueue(dto.jobId)
          }
        })

        socketioService.socket.on("OnJobCompleted", async dto => {
          console.log("Data = ", dto)
          if (dto.operatingUnitId === this.getSelectedOperatingUnitId && this.getScheduledDate === formatAsDateOnly(dto.scheduledDate)) {
            await this.addJobToQueue(dto)
          } else {
            await this.removeJobFromQueue(dto.jobId)
          }
        })

        socketioService.socket.on("OnJobDisclaimed", async dto => {
          console.log("Data = ", dto)
          if (dto.operatingUnitId === this.getSelectedOperatingUnitId && this.getScheduledDate === formatAsDateOnly(dto.scheduledDate)) {
            await this.addJobToQueue(dto)
          } else {
            await this.removeJobFromQueue(dto.jobId)
          }
        })

        socketioService.socket.on("OnJobScheduled", async dto => {
          console.log("Data = ", dto)
          if (dto.operatingUnitId === this.getSelectedOperatingUnitId && this.getScheduledDate === formatAsDateOnly(dto.scheduledDate)) {
            await this.addSnackbar(snackbarBuilder.infoSnackbar("A new job appeared on the schedule!", 5000))
            await this.addJobToQueue(dto)
            this.$eventBus.$emit("update-distance-from-me")
          } else {
            await this.removeJobFromQueue(dto.jobId)
          }
        })

        socketioService.socket.on("OnJobPreferredTimeSlotChanged", async dto => {
          console.log("Data = ", dto)
          if (dto.operatingUnitId === this.getSelectedOperatingUnitId && this.getScheduledDate === formatAsDateOnly(dto.scheduledDate)) {
            await this.addJobToQueue(dto)
          } else {
            await this.removeJobFromQueue(dto.jobId)
          }
        })

        socketioService.socket.on("OnJobScheduledDateChanged", async dto => {
          console.log("Data = ", dto)
          if (dto.operatingUnitId === this.getSelectedOperatingUnitId && this.getScheduledDate === formatAsDateOnly(dto.scheduledDate)) {
            await this.addJobToQueue(dto)
            this.$eventBus.$emit("update-distance-from-me")
          } else {
            await this.removeJobFromQueue(dto.jobId)
          }
        })

        socketioService.socket.on("OnFranchiseChanged", async dto => {
          console.log("Data = ", dto)
          if (dto.operatingUnitId === this.getSelectedOperatingUnitId && this.getScheduledDate === formatAsDateOnly(dto.scheduledDate)) {
            await this.addJobToQueue(dto)
            this.$eventBus.$emit("update-distance-from-me")
          } else {
            await this.removeJobFromQueue(dto.jobId)
          }
        })

        try {
          socketioService.socket.emit("join-operating-unit", metadata.id)
          console.log("redis.success subscribing to operating unit")
          await this.setHubConnectionState(true)
        } catch (e) {
          console.error("redis.Error subscribing to operating unit = ", e)
          await this.setHubConnectionState(false)
        }
      } else {
        console.log("redis.else, not connecting.")
      }
    })
  },
  destroyed() {
    this.$eventBus.$off("operating-unit-updated")
  }
}
</script>

<style>
#application {
  background-color: var(--v-background-base);
}

tr.v-data-table__selected {
  background: var(--v-primaryLight-base) !important;
}

.v-card,
.v-skeleton-loader,
.v-stepper {
  border-radius: 16px !important;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.cursor_pointer input {
  cursor: pointer;
}

.card_right_radius {
  border-radius: 0px 16px 16px 0px !important;
}

.v-treeview-node__label {
  cursor: pointer;
}

.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}

.cursor_disabled {
  cursor: not-allowed;
}

.primary-light-solid-border {
  border-style: solid;
  border-color: var(--v-primaryLight-base) !important;
}

.primary-solid-border {
  border-style: solid;
  border-color: var(--v-primaryLight-base) !important;
}

.word-wrap {
  word-wrap: break-word;
  word-break: normal;
}
</style>

<style lang="scss">
tbody {
  tr:hover {
    background-color: var(--v-dataTableHoverColor-base) !important;
  }
}
</style>
