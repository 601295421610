<template>
  <base-dialog ref="job-add-customer-address-dialog-root" title="Add Existing Addresses to Job" :is-dialog-visible="isDialogVisible" dialog-image="mdi-map-marker-plus-outline">
    <template v-slot:content>
      <div ref="job-add-customer-address-dialog-info" class="font-italic justify-center text-center mt-n4 mb-4">If you wish to create a new address for this customer, please select "Search Addresses", below.</div>
      <v-list rounded class="ma-0 pa-0 ms-n4">
        <v-list-item-group v-model="selectedAddresses" multiple>
          <template v-for="(address, index) in getAllCustomerContactAddresses">
            <v-list-item class="py-1 mt-3 text-md-body-1" :key="`item-${index}`" :value="address.id" active-class="activeSelectedItem" ripple>
              <template v-slot:default="{ active }">
                <v-list-item-action class="me-0 pe-4">
                  <v-checkbox :input-value="active" color="white"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap" ref="job-add-customer-address-dialog-address-item-title" v-text="formatAddressItemText(address)"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="job-add-customer-address-dialog-cancel-btn" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn
        class="pa-4"
        ref="job-add-customer-address-dialog-search-btn"
        color="primaryText"
        @click="setIsAddAddressDialogVisible(true)"
        :disabled="getIsLoadingCustomerAddresses"
        text
        rounded
        ripple
        >Search Addresses
      </v-btn>

      <v-btn
        class="pa-4"
        ref="job-add-customer-address-dialog-confirm-btn"
        color="primary"
        @click="save"
        :loading="getIsLoadingJobAddresses"
        :disabled="isSaveButtonDisabled"
        text
        rounded
        ripple
        >Confirm</v-btn
      >
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { mapActions, mapGetters } from "vuex"
import formatAddressText from "@/utils/FormatAddressText"

export default {
  name: "JobAddCustomerAddressDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedAddresses: []
    }
  },
  methods: {
    ...mapActions("Job", ["addJunkJobAddresses", "fetchCustomerAddresses"]),
    formatAddressItemText(item) {
      return formatAddressText.formatAddressText(item)
    },
    async save() {
      await this.addJunkJobAddresses(this.selectedAddresses).then(() => this.closeDialog())
    },
    closeDialog() {
      console.log("Closing...")
      this.$emit("closeDialog")
    },
    setIsAddAddressDialogVisible() {
      this.$emit("isAddressSearchDialogVisible", true)
    }
  },
  computed: {
    ...mapGetters("Job", ["getAllCustomerContactAddresses", "getIsLoadingJobAddresses", "getIsLoadingJobAddressesReorder", "getCurrentJob", "getIsLoadingCustomerAddresses"]),
    isSaveButtonDisabled() {
      return this.getIsLoadingJobAddresses || this.getIsLoadingJobAddressesReorder || this.selectedAddresses.length <= 0 || this.getIsLoadingCustomerAddresses
    },
    isSearchButtonDisabled() {
      return this.getIsLoadingCustomerAddresses
    }
  },
  async created() {
    console.log("Customer id to search addresses for: ", this.getCurrentJob.customerId)
    await this.fetchCustomerAddresses(this.getCurrentJob.customerId).then(customerAddresses => {
      if (customerAddresses.length === 0) this.setIsAddAddressDialogVisible(true)
    })
  }
}
</script>

<style scoped>
.activeSelectedItem {
  background-color: var(--v-primary-base) !important;
  font-weight: bold;
  color: white;
  opacity: 0.92;
}
</style>
