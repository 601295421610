/**
 * Given a positive or negative number, will round to the desired scale. By default, the scale is set to 2, i.e. - will return a formatted value with two decimal places.
 * For example, supplying a number of 10 will return 10.00.
 * @param number
 * @param scale
 * @returns {number}
 */
export function round(number, scale = 2) {
  let desiredScale = Math.pow(10, scale)
  let isNumberNegative = number < 0

  if (isNumberNegative) {
    number = Math.abs(number)
  }

  let numberToFixed = parseFloat((number * desiredScale).toFixed(11))
  let result = Math.round(numberToFixed) / desiredScale

  if (isNumberNegative) {
    result = -result
  }
  return result
}
