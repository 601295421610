export function filterByTotalDistance(job, distanceFilter) {
  if ((distanceFilter?.lower && distanceFilter?.upper) || distanceFilter?.lower === 0) {
    return job.totalDistance >= distanceFilter.lower && job.totalDistance < distanceFilter.upper
  } else {
    return job
  }
}

export function filterByPreferredTimeSlot(job, preferredTimeSlots) {
  if (preferredTimeSlots.length > 0) {
    return preferredTimeSlots.map(p => p.id).includes(job.preferredTimeSlotId)
  } else {
    return job
  }
}

export function filterBySelectedFranchise(job, selectedFranchises) {
  if (selectedFranchises.length > 0) {
    return selectedFranchises.map(f => f.franchiseId).includes(job.franchiseId)
  } else {
    return job
  }
}

export function filterBySelectedStatuses(job, selectedStatuses) {
  if (selectedStatuses?.length > 0) {
    return selectedStatuses.map(g => g.id).includes(job.jobStatusId)
  } else {
    return job
  }
}

export function filterBySelectedTruckCapacity(job, selectedTruckCapacity) {
  if (selectedTruckCapacity?.id) {
    return selectedTruckCapacity.id >= job.capacityId
  } else {
    return job
  }
}
