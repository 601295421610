<template>
  <v-card ref="job-modifiers-card-root" elevation="4" class="flex d-flex flex-column fill-height" style="min-width: 200px" :loading="getIsLoading">
    <v-card-title ref="job-modifiers-card-title" class="headline">Modifiers</v-card-title>
    <v-card-text class="mt-n1 px-8 justify-center">
      <v-row>
        <v-col class="flex-fill">
          <span class="text-md-body-1 font-weight-bold" ref="job-modifiers-tax-group-header"
            >Total Tax Applied: <app-number-formatter :amount="getTotalTax" currencySign="accounting" numberFormatStyle="currency"></app-number-formatter>
          </span>
          <v-radio-group v-if="getIsTaxSelectorVisible" class="mt-2 flex-fill fill-height" :value="getCurrentJob.franchiseTaxId" :key="franchiseKey" :disabled="getIsActionsDisabled" mandatory>
            <v-radio
              ref="job-modifiers-tax-group-radio-btn"
              v-for="franchiseTax in getActiveFranchiseTaxes"
              :key="franchiseTax.franchiseTaxId"
              :label="franchiseTax.formattedNameAndRate"
              :value="franchiseTax.franchiseTaxId"
              @click="franchiseTaxChangeHandler(franchiseTax)"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <job-discount-selector></job-discount-selector>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AppNumberFormatter from "@/components/AppNumberFormatter"
import JobDiscountSelector from "@/components/JobDiscountSelector"
import { mapActions, mapGetters } from "vuex"
import { putJobSelectedFranchiseTaxDto } from "@/api/dtos/JunkDtos"

export default {
  name: "JobModifiersCard",
  components: { JobDiscountSelector, AppNumberFormatter },
  data() {
    return {
      franchiseKey: 0
    }
  },
  methods: {
    ...mapActions("Job", ["updateJobSelectedFranchiseTax"]),
    async franchiseTaxChangeHandler(franchiseTax) {
      if (this.getCurrentJob?.franchiseTaxId !== franchiseTax.franchiseTaxId) {
        const dto = putJobSelectedFranchiseTaxDto(this.getCurrentJob.id, franchiseTax.franchiseTaxId)
        await this.updateJobSelectedFranchiseTax(dto).catch(() => this.updateFranchiseKeySelection())
      }
    },
    updateFranchiseKeySelection() {
      this.franchiseKey === 1 ? this.franchiseKey = 0 : this.franchiseKey = 1
    }
  },
  computed: {
    ...mapGetters("Job", [
      "getCurrentJob",
      "getIsLoadingJob",
      "getCurrentJobScheduledDateOrToday",
      "getCustomerContactsWithAddresses",
      "getIsLoadingJobModifiers",
      "getIsLoadingDiscount",
      "getActiveFranchiseTaxes",
      "getIsJobFieldsDisabled"
    ]),
    getTotalTax() {
      return this.getCurrentJob?.totalTax ?? 0
    },
    getIsActionsDisabled() {
      return this.getIsLoadingJobModifiers || this.getIsJobFieldsDisabled || this.getCustomerContactsWithAddresses?.isTaxExempt
    },
    getIsTaxSelectorVisible() {
      return !this.getCurrentJob?.isTaxExempt ?? false
    },
    getIsLoading() {
      return this.getIsLoadingDiscount || this.getIsLoadingJobModifiers
    }
  }
}
</script>
