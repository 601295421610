import { io } from 'socket.io-client';
import getEnv from "@/utils/Env"
import { redis } from "../../appsettings.json"
import store from "@/store/index"

class SocketioService {
  socket;
  constructor() {}

  setupSocketConnection() {
    if (!this.socket) {
      this.socket = io(getEnv("VUE_APP_NOTIFICATION_URL") || redis.VUE_APP_NOTIFICATION_URL, {
        secure: true,
        cors: {
          origin: "*",
          // extraHeaders: {
          //   "my-custom-header": "abcd"
          // }
        }
      })

      this.socket.on("connect", async () => {
        console.log("redis.set it green")
        await store.dispatch("setHubConnectionState", true)
      })

      this.socket.on("error", async () => {
        console.log("redis.set it red")
        await store.dispatch("setHubConnectionState", false)
      })
    }
  }
}

export default new SocketioService();