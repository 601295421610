<template>
  <v-container fluid>
    <base-dialog title="Which Franchise?" :is-dialog-visible="isDialogVisible" dialog-image="mdi-receipt-text-plus-outline">
      <template v-slot:content>
        <v-form ref="app-create-job-franchise-selector-dialog-form">
          <v-row dense>
            <v-col>
              <v-autocomplete
                ref="app-create-job-franchise-selector"
                label="Selected Franchise *"
                v-model="selectedFranchise"
                :items="getUserActiveFranchises"
                item-text="franchiseNameAndNumber"
                item-value="franchiseId"
                return-object
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="app-create-job-franchise-dialog-cancel-btn" color="secondary" @click="closeDialog" :disabled="false" text rounded ripple>Cancel</v-btn>
        <v-btn class="pa-4" ref="app-create-job-franchise-dialog-confirm-btn" color="primary" @click="save" :loading="isLoading" :disabled="isConfirmDisabled" text rounded ripple>Confirm</v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "AppCreateJobFranchiseSelector",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedFranchise: {}
    }
  },
  computed: {
    ...mapGetters(["getUserActiveFranchises"]),
    isConfirmDisabled() {
      return Object.keys(this.selectedFranchise).length === 0 || this.isLoading
    }
  },
  methods: {
    ...mapActions(["fetchActiveFranchisesForUserByOperatingUnitIds"]),
    save() {
      this.$emit("save", this.selectedFranchise)
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  async created() {
    if (this.getUserActiveFranchises.length === 0) await this.fetchActiveFranchisesForUserByOperatingUnitIds()
  }
}
</script>

<style scoped></style>
