import * as msal from "@azure/msal-browser"
import getEnv from "@/utils/Env"
import { azureauth } from "../../appsettings.json"

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
    clientId: getEnv("VUE_APP_AZURE_CLIENT_ID") || azureauth.VUE_APP_AZURE_CLIENT_ID, //"12408ce1-55dd-443b-8036-85251b59848a",
    // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
    authority: getEnv("VUE_APP_AZURE_AUTHORITY") || azureauth.VUE_APP_AZURE_AUTHORITY, //"https://login.microsoftonline.com/f6078cd4-5d0c-451b-b2eb-ba53ec719095",
    // Full redirect URL, in form of http://localhost:3000
    redirectUri: getEnv("VUE_APP_AZURE_REDIRECT_URI") || azureauth.VUE_APP_AZURE_REDIRECT_URI
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: { // TODO: NOTE: We don't want this in prod
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message)
            return
          case msal.LogLevel.Info:
            console.info(message)
            return
          case msal.LogLevel.Verbose:
            console.debug(message)
            return
          case msal.LogLevel.Warning:
            console.warn(message)
            return
        }
      }
    }
  }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
// export const loginRequest = {
//   // TODO: Extract to app configuration.
//   scopes: ["api://8635a5d4-38f4-4b2e-832c-3ea06b2c7656/.default"]
// }

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
// export const tokenRequest = {
//   // TODO: Extract to app configuration.
//   scopes: ["api://8635a5d4-38f4-4b2e-832c-3ea06b2c7656/.default"],
//   forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
// }
