<template>
  <v-menu ref="app-date-range-menu-picker-menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="min-content">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="app-date-range-menu-picker-text"
        :class="textFieldClass"
        :value="dateRangeText"
        :disabled="isDisabled"
        :readonly="true"
        :outlined="isTextFieldOutlined"
        label="Selected Date Range"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      elevation="16"
      class="transparent"
      ref="app-date-range-menu-picker-picker"
      v-model="selectedDateRange"
      range
      color="primary"
      :min="minimumDateValue"
      :max="maximumDateValue"
      @input="$emit('updatedRange', sortedDateRange)"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from "dayjs"
import { todayAsDate } from "@/utils/DateTimeFormatters"

export default {
  name: "AppDateRangeMenuPicker",
  props: {
    dateRange: {
      type: Array,
      default: () => [todayAsDate()]
    },
    minimumDateValue: {
      type: String,
      required: false
    },
    maximumDateValue: {
      type: String,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isTextFieldOutlined: {
      type: Boolean,
      default: true
    },
    textFieldDateFormat: {
      type: String,
      default: "dddd, MMMM DD, YYYY"
    },
    isOverrideAutoAdjustTextFieldDateFormatEnabled: {
      type: Boolean,
      default: false
    },
    autoAdjustedTextFieldDateFormat: {
      type: String,
      default: "MM/DD/YYYY"
    },
    textFieldClass: {
      type: String,
      default: "text-md-body-1 d-inline-flex"
    }
  },
  data() {
    return {
      selectedDateRange: this.dateRange
    }
  },
  computed: {
    dateRangeText() {
      return this.sortedDateRange
        .map(element => {
          return new dayjs(element).format(this.formatTextFieldDate)
        })
        .join(" thru ")
    },
    sortedDateRange() {
      return this.selectedDateRange.slice().sort()
    },
    formatTextFieldDate() {
      return !this.isOverrideAutoAdjustTextFieldDateFormatEnabled && this.$vuetify.breakpoint.smAndDown ? this.autoAdjustedTextFieldDateFormat : this.textFieldDateFormat
    }
  }
}
</script>

<style scoped>
.v-picker__title {
  background-color: black;
  border-radius: 0px;
  border-style: solid;
}
</style>
