export default {
  formatPhoneNumber(phoneNumberString) {
    const cleaned = phoneNumberString?.replace(/\D/g, "")
    const cleanedLength = cleaned?.length ?? 0

    if (cleanedLength > 10) {
      let regex = new RegExp(`^([0-9]{0,${cleanedLength - 10}})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})`)
      let match = cleaned.match(regex)
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
    }
    if (cleanedLength === 10) {
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      return `(${match[1]}) ${match[2]}-${match[3]}`
    }
    if (cleanedLength === 7) {
      let match = cleaned.match(/^(\d{3})(\d{4})$/)
      return `${match[1]}-${match[2]}`
    }

    return phoneNumberString
  }
}
