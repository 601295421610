<template>
  <v-tooltip content-class="elevation-8" :color="tooltipColor" :nudge-right="nudgeDialog" :bottom="isBottomDialog" :top="isTopDialog" :right="isRightDialog" close-delay="50">
    <template v-slot:activator="{ on, attrs }">
      <v-icon :color="iconColor" @click.stop="$emit('tooltipClicked')" v-bind="attrs" v-on="on">{{ tooltipIcon }}</v-icon>
    </template>
    <span class="headline mt-2">{{ dialogTitle }}</span>
    <slot name="content"></slot>
  </v-tooltip>
</template>

<script>
export default {
  name: "BaseToolTip",
  props: {
    tooltipColor: {
      type: String,
      default: "primary"
    },
    iconColor: {
      type: String,
      default: "primary"
    },
    dialogTitle: {
      type: String,
      default: "Title"
    },
    tooltipIcon: {
      type: String,
      default: "mdi-blank"
    },
    nudgeDialog: {
      type: String,
      default: "50"
    },
    isTopDialog: {
      type: Boolean,
      default: false
    },
    isBottomDialog: {
      type: Boolean,
      default: false
    },
    isRightDialog: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped></style>
