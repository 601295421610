<template>
  <base-dialog ref="app-edit-contact-dialog-root" title="Edit Contact" :is-dialog-visible="isDialogVisible" dialog-image="mdi-account-plus-outline">
    <template class="flex-fill" v-slot:content>
      <v-form class="ma-0 pa-0" ref="app-edit-contact-dialog-form" v-model="isEditContactDialogFieldsValid">
        <v-row dense>
          <v-col>
            <v-text-field ref="app-edit-contact-dialog-first-name-text" label="First Name *" v-model="firstName" outlined :rules="isRequiredRules" counter="50" @keyup.enter="save" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-text-field ref="app-edit-contact-dialog-last-name-text" label="Last Name *" v-model="lastName" outlined :rules="isRequiredRules" counter="50" @keyup.enter="save" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-phone-input class="d-flex" ref="app-edit-contact-dialog-phone-primary-text" label="Primary Phone Number *" v-model="phonePrimary" :rules="isRequiredRules" outlined validate-on-blur @keyup.enter="save" />
          </v-col>
          <v-col cols="3">
            <v-text-field ref="app-edit-contact-dialog-phone-primary-ext-text" label="Extension" v-model="phonePrimaryExtension" type="number" outlined counter="6" @keyup.enter="save" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-phone-input class="d-flex" ref="app-edit-contact-dialog-phone-secondary-text" label="Secondary Phone Number" v-model="phoneSecondary" outlined validate-on-blur @keyup.enter="save" />
          </v-col>
          <v-col cols="3">
            <v-text-field ref="app-edit-contact-dialog-phone-secondary-ext-text" label="Extension" v-model="phoneSecondaryExtension" outlined counter="6" @keyup.enter="save" />
          </v-col>
        </v-row>

        <v-row dense>
          <div v-if="refusedToProvideEmail" class="mt-n2 ps-1 pb-2" v-html="franchiseEmailUsedMessage" style="color: var(--v-primary-base)" />
          <v-col class="flex-column">
            <v-text-field ref="app-edit-contact-dialog-email-address-text" label="Email Address *" v-model="email" :disabled="refusedToProvideEmail" outlined counter="50" validate-on-blur @keyup.enter="save" :rules="emailRules" />
          </v-col>
          <v-col cols="3" class="flex-column">
<!--            <v-checkbox class="align-center mt-1" label="Refused to Provide" :input-value="refusedToProvideEmail" @change="setIsEmailOptedOut($event)" color="primary" :disabled="isActionsDisabled" />-->
            <v-checkbox class="align-center mt-1" label="Refused to Provide" :false-value="false" :true-value="true" :value="refusedToProvideEmail" :input-value="refusedToProvideEmail" @change="setIsEmailOptedOut($event)"></v-checkbox>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-select class="cursor_pointer" ref="app-edit-contact-dialog-preferred-contact-method-selector" label="Preferred Method of Contact *" :rules="preferredMethodOfContactRules" v-model="preferredMethodOfContactId" :items="getPreferredMethodOfContactsFiltered" item-value="id" item-text="name" validate-on-blur outlined :menu-props="{ bottom: true, offsetY: true }" />
          </v-col>
        </v-row>

        <v-row dense class="my-n6">
          <v-col>
            <v-checkbox ref="app-edit-contact-dialog-primary-contact-checkbox" v-model="isPrimaryContact" label="Primary Contact" :disabled="contact.isPrimary" />
          </v-col>
          <v-col>
            <v-checkbox ref="app-edit-contact-dialog-email-message-opt-in-checkbox" v-model="isOptedIntoEmailMessaging" label="Opted-In to Email Messaging" />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="app-edit-contact-dialog-cancel-btn" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" ref="app-edit-contact-dialog-confirm-btn" color="primary" @click="save" :loading="isLoading" :disabled="isLoading" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { mapGetters } from "vuex"
import { PreferredMethodOfContact } from "@/enums/PreferredMethodOfContacts"

export default {
  name: "AppEditContactDialog",
  components: { BaseDialog },
  props: {
    contact: Object,
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    franchiseId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      id: undefined,
      firstName: "",
      lastName: "",
      businessName: undefined,
      phonePrimary: "",
      phonePrimaryExtension: undefined,
      phoneSecondary: undefined,
      phoneSecondaryExtension: undefined,
      email: undefined,
      isOptedIntoTextMessaging: false,
      isOptedIntoEmailMessaging: false,
      selectedCustomerType: 0,
      preferredMethodOfContactId: undefined,
      refusedToProvideEmail: false,
      isPrimaryContact: false,
      isEditContactDialogFieldsValid: false,
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."],
      emailRules: [v => /.+@.+\..+/.test((v ?? "").trim()) || "E-mail must be valid"],
      preferredMethodOfContactRules: [value => !(value === 2 && (this.email || "").length <= 0) || "Please provide an email address or select a different method of contact"]
    }
  },
  methods: {
    async save() {
      if (this.$refs["app-edit-contact-dialog-form"].validate()) {
        const dto = {
          id: this.id,
          firstName: this.firstName,
          lastName: this.lastName,
          phonePrimary: this.phonePrimary,
          phonePrimaryExtension: this.phonePrimaryExtension,
          phoneSecondary: this.phoneSecondary,
          phoneSecondaryExtension: this.phoneSecondaryExtension,
          email: this.email?.length > 0 ? this.email : null,
          preferredMethodOfContactId: this.preferredMethodOfContactId,
          isOptedIntoTextMessaging: this.isOptedIntoTextMessaging,
          isOptedIntoEmailMessaging: this.isOptedIntoEmailMessaging,
          isPrimary: this.isPrimaryContact,
          refusedToProvideEmail: this.refusedToProvideEmail
        }

        this.$emit("editContact", dto)
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    setIsEmailOptedOut(isOptedOut) {
      this.refusedToProvideEmail = isOptedOut
      if (isOptedOut) {
        this.setEmailToSelectedFranchise(this.franchiseId)
      } else {
        this.email = this.contact.emailPrimary
      }
    },
    setEmailToSelectedFranchise(franchiseId) {
      let franchiseEmailAddress = this.getFranchiseEmailByFranchiseId(franchiseId)
      this.email = franchiseEmailAddress !== undefined ? franchiseEmailAddress : ""
    },
    setIsOptedIntoTextMessaging(isOptedIn) {
      this.isOptedIntoTextMessaging = isOptedIn
      if (!isOptedIn && this.preferredMethodOfContactId === PreferredMethodOfContact.TEXT.id) {
        this.preferredMethodOfContactId = PreferredMethodOfContact.PHONE.id
      }
    }
  },
  computed: {
    ...mapGetters(["getPreferredMethodOfContacts", "getFranchiseEmailByFranchiseId"]),
    getPreferredMethodOfContactsFiltered() {
      let preferredMethodOfContacts = JSON.parse(JSON.stringify(this.getPreferredMethodOfContacts))
      if (!this.isOptedIntoTextMessaging) {
        preferredMethodOfContacts = preferredMethodOfContacts.filter(contactMethod => contactMethod.id !== PreferredMethodOfContact.TEXT.id)
      }
      if (this.refusedToProvideEmail) {
        preferredMethodOfContacts = preferredMethodOfContacts.filter(contactMethod => contactMethod.id !== PreferredMethodOfContact.EMAIL.id)
      }
      return preferredMethodOfContacts
    },
    franchiseEmailUsedMessage() {
      return "* By selecting <em>Refused to Provide</em>, the franchise's email address will be stored for this customer instead. The franchise will be responsible for passing along any communications that would've been emailed to the customer."
    }
  },
  created() {
    console.log("Edit contact dto: ", JSON.stringify(this.contact, null,4))
    this.id = this.contact.id
    this.firstName = this.contact.firstName
    this.lastName = this.contact.lastName
    this.phonePrimary = this.contact.phonePrimary
    this.phonePrimaryExtension = this.contact.phonePrimaryExtension
    this.phoneSecondary = this.contact.phoneSecondary
    this.phoneSecondaryExtension = this.contact.phoneSecondaryExtension
    this.email = this.contact.refusedToProvideEmail ? this.getFranchiseEmailByFranchiseId(this.franchiseId) : this.contact.emailPrimary
    this.preferredMethodOfContact = this.contact.preferredMethodOfContact
    this.preferredMethodOfContactId = this.contact.preferredMethodOfContactId
    this.isOptedIntoTextMessaging = this.contact.isOptedIntoTextMessaging
    this.isOptedIntoEmailMessaging = this.contact.isOptedIntoEmailMessaging
    this.isPrimaryContact = this.contact.isPrimary
    this.refusedToProvideEmail = this.contact.refusedToProvideEmail
  }
}
</script>

<style scoped></style>
