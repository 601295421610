<template>
  <dashboard-chart-card ref="dashboard-revenue-chard-card-root" class="fill-height" title="Revenue" sub-card-text="DAILY" :is-loading="getIsLoadingOperatingUnitRevenue">
    <template v-slot:content>
      <v-card-text class="fill-height mt-n4">
        <v-row class="fill-height ma-0 pa-0">
          <v-col cols="auto" class="ma-0 pa-0 col-lg-9">
            <g-chart
              id="dashboard-revenue-chart-card-chart"
              class="ma-0 pa-0 flex-fill fill-height align-content-stretch"
              :type="chartType"
              :data="getChartData"
              :options="chartOptions"
              style="min-height: 400px"
            />
          </v-col>
          <v-col cols="auto" class="col-lg-3 ms-n4">
            <v-list-item two-line ref="dashboard-revenue-chard-card-confirmed-list-item">
              <v-list-item-avatar rounded color="#a1d699">
                <base-tool-tip
                  dialog-title="Closed"
                  tooltip-color="#a1d699"
                  tooltip-icon="mdi-trending-up"
                  :is-bottom-dialog="true"
                  icon-color="white"
                  nudgeDialog="0"
                ></base-tool-tip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ getFormattedConfirmedRevenue }}</v-list-item-title>
                <v-list-item-subtitle>Confirmed</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line ref="dashboard-revenue-chard-card-pending-list-item">
              <v-list-item-avatar rounded color="var(--v-accent-lighten1)">
                <base-tool-tip
                  dialog-title="Created, Scheduled, Claimed, Completed"
                  tooltip-color="var(--v-accent-lighten1)"
                  tooltip-icon="mdi-clock-outline"
                  :is-bottom-dialog="true"
                  icon-color="white"
                  nudgeDialog="0"
                ></base-tool-tip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ getFormattedPendingRevenue }}</v-list-item-title>
                <v-list-item-subtitle>Pending</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line ref="dashboard-revenue-chard-card-cancelled-list-item">
              <v-list-item-avatar rounded :color="getCancelledApplicationColor">
                <v-icon color="white">mdi-cancel</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ getCancelledCount }}</v-list-item-title>
                <v-list-item-subtitle>Cancelled</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line ref="dashboard-revenue-chard-card-average-lifecycle-time-list-item">
              <v-list-item-avatar rounded color="#96A5AE">
                <base-tool-tip
                  dialog-title="Avg. Time from Created to Completed"
                  tooltip-color="#96A5AE"
                  tooltip-icon="mdi-clock-fast"
                  :is-bottom-dialog="true"
                  icon-color="white"
                  nudgeDialog="0"
                ></base-tool-tip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ getAverageJobLifecycleTimeInHours }}</v-list-item-title>
                <v-list-item-subtitle>Avg Completion Hrs</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </dashboard-chart-card>
</template>

<script>
import { GChart } from "vue-google-charts/legacy"
import applicationColors from "@/utils/ApplicationColors"
import DashboardChartCard from "@/components/DashboardChartCard"
import { mapGetters } from "vuex"
import { getJobStatusColorByStatusName } from "@/utils/JobStatusColors"
import { JobStatus } from "@/enums/JobStatus"
import { formatInternationalCurrency } from "@/utils/InternationalCurrencyFormatters"
import BaseToolTip from "@/components/BaseToolTip"

export default {
  name: "DashboardRevenueChartCard",
  components: { BaseToolTip, DashboardChartCard, "g-chart": GChart },
  data() {
    return {
      chartType: "ColumnChart"
    }
  },
  computed: {
    ...mapGetters("Dashboard", [
      "getCancelledCount",
      "getPendingRevenue",
      "getConfirmedRevenue",
      "getOperatingUnitRevenueData",
      "getIsLoadingOperatingUnitRevenue",
      "getCancelledCount",
      "getAverageJobLifecycleTimeInHours"
    ]),
    getFormattedConfirmedRevenue() {
      return formatInternationalCurrency(this.getConfirmedRevenue)
    },
    getFormattedPendingRevenue() {
      return formatInternationalCurrency(this.getPendingRevenue)
    },
    getChartData() {
      return [
        ["Day", "Confirmed", { role: "annotation" }, "Pending", { role: "annotation" }],
        ...this.getOperatingUnitRevenueData.map(a => [
          a.dateDisplay,
          a.confirmedRevenue,
          a.confirmedRevenue ? formatInternationalCurrency(a.confirmedRevenue) : "",
          a.pendingRevenue,
          a.pendingRevenue ? formatInternationalCurrency(a.pendingRevenue) : ""
        ])
      ]
    },
    getCancelledApplicationColor() {
      return getJobStatusColorByStatusName(JobStatus.CANCELLED.name)
    },
    isDarkThemeEnabled() {
      return this.$vuetify.theme.dark
    },
    chartOptions() {
      return {
        annotations: {
          textStyle: {
            fontName: "Times-Roman",
            bold: true,
            italic: true,
            color: "black",
            opacity: 0.8
          }
        },
        chart: {},
        bars: "vertical",
        backgroundColor: "transparent",
        isStacked: true,
        legend: "none",
        series: {
          0: {
            color: applicationColors.tmtGreen.pastelMedium,
            annotations: {
              stem: {
                length: -20
              }
            }
          },
          1: {
            color: applicationColors.tmtOrange.medium,
            annotations: {
              textStyle: { color: this.isDarkThemeEnabled ? applicationColors.junkGray.fifty : applicationColors.junkGray.eightHundred },
              stem: {
                length: 10
              }
            }
          }
        },
        vAxis: {
          title: "Revenue",
          format: "$#,###.##",
          titleTextStyle: { color: this.isDarkThemeEnabled ? applicationColors.junkGray.fifty : applicationColors.junkGray.eightHundred },
          textStyle: { color: this.isDarkThemeEnabled ? applicationColors.junkGray.fifty : applicationColors.junkGray.eightHundred }
        },
        hAxis: {
          title: "Days",
          titleTextStyle: { color: this.isDarkThemeEnabled ? applicationColors.junkGray.fifty : applicationColors.junkGray.eightHundred },
          textStyle: { color: this.isDarkThemeEnabled ? applicationColors.junkGray.fifty : applicationColors.junkGray.eightHundred }
        }
      }
    }
  }
}
</script>

<style scoped></style>
