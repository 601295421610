<template>
  <v-speed-dial ref="base-fab-speed-dial-root" v-model="fab" direction="top" :open-on-hover="true" transition="slide-x-reverse-transition" color="primary" large fixed bottom right>
    <template v-if="isVisible" v-slot:activator>
      <v-btn v-model="fab" color="primary" fab @click="handleBaseFabClick">
        <v-icon v-if="fab">mdi-close</v-icon>
        <v-icon v-else>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-hover ref="base-fab-item-hover" v-for="(element, index) in fabOptions" close-delay="50" open-delay="50" :key="index" v-slot="{ hover }">
      <v-btn
        ref="base-fab-item-btn"
        :color="hover ? 'primary' : 'secondary'"
        class="me-0 align-self-end"
        elevation="8"
        ripple
        :disabled="element.isFabDisabled"
        :fab="!hover"
        :rounded="hover"
        :x-large="hover"
        @click="element.onClickAction()"
      >
        <div v-show="hover" class="mx-2 align-self-center">{{ element.text }}</div>
        <v-icon v-show="hover" class="mx-1 pa-1 white primary--text" style="border-radius: 16px">{{ element.icon }}</v-icon>
        <v-icon v-show="!hover" class="transparent white--text">{{ element.icon }}</v-icon>
      </v-btn>
    </v-hover>
  </v-speed-dial>
</template>

<script>
// import { consoleLog } from "@/utils/Logging";

export default {
  name: "BaseFab",
  props: {
    fabOptions: {
      type: Array,
      default: () => [{ icon: "mdi-blank", text: "", isFabDisabled: false, onClickAction: undefined }]
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fab: false
    }
  },
  methods: {
    handleBaseFabClick() {
      console.log("handleButtonClick")
      this.fab = !this.fab
    }
  }
}
</script>

<style scoped></style>
