<template>
  <v-card class="primary-light-solid-border" elevation="0" ref="job-trucks-card-root">
    <v-card-title ref="job-trucks-card-title">Trucks</v-card-title>
    <v-card-text class="pb-2">
      <v-data-table
        ref="job-trucks-card-trucks-table"
        :headers="truckHeaders"
        :items="getTrucksOnJob"
        :loading="getIsLoadingTrucksAndEmployees"
        loader-height="0"
        loading-text="🔎 Fetching Trucks 🔍"
        mobile-breakpoint="900"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:no-data>
          <v-col class="ma-0 pa-0 align-self-center d-flex">
            <app-not-found title="No trucks!" content="This job does not currently have any trucks.">
              <template v-slot:actions>
                <div></div>
              </template>
            </app-not-found>
          </v-col>
        </template>
        <template v-slot:item.name="{ value }">
          <span class="text-md-body-1">{{ value }}</span>
        </template>
        <template v-slot:item.startDateTime="{ item }">
          <app-time-picker-menu
            text-field-label="Time"
            :time-value="formatTime(item.startDateTime)"
            @updateTimeValue="updateTruckStartDateTime(item, $event)"
            :allowed-minutes="allowedMinutes"
            :is-hide-details-enabled="true"
            :max-value="formatTime(item.endDateTime)"
            :is-disabled="isActionsDisabled"
          ></app-time-picker-menu>
        </template>
        <template v-slot:item.totalDownTime="{ item }">
          <v-text-field
            class="mt-4 mb-n4"
            ref="job-trucks-and-employees-truck-total-down-time-text"
            style="min-width: 100px"
            :value="formatDecimal(item.totalDownTime)"
            :rules="totalDownTimeValidationRules"
            @change="updateTruckTotalBreakTimeHours(item, $event)"
            :disabled="isActionsDisabled"
            validate-on-blur
            type="number"
            min="0"
            max="24"
            outlined
          ></v-text-field>
        </template>
        <template v-slot:item.endDateTime="{ item }">
          <app-time-picker-menu
            text-field-label="Time"
            :time-value="formatTime(item.endDateTime)"
            @updateTimeValue="updateTruckEndDateTime(item, $event)"
            :allowed-minutes="allowedMinutes"
            :is-hide-details-enabled="true"
            :min-value="formatTime(item.startDateTime)"
            :is-disabled="isActionsDisabled"
          ></app-time-picker-menu>
        </template>
        <template v-slot:item.totalHoursMinusDownTime="{ value }">
          <app-decimal-formatter :amount="value"></app-decimal-formatter>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn ref="job-trucks-and-employees-card-delete-truck-btn" @click="deleteTruck(item)" :disabled="isActionsDisabled" icon>
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <template v-if="isTruckTotalsItemVisible" v-slot:body.append>
          <tr>
            <td colspan="4">
              <div v-if="$vuetify.breakpoint.smAndDown"><strong>Totals Hours: </strong></div>
            </td>
            <td class="justify-center">
              <app-decimal-formatter :amount="totalTruckHours"></app-decimal-formatter>
            </td>
            <td colspan="1"></td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AppNotFound from "@/components/AppNotFound"
import AppDecimalFormatter from "@/components/AppDecimalFormatter"
import { formatTimeAsDateUtc } from "@/utils/DateTimeFormatters"
import { putJunkJobTruckEndDateTimeDto, putJunkJobTruckStartDateTimeDto, updateJunkJobTruckDownTimeDto } from "@/api/dtos/JunkDtos"
import { formatDecimalValue } from "@/utils/FormatDecimalValue"
import dayjs from "dayjs"
import { mapActions, mapGetters } from "vuex"
import AppTimePickerMenu from "@/components/AppTimePickerMenu"

export default {
  name: "JobTrucksCard",
  components: { AppTimePickerMenu, AppDecimalFormatter, AppNotFound },
  data() {
    return {
      truckHeaders: [
        { text: "Truck Name", align: "start", value: "name", width: "25%" },
        { text: "Start Time", align: "center", value: "startDateTime", width: "20%" },
        { text: "Down Time", align: "center", value: "totalDownTime" },
        { text: "End Time", align: "center", value: "endDateTime", width: "20%" },
        { text: "Total Hours", align: "center", value: "totalHoursMinusDownTime" },
        { text: "Actions", align: "end", value: "actions", sortable: false, width: "5%" }
      ],
      totalDownTimeValidationRules: [
        value => value >= 0 || "Time must be positive or zero.",
        value => value <= 24 || "Time must be less than 24 hours."
      ]
    }
  },
  methods: {
    ...mapActions("Job", ["deleteJunkJobTruck", "updateJunkJobTruckStartDateTime", "updateJunkJobTruckEndDateTime", "updateJunkJobTruckDownTime"]),
    allowedMinutes: m => m % 15 === 0,
    formatTime(value) {
      return new dayjs(value).format("HH:mm")
    },
    formatDecimal(value) {
      return formatDecimalValue(value)
    },
    async deleteTruck(item) {
      await this.deleteJunkJobTruck(item.jobTruckId)
    },
    async updateTruckStartDateTime(item, timeEvent) {
      const formattedStartDateTime = formatTimeAsDateUtc(this.getCurrentJobScheduledDateOrToday, timeEvent)
      const dto = putJunkJobTruckStartDateTimeDto(item.jobTruckId, formattedStartDateTime, this.$msal.getCurrentUserId())
      await this.updateJunkJobTruckStartDateTime(dto)
    },
    async updateTruckEndDateTime(item, timeEvent) {
      const formattedEndDateTime = formatTimeAsDateUtc(this.getCurrentJobScheduledDateOrToday, timeEvent)
      const dto = putJunkJobTruckEndDateTimeDto(item.jobTruckId, formattedEndDateTime, this.$msal.getCurrentUserId())
      await this.updateJunkJobTruckEndDateTime(dto)
    },
    async updateTruckTotalBreakTimeHours(item, downTimeEvent) {
      const downTimeEventFormatted = parseFloat(downTimeEvent)
      const downTime = isNaN(downTimeEventFormatted) ? formatDecimalValue(0) : downTimeEventFormatted
      const dto = updateJunkJobTruckDownTimeDto(item.jobTruckId, downTime, this.$msal.getCurrentUserId())

      await this.updateJunkJobTruckDownTime(dto)
    }
  },
  computed: {
    ...mapGetters("Job", ["getCurrentJob", "getCurrentJobScheduledDateOrToday", "getTrucksOnJob", "getIsLoadingTrucksAndEmployees", "getIsJobFieldsDisabled"]),
    isTruckTotalsItemVisible() {
      return this.getTrucksOnJob?.length > 1
    },
    totalTruckHours() {
      return this.getTrucksOnJob?.reduce((total, truck) => {
        return total + (truck?.totalHoursMinusDownTime ?? 0.0)
      }, 0)
    },
    isActionsDisabled() {
      return this.getIsLoadingTrucksAndEmployees || this.getIsJobFieldsDisabled
    }
  }
}
</script>
