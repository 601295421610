<template>
  <v-container fluid>
    <base-dialog ref="job-send-customer-communication-dialog-root" :is-dialog-visible="isDialogVisible" :title="message" :dialog-image="icon">
      <template v-slot:content>
        <v-card-text ref="job-send-customer-communication-dialog-text-caption" v-if="shouldDisplayTextCaption" class="text-center">
          Text will be sent to {{ primaryContactFullName }} at <strong>{{ primaryContactPhoneFormatted }}</strong>
        </v-card-text>
        <div ref="job-send-customer-communication-dialog-email-caption-container" v-if="shouldDisplayEmailCaption">
          <v-card-text ref="job-send-customer-communication-dialog-email-caption" v-if="!shouldDisplayJobStatementRecipients" class="text-center">
            Email will be sent to {{ primaryContactFullName }} at <strong>{{ getPrimaryContactDetails.emailPrimary }}</strong>
          </v-card-text>
          <v-card-text ref="job-send-customer-communication-dialog-email-caption" v-else class="text-center">
            {{ getSelectedEmailRecipientsCaptionText }}
            <v-card-text>
              <strong>{{ getSelectedEmailRecipientsFormattedList }}</strong>
            </v-card-text>
            <v-btn
              ref="job-send-customer-communication-dialog-email-caption-see-more-button"
              v-if="shouldDisplaySelectedEmailRecipientsListSeeMoreButton"
              x-small
              @click="toggleSelectedEmailRecipientsListExpanded"
            >
              {{ getToggleEmailRecipientsListViewButtonText }}
            </v-btn>
          </v-card-text>
        </div>
        <v-card-text class="mb-n4" v-if="shouldDisplayCommunicationOptions">
          <v-row class="justify-center">
            <v-col cols="auto">
              <v-row class="mx-4">
                <v-checkbox ref="job-send-customer-communication-dialog-text-checkbox" class="mx-2" v-model="isSendText" label="TEXT" />
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="mx-4">
                <v-checkbox ref="job-send-customer-communication-dialog-email-checkbox" class="mx-2" v-model="isSendEmail" @click="handleEmailCheckboxClick" label="EMAIL" />
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-form class="d-flex justify-center" v-if="shouldDisplayEtaTimeOptions">
          <v-col>
            <v-row>
              <v-col>
                <v-card
                  ref="job-send-customer-communication-dialog-eta-time-fifteen"
                  class="pt-4 px-4 text-center selectable"
                  :class="{ selected: selectedComponent === 'job-send-customer-communication-dialog-eta-time-fifteen' }"
                  @click.stop="updateProposedEta('job-send-customer-communication-dialog-eta-time-fifteen', 15)"
                >
                  <v-icon class="text-h4">
                    mdi-clock-time-three-outline
                  </v-icon>
                  <v-card-subtitle class="justify-center">
                    15 min
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col>
                <v-card
                  ref="job-send-customer-communication-dialog-eta-time-thirty"
                  class="pt-4 px-4 text-center selectable"
                  :class="{ selected: selectedComponent === 'job-send-customer-communication-dialog-eta-time-thirty' }"
                  @click.stop="updateProposedEta('job-send-customer-communication-dialog-eta-time-thirty', 30)"
                >
                  <v-icon class="text-h4">
                    mdi-clock-time-six-outline
                  </v-icon>
                  <v-card-subtitle class="justify-center">
                    30 min
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col>
                <v-card
                  ref="job-send-customer-communication-dialog-eta-time-forty-five"
                  class="pt-4 px-4 text-center selectable"
                  :class="{ selected: selectedComponent === 'job-send-customer-communication-dialog-eta-time-forty-five' }"
                  @click.stop="updateProposedEta('job-send-customer-communication-dialog-eta-time-forty-five', 45)"
                >
                  <v-icon class="text-h4">
                    mdi-clock-time-nine-outline
                  </v-icon>
                  <v-card-subtitle class="justify-center">
                    45 min
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col>
                <v-card
                  ref="job-send-customer-communication-dialog-eta-time-sixty"
                  class="pt-4 px-4 text-center selectable"
                  :class="{ selected: selectedComponent === 'job-send-customer-communication-dialog-eta-time-sixty' }"
                  @click.stop="updateProposedEta('job-send-customer-communication-dialog-eta-time-sixty', 60)"
                >
                  <v-icon class="text-h4">
                    mdi-clock-time-twelve-outline
                  </v-icon>
                  <v-card-subtitle class="justify-center">
                    1 hour
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-form>
        <job-receipt-recipients-card
          ref="job-receipt-resend-dialog-job-receipt-recipients-card"
          v-if="shouldDisplayJobStatementRecipients"
          :showIsPrimaryContactEmailNotProvidedDisclaimer="true"
        />
      </template>
      <template v-slot:actions>
        <v-btn ref="job-send-customer-communication-dialog-cancel-button" class="pa-4" text rounded ripple color="primaryText" @click="handleCancel">
          Cancel
        </v-btn>
        <v-btn
          ref="job-send-customer-communication-dialog-send-button"
          class="pa-4"
          color="primary"
          text
          rounded
          ripple
          :loading="getIsLoadingCustomerCommunication"
          :disabled="shouldDisableSendButton"
          @click="handleSend"
        >
          {{ confirmButtonText }}
        </v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import JobReceiptRecipientsCard from "@/components/JobReceiptRecipientsCard"
import phoneNumberFormatter from "@/utils/PhoneNumberFormatter"
import { CustomerCommunications } from "@/enums/CustomerCommunications"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "JobSendCustomerCommunicationDialog",
  components: { JobReceiptRecipientsCard, BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    communicationType: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    confirmButtonText: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      eta: 15,
      selectedComponent: "job-send-customer-communication-dialog-eta-time-fifteen",
      isSendText: false,
      isSendEmail: false,
      emailRecipientsListTruncateLimit: 10,
      isSelectedEmailRecipientsListExpanded: false
    }
  },
  methods: {
    ...mapActions("Job", ["setSelectedEmailReceiptRecipients"]),
    handleEmailCheckboxClick() {
      this.setSelectedEmailReceiptRecipients([])
    },
    updateProposedEta(component, eta) {
      this.selectedComponent = component
      this.eta = eta
    },
    toggleSelectedEmailRecipientsListExpanded() {
      this.isSelectedEmailRecipientsListExpanded = !this.isSelectedEmailRecipientsListExpanded
    },
    handleSend() {
      let customerCommunicationConfig = {
        isSendText: this.isSendText,
        isSendEmail: this.isSendEmail
      }
      switch (this.communicationType) {
        case CustomerCommunications.BOOKING_CONFIRMATION.id:
          this.$emit("handle-send-booking-confirmation", customerCommunicationConfig)
          break
        case CustomerCommunications.DRIVER_ETA.id:
          customerCommunicationConfig = {
            ...customerCommunicationConfig,
            eta: this.eta
          }
          this.$emit("handle-send-driver-eta", customerCommunicationConfig)
          break
        case CustomerCommunications.JOB_STATEMENT.id:
          this.$emit("handle-send-job-statement", customerCommunicationConfig)
          break
      }
    },
    handleCancel() {
      this.setSelectedEmailReceiptRecipients([])
      this.$emit("close-dialog")
    }
  },
  computed: {
    phoneNumberFormatter() {
      return phoneNumberFormatter
    },
    ...mapGetters("Job", ["getSelectedFranchise", "getPrimaryContactDetails", "getIsLoadingCustomerCommunication", "getSelectedEmailReceiptRecipients"]),
    shouldDisplayTextCaption() {
      return this.isSendText || this.communicationType === CustomerCommunications.DRIVER_ETA.id
    },
    primaryContactFullName() {
      return `${this.getPrimaryContactDetails.firstName} ${this.getPrimaryContactDetails.lastName}`
    },
    primaryContactPhoneFormatted() {
      return this.phoneNumberFormatter.formatPhoneNumber(this.getPrimaryContactDetails.phonePrimary)
    },
    shouldDisplayEmailCaption() {
      return (
        (this.communicationType === CustomerCommunications.JOB_STATEMENT.id && this.getSelectedEmailRecipientsListSize > 0) ||
        (this.communicationType === CustomerCommunications.BOOKING_CONFIRMATION.id && this.isSendEmail && this.isCustomerEmailAvailable)
      )
    },
    getSelectedEmailRecipientsCaptionText() {
      return this.getSelectedEmailRecipientsListSize === 1
        ? `Email will be sent to the following recipient:`
        : `Email will be sent to the following ${this.getSelectedEmailRecipientsListSize} recipients:`
    },
    getSelectedEmailRecipientsFormattedList() {
      return this.getSelectedEmailRecipientsListSize < this.emailRecipientsListTruncateLimit + 1 ||
        (this.getSelectedEmailRecipientsListSize > this.emailRecipientsListTruncateLimit && this.isSelectedEmailRecipientsListExpanded)
        ? this.getSelectedEmailReceiptRecipients
            .map(recipient => recipient.email)
            .join(", ")
            .trim()
        : this.getSelectedEmailReceiptRecipients
            .slice(0, this.emailRecipientsListTruncateLimit)
            .map(recipient => recipient.email)
            .join(", ") + "...".trim()
    },
    getSelectedEmailRecipientsListSize() {
      return this.getSelectedEmailReceiptRecipients.length
    },
    shouldDisplaySelectedEmailRecipientsListSeeMoreButton() {
      return this.getSelectedEmailRecipientsListSize > this.emailRecipientsListTruncateLimit
    },
    getToggleEmailRecipientsListViewButtonText() {
      return this.isSelectedEmailRecipientsListExpanded ? "See Less" : "See More"
    },
    shouldDisplayCommunicationOptions() {
      return (
        this.isCustomerTextAvailable &&
        (this.isCustomerEmailAvailable || this.communicationType === CustomerCommunications.JOB_STATEMENT.id) &&
        this.communicationType !== CustomerCommunications.DRIVER_ETA.id
      )
    },
    shouldDisplayEtaTimeOptions() {
      return this.communicationType === CustomerCommunications.DRIVER_ETA.id
    },
    shouldDisplayJobStatementRecipients() {
      return this.communicationType === CustomerCommunications.JOB_STATEMENT.id && (!this.isCustomerTextAvailable || this.isSendEmail)
    },
    isOnlyEmailSelectedWithoutAtLeastOneRecipient() {
      return this.isSendEmail && this.getSelectedEmailReceiptRecipients <= 0
    },
    shouldDisableSendButton() {
      return (
        this.getIsLoadingCustomerCommunication ||
        (this.communicationType === CustomerCommunications.JOB_STATEMENT.id && this.isOnlyEmailSelectedWithoutAtLeastOneRecipient) ||
        (!this.isSendText && !this.isSendEmail)
      )
    },
    isCustomerTextAvailable() {
      return this.getSelectedFranchise.isDialpadEnabled && this.getPrimaryContactDetails.isPrimaryPhoneMobile && this.getPrimaryContactDetails.isPrimaryPhoneValid
    },
    isCustomerEmailAvailable() {
      return (
        this.getPrimaryContactDetails.emailPrimary !== "" &&
        this.getPrimaryContactDetails.emailPrimary !== null &&
        this.getPrimaryContactDetails.emailPrimary !== undefined &&
        this.getPrimaryContactDetails.refusedToProvideEmail === false
      )
    }
  },
  created() {
    this.isSendText = this.isCustomerTextAvailable
    this.isSendEmail = this.isCustomerEmailAvailable || !this.isCustomerTextAvailable
  }
}
</script>

<style>
.selectable::before {
  display: none !important;
}

.selectable.selected::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 255, 0, 0.2) !important;
  border-radius: inherit;
  pointer-events: none;
}
</style>
