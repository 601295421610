<template>
  <base-dialog
    ref="settings-add-franchise-operating-unit-discount-dialog-root"
    :title="`Associate Franchises to ${operatingUnitDiscount.name}`"
    :is-dialog-visible="isDialogVisible"
    dialog-image="mdi-cash-plus"
  >
    <template class="flex-fill" v-slot:content>
      <v-form ref="settings-add-franchise-operating-unit-discount-dialog-form" v-model="isFormValid">
        <v-layout row>
          <v-select
            ref="settings-add-franchise-operating-unit-discount-dialog-franchise-selector"
            label="Selected Franchises"
            v-model="selectedFranchiseIds"
            item-text="franchiseNameAndNumber"
            item-value="franchiseId"
            multiple
            outlined
            :items="getFranchisesInOperatingUnit"
            :rules="isRequiredSelectorRules"
            menu-props="{ bottom: true, offsetY: true }"
          />
        </v-layout>
        <v-layout row>
          <v-col cols="6" class="ps-0 d-flex">
            <app-date-picker-field-selector
              ref="settings-add-franchise-operating-unit-discount-dialog-begin-date-input"
              text-field-format="MM/DD/YYYY"
              text-field-label="Begin Date *"
              :date-picker-value="beginDate"
              :minimum-date-value="getMinimumPickerDate"
              :maximum-date-value="endDate"
              @updatedPickerValue="setBeginDateTime($event)"
            />
          </v-col>
          <v-col cols="6" class="pe-0 d-flex">
            <app-date-picker-field-selector
              ref="settings-add-franchise-operating-unit-discount-dialog-end-date-input"
              text-field-format="MM/DD/YYYY"
              text-field-label="End Date *"
              :date-picker-value="endDate"
              :minimum-date-value="beginDate"
              :maximum-date-value="maximumDate"
              @updatedPickerValue="setEndDateTime($event)"
            />
          </v-col>
        </v-layout>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="settings-add-franchise-operating-unit-discount-dialog-cancel-button" color="primaryText" @click="closeDialog" text rounded ripple
        >Cancel</v-btn
      >
      <v-btn
        class="pa-4"
        ref="settings-add-franchise-operating-unit-discount-dialog-confirmation-button"
        color="primary"
        text
        rounded
        ripple
        :disabled="getIsLoadingDiscounts"
        :loading="getIsLoadingDiscounts"
        @click="confirm"
        >Confirm</v-btn
      >
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { mapActions, mapGetters } from "vuex"
import { createOperatingUnitDiscountElectedFranchisesDto } from "@/api/dtos/JunkDtos"
import { formatAsDateOnly, todayAsDate } from "@/utils/DateTimeFormatters"

export default {
  name: "SettingsAddFranchiseOperatingUnitDiscountDialog",
  components: { AppDatePickerFieldSelector, BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    operatingUnitDiscount: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isFormValid: false,
      maximumDate: todayAsDate(),
      selectedFranchiseIds: [],
      beginDate: todayAsDate(),
      endDate: null,
      isRequiredSelectorRules: [value => (!!value && value.length > 0) || "Is required."]
    }
  },
  methods: {
    ...mapActions("Settings", ["associateFranchisesToOperatingUnitDiscount"]),
    async confirm() {
      if (this.$refs["settings-add-franchise-operating-unit-discount-dialog-form"].validate()) {
        const dto = createOperatingUnitDiscountElectedFranchisesDto(
          this.operatingUnitDiscount.operatingUnitDiscountId,
          this.selectedFranchiseIds,
          this.beginDate,
          this.endDate,
          this.$msal.getCurrentUserId()
        )
        await this.associateFranchisesToOperatingUnitDiscount(dto).then(() => this.closeDialog())
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    setBeginDateTime(dateTime) {
      this.beginDate = dateTime
    },
    setEndDateTime(dateTime) {
      this.endDate = dateTime
    }
  },
  computed: {
    ...mapGetters("Settings", ["getFranchisesInOperatingUnit", "getIsLoadingDiscounts"]),
    getMinimumPickerDate() {
      let ouDiscountFormatted = formatAsDateOnly(this.operatingUnitDiscount.beginDate)
      return ouDiscountFormatted > todayAsDate() ? ouDiscountFormatted : todayAsDate()
    }
  },
  created() {
    this.setBeginDateTime(this.getMinimumPickerDate)
    this.setEndDateTime(formatAsDateOnly(this.operatingUnitDiscount.endDate))
    this.maximumDate = formatAsDateOnly(this.operatingUnitDiscount.endDate)
  }
}
</script>
