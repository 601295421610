<template>
  <base-dialog ref="app-edit-address-dialog-root" :is-dialog-visible="isDialogVisible" dialog-image="mdi-map-marker-plus-outline" title="Edit Address" @close-dialog="closeDialog">
    <template v-slot:content>
      <v-form ref="app-edit-address-dialog-form" v-model="isEditAddressFormValid">
        <v-layout row>
          <v-col class="ma-0 pa-0">
            <div v-if="isUserValidated" class="font-italic text-md-body-1 justify-center text-center align-content-center align-self-center mb-4">Modifications for Display Purposes Only.</div>
          </v-col>
        </v-layout>
        <v-layout row>
          <v-text-field ref="app-edit-address-dialog-address-line-one-text" label="Address Line 1" v-model="mutableAddressLine1" :disabled="isFieldsDisabled" counter="100" outlined />
        </v-layout>
        <v-layout row>
          <v-text-field ref="app-edit-address-dialog-address-line-two-text" label="Address Line 2" counter="100" v-model="mutableAddressLine2" :rules="isLessThanOneHundredRule" outlined validate-on-blur />
        </v-layout>
        <v-layout row>
          <v-text-field ref="app-edit-address-dialog-complex" label="Complex" counter="100" v-model.trim="mutableComplex" :rules="isLessThanOneHundredRule" outlined validate-on-blur />
        </v-layout>
        <v-layout row>
          <v-text-field ref="app-edit-address-dialog-city-text" label="City" v-model="mutableCity" :disabled="isFieldsDisabled" counter="100" outlined />
        </v-layout>
        <v-layout row>
          <v-autocomplete ref="app-edit-address-dialog-state-text" label="State" v-model="mutableState" :items="getStateCodes" :disabled="isFieldsDisabled" counter="100" outlined />
        </v-layout>
        <v-layout row>
          <v-autocomplete ref="app-edit-address-dialog-country-text" label="Country" v-model="mutableCountry" :items="getCountryCodes" :disabled="isFieldsDisabled" outlined />
        </v-layout>
        <v-layout row>
          <v-text-field ref="app-edit-address-dialog-postal-code-text" label="Postal Code" v-model="mutableZip" :disabled="isFieldsDisabled" counter="10" outlined />
        </v-layout>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="app-edit-address-dialog-cancel-btn" color="secondary" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" ref="app-edit-address-dialog-confirm-btn" color="primary" @click="save" :loading="isLoading" :disabled="isLoading" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { countryCodes, provinceCodes, stateCodes } from "@/enums/StateCodes"

export default {
  name: "AppEditAddressDialog",
  components: { BaseDialog },
  data() {
    return {
      isEditAddressFormValid: false,
      mutableAddressLine1: null,
      mutableAddressLine2: null,
      mutableComplex: null,
      mutableCity: null,
      mutableState: null,
      mutableCountry: null,
      mutableZip: null,
      isLessThanOneHundredRule: [value => (value ?? "").length <= 100 || "Must 100 characters or less."]
    }
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    addressLine1: {
      type: String,
      default: ""
    },
    addressLine2: {
      type: String,
      default: ""
    },
    city: {
      type: String,
      default: ""
    },
    state: {
      type: String,
      default: ""
    },
    country: {
      type: String,
      default: ""
    },
    zip: {
      type: String,
      default: ""
    },
    isUserValidated: {
      type: Boolean,
      default: true
    },
    complex: {
      type: String,
      default: ""
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog")
    },
    save() {
      if (this.$refs["app-edit-address-dialog-form"].validate()) {
        if (this.isUserValidated) {
          this.$emit("confirmUserValidated", {
            addressLine1: this.mutableAddressLine1,
            addressLine2: this.mutableAddressLine2,
            complex: this.mutableComplex,
            city: this.mutableCity,
            state: this.mutableState,
            country: this.mutableCountry,
            zip: this.mutableZip
          })
        } else {
          this.$emit("confirm", {
            addressLine2: this.mutableAddressLine2,
            complex: this.mutableComplex
          })
        }
      }
    }
  },
  computed: {
    isFieldsDisabled() {
      return !this.isUserValidated
    },
    getStateCodes() {
      return this.mutableCountry?.toUpperCase() === "USA" ? this.getUnitedStatesStateCodes : this.getCanadaProvinceCodes
    },
    getCountryCodes() {
      return countryCodes
    },
    getCanadaProvinceCodes() {
      return provinceCodes
    },
    getUnitedStatesStateCodes() {
      return stateCodes
    }
  },
  created() {
    this.mutableAddressLine1 = this.addressLine1
    this.mutableAddressLine2 = this.addressLine2
    this.mutableCity = this.city
    this.mutableState = this.state
    this.mutableCountry = this.country
    this.mutableZip = this.zip
    this.mutableComplex = this.complex
  }
}
</script>

<style scoped></style>
