<template>
  <v-container fluid class="mb-16 mt-1" ref="dashboard-view-root">
    <v-row class="justify-center align-self-center align-center">
      <v-col cols="auto">
        <v-row>
          <v-col cols="12" class="flex-column flex-fill">
            <dashboard-card
              ref="dashboard-view-dashboard-truck-card"
              class="justify-center"
              sub-title="Truck"
              icon="mdi-truck-outline"
              :is-loading="getIsLoadingDashboardRevenueByTruckReport"
              :revenue-amount="getRevenueByTruckReportData"
            ></dashboard-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="flex-column d-flex flex-fill">
            <dashboard-card
              ref="dashboard-view-dashboard-driver-card"
              class="justify-center"
              sub-title="Driver"
              icon="mdi-account-outline"
              :is-loading="getIsLoadingDashboardRevenueByDriverReport"
              :revenue-amount="getRevenueByDriverReportData"
            ></dashboard-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <dashboard-revenue-chart-card ref="dashboard-view-revenue-chart-card" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-md-6 flex-fill" cols="auto">
        <dashboard-scheduled-conversion-chart-card ref="dashboard-view-scheduled-conversion-card" />
      </v-col>
      <v-col class="col-md-6 flex-fill" cols="auto">
        <dashboard-closed-conversion-chart-card ref="dashboard-view-closed-conversion-card" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" class="flex-fill">
        <dashboard-self-scheduled-jobs-card ref="dashboard-view-self-scheduled-jobs-card" />
      </v-col>
    </v-row>
    <v-row v-if="isCloseOutReminderJobsVisible">
      <v-col cols="auto" class="flex-fill fill-height">
        <dashboard-no-receipt-delivered-card ref="dashboard-view-receipts-not-delivered-card" />
      </v-col>
      <v-col cols="auto" class="flex-fill fill-height">
        <dashboard-open-jobs ref="dashboard-view-open-jobs-card" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pb-8" :loading="getIsLoadingDashboardSlotAllocation" ref="dashboard-view-slot-allocation-chart-card">
          <v-card-title class="headline">Schedule Slot Counts</v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-for="(item, date) in getOperatingUnitSlotAllocationsOperatingUnit" :key="date">
                <v-card-title class="text-break mb-n2">{{ formatDate(date) }}</v-card-title>
                <div class="ms-4">Remaining: {{ item.totalSlotsRemaining }}</div>
                <div class="ms-4">Booked: {{ item.totalSlotsScheduled }}</div>
                <div class="ms-4 ps-1">* First: {{ item.firstSlotsScheduled }}</div>
                <div class="ms-4 ps-1">* AM: {{ item.amSlotsScheduled }}</div>
                <div class="ms-4 ps-1">* PM: {{ item.pmSlotsScheduled }}</div>
                <div class="ms-4 ps-1">* No Pref: {{ item.noPreferenceSlotsScheduled }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardCard from "@/components/DashboardCard"
import DashboardRevenueChartCard from "@/components/DashboardRevenueChartCard"
import DashboardScheduledConversionChartCard from "@/components/DashboardScheduledConversionChartCard"
import DashboardClosedConversionChartCard from "@/components/DashboardClosedConversionChartCard"
import DashboardSelfScheduledJobsCard from "@/components/DashboardSelfScheduledJobsCard"
import DashboardNoReceiptDeliveredCard from "@/components/DashboardNoReceiptDeliveredCard"
import DashboardOpenJobs from "@/components/DashboardOpenJobs"
import { mapActions, mapGetters } from "vuex"
import dayjs from "dayjs"

let utc = require("dayjs/plugin/utc")
dayjs.extend(utc)

export default {
  name: "Dashboard",
  components: {
    DashboardCard,
    DashboardRevenueChartCard,
    DashboardScheduledConversionChartCard,
    DashboardClosedConversionChartCard,
    DashboardSelfScheduledJobsCard,
    DashboardNoReceiptDeliveredCard,
    DashboardOpenJobs
  },
  computed: {
    ...mapGetters(["getUserActiveFranchises"]),
    ...mapGetters("Dashboard", [
      "getRevenueByDriverReportData",
      "getRevenueByTruckReportData",
      "getOperatingUnitSlotAllocations",
      "getOperatingUnitSlotAllocationsOperatingUnit",
      "getIsLoadingDashboardRevenueByTruckReport",
      "getIsLoadingDashboardRevenueByDriverReport",
      "getIsLoadingDashboardSlotAllocation"
    ]),
    isCloseOutReminderJobsVisible() {
      return this.$msal.getUserHasWriteCloseJobPermission()
    }
  },
  methods: {
    ...mapActions("Dashboard", [
      "fetchRevenueForTruckAndDriver",
      "fetchScheduledConversionReport",
      "fetchClosedConversionReport",
      "fetchOperatingUnitRevenue",
      "fetchOperatingUnitSlotAllocation",
      "fetchSelfScheduledJobs",
      "fetchCloseOutReminderJobs",
      "fetchFranchisesInOperatingUnit",
      "fetchReceiptsNotSent"
    ]),
    async fetchReports() {
      const promises = [
        this.fetchRevenueForTruckAndDriver(),
        this.fetchScheduledConversionReport(),
        this.fetchClosedConversionReport(),
        this.fetchOperatingUnitRevenue(),
        this.fetchOperatingUnitSlotAllocation(),
        this.fetchSelfScheduledJobs(),
        this.fetchReceiptsNotSentToCustomer()
      ]

      if (this.isCloseOutReminderJobsVisible) {
        promises.push(this.fetchCloseOutReminderJobs())
      }

      await Promise.allSettled(promises)
    },
    async fetchReceiptsNotSentToCustomer() {
      await this.fetchFranchisesInOperatingUnit()
      await this.fetchReceiptsNotSent()
    },
    formatDate(dateStr) {
      return dayjs.utc(dateStr, "ddd, MMM D").format("ddd, MMM D")
    }
  },
  async created() {
    if (this.getUserActiveFranchises.length > 0) {
      await this.fetchReports()
    }
    this.$eventBus.$on("operating-unit-updated", async () => {
      await this.fetchReports()
    })
  }
}
</script>
