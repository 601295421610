import Vue from "vue"
import App from "@/App.vue"
import router from "@/router"
import store from "@/store/index"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import { datadog, sentry } from "../appsettings.json"
import vuetify from "@/plugins/vuetify"
import getEnv from "@/utils/Env"
import { createVPhoneInput } from "v-phone-input"
import "v-phone-input/dist/v-phone-input.css"
import "flag-icons/css/flag-icons.min.css"
import { Providers } from "@microsoft/mgt-element"
import { datadogRum } from "@datadog/browser-rum"
import { Msal2Provider } from "@microsoft/mgt"
import { msalInstance, MsalPlugin } from "@/plugins/MsalPlugin"
import { msalConfig } from "@/auth/authConfig"
import VueSignature from "vue-signature-pad"

Sentry.init({
  Vue: Vue,
  dsn: getEnv("VUE_APP_SENTRY_DSN") || sentry.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: parseFloat(sentry.VUE_APP_TRACES_SAMPLE_RATE),
  maxBreadcrumbs: 50,
  environment: getEnv("VUE_APP_SENTRY_ENVIRONMENT") || sentry.VUE_APP_SENTRY_ENVIRONMENT
})

datadogRum.init({
  applicationId: getEnv("VUE_APP_DATADOG_APPLICATION_ID") || datadog.VUE_APP_DATADOG_APPLICATION_ID,
  clientToken: getEnv("VUE_APP_DATADOG_CLIENT_TOKEN") || datadog.VUE_APP_DATADOG_CLIENT_TOKEN,
  site: getEnv("VUE_APP_DATADOG_SITE") || datadog.VUE_APP_DATADOG_SITE,
  service: getEnv("VUE_APP_DATADOG_SERVICE") || datadog.VUE_APP_DATADOG_SERVICE,
  env: getEnv("VUE_APP_DATADOG_ENV") || datadog.VUE_APP_DATADOG_ENV,
  version: getEnv("VUE_APP_DATADOG_VERSION") || datadog.VUE_APP_DATADOG_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "allow"
})

datadogRum.startSessionReplayRecording()

Vue.prototype.$eventBus = new Vue()
Vue.config.productionTip = false

const vPhoneInput = createVPhoneInput({
  countryIconMode: "svg"
})

/**
 * Configuration from https://learn.microsoft.com/en-us/graph/toolkit/providers/msal2#pass-an-existing-publicclientapplication-in-the-publicclientapplication-property
 */
Vue.use(new MsalPlugin(), msalConfig)

Vue.use(VueSignature)

Providers.globalProvider = new Msal2Provider({
  publicClientApplication: msalInstance
})

// Outstanding issue, unresolved since 2020: https://github.com/microsoftgraph/microsoft-graph-toolkit/issues/653
Providers.globalProvider.graph.client.config.fetchOptions = { headers: { ConsistencyLevel: "eventual" } }

Vue.use(vPhoneInput)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
  name: "Junk Drawer"
}).$mount("#app")
