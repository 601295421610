<template>
  <base-dialog
    v-if="updateSelectedFranchiseConfirmationObject.isVisible"
    :is-dialog-visible="updateSelectedFranchiseConfirmationObject.isVisible"
    ref="job-franchise-select-dialog-root"
    title="Switch Selected Franchise?"
    dialog-image="mdi-alert-outline"
    icon-color="error"
  >
    <template class="flex-fill" v-slot:content>
      <v-row class="mb-2" ref="job-franchise-select-dialog-trucks-and-employees-warning" v-if="updateSelectedFranchiseConfirmationObject.isTrucksAndEmployeesWarningVisible">
        <div class="headline">Trucks and Employees</div>
        <span class="text-md-body-1 ms-4">Moving the job to this franchise will <strong>remove</strong> any assigned <strong>trucks and employees</strong>.</span>
      </v-row>
      <v-row ref="job-franchise-select-dialog-over-slot-allocation-warning" v-if="updateSelectedFranchiseConfirmationObject.isOverSlotAllocationWarningVisible">
        <div class="headline">Over Slot Allocation</div>
        <span class="text-md-body-1 ms-4"
          >Scheduling this job will put you over the allocation amount for this job's
          <strong
            >Preferred Time of Day: <em>{{ updateSelectedFranchiseConfirmationObject.preferredTimeSlotName }}</em></strong
          >
          and
          <strong
            >Scheduled Date: <em>{{ updateSelectedFranchiseConfirmationObject.scheduledDate }}</em></strong
          >.</span
        >
        <v-col>
          <v-row class="mt-4">
            <v-col>
              <v-row class="text-h6 justify-center">First Slots</v-row>
              <v-row class="text-h6 justify-center" :class="{ 'red--text': updateSelectedFranchiseConfirmationObject.firstSlotsRemaining < 0 }"
                >{{ updateSelectedFranchiseConfirmationObject.firstSlotsRemaining }}
              </v-row>
            </v-col>
            <v-col>
              <v-row class="text-h6 justify-center">AM Slots</v-row>
              <v-row class="text-h6 justify-center" :class="{ 'red--text': updateSelectedFranchiseConfirmationObject.totalAmSlotsRemaining < 0 }"
                >{{ updateSelectedFranchiseConfirmationObject.totalAmSlotsRemaining }}
              </v-row>
            </v-col>
            <v-col>
              <v-row class="text-h6 justify-center">PM Slots</v-row>
              <v-row class="text-h6 justify-center" :class="{ 'red--text': updateSelectedFranchiseConfirmationObject.totalPmSlotsRemaining < 0 }"
                >{{ updateSelectedFranchiseConfirmationObject.totalPmSlotsRemaining }}
              </v-row>
            </v-col>
            <v-col>
              <v-row class="text-h6 justify-center">Total Slots</v-row>
              <v-row class="text-h6 justify-center" :class="{ 'red--text': updateSelectedFranchiseConfirmationObject.totalSlotsRemaining < 0 }"
                >{{ updateSelectedFranchiseConfirmationObject.totalSlotsRemaining }}
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-8 justify-center">
            <span class="text-md-body-1">Do you still wish to continue?</span>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="job-franchise-select-dialog-cancel-button" color="primaryText" @click="cancelUpdateSelectedFranchise" text rounded ripple>Cancel </v-btn>
      <v-btn
        class="pa-4"
        ref="job-franchise-select-dialog-confirm-button"
        color="primary"
        :disabled="getIsLoadingFranchiseAndSatelliteOffices"
        :loading="getIsLoadingFranchiseAndSatelliteOffices"
        @click="confirmUpdateSelectedFranchise"
        text
        rounded
        ripple
        >Confirm
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog.vue"
import { mapGetters } from "vuex"

export default {
  name: "JobFranchiseSelectDialog",
  components: { BaseDialog },
  props: {
    updateSelectedFranchiseConfirmationObject: {
      type: Object,
      required: true,
      default: () => ({
        isVisible: false,
        isOverSlotAllocationWarningVisible: false,
        isTrucksAndEmployeesWarningVisible: false,
        newFranchiseId: null,
        firstSlotsRemaining: 0,
        totalAmSlotsRemaining: 0,
        totalPmSlotsRemaining: 0,
        totalSlotsRemaining: 0,
        preferredTimeSlotName: "",
        scheduledDate: ""
      })
    }
  },
  methods: {
    async confirmUpdateSelectedFranchise() {
      this.$emit("confirm-dialog", this.updateSelectedFranchiseConfirmationObject.newFranchiseId)
    },
    cancelUpdateSelectedFranchise() {
      this.$emit("close-dialog")
    }
  },
  computed: {
    ...mapGetters("Job", ["getIsLoadingFranchiseAndSatelliteOffices"])
  }
}
</script>
