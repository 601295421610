<template>
  <v-layout class="ma-0 pa-0 mb-16 flex-fill flex-column">
    <v-card ref="job-trucks-and-employees-card-root" class="text-center" elevation="4" :loading="getIsLoadingTrucksAndEmployees">
      <v-card-title ref="job-trucks-and-employees-card-title" class="headline">Trucks & Employees</v-card-title>
      <v-row class="mx-4 justify-center align-self-center" dense>
        <v-col cols="auto" class="mt-n2 flex-fill justify-center align-self-center">
          <app-time-picker-menu
            ref="job-trucks-and-employees-card-global-start-time-picker"
            text-field-label="Global Start Time"
            :time-value="getTrucksAndEmployeesGlobalStartTime"
            @updateTimeValue="SET_TRUCKS_AND_EMPLOYEES_GLOBAL_START_TIME($event)"
            :allowed-minutes="allowedMinutes"
            :max-value="getTrucksAndEmployeesGlobalEndTime"
            :is-disabled="isActionsDisabled"
          ></app-time-picker-menu>
        </v-col>
        <v-col cols="auto" class="mt-n2 mx-2 flex-fill justify-center align-self-center">
          <v-text-field
            style="min-width: 200px"
            :value="getTrucksAndEmployeesGlobalDownTime"
            @change="SET_TRUCKS_AND_EMPLOYEES_GLOBAL_DOWN_TIME"
            :disabled="isActionsDisabled"
            validate-on-blur
            label="Global Down Time"
            type="number"
            min="0"
            max="24"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="mt-n2 flex-fill justify-center align-self-center">
          <app-time-picker-menu
            ref="job-trucks-and-employees-card-global-end-time-picker"
            text-field-label="Global End Time"
            :time-value="getTrucksAndEmployeesGlobalEndTime"
            @updateTimeValue="SET_TRUCKS_AND_EMPLOYEES_GLOBAL_END_TIME($event)"
            :allowed-minutes="allowedMinutes"
            :min-value="getTrucksAndEmployeesGlobalStartTime"
            :is-disabled="isActionsDisabled"
          ></app-time-picker-menu>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0 mt-n4 float-end">
          <v-btn
            class="primary float-end mb-2 me-8"
            ref="job-trucks-and-employees-card-apply-global-btn"
            :disabled="isTrucksAndEmployeesGlobalSaveButtonDisabled"
            @click="setIsOverrideGlobalDownTimeConfirmationDialogVisible(true)"
            ripple
            rounded
            >Apply to Trucks & Employees</v-btn
          >
        </v-col>
      </v-row>
      <v-card-text class="pt-2">
        <job-trucks-card></job-trucks-card>
        <job-employees-card></job-employees-card>
      </v-card-text>
    </v-card>
    <job-override-global-down-time-confirmation-dialog
      v-if="isOverrideGlobalDownTimeConfirmationDialogVisible"
      :is-dialog-visible="isOverrideGlobalDownTimeConfirmationDialogVisible"
      @closeDialog="setIsOverrideGlobalDownTimeConfirmationDialogVisible(false)"
    />
  </v-layout>
</template>

<script>
import AppTimePickerMenu from "@/components/AppTimePickerMenu"
import { mapGetters, mapMutations } from "vuex"
import JobOverrideGlobalDownTimeConfirmationDialog from "@/components/JobOverrideGlobalDownTimeConfirmationDialog"
import JobTrucksCard from "@/components/JobTrucksCard"
import JobEmployeesCard from "@/components/JobEmployeesCard"

export default {
  name: "JobTrucksAndEmployeesCard",
  components: { JobEmployeesCard, JobTrucksCard, JobOverrideGlobalDownTimeConfirmationDialog, AppTimePickerMenu },
  data() {
    return {
      isOverrideGlobalDownTimeConfirmationDialogVisible: false
    }
  },
  methods: {
    ...mapMutations("Job", ["SET_TRUCKS_AND_EMPLOYEES_GLOBAL_START_TIME", "SET_TRUCKS_AND_EMPLOYEES_GLOBAL_END_TIME", "SET_TRUCKS_AND_EMPLOYEES_GLOBAL_DOWN_TIME"]),
    allowedMinutes: m => m % 15 === 0,
    setIsOverrideGlobalDownTimeConfirmationDialogVisible(isVisible) {
      this.isOverrideGlobalDownTimeConfirmationDialogVisible = isVisible
    }
  },
  computed: {
    ...mapGetters("Job", [
      "getIsLoadingTrucksAndEmployees",
      "getTrucksAndEmployeesGlobalStartTime",
      "getTrucksAndEmployeesGlobalEndTime",
      "getTrucksAndEmployeesGlobalDownTime",
      "getIsJobFieldsDisabled"
    ]),
    isTrucksAndEmployeesGlobalSaveButtonDisabled() {
      return this.getTrucksAndEmployeesGlobalStartTime === undefined || this.getTrucksAndEmployeesGlobalEndTime === undefined
    },
    isActionsDisabled() {
      return this.getIsLoadingTrucksAndEmployees || this.getIsJobFieldsDisabled
    }
  }
}
</script>
