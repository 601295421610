<template>
  <v-container fluid>
    <v-card class="flex-fill flex-column flex" flat>
      <v-card-title>Edit Satellite Office Details</v-card-title>
      <v-card-text>
        <v-form ref="administrator-edit-satellite-office-form" :disabled="isSatelliteOfficeClosed" v-model="isEditFranchiseDetailsFormValid">
          <v-row dense>
            <v-col>
              <v-text-field ref="administrator-edit-satellite-office-name-text" v-model="mutableSatelliteOfficeName" label="Satellite Office Name *" outlined :rules="isRequiredRules" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-phone-input class="d-flex" ref="administrator-edit-satellite-office-primary-phone-text" v-model="mutablePhonePrimary" label="Primary Phone Number *" outlined :rules="isRequiredRules" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-edit-satellite-office-address-text"
                class="cursor_pointer"
                label="Address *"
                readonly
                :value="formatAddress(mutableAddress)"
                outlined
                :rules="isRequiredRules"
                @click="setIsAddressSearchDialogVisible(true)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex">
              <app-date-picker-field-selector
                ref="administrator-edit-satellite-office-start-date-picker"
                text-field-label="First Day of Business Date *"
                :date-picker-value="mutableFirstDayOfBusiness"
                :minimum-date-value="minimumDate"
                :maximum-date-value="mutableLastDayOfBusiness"
                text-field-format="MMMM D, YYYY"
                @updatedPickerValue="updateFirstDateOfBusiness($event)"
                :text-field-disabled="isFirstDayOfBusinessDisabled"
                :is-clear-enabled="!isFirstDayOfBusinessDisabled"
              />
            </v-col>
            <v-col class="d-flex">
              <app-date-picker-field-selector
                ref="administrator-edit-satellite-office-end-date-picker"
                text-field-label="Last Day of Business Date"
                :date-picker-value="mutableLastDayOfBusiness"
                :minimum-date-value="getMinimumEndDate"
                text-field-format="MMMM D, YYYY"
                @updatedPickerValue="updateLastDateOfBusiness($event)"
                :is-clear-enabled="!isSatelliteOfficeClosed"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                ref="administrator-edit-sat-office-google-review-url-text"
                label="Google Review URL"
                v-model="mutableGoogleReviewUrl"
                outlined
                counter="256"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!isSatelliteOfficeClosed">
            <v-col>
              <v-btn ref="administrator-edit-satellite-office-save-btn" class="float-end" color="primary" :disabled="getIsLoadingOperatingUnitFranchisesAndSatellites" :loading="getIsLoadingOperatingUnitFranchisesAndSatellites" @click="save" rounded ripple large>Update Satellite Office Details</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <app-address-search-dialog
      v-if="isAddressSearchDialogVisible"
      :is-dialog-visible="isAddressSearchDialogVisible"
      :initial-camera-position="{ lat: 42.435712, lng: -84.331992 }"
      :is-create-address-from-marker-button-visible="false"
      @createAddressFromSearch="setFranchiseAddress($event)"
      @createAddressFromForm="setFranchiseAddress($event)"
      @closeDialog="setIsAddressSearchDialogVisible(false)"
    ></app-address-search-dialog>
  </v-container>
</template>

<script>
import { formatAsDateOnly, getIsFirstDateBeforeSecondDate, todayAsDate } from "@/utils/DateTimeFormatters"
import { mapActions, mapGetters } from "vuex"
import { putSatelliteOfficeDetailsDto, putSatelliteOfficeDetailsWithUpdatedAddressDto } from "@/api/dtos/JunkDtos"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { successSnackbar } from "@/utils/SnackbarBuilder"
import AppAddressSearchDialog from "@/components/AppAddressSearchDialog"
import { formatHereAddressText } from "@/utils/FormatAddressText"

export default {
  name: "AdministratorEditSatelliteOffice",
  components: { AppAddressSearchDialog, AppDatePickerFieldSelector },
  props: {
    satelliteOfficeInitial: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      satelliteOffice: {},
      isAddressSearchDialogVisible: false,
      mutableAddress: undefined,
      isEditFranchiseDetailsFormValid: false,
      minimumDate: todayAsDate(),
      mutableSatelliteOfficeName: "",
      mutablePhonePrimary: "",
      mutableFirstDayOfBusiness: null,
      mutableLastDayOfBusiness: null,
      mutableGoogleReviewUrl: "",
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."]
    }
  },
  computed: {
    ...mapGetters("Administrator", ["getIsLoadingOperatingUnitFranchisesAndSatellites"]),
    isFirstDayOfBusinessDisabled() {
      return this.mutableFirstDayOfBusiness <= todayAsDate() || this.isSatelliteOfficeClosed
    },
    isSatelliteOfficeClosed() {
      return this.satelliteOffice?.endDate !== undefined && this.satelliteOffice?.endDate !== null
    },
    getMinimumEndDate() {
      return getIsFirstDateBeforeSecondDate(this.minimumDate, this.mutableFirstDayOfBusiness) ? this.mutableFirstDayOfBusiness : this.minimumDate
    }
  },
  methods: {
    ...mapActions(["addSnackbar"]),
    ...mapActions("Administrator", ["updateSatelliteOfficeDetails"]),
    async save() {
      if (this.$refs["administrator-edit-satellite-office-form"].validate()) {
        const dto = this.generatePutSatelliteOfficeDetailsDto()
        await this.updateSatelliteOfficeDetails(dto).then(data => {
          this.addSnackbar(successSnackbar("Satellite Office Successfully Updated"))
          let updatedSatelliteOffices = data.children.flatMap(oufs => oufs.children)?.find(x => x.satelliteOfficeId === this.satelliteOffice.satelliteOfficeId)
          this.setMutableStates(updatedSatelliteOffices)
        })
      }
    },
    generatePutSatelliteOfficeDetailsDto() {
      return this.mutableAddress.latitude === this.satelliteOffice.addressDto.latitude && this.mutableAddress.longitude === this.satelliteOffice.addressDto.longitude
        ? putSatelliteOfficeDetailsDto(
            this.satelliteOffice.satelliteOfficeId,
            this.mutableSatelliteOfficeName,
            this.mutablePhonePrimary,
            this.mutableFirstDayOfBusiness,
            this.mutableLastDayOfBusiness,
            this.$msal.getCurrentUserId(),
            this.mutableGoogleReviewUrl
          )
        : putSatelliteOfficeDetailsWithUpdatedAddressDto(
            this.satelliteOffice.satelliteOfficeId,
            this.mutableSatelliteOfficeName,
            this.mutablePhonePrimary,
            this.mutableFirstDayOfBusiness,
            this.mutableLastDayOfBusiness,
            this.$msal.getCurrentUserId(),
            this.mutableGoogleReviewUrl,
            this.mutableAddress
          )
    },
    updateFirstDateOfBusiness(date) {
      this.mutableFirstDayOfBusiness = date
    },
    updateLastDateOfBusiness(date) {
      this.mutableLastDayOfBusiness = date
    },
    setFranchiseAddress(event) {
      this.mutableAddress = event
      this.isAddressSearchDialogVisible = false
    },
    setIsAddressSearchDialogVisible(isVisible) {
      this.isAddressSearchDialogVisible = isVisible
    },
    formatAddress(address) {
      return formatHereAddressText(address)
    },
    toMutableAddress(franchiseAddress) {
      return {
        addressLine1: franchiseAddress.addressLine1,
        addressLine2: franchiseAddress.addressLine2,
        city: franchiseAddress.city,
        stateCode: franchiseAddress.stateOrProvince,
        postalCode: franchiseAddress.zipCode,
        countryCode: franchiseAddress.country,
        isUserValidated: franchiseAddress.isUserValidated,
        latitude: franchiseAddress.latitude,
        longitude: franchiseAddress.longitude
      }
    },
    setMutableStates(satelliteOffice) {
      this.satelliteOffice = satelliteOffice
      this.mutableSatelliteOfficeName = satelliteOffice.satelliteOfficeName
      this.mutablePhonePrimary = satelliteOffice.phone
      this.mutableAddress = this.toMutableAddress(satelliteOffice.addressDto)
      this.mutableFirstDayOfBusiness = formatAsDateOnly(satelliteOffice.beginDate)
      this.mutableLastDayOfBusiness = formatAsDateOnly(satelliteOffice.endDate)
      this.mutableGoogleReviewUrl = satelliteOffice.googleReviewUrl
    }
  },
  created() {
    this.setMutableStates(this.satelliteOfficeInitial)
  }
}
</script>

<style scoped></style>
