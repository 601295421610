<template>
  <v-card elevation="0" class="flex-fill ma-0 pa-0 background">
    <v-tab-transition hide-on-leave>
      <v-tabs class="ma-0 pa-0" fixed-tabs color="primary" background-color="background">
        <v-tab ref="administrator-settings-tab" href="#administrator-settings">Operations</v-tab>
        <v-tab-item ref="administrator-settings" value="administrator-settings" class="ma-0 pa-0 pt-2 pb-2 flex-fill fill-height no-gutters flex-column background">
          <administrator-settings ref="administrator-settings-card" class="ma-0 pa-0 flex-fill background" />
        </v-tab-item>
        <v-tab ref="administrator-discounts-tab" href="#administrator-discounts" class="transparent">Discounts</v-tab>
        <v-tab-item ref="administrator-discounts" value="administrator-discounts" class="ma-0 pa-0 pt-2 pb-2 background">
          <administrator-discounts-card ref="administrator-discounts-card" />
        </v-tab-item>
      </v-tabs>
    </v-tab-transition>
  </v-card>
</template>

<script>
import AdministratorSettings from "@/views/AdministratorSettings"
import AdministratorDiscountsCard from "@/components/AdministratorDiscountsCard"

export default {
  name: "Administrator",
  components: { AdministratorDiscountsCard, AdministratorSettings }
}
</script>
