<template>
  <v-overlay style="z-index: 203" :value="isEnabled">
    <v-col>
      <v-row align="center" justify="center">
        <v-progress-circular
          :rotate="360"
          :size="100"
          :width="15"
          :value="progressState"
          :indeterminate="isIndeterminateLoadingState"
          color="primary"
          justify="center"
        ></v-progress-circular>
      </v-row>
      <v-row align="center" justify="center">
        <div>{{ loadingText }}</div>
      </v-row>
    </v-col>
  </v-overlay>
</template>

<script>
export default {
  name: "AppFullScreenProgressBarCircular",
  props: {
    loadingText: {
      type: String,
      default: "Loading..."
    },
    isEnabled: {
      type: Boolean,
      default: false
    },
    progressState: {
      type: Number,
      default: 0
    },
    isIndeterminateLoadingState: {
      type: Boolean,
      default: false
    }
  }
}
</script>
