<template>
  <div>
    <div class="here-map">
      <div class="mt-n4" ref="map" style="width: 100%; height: 60vh"></div>
    </div>
    <div class="justify-center text-center align-self-center align-center align-content-center">
      <v-data-table class="row-pointer" :items="potentialAddresses" :headers="headers" @click:row="onRowClick" single-select item-key="id" hide-default-footer></v-data-table>
      <v-divider class="mt-0 secondaryLight"></v-divider>
      <v-btn v-if="isCreateAddressFromMarkerButtonVisible" :disabled="isCreateAddressButtonDisabled" class="mt-8" color="primary" @click="promptDialog">Create New Address From Marker</v-btn>
    </div>
    <app-new-address-form-dialog
      v-if="isAppAddNewAddressFormDialogVisible"
      :is-dialog-visible="isAppAddNewAddressFormDialogVisible"
      :address-line1="currentSelectedItem.addressLine1"
      :country="currentSelectedItem.country"
      :city="currentSelectedItem.city"
      :state="currentSelectedItem.state"
      :zip="currentSelectedItem.zip"
      :latitude="currentSelectedItem.latitude"
      :longitude="currentSelectedItem.longitude"
      :is-user-validated="currentSelectedItem.isUserValidated"
      :is-fields-disabled="currentSelectedItem.isFieldsDisabled"
      :is-loading="isLoadingAppNewAddressFormDialog"
      @closeDialog="resetSelectedAddress"
      @createAddress="emitCreateAddress"
    />
  </div>
</template>

<script>
import routingApi from "@/api/Routing"
import AppNewAddressFormDialog from "@/components/AppNewAddressFormDialog"
import { formatDecimalValue } from "@/utils/FormatDecimalValue"
import { app } from "../../appsettings.json"
import getEnv from "@/utils/Env"

export default {
  name: "AppMapSuggestionDialog",
  components: { AppNewAddressFormDialog },
  data() {
    return {
      platform: {},
      map: {},
      potentialAddresses: [],
      headers: [{ text: "Potential Addresses", align: "start", value: "title" }],
      isAppAddNewAddressFormDialogVisible: false,
      currentSelectedItem: {},
      coordinates: {}
    }
  },
  props: {
    isLoadingAppNewAddressFormDialog: {
      type: Boolean,
      default: false
    },
    initialPositionLatitude: {
      type: Number,
      required: true
    },
    initialPositionLongitude: {
      type: Number,
      required: true
    },
    isCreateAddressFromMarkerButtonVisible: {
      type: Boolean,
      default: true
    }
  },
  created() {
    const hereKey = getEnv("VUE_APP_HERE_KEY") || app.VUE_APP_HERE_KEY
    this.platform = new window.H.service.Platform({ apiKey: hereKey })
  },
  computed: {
    isCreateAddressButtonDisabled() {
      return this.coordinates?.lat === undefined || this.coordinates?.lng === undefined
    }
  },
  methods: {
    emitCreateAddress(newAddress) {
      this.$emit("createAddress", newAddress)
    },
    mapTapHandler(param) {
      let coordinates = this.map.screenToGeo(param.currentPointer.viewportX, param.currentPointer.viewportY)
      console.log("coord = ", coordinates)
      this.dropMarker(coordinates.lat, coordinates.lng)
      this.fetchPotentialMatches(coordinates)
      this.coordinates = { lat: formatDecimalValue(coordinates.lat, 6), lng: formatDecimalValue(coordinates.lng, 6) }
    },
    async fetchPotentialMatches(coordinates) {
      await routingApi.fetchReverseGeocode(coordinates.lat, coordinates.lng).then(data => {
        this.potentialAddresses = data
        console.log("fetchReverseGeocode.response = ", JSON.stringify(data, null, 4))
      })
    },
    dropMarker(latitude, longitude) {
      this.map.removeObjects(this.map.getObjects())

      let newMarker = new window.H.map.Marker({ lat: latitude, lng: longitude })
      this.map.addObject(newMarker)
    },
    initMount() {
      const engineType = window.H.Map.EngineType["HARP"]
      const defaultLayers = this.platform.createDefaultLayers({ engineType })
      const map = new window.H.Map(
        this.$refs.map,
        // Select a map style
        defaultLayers.raster.normal.map, {
          engineType,
          zoom: 10,
          center: { lat: this.initialPositionLatitude, lng: this.initialPositionLongitude }
        }
      )

      this.map = map

      const behavior = new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(map))
      behavior.enable(window.H.mapevents.Behavior.Feature.PANNING)
      behavior.enable(window.H.mapevents.Behavior.Feature.PINCH_ZOOM)
      behavior.enable(window.H.mapevents.Behavior.Feature.WHEEL_ZOOM)
      behavior.enable(window.H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM)
      behavior.enable(window.H.mapevents.Behavior.Feature.HEADING)
      behavior.enable(window.H.mapevents.Behavior.Feature.TILT)

      window.H.ui.UI.createDefault(this.map, defaultLayers)

      this.map.addEventListener("tap", evt => this.mapTapHandler(evt))
    },
    onRowClick(item, row) {
      row.select(row.isSelected)
      this.currentSelectedItem = this.generateBundle(item, false) // todo - we need to clean this - specifically when currentSelectedItem.address.houseNumber or currentSelectedItem.address.street is null
      this.isAppAddNewAddressFormDialogVisible = true
    },
    resetSelectedAddress() {
      this.isAppAddNewAddressFormDialogVisible = false
      this.currentSelectedItem = Object.assign({}, {})
    },
    promptDialog() {
      this.isAppAddNewAddressFormDialogVisible = true
      this.currentSelectedItem = {
        addressLine1: null,
        country: null,
        state: null,
        city: null,
        zip: null,
        latitude: this.coordinates.lat,
        longitude: this.coordinates.lng,
        isUserValidated: true
      }
    },
    generateBundle(item, isUserValidated) {
      let bundle = {
        addressLine1: null,
        country: item.address?.countryCode,
        state: item.address?.state,
        city: item.address?.city,
        zip: item.address?.postalCode,
        latitude: item.position.latitude,
        longitude: item.position.longitude,
        isUserValidated: isUserValidated
      }

      let houseNumber = item.address?.houseNumber
      let street = item.address?.street

      if (houseNumber !== null) {
        bundle.addressLine1 = houseNumber.trim()
      }
      if (street !== null) {
        let address = (bundle?.addressLine1 ?? "") + " " + street
        bundle.addressLine1 = address.trim()
      }
      return bundle
    }
  },
  mounted() {
    this.initMount()
  },
  beforeDestroy() {
    if (Object.keys(this.map).length !== 0) this.map.removeEventListener("tap", evt => this.mapTapHandler(evt))
  }
}
</script>

<style scoped></style>
