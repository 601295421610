import { JobStatus } from "@/enums/JobStatus"
import applicationColors from "@/utils/ApplicationColors"

export function getJobStatusColorByStatusName(statusName) {
  switch (statusName) {
    case JobStatus.ABANDONED.name:
      return applicationColors.tmtRed.pastelMedium
    case JobStatus.CREATED.name:
      return applicationColors.tmtBlue.pastelMedium
    case JobStatus.SCHEDULED.name:
      return applicationColors.tmtGreen.pastelMedium
    case JobStatus.CLAIMED.name:
      return applicationColors.tmtOrange.pastelMedium
    case JobStatus.COMPLETED.name:
      return applicationColors.tmtBlueGray.pastelMedium
    case JobStatus.CANCELLED.name:
      return applicationColors.tmtRed.pastelMedium
    case JobStatus.CLOSED.name:
      return applicationColors.tmtGray.pastelMedium
    default:
      return "transparent"
  }
}
