<template>
  <base-dialog ref="settings-create-franchise-operating-unit-allocation-dialog-root" title="Associate Franchise to Operating Unit Allocation Group" :is-dialog-visible="isDialogVisible" dialog-image="mdi-basket-plus">
    <template class="flex-fill" v-slot:content>
      <v-form ref="settings-create-franchise-operating-unit-allocation-dialog-form">
        <v-row dense>
          <v-col>
            <v-select
              ref="settings-create-franchise-operating-unit-allocation-dialog-franchise-selector"
              label="Selected Franchises"
              v-model="selectedFranchises"
              :items="getFranchisesNotAlreadyAssignedToGroup"
              item-text="franchiseNameAndNumber"
              item-value="franchiseId"
              :rules="isRequiredSelectorRules"
              multiple
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="settings-create-franchise-operating-unit-allocation-dialog-cancel-btn" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" ref="settings-create-franchise-operating-unit-allocation-association-dialog-confirm-btn" color="primary" @click="save" :loading="getIsLoadingOperatingUnitAllocationGroups" :disabled="getIsLoadingOperatingUnitAllocationGroups" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog.vue"
import { mapActions, mapGetters } from "vuex"
import { createFranchiseOperatingUnitAllocationGroupDto } from "@/api/dtos/JunkDtos"

export default {
  name: "SettingsCreateFranchiseOperatingUnitAllocationDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    operatingUnitAllocationGroupId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedFranchises: [],
      isRequiredSelectorRules: [value => (!!value && value.length > 0) || "Is required."]
    }
  },
  methods: {
    ...mapActions("Settings", ["createFranchiseOperatingUnitAllocationGroup"]),
    closeDialog() {
      this.$emit("closeDialog")
    },
    async save() {
      if (this.$refs["settings-create-franchise-operating-unit-allocation-dialog-form"].validate()) {
        const dto = createFranchiseOperatingUnitAllocationGroupDto(this.operatingUnitAllocationGroupId, this.selectedFranchises)
        await this.createFranchiseOperatingUnitAllocationGroup(dto).then(() => {
          this.closeDialog()
        })
      }
    }
  },
  computed: {
    ...mapGetters("Settings", ["getFranchisesInOperatingUnit", "getOperatingUnitAllocationGroups", "getIsLoadingOperatingUnitAllocationGroups"]),
    getFranchisesNotAlreadyAssignedToGroup() {
      let matchingAllocationGroup = this.getOperatingUnitAllocationGroups.find(x => x.operatingUnitAllocationGroupId === this.operatingUnitAllocationGroupId)
      if (matchingAllocationGroup !== undefined && matchingAllocationGroup !== null) {
        return this.getFranchisesInOperatingUnit
          ?.slice()
          .filter(gfiou => !matchingAllocationGroup.franchiseOperatingUnitAllocationDtos?.some(f => gfiou.franchiseId === f.franchiseId))
      }
      return this.getFranchisesInOperatingUnit?.slice()
    }
  }
}
</script>

<style scoped></style>
