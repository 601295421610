<template>
  <base-dialog ref="app-confirmation-dialog-root" :title="title" :is-dialog-visible="isDialogVisible" :dialog-image="dialogImage">
    <template class="flex-fill" v-slot:content>
      <span ref="app-confirmation-dialog-content" class="text-md-body-1">{{ contentText }}</span>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="app-confirmation-dialog-cancel-btn" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" ref="app-confirmation-dialog-confirm-btn" color="primary" @click="save" :loading="isLoading" :disabled="isLoading" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"

export default {
  name: "AppConfirmationDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    contentText: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    dialogImage: {
      type: String,
      default: "mdi-blank"
    }
  },
  methods: {
    save() {
      this.$emit("confirmed")
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  }
}
</script>

<style scoped></style>
