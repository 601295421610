<template>
  <v-dialog ref="base-dialog-root" :value="isDialogVisible" :max-width="maxDialogWidth" open-delay="1000" scrollable persistent>
    <v-card class="elevation-8">
      <v-card-title ref="base-dialog-card-title" class="pt-5 headline white--text align-content-start elevation-1" :class="titleBackgroundColor" style="min-height: 90px">
        {{ title }}
      </v-card-title>
      <v-card class="mt-n8 px-0 mb-n4 align-self-center" elevation="0">
        <v-card-text class="pa-2 pt-1 mb-3">
          <v-icon large :color="dotColor">mdi-circle-small</v-icon>
          <v-icon large class="mx-2" :color="iconColor">{{ this.dialogImage }}</v-icon>
          <v-icon large :color="dotColor">mdi-circle-small</v-icon>
        </v-card-text>
      </v-card>
      <v-card-text class="justify-center align-self-center mt-n1 px-8 px-md-12 px-sm-12 py-8 flex-fill">
        <slot name="content"></slot>
      </v-card-text>
      <v-divider class="mx-10 mt-1"></v-divider>
      <v-card-actions class="justify-end my-1 mx-6">
        <slot name="actions">
          <v-btn class="pa-4" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
          <v-btn class="pa-4" color="primary" @click="save" text rounded ripple>Confirm</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseDialog",
  props: {
    title: {
      type: String,
      default: ""
    },
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    dialogImage: {
      type: String,
      default: "mdi-blank"
    },
    maxDialogWidth: {
      type: String,
      default: "650px"
    },
    titleBackgroundColor: {
      type: String,
      default: "primary"
    },
    iconColor: {
      type: String,
      default: "primary"
    },
    dotColor: {
      type: String,
      default: "primary"
    }
  },
  methods: {
    save() {
      this.closeDialog()
    },
    closeDialog() {
      this.$emit("close-dialog")
    }
  }
}
</script>
