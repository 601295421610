import { errorSnackbar } from "@/utils/SnackbarBuilder"
import * as Sentry from "@sentry/vue"
import junkApi from "@/api/Junk"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { formatAsDateOnly, todayAsDate } from "@/utils/DateTimeFormatters"

export const state = {
  _isLoadingOperatingUnitFranchisesAndSatellites: false,
  _operatingUnitFranchisesAndSatellites: [],
  _isLoadingCreateEmployee: false,

  // discounts
  _isLoadingDiscounts: false,
  _discounts: []
}

export const getters = {
  getIsLoadingOperatingUnitFranchisesAndSatellites(state) {
    return state._isLoadingOperatingUnitFranchisesAndSatellites
  },
  getOperatingUnitFranchisesAndSatellites(state) {
    return state._operatingUnitFranchisesAndSatellites.slice().sort((a, b) => a.displayName.localeCompare(b.displayName))
  },
  getFranchisesAndSatellites(state) {
    return state._operatingUnitFranchisesAndSatellites.slice().flatMap(oufs => oufs.children).sort((a, b) => a.displayName.localeCompare(b.displayName)) ?? []
  },
  getIsLoadingCreateEmployee() {
    return state._isLoadingCreateEmployee
  },

  // discounts
  getIsLoadingDiscounts(state) {
    return state._isLoadingDiscounts
  },
  getDiscounts(state) {
    return state._discounts
  },
  getActiveDiscounts(state) {
    return state._discounts.filter(discount => formatAsDateOnly(discount.endDate) >= todayAsDate() || discount.endDate === null)
  }
}

export const mutations = {
  SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES(state, isLoading) {
    state._isLoadingOperatingUnitFranchisesAndSatellites = isLoading
  },
  SET_OPERATING_UNIT_FRANCHISES_AND_SATELLITES(state, operatingUnitLeaf) {
    state._operatingUnitFranchisesAndSatellites.splice(0, state._operatingUnitFranchisesAndSatellites?.length ?? 0, ...operatingUnitLeaf)
  },
  UPDATE_OPERATING_UNIT_FRANCHISES_AND_SATELLITES(state, operatingUnitLeaf) {
    let index = state._operatingUnitFranchisesAndSatellites.findIndex(oufs => oufs.operatingUnitId === operatingUnitLeaf.operatingUnitId)
    if (~index) {
      state._operatingUnitFranchisesAndSatellites.splice(index, 1, operatingUnitLeaf)
    }
  },
  ADD_OPERATING_UNIT_FRANCHISES_AND_SATELLITES(state, operatingUnitLeaf) {
    state._operatingUnitFranchisesAndSatellites.push(operatingUnitLeaf)
  },
  SET_IS_LOADING_CREATE_EMPLOYEE(state, isLoading) {
    state._isLoadingCreateEmployee = isLoading
  },

  // discounts
  SET_IS_LOADING_DISCOUNTS(state, isLoading) {
    state._isLoadingDiscounts = isLoading
  },
  SET_DISCOUNTS(state, discounts) {
    state._discounts = discounts
  },
  ADD_DISCOUNT(state, discount) {
    state._discounts.push(discount)
  },
  UPDATE_DISCOUNT(state, discount) {
    let index = state._discounts.findIndex(d => d.nationalDiscountId === discount.nationalDiscountId)
    if (~index) {
      state._discounts.splice(index, 1, discount)
    }
  }
}

export const actions = {
  async fetchAllOperatingUnitFranchisesAndSatellites({ commit }) {
    commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", true)
    return await junkApi
      .fetchAllOperatingUnitFranchisesAndSatellites()
      .then(operatingUnitLeaf => {
        commit("SET_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", operatingUnitLeaf)
        return Promise.resolve(operatingUnitLeaf)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(errorMessageHandler(error)), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", false)
      })
  },
  async createOperatingUnit({ commit }, createOperatingUnitDto) {
    commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", true)
    return await junkApi
      .createOperatingUnit(createOperatingUnitDto)
      .then(operatingUnitLeaf => {
        commit("ADD_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", operatingUnitLeaf)
        return Promise.resolve(operatingUnitLeaf)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(errorMessageHandler(error)), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", false)
      })
  },
  async updateOperatingUnit({ commit }, putOperatingUnitDto) {
    commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", true)
    return await junkApi
      .updateOperatingUnit(putOperatingUnitDto)
      .then(operatingUnitLeaf => {
        commit("UPDATE_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", operatingUnitLeaf)
        return Promise.resolve(operatingUnitLeaf)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(errorMessageHandler(error)), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", false)
      })
  },
  async createFranchise({ commit }, enrollFranchiseDto) {
    commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", true)
    return await junkApi
      .enrollFranchise(enrollFranchiseDto)
      .then(operatingUnitLeaf => {
        commit("UPDATE_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", operatingUnitLeaf)
        return Promise.resolve(operatingUnitLeaf)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(errorMessageHandler(error)), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", false)
      })
  },
  async updateFranchiseDetails({ commit }, putFranchiseDetailsDto) {
    commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", true)
    return await junkApi
      .updateFranchiseDetails(putFranchiseDetailsDto)
      .then(operatingUnitLeaf => {
        commit("UPDATE_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", operatingUnitLeaf)
        return Promise.resolve(operatingUnitLeaf)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(errorMessageHandler(error)), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", false)
      })
  },
  async createSatelliteOffice({ commit }, createSatelliteOfficeDto) {
    commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", true)
    return await junkApi
      .createSatelliteOffice(createSatelliteOfficeDto)
      .then(operatingUnitLeaf => {
        commit("UPDATE_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", operatingUnitLeaf)
        return Promise.resolve(operatingUnitLeaf)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(errorMessageHandler(error)), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", false)
      })
  },
  async updateSatelliteOfficeDetails({ commit }, putSatelliteOfficeDetailsDto) {
    commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", true)
    return await junkApi
      .updateSatelliteOfficeDetails(putSatelliteOfficeDetailsDto)
      .then(operatingUnitLeaf => {
        commit("UPDATE_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", operatingUnitLeaf)
        return Promise.resolve(operatingUnitLeaf)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(errorMessageHandler(error)), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", false)
      })
  },
  async createEmployee({ commit }, createEmployeeDto) {
    commit("SET_IS_LOADING_CREATE_EMPLOYEE", true)
    return await junkApi
      .createEmployee(createEmployeeDto)
      .then(result => {
        // commit("UPDATE_OPERATING_UNIT_FRANCHISES_AND_SATELLITES", operatingUnitLeaf)
        return Promise.resolve(result)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(errorMessageHandler(error)), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_CREATE_EMPLOYEE", false)
      })
  },

  // discounts
  async fetchNationalDiscounts({ commit }) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    await junkApi
      .fetchNationalDiscounts()
      .then(discounts => {
        commit("SET_DISCOUNTS", discounts)
        return Promise.resolve(discounts)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async createNationalDiscount({ commit }, createDiscountDto) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    await junkApi
      .createNationalDiscount(createDiscountDto)
      .then(discount => {
        commit("ADD_DISCOUNT", discount)
        commit("ADD_SNACKBAR", { message: "Discount Created", color: "success" }, { root: true })
        return Promise.resolve(discount)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_DISCOUNTS", false))
  },
  async updateNationalDiscount({ commit }, updateNationalDiscountDto) {
    commit("SET_IS_LOADING_DISCOUNTS", true)
    return await junkApi
      .updateNationalDiscount(updateNationalDiscountDto)
      .then(discount => {
        commit("UPDATE_DISCOUNT", discount)
        return Promise.resolve(discount)
      })
      .catch(error => {
        let formattedError = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(formattedError), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_DISCOUNTS", false)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {}
}
