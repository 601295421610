<template>
  <v-card ref="job-receipt-add-tip-card-container" class="px-8 pb-4 mb-0 align-self-center justify-center" flat :loading="getIsLoadingEmployeeTips">
    <v-card-title ref="job-receipt-add-tip-card-title" class="text-h6 justify-center">
      {{ getTitleMessage }}
    </v-card-title>
    <v-form ref="job-receipt-add-tip-card-form" class="ma-0 pa-0" v-model="isValid" v-on:submit.prevent="() => {}">
      <v-divider class="mb-4" />
      <v-row class="mt-n8 justify-center align-center">
        <v-col class="justify-center align-center">
          <v-card-text class="text-h6 text-center justify-center align-center">
            Price
            <span ref="job-receipt-add-tip-card-price-label" class="text-h5"> ${{ getCurrentJobPriceWithDiscount }}</span>
          </v-card-text>
        </v-col>
        <v-col v-if="hasTip" class="justify-center align-center">
          <v-card-text ref="job-receipt-add-tip-card-tip-label" v-if="hasTip" class="text-h6 text-center justify-center align-center">
            Current Tip
            <span ref="job-receipt-add-tip-card-price-label" class="text-h5"> ${{ getCurrentJobTip }}</span>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row ref="job-receipt-add-tip-card-tip-options" v-if="shouldDisplayTipOptions" class="mt-2" style="min-height: 128px">
        <v-col>
          <v-card
            ref="job-receipt-add-tip-card-tip-option-one"
            class="text-center selectable"
            :class="{ selected: selectedComponent === 'job-receipt-add-tip-card-tip-option-one' }"
            :disabled="isPercentageDisabled"
            @click.stop="updateProposedTipAmount('job-receipt-add-tip-card-tip-option-one', calculateMinimumTipInDollars)"
          >
            <v-card-title ref="job-receipt-add-tip-card-tip-option-one-title" class="justify-center headline">
              {{ getFormattedTipOptionOne }}
            </v-card-title>
            <v-card-subtitle>
              <app-number-formatter
                ref="job-receipt-add-tip-card-tip-option-one-amount"
                v-if="getTipConfiguration.isTipOptionsPercentage"
                :amount="calculateMinimumTipInDollars"
                number-format-style="currency"
                currency-sign="accounting"
              />
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            ref="job-receipt-add-tip-card-tip-option-two"
            class="text-center selectable"
            :class="{ selected: selectedComponent === 'job-receipt-add-tip-card-tip-option-two' }"
            :disabled="isPercentageDisabled"
            @click.stop="updateProposedTipAmount('job-receipt-add-tip-card-tip-option-two', calculateMediumTipInDollars)"
          >
            <v-card-title ref="job-receipt-add-tip-card-tip-option-two-title" class="justify-center headline">
              {{ getFormattedTipOptionTwo }}
            </v-card-title>
            <v-card-subtitle>
              <app-number-formatter
                ref="job-receipt-add-tip-card-tip-option-two-amount"
                v-if="getTipConfiguration.isTipOptionsPercentage"
                :amount="calculateMediumTipInDollars"
                number-format-style="currency"
                currency-sign="accounting"
              />
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            ref="job-receipt-add-tip-card-tip-option-three"
            class="text-center selectable"
            :class="{ selected: selectedComponent === 'job-receipt-add-tip-card-tip-option-three' }"
            :disabled="isPercentageDisabled"
            @click.stop="updateProposedTipAmount('job-receipt-add-tip-card-tip-option-three', calculateMaximumTipInDollars)"
          >
            <v-card-title ref="job-receipt-add-tip-card-tip-option-three-title" class="justify-center headline">
              {{ getFormattedTipOptionThree }}
            </v-card-title>
            <v-card-subtitle>
              <app-number-formatter
                ref="job-receipt-add-tip-card-tip-option-three-amount"
                v-if="getTipConfiguration.isTipOptionsPercentage"
                :amount="calculateMaximumTipInDollars"
                number-format-style="currency"
                currency-sign="accounting"
              />
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-row ref="job-receipt-add-tip-card-custom-tip" v-else class="mt-2" style="min-height: 128px">
        <v-col ref="job-receipt-add-tip-card-custom-tip-unit-column" v-if="!isPercentageDisabled" cols="4">
          <v-chip-group
            ref="job-receipt-add-tip-card-is-percentage-chip-group"
            v-if="!isPercentageDisabled"
            class="mt-1 fill-height"
            color="primary"
            mandatory
            v-model="isPercentage"
            @change="toggleIsCustomTipPercentage"
          >
            <v-chip ref="job-receipt-add-tip-card-is-percentage-chip" label v-for="item in getTipUnitChips" outlined :value="item.value" :key="item.value" :aria-label="item.label">
              <v-icon ref="job-receipt-add-tip-chip-icon">
                {{ item.icon }}
              </v-icon>
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col>
          <v-text-field
            ref="job-receipt-add-tip-card-custom-tip-amount"
            label="Custom Tip Amount"
            class="text-h6 text-center flex-fill"
            type="number"
            outlined
            v-model="customTipAmount"
            :prefix="getCustomTipPrefix"
            :append-icon="getCustomTipAppend"
            :error-messages="getCustomTipErrorMessage"
            :disabled="getIsLoadingEmployeeTips"
            @focus="handleFocusOnCustomTipField"
            @input="updateCustomTipAmount"
            @blur="formatCustomTipAmount"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col ref="job-receipt-add-tip-toggle-column" v-if="shouldDisplayToggleButton">
          <v-card ref="job-receipt-add-tip-card-toggle-button" class="text-center selectable" :disabled="getIsLoadingEmployeeTips" @click.stop="toggleIsCustomTipVisible">
            <v-card-title ref="job-receipt-add-tip-card-toggle-button-title" class="justify-center headline">
              {{ getCustomTipToggleButtonTitle }}
            </v-card-title>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            ref="job-receipt-add-tip-card-no-tip-button"
            class="text-center selectable"
            :disabled="getIsLoadingEmployeeTips"
            :class="{ selected: selectedComponent === 'job-receipt-add-tip-card-no-tip-button' }"
            @click.stop="updateProposedTipAmount('job-receipt-add-tip-card-no-tip-button', 0)"
          >
            <v-card-title class="justify-center headline">{{ getNoTipButtonText }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import AppNumberFormatter from "@/components/AppNumberFormatter"
import { mapGetters } from "vuex"

export default {
  name: "JobReceiptAddTipCard",
  components: { AppNumberFormatter },
  props: {
    customerName: {
      type: String,
      required: false
    },
    isTipResolved: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      isValid: false,
      selectedComponent: null,
      isPercentage: false,
      isCustomTipVisible: false,
      isCustomTipPercentage: false,
      proposedTipAmount: 0.0,
      customTipAmount: 0.0
    }
  },
  methods: {
    calculatePercentage(percentage) {
      return parseFloat(this.getCurrentJob.priceWithDiscount) * percentage / 100
    },
    handleFocusOnCustomTipField(event) {
      event.target.select()
      this.selectedComponent = null
      this.updateCustomTipAmount()
    },
    toggleIsCustomTipVisible() {
      this.isCustomTipVisible = !this.isCustomTipVisible
      this.selectedComponent = null
      this.customTipAmount = 0
      this.proposedTipAmount = 0

      if (!this.hasTip) {
        this.$emit("update:tipState", { isTipResolved: false, tipAmount: 0 })
      }
    },
    toggleIsCustomTipPercentage() {
      this.isCustomTipPercentage = !this.isCustomTipPercentage
      this.selectedComponent = null
      this.formatCustomTipAmount()
      this.updateCustomTipAmount()
    },
    formatCustomTipAmount() {
      if (!this.isCustomTipPercentage) {
        this.customTipAmount = parseFloat(this.customTipAmount).toFixed(2)
      }
    },
    updateCustomTipAmount() {
      let calculatedTipAmount = Number(parseFloat(this.customTipAmount))

      if (this.isPercentage) {
        calculatedTipAmount = this.calculatePercentage(this.customTipAmount)
      }

      const resolved = calculatedTipAmount > 0
      this.$emit("update:tipState", { isTipResolved: resolved, tipAmount: calculatedTipAmount.toFixed(2) || 0 })
    },
    updateProposedTipAmount(component, amount) {
      this.selectedComponent = component

      if (amount === 0) {
        this.proposedTipAmount = 0
        this.customTipAmount = null
      }
      amount = parseFloat(amount).toFixed(2)

      this.$emit("update:tipState", { isTipResolved: true, tipAmount: amount })
    },
  },
  computed: {
    ...mapGetters("Job", ["getCurrentJob", "getEmployeesOnJob", "getIsLoadingEmployeeTips", "getTipConfiguration"]),
    getTitleMessage() {
      return this.customerName ? this.customerName + `, would you like to add a tip?` : "Would you like to add a tip?"
    },
    getCurrentJobPriceWithDiscount() {
      return this.getCurrentJob.priceWithDiscount.toFixed(2)
    },
    getCurrentJobTip() {
      return this.getCurrentJob.totalTip.toFixed(2)
    },
    hasTip() {
      return this.getCurrentJob.totalTip > 0
    },
    shouldDisplayTipOptions() {
      return this.getTipConfiguration.isTipOptionsEnabled && !this.isCustomTipVisible && this.getCurrentJob.priceWithDiscount > 0
    },
    getTipUnitChips() {
      return this.getCurrentJob.priceWithDiscount > 0
        ? [
            { label: "Percentage", icon: "mdi-percent-outline", value: true },
            { label: "Dollar", icon: "mdi-currency-usd", value: false }
          ]
        : [{ label: "Dollar", icon: "mdi-currency-usd", value: false }]
    },
    shouldDisplayToggleButton() {
      return this.getTipConfiguration.isTipOptionsEnabled && this.getCurrentJob.priceWithDiscount > 0
    },
    getFormattedTipOptionOne() {
      return this.getTipConfiguration.isTipOptionsPercentage ? `${this.getTipConfiguration.tipOptionOneAmount}%` : `$${this.getTipConfiguration.tipOptionOneAmount}`
    },
    getFormattedTipOptionTwo() {
      return this.getTipConfiguration.isTipOptionsPercentage ? `${this.getTipConfiguration.tipOptionTwoAmount}%` : `$${this.getTipConfiguration.tipOptionTwoAmount}`
    },
    getFormattedTipOptionThree() {
      return this.getTipConfiguration.isTipOptionsPercentage ? `${this.getTipConfiguration.tipOptionThreeAmount}%` : `$${this.getTipConfiguration.tipOptionThreeAmount}`
    },
    calculateMinimumTipInDollars() {
      if (this.getTipConfiguration.isTipOptionsPercentage) {
        return this.calculatePercentage(this.getTipConfiguration.tipOptionOneAmount)
      } else {
        return this.getTipConfiguration.tipOptionOneAmount
      }
    },
    calculateMediumTipInDollars() {
      if (this.getTipConfiguration.isTipOptionsPercentage) {
        return this.calculatePercentage(this.getTipConfiguration.tipOptionTwoAmount)
      } else {
        return this.getTipConfiguration.tipOptionTwoAmount
      }
    },
    calculateMaximumTipInDollars() {
      if (this.getTipConfiguration.isTipOptionsPercentage) {
        return this.calculatePercentage(this.getTipConfiguration.tipOptionThreeAmount)
      } else {
        return this.getTipConfiguration.tipOptionThreeAmount
      }
    },
    isPercentageDisabled() {
      return (this.getTipConfiguration.isTipOptionsPercentage && this.getCurrentJob.priceWithDiscount <= 0) || this.getIsLoadingEmployeeTips
    },
    getCustomTipPrefix() {
      return this.isCustomTipPercentage ? "" : "$"
    },
    getCustomTipAppend() {
      return this.isCustomTipPercentage ? "mdi-percent" : ""
    },
    getCustomTipErrorMessage() {
      const isPositive = Number(parseFloat(this.customTipAmount)) > 0
      const isResolved = this.isTipResolved
      return !isPositive && !isResolved ? "Positive value required." : ""
    },
    getCustomTipToggleButtonTitle() {
      return this.isCustomTipVisible ? "QUICK TIP" : "CUSTOM TIP"
    },
    getNoTipButtonText() {
      return this.hasTip ? "REMOVE TIP" : "NO TIP"
    }
  },
  created() {
    if (this.getCurrentJob.totalTip === 0 && !this.isTipResolved) {
      this.$emit("update:tipState", { isTipResolved: false, tipAmount: 0 })
    }
  }
}
</script>

<style>
.selectable::before {
  display: none !important;
}
.selectable.selected::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 255, 0, 0.2) !important;
  border-radius: inherit;
  pointer-events: none;
}
</style>
