<template>
  <v-card elevation="4">
    <v-card-title>
      <v-row>
        <v-col>
          <span ref="settings-franchise-special-hours-title" class="headline word-wrap">Special Business Hours</span>
        </v-col>
        <v-col>
          <v-switch class="float-end" v-model="showPastSpecialHours" label="Show past special hours"></v-switch>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="settings-franchise-special-hours-form">
        <v-data-table
          ref="settings-franchise-special-hours-data-table"
          :items="getListItems"
          :headers="daysOffHeaders"
          sort-by="overriddenDate"
          disable-pagination
          hide-default-footer
          :loading="getIsLoadingFranchiseSpecialHours"
          loading-text="🔎 Loading Items 🔍"
          item-key="franchiseSpecialHourId"
        >
          <template v-slot:no-data>
            <v-col class="ma-0 pa-0 align-self-center d-flex">
              <app-not-found title="Ope! No available records :(" :content="getContentText">
                <template v-slot:actions>
                  <v-btn
                    v-if="!getSelectedFranchise"
                    ref="settings-special-hours-card-franchise-selector-btn"
                    class="mt-4 primary justify-center align-self-center"
                    @click="$emit('selectFranchiseSelector')"
                    ripple
                    rounded
                    large
                  >Select Franchise</v-btn>
                  <div v-else></div>
                </template>
              </app-not-found>
            </v-col>
          </template>
          <template v-slot:item.displayDate="{ item }">
            <div ref="settings-franchise-special-hours-date-read-only" v-if="!isItemBeingEdited(item.franchiseSpecialHourId)">{{ item.displayDate }}</div>
            <app-date-picker-field-selector
              v-else
              ref="settings-franchise-special-hours-date-selector"
              :minimum-date-value="minimumDate"
              :date-picker-value="editedItem.overriddenDate"
              @updatedPickerValue="setEditedItemDate($event)"
              text-field-format="YYYY-MM-DD"
              :text-field-disabled="isActionsDisabled"
            />
          </template>

          <template v-slot:item.description="{ item }">
            <div ref="settings-franchise-special-hours-description-read-only" v-if="!isItemBeingEdited(item.franchiseSpecialHourId)">{{ item.description }}</div>
            <v-textarea
              v-else
              ref="settings-franchise-special-hours-description"
              class="mt-2 mb-n1"
              style="min-width: 170px"
              :disabled="getIsLoadingFranchiseSpecialHours"
              outlined
              auto-grow
              v-model="editedItem.description"
              counter="50" />
          </template>

          <template v-slot:item.officeOpenTime="{item}">
            <div ref="settings-franchise-special-hours-office-open-time-read-only" v-if="!isItemBeingEdited(item.franchiseSpecialHourId)">{{ item.isOfficeClosed ? "Closed" : formatTime(item.officeOpenTime) }}</div>
            <app-time-picker-menu
              v-else
              class="mt-2 mb-n5 mx-0 pa-0"
              ref="settings-franchise-special-hours-card-business-start-time-picker"
              text-field-label=""
              :time-value="editedItem.officeOpenTime"
              :is-disabled="!isItemBeingEdited(item.franchiseSpecialHourId) || editedItem.isOfficeClosed"
              @updateTimeValue="setEditableItemFranchiseOpenTime($event)"
              :validation-rules="franchiseTimesValid"
              :allowed-minutes="allowedMinutes"
              dense
            ></app-time-picker-menu>
          </template>

          <template v-slot:item.officeCloseTime="{item}">
            <div ref="settings-franchise-special-hours-office-close-time-read-only" v-if="!isItemBeingEdited(item.franchiseSpecialHourId)">{{ item.isOfficeClosed ? "Closed" : formatTime(item.officeCloseTime) }}</div>
            <app-time-picker-menu
              v-else
              class="mt-2 mb-n5 mx-0 pa-0"
              ref="settings-franchise-special-hours-card-business-start-time-picker"
              text-field-label=""
              :time-value="editedItem.officeCloseTime"
              :is-disabled="!isItemBeingEdited(item.franchiseSpecialHourId) || editedItem.isOfficeClosed"
              @updateTimeValue="setEditableItemBusinessCloseTime($event)"
              :min-value="editedItem.officeOpenTime"
              :validation-rules="franchiseTimesValid"
              :allowed-minutes="allowedMinutes"
              dense
            ></app-time-picker-menu>
          </template>

          <template v-slot:item.isOfficeClosed="{ item }">
            <v-checkbox ref="settings-franchise-special-hours-is-office-closed-read-only" v-if="!isItemBeingEdited(item.franchiseSpecialHourId)" :disabled="!isItemBeingEdited(item.franchiseSpecialHourId)" :input-value="item.isOfficeClosed" />
            <v-checkbox v-else :disabled="!isItemBeingEdited(item.franchiseSpecialHourId)" :input-value="editedItem.isOfficeClosed" @change="setEditableItemIsOfficeClosed($event)"/>
          </template>

          <template v-slot:item.isHaulingClosed="{ item }">
            <v-checkbox ref="settings-franchise-special-hours-is-hauling-closed-read-only" v-if="!isItemBeingEdited(item.franchiseSpecialHourId)" :disabled="!isItemBeingEdited(item.franchiseSpecialHourId)" :input-value="item.isHaulingClosed" />
            <v-checkbox v-else :disabled="!isItemBeingEdited(item.franchiseSpecialHourId)" :input-value="editedItem.isHaulingClosed" @change="setEditableItemIsHaulingClosed($event)"/>
          </template>

          <template v-slot:item.customerBookingAmCutoffTime="{item}">
            <div ref="settings-franchise-special-hours-customer-am-cutoff-read-only" v-if="!isItemBeingEdited(item.franchiseSpecialHourId)">{{ item.isHaulingClosed ? "Closed" : formatTime(item.customerBookingAmCutoffTime) }}</div>
            <app-time-picker-menu
              v-else
              class="mt-2 mb-n5 mx-0 pa-0"
              ref="settings-franchise-special-hours-card-business-start-time-picker"
              text-field-label=""
              :time-value="editedItem.customerBookingAmCutoffTime"
              :is-disabled="!isItemBeingEdited(item.franchiseSpecialHourId) || editedItem.isHaulingClosed"
              @updateTimeValue="setEditableItemCustomerBookingAmCutoff($event)"
              :allowed-minutes="allowedMinutes"
              :max-value="'11:59'"
              dense
            ></app-time-picker-menu>
          </template>

          <template v-slot:item.customerBookingPmCutoffTime="{item}">
            <div ref="settings-franchise-special-hours-customer-pm-cutoff-read-only" v-if="!isItemBeingEdited(item.franchiseSpecialHourId)">{{ item.isHaulingClosed ? "Closed" : formatTime(item.customerBookingPmCutoffTime) }}</div>
            <app-time-picker-menu
              v-else
              class="mt-2 mb-n5 mx-0 pa-0"
              ref="settings-franchise-special-hours-card-business-start-time-picker"
              text-field-label=""
              :time-value="editedItem.customerBookingPmCutoffTime"
              :is-disabled="!isItemBeingEdited(item.franchiseSpecialHourId) || editedItem.isHaulingClosed"
              @updateTimeValue="setEditableItemCustomerBookingPmCutoff($event)"
              :allowed-minutes="allowedMinutes"
              dense
            ></app-time-picker-menu>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="justify-center" v-if="isItemBeingEdited(item.franchiseSpecialHourId)">
              <v-btn color="primary" ref="settings-franchise-special-hours-days-off-save-item-btn" data-cy="settings-franchise-days-off-save-item-btn" @click="save" :disabled="getIsLoadingFranchiseSpecialHours" icon>
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-btn class="mx-4" color="red" ref="settings-franchise-special-hours-cancel-item-btn" data-cy="settings-franchise-days-off-cancel-item-btn" @click="setIsEditing(false)" :disabled="getIsLoadingFranchiseSpecialHours" icon>
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
              <v-btn color="red" ref="settings-franchise-special-hours-delete-item-btn" data-cy="settings-franchise-days-off-delete-item-btn" @click="deleteSpecialHour(item.franchiseSpecialHourId)" :disabled="getIsLoadingFranchiseSpecialHours" icon>
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn v-if="isItemEditable(item)" color="primary" ref="settings-franchise-days-off-edit-item-btn" data-cy="settings-franchise-days-off-edit-item-btn" @click="setIsEditing(item)" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { todayAsDate } from "@/utils/DateTimeFormatters"
import { mapActions, mapGetters } from "vuex"
import AppTimePickerMenu from "@/components/AppTimePickerMenu.vue"
import { consoleLog } from "@/utils/Logging"
import { putFranchiseSpecialHoursDto } from "@/api/dtos/JunkDtos"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector.vue"
import AppNotFound from "@/components/AppNotFound.vue";

export default {
  name: "SettingsFranchiseSpecialHoursCard",
  components: { AppNotFound, AppDatePickerFieldSelector, AppTimePickerMenu },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      minimumDate: todayAsDate(),
      isEditing: false,
      editedItem: {},
      franchiseTimesValid: [],
      description: "",
      showPastSpecialHours: false
    }
  },
  methods: {
    ...mapActions("Settings", ["fetchFranchiseSpecialHoursByFranchiseId", "updateFranchiseSpecialHour", "deleteFranchiseSpecialHour"]),
    allowedMinutes: m => m % 15 === 0,
    setEditedItemDate(date) {
      this.editedItem.overriddenDate = date
    },
    setIsEditing(item) {
      this.editedItem = Object.assign({}, item)
    },
    isItemEditable(item){
      return item.overriddenDate >= todayAsDate()
    },
    async save() {
      await this.setFranchiseTimesValidRules()
      if (this.$refs["settings-franchise-special-hours-form"].validate()) {
        const dto = this.generatePutFranchiseSpecialHoursDto()
        console.log("saving franchise day off = ", JSON.stringify(dto,null,4))
        await this.updateFranchiseSpecialHour(dto).then(() => this.resetEditableItem())
      }
    },
    generatePutFranchiseSpecialHoursDto() {
      return putFranchiseSpecialHoursDto(
        this.editedItem.franchiseSpecialHourId,
        this.editedItem.description,
        this.editedItem.overriddenDate,
        this.editedItem.isOfficeClosed,
        this.editedItem.isHaulingClosed,
        this.editedItem.isOfficeClosed ? "00:00:00" : this.editedItem.officeOpenTime,
        this.editedItem.isOfficeClosed ? "00:00:00" : this.editedItem.officeCloseTime,
        this.editedItem.isHaulingClosed ? "00:00:00" : this.editedItem.customerBookingAmCutoffTime,
        this.editedItem.isHaulingClosed ? "00:00:00" : this.editedItem.customerBookingPmCutoffTime
      )
    },
    async resetEditableItem() {
      this.editedItem = {}
      await this.resetFranchiseTimesValid()
    },
    async deleteSpecialHour(franchiseSpecialHourId) {
      console.log("deleting franchise day off id = ", franchiseSpecialHourId)
      await this.deleteFranchiseSpecialHour(franchiseSpecialHourId)
    },
    formatTime(time) {
      const [hours, minutes] = time.split(":").map(Number)
      const period = hours < 12 ? "AM" : "PM"
      const twelveHour = (hours % 12 === 0) ? 12 : hours % 12;
      return `${String(twelveHour)}:${String(minutes).padStart(2, "0")} ${period}`
    },
    isItemBeingEdited(franchiseSpecialHourId) {
      return this.editedItem?.franchiseSpecialHourId === franchiseSpecialHourId
    },
    setEditableItemFranchiseOpenTime(time) {
      this.editedItem.officeOpenTime = `${time}:00`
    },
    setEditableItemBusinessCloseTime(time) {
      this.editedItem.officeCloseTime = `${time}:00`
    },
    setEditableItemIsOfficeClosed(isClosed) {
      this.editedItem.isOfficeClosed = isClosed
    },
    setEditableItemIsHaulingClosed(isClosed) {
      this.editedItem.isHaulingClosed = isClosed
    },
    setEditableItemCustomerBookingAmCutoff(time) {
      consoleLog("setEditableItemCustomerBookingAmCutoff.time", time)
      this.editedItem.customerBookingAmCutoffTime = `${time}:00`
    },
    setEditableItemCustomerBookingPmCutoff(time) {
      this.editedItem.customerBookingPmCutoffTime = `${time}:00`
    },
    async setFranchiseTimesValidRules() {
      await this.resetFranchiseTimesValid()
      if (!this.editedItem.isOfficeClosed) {
        const [hours1, minutes1, seconds1] = this.editedItem.officeOpenTime.split(":")
        const [hours2, minutes2, seconds2] = this.editedItem.officeCloseTime.split(":")
        const officeOpenTime = new Date(0, 0, 1, +hours1, +minutes1, +seconds1)
        consoleLog("setFranchiseTimesValidRules.officeOpenTime", officeOpenTime)
        const officeCloseTime = new Date(0, 0, 1, +hours2, +minutes2, +seconds2)
        consoleLog("setFranchiseTimesValidRules.officeCloseTime", officeCloseTime)
        this.franchiseTimesValid.push(officeOpenTime <= officeCloseTime || "Franchise must open before it closes.")
      }
    },
    async resetFranchiseTimesValid() {
      this.franchiseTimesValid = []
    }
  },
  computed: {
    ...mapGetters("Settings", ["getFranchiseSpecialHours", "getIsLoadingFranchiseSpecialHours", "getSelectedFranchise"]),
    daysOffHeaders() {
      const headers = [
        { text: "Day", align: "start", value: "displayDate" },
        { text: "Description", align: "start", value: "description" },
        { text: "Office Closed", align: "start", value: "isOfficeClosed", sortable: false },
        { text: "Opens At", align: "start", value: "officeOpenTime", sortable: false },
        { text: "Closes At", align: "start", value: "officeCloseTime", sortable: false },
        { text: "Hauling Closed", align: "start", value: "isHaulingClosed", sortable: false },
        { text: "Self-Scheduling AM Cutoff", align: "start", value: "customerBookingAmCutoffTime", sortable: false },
        { text: "Self-Scheduling PM Cutoff", align: "start", value: "customerBookingPmCutoffTime", sortable: false },
        { text: "Actions", align: "center", value: "actions", sortable: false }
      ]

      if (this.isActionsDisabled) {
        return headers.slice().filter(header => header.value !== "actions" && header.value !== "isOfficeClosed" && header.value !== "isHaulingClosed")
      } else if (Object.keys(this.editedItem).length === 0) {
        return headers.slice().filter(header => header.value !== "isOfficeClosed" && header.value !== "isHaulingClosed")
      }
      return headers
    },
    getListItems() {
      return this.showPastSpecialHours ? this.getFranchiseSpecialHours : this.getActiveFranchiseSpecialHours
    },
    getActiveFranchiseSpecialHours(){
      return this.getFranchiseSpecialHours.filter(c => c.overriddenDate >= todayAsDate())
    },
    getContentText() {
      if (this.getSelectedFranchise) {
        return "Create special hours using the add fab"
      } else {
        return "Looks like you don't have any records, please select a franchise."
      }
    }
  }
}
</script>

<style scoped></style>
