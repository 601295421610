<template>
  <v-layout class="ma-0 pa-0 flex-fill flex-column">
    <v-card id="settings-franchise-taxes-card" elevation="4">
      <v-card-title class="headline">
        <v-col class="ms-0 mb-4 me-0 mt-0 pa-0 align-self-start d-flex">
          <span ref="settings-franchise-taxes-title-heading" class="headline text-no-wrap align-self-start d-flex pe-1">Taxes</span>
          <base-tool-tip
            class="float-start align-self-start text-start justify-start"
            ref="settings-franchise-taxes-tool-tip"
            dialog-title="If a tax is setup, and is active, a tax will be associated to a job"
            nudge-dialog="-5"
            tooltip-icon="mdi-information-outline"
            :is-bottom-dialog="false"
            :is-right-dialog="true"
          />
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="ma-0 pa-0 d-flex flex-fill ms-0 ms-sm-4 ms-md-8 ms-lg-12 ms-xl-6 col-lg-6 col-xl-5" cols="auto">
          <v-switch
            ref="settings-franchise-taxes-include-past-taxes-switch"
            class="ma-0 mx-2 pa-0 justify-end align-end float-end align-self-end text-break"
            :disabled="isSearchTextFieldAndPastTaxesToggleDisabled"
            v-model="isDisplayPastTaxesEnabled"
            @click="scrollToSettingsTaxesCard"
            label="Include Past Taxes"
          ></v-switch>
          <v-text-field
            id="settings-franchise-taxes-search-field"
            class="ma-0 mx-4 ps-4 float-end d-flex align-self-end flex-fill"
            v-model="searchText"
            :disabled="isSearchTextFieldAndPastTaxesToggleDisabled"
            clearable="clearable"
            clear-icon="mdi-trash-can-outline"
            append-icon="mdi-magnify"
            label="Search Taxes"
          />
        </v-col>
      </v-card-title>
      <v-card-text class="mt-n4">
        <v-responsive class="overflow-y-auto" max-height="800">
          <v-data-table
            id="settings-franchise-tax-table"
            :headers="franchiseTaxesHeaders"
            :items="getFranchiseTaxList"
            item-key="taxId"
            :loading="getIsLoadingFranchiseTax"
            show-expand
            :search="searchText"
            single-expand
            sort-by="name"
            loading-text="🔎 Fetching Taxes 🔍"
            style="min-height: 120px"
            fixed-header
            hide-default-footer
            disable-pagination
          >
            <template v-slot:no-data>
              <v-col class="ma-0 pa-0 align-self-center d-flex">
                <app-not-found title="Ope! No available records :(" content="Looks like you don't have any records, please retry or refresh the page.">
                  <template v-slot:actions>
                    <v-btn class="mt-4 primary justify-center align-self-center" ref="settings-franchise-taxes-card-fetch-btn" ripple rounded large @click="fetchFranchiseTaxesBySelectedOperatingUnitId">Fetch Franchise Taxes</v-btn>
                  </template>
                </app-not-found>
              </v-col>
            </template>
            <template v-slot:item.taxName="{ value }">
              <span class="text-md-body-1 grey--text">{{ value }}</span>
            </template>
            <template v-slot:item.rate="{ value }">
              <span class="text-md-body-1 grey--text">{{ value }}</span>
            </template>
            <template v-if="!isActionsDisabled" v-slot:item.actions="{ item }">
              <div class="justify-center">
                <v-btn color="primary" ref="settings-franchise-taxes-edit-btn" @click="setAddFranchiseTaxAssociationDialogState(true, item)" icon>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn color="error" ref="settings-franchise-taxes-end-date-btn" @click="setFranchiseTaxesEndDateDialogState(true, item)" icon>
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
              <td v-if="item.franchises.length > 0" class="text-start">
                <v-btn icon @click="expand(!isExpanded)" class="v-data-table__expand-icon" :class="{ 'v-data-table__expand-icon--active': isExpanded }">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td class="pa-0" :colspan="headers.length">
                <v-responsive class="overflow-y-auto" max-height="350">
                  <v-data-table
                    :headers="franchiseTaxesSubHeaders"
                    :items="item.franchises"
                    fixed-header
                    dark
                    :item-class="itemRowBackground"
                    no-data-text="There are no franchises associated with this tax record."
                    mobile-breakpoint="590"
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:item.franchiseNumberAndName="{ value }">
                      <span class="text-md-body-1 grey--text">{{ value }}</span>
                    </template>
                    <template v-slot:item.beginDate="{ value }">
                      <span class="text-md-body-1 grey--text">{{ value }}</span>
                    </template>
                    <template v-slot:item.endDate="{ value }">
                      <span class="text-md-body-1 grey--text">{{ value }}</span>
                    </template>
                  </v-data-table>
                </v-responsive>
              </td>
            </template>
          </v-data-table>
        </v-responsive>
      </v-card-text>
    </v-card>
    <settings-add-franchise-tax-association-dialog
      v-if="isAddFranchiseTaxAssociationDialogVisible && !isActionsDisabled"
      ref="settings-add-franchise-tax-association-dialog"
      :is-dialog-visible="isAddFranchiseTaxAssociationDialogVisible"
      :franchise-tax="selectedFranchiseTaxRecord"
      @closeDialog="resetAddFranchiseTaxAssociationDialogState"
    />
    <settings-end-date-franchise-tax-dialog
      v-if="isEditFranchiseTaxDialogVisible && !isActionsDisabled"
      ref="settings-end-date-franchise-tax-dialog"
      :is-dialog-visible="isEditFranchiseTaxDialogVisible"
      :franchise-tax="selectedFranchiseTaxRecord"
      @closeDialog="resetFranchiseTaxesEndDateDialogState"
    ></settings-end-date-franchise-tax-dialog>
  </v-layout>
</template>

<script>
import AppNotFound from "@/components/AppNotFound"
import { mapActions, mapGetters } from "vuex"
import BaseToolTip from "@/components/BaseToolTip"
import { formatAsDateOnly, todayAsDate } from "@/utils/DateTimeFormatters"
import SettingsEndDateFranchiseTaxDialog from "@/components/SettingsEndDateFranchiseTaxDialog"
import SettingsAddFranchiseTaxAssociationDialog from "@/components/SettingsAddFranchiseTaxAssociationDialog"

export default {
  name: "SettingsFranchiseTaxesCard",
  components: { SettingsAddFranchiseTaxAssociationDialog, SettingsEndDateFranchiseTaxDialog, BaseToolTip, AppNotFound },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searchText: "",
      isDisplayPastTaxesEnabled: false,
      isEditFranchiseTaxDialogVisible: false,
      isAddFranchiseTaxAssociationDialogVisible: false,
      selectedFranchiseTaxRecord: {},
      franchiseTaxesSubHeaders: [
        { text: "Franchise Name", align: "start", value: "franchiseNumberAndName", class: "secondaryDark white--text" },
        { text: "Begin Date", align: "center", value: "beginDate", class: "secondaryDark white--text" },
        { text: "End Date", align: "center", value: "endDate", class: "secondaryDark white--text" }
      ]
    }
  },
  methods: {
    ...mapActions("Settings", ["fetchFranchiseTaxes"]),
    async scrollToSettingsTaxesCard() {
      if (this.isDisplayPastTaxesEnabled) return await this.$vuetify.goTo("#settings-franchise-taxes-card")
    },
    itemRowBackground() {
      if (!this.$vuetify.breakpoint.xs) return "backgroundDisabled"
    },
    resetFranchiseTaxesEndDateDialogState() {
      this.setFranchiseTaxesEndDateDialogState(false, {})
    },
    setFranchiseTaxesEndDateDialogState(isVisible, franchiseTax) {
      this.isEditFranchiseTaxDialogVisible = isVisible
      this.setSelectedFranchiseTaxRecord(franchiseTax)
    },
    setSelectedFranchiseTaxRecord(franchiseTax) {
      this.selectedFranchiseTaxRecord = Object.assign({}, franchiseTax)
    },
    setAddFranchiseTaxAssociationDialogState(isVisible, franchiseTax) {
      this.isAddFranchiseTaxAssociationDialogVisible = isVisible
      this.setSelectedFranchiseTaxRecord(franchiseTax)
    },
    resetAddFranchiseTaxAssociationDialogState() {
      this.setAddFranchiseTaxAssociationDialogState(false, {})
    },
    async fetchFranchiseTaxesBySelectedOperatingUnitId() {
      await this.fetchFranchiseTaxes(this.getSelectedOperatingUnitId)
    }
  },
  computed: {
    ...mapGetters(["getSelectedOperatingUnitId"]),
    ...mapGetters("Settings", ["getFranchiseTaxes", "getIsLoadingFranchiseTax"]),
    isSearchTextFieldAndPastTaxesToggleDisabled() {
      return this.getFranchiseTaxes?.length <= 0 || this.getIsLoadingFranchiseTax
    },
    getFranchiseTaxList() {
      return this.isDisplayPastTaxesEnabled ? this.getFranchiseTaxes : this.getActiveFranchiseTaxes
    },
    getActiveFranchiseTaxes() {
      let franchiseTaxesDeepCopy = JSON.parse(JSON.stringify(this.getFranchiseTaxes))
      return franchiseTaxesDeepCopy?.filter(o => {
        o.franchises = o.franchises?.filter(e => e.endDate ? (formatAsDateOnly(e.endDate) >= todayAsDate()) : 1 === 1)
        return o.franchises?.length > 0
      })
    },
    franchiseTaxesHeaders() {
      let headers = [
        { text: "Name", align: "start", value: "taxName" },
        { text: "Rate", align: "start", value: "rate" },
        { text: "Actions", align: "center", value: "actions", sortable: false, width: "10%" }
      ]
      return this.isActionsDisabled ? headers.slice().filter(header => header.text !== "Actions") : headers
    }
  },
  async created() {
    await this.fetchFranchiseTaxesBySelectedOperatingUnitId()
  }
}
</script>

<style scoped></style>
