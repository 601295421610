/**
 * Check if object is empty, null, or undefined.
 * @param obj
 * @returns {boolean}
 */
export const isObjectEmpty = obj => {
  if (obj === null || obj === undefined) {
    return true
  }

  if (typeof obj === "object" && !Array.isArray(obj)) {
    return Object.keys(obj).length === 0
  }

  return false
}
