import axios from "axios"
import getEnv from "@/utils/Env"
import { app } from "../../appsettings.json"
import apiUtils from "@/api/Utils"

/* This client is used for interacting with the document-service */
const documentClient = axios.create({
  baseURL: getEnv("VUE_APP_DOCUMENT_API_URL") || app.VUE_APP_DOCUMENT_API_URL,
  timeout: 60000,
  json: true
})

export default {
  async execute(method, resource, dataParam, queryParams) {
    return documentClient({
      method,
      url: resource,
      data: dataParam,
      params: queryParams,
      headers: {
        Authorization: await apiUtils.documentHeader(),
        "User-Time-Zone-Id": Intl.DateTimeFormat().resolvedOptions().timeZone,
        "Access-Control-Allow-Origin": "*"
      }
    }).then(req => {
      return req.data
    })
  },

  // receipts
  async fetchJobReceipt(jobId) {
    return await this.execute("get", `api/v1/receipt/job/${jobId}`)
  },
  async postJobReceiptToCreate(createReceiptDto) {
    return await this.execute("post", `api/v1/receipt/create`, createReceiptDto)
  },
  async postJobReceiptToGenerate(generateReceiptDto) {
    return await this.execute("post", `api/v1/receipt/generate`, generateReceiptDto)
  },
  async postJobReceiptToResend(resendReceiptDto) {
    return await this.execute("post", `api/v1/receipt/resend`, resendReceiptDto)
  },
  async fetchReceiptsNotSent(franchiseIds) {
    let params = await apiUtils.generateQueryParams("franchiseIds", franchiseIds)
    return await this.execute("get", `api/v1/receipt/not-sent-receipts`, null, params)
  },

  // customer representative profiles
  async fetchCustomerRepresentativeSignatureProfilesByJobId(jobId) {
    return await this.execute("get", `api/v1/customer-representative/${jobId}`)
  },
  async postCustomerRepresentativeSignatureProfile(createCustomerRepresentativeSignatureProfileDto) {
    return await this.execute("post", `api/v1/customer-representative/create`, createCustomerRepresentativeSignatureProfileDto)
  },

  // employee profiles
  async fetchJobDocumentEmployeeProfileByEmployeeId(employeeId) {
    return await this.execute("get", `api/v1/employee-profile/${employeeId}`)
  },
  async fetchJobDocumentEmployeeProfileByProfileId(employeeProfileId) {
    return await this.execute("get", `api/v1/employee-profile/profile/${employeeProfileId}`)
  },
  async postJobDocumentEmployeeProfileToCreate(createEmployeeProfileDto) {
    return await this.execute("post", `api/v1/employee-profile/create`, createEmployeeProfileDto)
  },

  // phrases
  async postJobDocumentPhraseToUpdate(updatePhraseDto) {
    return await this.execute("put", `api/v1/document-phrase/update`, updatePhraseDto)
  },

  // payments
  async fetchJobReceiptPayments(jobFranchiseDocumentId) {
    return await this.execute("get", `api/v1/payments/${jobFranchiseDocumentId}`)
  },
  async postJobReceiptPaymentToSave(savePaymentDto) {
    return await this.execute("post", `api/v1/payment`, savePaymentDto)
  },
  async deleteJobReceiptPayment(paymentId) {
    return await this.execute("delete", `api/v1/payment/${paymentId}`)
  }
}
