<template>
  <v-banner ref="app-environment-root" color="backgroundDisabled" class="ms-n4 my-n1" elevation="0" app>
    <v-row align="center" justify="center">
      <v-col cols="auto">
        <v-avatar class="ms-4 ms-md-3" rounded color="primary" size="40">
          <v-icon color="white" ref="app-environment-icon">
            mdi-information-outline
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col class="ms-n2">
        <span class="flex-fill" ref="app-environment-banner-main-text">
          You are currently in the Training environment. Do not enter production data here.
        </span>
      </v-col>
    </v-row>
  </v-banner>
</template>
<script>
export default {
  name: "AppEnvironmentBanner"
}
</script>

<style scoped></style>
