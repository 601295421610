<template>
  <base-dialog ref="settings-add-franchise-tax-association-dialog-root" :title="`Associate Franchises to ${franchiseTax.taxName}`" :is-dialog-visible="isDialogVisible" dialog-image="mdi-cash-plus">
    <template class="flex-fill" v-slot:content>
      <v-form ref="settings-add-franchise-tax-association-dialog-form" v-model="isFormValid">
        <v-layout row>
          <v-select
            ref="settings-add-franchise-tax-association-dialog-franchise-selector"
            label="Selected Franchises"
            v-model="selectedFranchiseIds"
            :items="getFranchisesInOperatingUnitNotAssociatedToTaxRecord"
            item-text="franchiseNameAndNumber"
            item-value="franchiseId"
            :rules="isRequiredSelectorRules"
            multiple
            outlined
            :menu-props="{ bottom: true, offsetY: true }"
          />
        </v-layout>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="settings-add-franchise-tax-association-dialog-cancel-btn" color="primaryText" @click="closeDialog" :disabled="false" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" ref="settings-add-franchise-tax-association-dialog-confirm-btn" color="primary" @click="confirm" :loading="getIsLoadingFranchiseTax" :disabled="getIsLoadingFranchiseTax" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { mapActions, mapGetters } from "vuex"
import { associateTaxToFranchisesDto } from "@/api/dtos/JunkDtos"

export default {
  name: "SettingsAddFranchiseTaxAssociationDialog",
  components: { BaseDialog },
  props: {
    franchiseTax: Object,
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFormValid: false,
      selectedFranchiseIds: [],
      isRequiredSelectorRules: [value => (!!value && value.length > 0) || "Is required."]
    }
  },
  methods: {
    ...mapActions("Settings", ["addFranchiseTaxAssociation"]),
    async confirm() {
      if (this.$refs["settings-add-franchise-tax-association-dialog-form"].validate()) {
        const franchiseTaxId = this.franchiseTax.franchises[0].franchiseTaxId
        const dto = associateTaxToFranchisesDto(this.selectedFranchiseIds, franchiseTaxId, this.$msal.getCurrentUserId())
        await this.addFranchiseTaxAssociation(dto).then(() => this.closeDialog())
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    ...mapGetters("Settings", ["getFranchisesInOperatingUnit", "getIsLoadingFranchiseTax"]),
    getFranchisesInOperatingUnitNotAssociatedToTaxRecord() {
      return this.getFranchisesInOperatingUnit?.slice().filter(gfiou => !this.franchiseTax.franchises?.some(f => gfiou.franchiseId === f.franchiseId))
    }
  }
}
</script>

<style scoped></style>
