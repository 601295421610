<template>
  <base-dialog :is-dialog-visible="isDialogVisible" title="Create Operating Unit" dialog-image="mdi-account-plus-outline" ref="admin-create-operating-unit-dialog-root">
    <template class="flex-fill" v-slot:content>
      <v-form class="ma-0 pa-0" ref="admin-create-operating-unit-dialog-form">
        <v-row dense>
          <v-col>
            <v-text-field
              ref="admin-create-operating-unit-dialog-operating-unit-number-text"
              label="Operating Unit Number *"
              type="number"
              v-model="operatingUnitNumber"
              outlined
              :rules="isRequiredRules"
              counter="5"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              ref="admin-create-operating-unit-dialog-operating-unit-name-text"
              label="Operating Unit Name *"
              v-model="operatingUnitName"
              outlined
              :rules="isRequiredRules"
              counter="50"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              ref="admin-create-operating-unit-dialog-dot-number-text"
              label="Department of Transportation Number"
              v-model="dotNumber"
              outlined
              type="number"
              counter="50"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-checkbox
              ref="admin-create-operating-unit-dialog-is-test-operating-unit-checkbox"
              label="Is Test Operating Unit"
              v-model="isTestOperatingUnit"
              :value="isTestOperatingUnit"
              :input-value="isTestOperatingUnit"
              @change="setIsTestOperatingUnit($event)"
              outlined
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="admin-create-operating-unit-dialog-cancel-btn" color="primaryText" @click="closeDialog" :disabled="false" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" ref="admin-create-operating-unit-dialog-confirm-btn" color="primary" @click="save" :loading="getIsLoadingOperatingUnitFranchisesAndSatellites" :disabled="getIsLoadingOperatingUnitFranchisesAndSatellites" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { mapActions, mapGetters } from "vuex"
import { createOperatingUnitDto } from "@/api/dtos/JunkDtos"

export default {
  name: "AdministratorCreateOperatingUnitDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      operatingUnitName: "",
      operatingUnitNumber: "",
      dotNumber: "",
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."],
      isTestOperatingUnit: false
    }
  },
  methods: {
    ...mapActions("Administrator", ["createOperatingUnit"]),
    async save() {
      if (this.$refs["admin-create-operating-unit-dialog-form"].validate()) {
        let dto = createOperatingUnitDto(this.operatingUnitName, this.operatingUnitNumber, this.dotNumber, this.isTestOperatingUnit)
        await this.createOperatingUnit(dto).then(() => this.closeDialog())
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    setIsTestOperatingUnit(bool) {
      this.isTestOperatingUnit = bool ?? false
    }
  },
  computed: {
    ...mapGetters("Administrator", ["getIsLoadingOperatingUnitFranchisesAndSatellites"])
  }
}
</script>

<style scoped></style>
