<template>
  <v-card ref="job-queue-claimed-jobs-card-root" class="pb-4">
    <v-card-title class="headline">Claimed Jobs</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select
            ref="job-queue-claimed-jobs-card-truck-selector"
            class="d-inline-flex cursor_pointer"
            label="Selected Truck"
            item-value="junkTruckId"
            :item-text="formatTruckName"
            no-data-text="No Trucks Available"
            :loading="getIsLoadingActiveTrucks"
            :disabled="getIsLoadingActiveTrucks"
            :items="getActiveTrucks"
            :value="getSelectedTruckId"
            @change="setSelectedJunkTruckId"
            :menu-props="{ bottom: true, offsetY: true }"
            outlined
            rounded
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-responsive class="overflow-y-auto" max-height="550" min-height="100">
          <v-data-table
            class="flex-fill"
            ref="job-queue-claimed-jobs-data-table"
            :headers="claimedJobsHeaders"
            :loading="getIsLoadingSlotQueues"
            loading-text="🔎 Fetching Queued Jobs 🔍"
            :items="getSelectedTruckJobQueue"
            mobile-breakpoint="840"
            hide-default-footer
          >
            <template v-slot:no-data>
              <v-col class="ma-0 pa-0 align-self-center d-flex">
                <app-not-found v-if="isInitialEmptyStateVisible" ref="job-queue-claimed-jobs-card-initial-empty-state" title="No Results" content="Please select a truck.">
                  <template v-slot:actions><div></div></template>
                </app-not-found>
                <app-not-found v-else title="No Results" ref="job-queue-claimed-jobs-card-empty-state" content="No Jobs Are Currently Claimed for the Selected Date and Truck.">
                  <template v-slot:actions><div></div></template>
                </app-not-found>
              </v-col>
            </template>
            <template v-slot:item.jobNumber="{ item }">
              <router-link class="text-md-body-1" :to="{ name: 'Job', params: { jobId: item.jobId } }">{{ item.jobNumber }}</router-link>
              <v-icon color="primary" @click="addNewJobTab(item)">
                mdi-pin-outline
              </v-icon>
            </template>
            <template v-slot:item.jobAddresses="{ item }">
              <v-list dense class="transparent">
                <v-list-item-group class="transparent">
                  <v-list-item class="transparent" v-for="(address, index) in getCustomerAddresses(item.jobAddresses)" :key="index" inactive @click.stop="copyText(address)">
                    <v-list-item-content>
                      <span role="button" class="text-md-body-1 primaryText--text"
                        >{{ address.fullAddress }}
                        <v-btn @click.stop="copyText(address)" icon small elevation="0">
                          <v-icon small>mdi-content-copy</v-icon>
                        </v-btn>
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
            <template v-slot:item.customerAndAddress="{ item }">
              <span class="text-md-body-1" style="white-space: pre-line">{{ customerAndAddress(item) }}</span>
            </template>
            <template v-slot:item.capacityName="{ item }">
              <span class="text-md-body-1">{{ item.capacityName }}</span>
            </template>
            <template v-slot:item.preferredTimeSlotName="{ item }">
              <v-row class="justify-center text-center align-center" dense>
                <v-col v-if="isPreferredTimeSlotTextVisible" cols="auto" class="d-flex">
                  <div>{{ item.preferredTimeSlotName }}</div>
                </v-col>
                <v-col cols="auto" class="d-flex mt-n1">
                  <v-icon>
                    {{ getPreferredTimeSlotIcon(item.preferredTimeSlotId) }}
                  </v-icon>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.statusName="{ item }">
              <v-chip :color="determineBackgroundColor(item.statusName)" :text-color="determineBackgroundColor(item.statusName)" outlined>
                {{ item.statusName }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn ref="job-addresses-card-navigate-to-address-btn" @click.stop="promptDesiredNavigationDialog(item.jobAddresses)" icon>
                <v-icon>mdi-navigation-variant-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.totalDistance="{ value }">
              <span>{{formatDistanceFromUser(value)}}</span>
            </template>
          </v-data-table>
        </v-responsive>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AppNotFound from "@/components/AppNotFound"
import { mapActions, mapGetters } from "vuex"
import { successSnackbar } from "@/utils/SnackbarBuilder"
import { getJobStatusColorByStatusName } from "@/utils/JobStatusColors"
import { PreferredTimeSlot } from "@/enums/PreferredTimeSlot"
import { JobTab } from "@/models/JobTab"

export default {
  name: "JobQueueClaimedJobsCard",
  components: { AppNotFound },
  data() {
    return {
      claimedJobsHeaders: [
        { text: "Job Number", align: "start", value: "jobNumber" },
        { text: "Customer & Address", align: "start", value: "customerAndAddress" },
        { text: "Distance From Me", align: "start", value: "totalDistance" },
        { text: "Capacity", align: "start", value: "capacityName" },
        { text: "Preferred Time Slot", align: "center", value: "preferredTimeSlotName" },
        { text: "Status", align: "center", value: "statusName" },
        { text: "Actions", align: "center", value: "actions" }
      ]
    }
  },
  computed: {
    ...mapGetters("JobQueue", ["getActiveTrucks", "getIsLoadingActiveTrucks", "getSelectedTruckJobQueue", "getSelectedTruckId", "getIsLoadingSlotQueues"]),
    isPreferredTimeSlotTextVisible() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    isInitialEmptyStateVisible() {
      return this.getSelectedTruckId === undefined
    }
  },
  methods: {
    ...mapActions("JobQueue", ["setSelectedJunkTruckId", "setAddressesForNavigation"]),
    ...mapActions(["addSnackbar", "addJobTab"]),
    async addNewJobTab(item) {
      let tab = new JobTab(item.jobId, item.jobNumber, item.customerId, item.customerLastName, item.businessName)
      await this.addJobTab(tab)
    },
    async copyText(textToCopy) {
      await navigator.clipboard.writeText(textToCopy)
      await this.addSnackbar(successSnackbar("Address copied to clipboard!", 6000))
    },
    formatDistanceFromUser(distance) {
      return distance >= 0 ? `${distance} Miles` : "No Address"
    },
    formatTruckName(item) {
      console.log("JobQueueClaimedJobsCard.item = ", JSON.stringify(item, null, 4))
      return item.isUnavailable ? `${item.name} (unavailable)` : `${item.name}`
    },
    customerAndAddress(item) {
      let address = item.jobAddresses.find(ja => !ja.isFranchiseLocation)
      if (address) {
        return `${item.customerName}\n${address?.fullAddress ?? ""}`
      }
      return item.customerName
    },
    getPreferredTimeSlotIcon(preferredTimeSlotId) {
      switch (preferredTimeSlotId) {
        case PreferredTimeSlot.FIRST.id:
          return `mdi-numeric-1-circle`
        case PreferredTimeSlot.AM.id:
          return `mdi-sun-angle`
        case PreferredTimeSlot.PM.id:
          return `mdi-weather-night`
        case PreferredTimeSlot.NONE.id:
          return `mdi-theme-light-dark`
        default:
          return `mdi-blank`
      }
    },
    determineBackgroundColor(statusName) {
      return getJobStatusColorByStatusName(statusName)
    },
    promptDesiredNavigationDialog(jobAddresses) {
      console.log(JSON.stringify(jobAddresses, null, 4))
      this.setAddressesForNavigation(jobAddresses)
      this.$emit("addresses-selected-for-navigation", jobAddresses)
    },
    getCustomerAddresses(jobAddresses) {
      console.log("filtering job addresses...", JSON.stringify(jobAddresses, null, 4))
      return jobAddresses.filter(a => !a.isFranchiseLocation).slice()
    }
  }
}
</script>

<style scoped>
.center {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
</style>
