<template>
  <base-dialog ref="app-address-search-dialog-root" :is-dialog-visible="isDialogVisible" title="Address Search" dialog-image="mdi-magnify" @close-dialog="closeDialog">
    <template v-slot:content>
      <div v-if="!isHereMapVisible">
        <v-row dense>
          <v-col>
            <v-autocomplete
              ref="app-address-search-dialog-autocomplete"
              v-model="address"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              autocomplete="null"
              item-text="suggestion"
              label="Search Address"
              placeholder="Start typing to Search"
              clearable
              cache-items
              hide-selected
              outlined
              return-object
            >
              <template v-slot:no-data>
                <div class="text-center py-4">
                  <v-row>
                    <v-col>
                      <span class="text-center text-md-body-1">No Results.</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn outlined rounded x-large @click="setIsHereMapVisible(true)">Open Map View</v-btn>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field ref="app-address-search-dialog-line-2" outlined label="Address Line 2/ Apartment/ Suite Number" v-model="addressLine2" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field ref="app-address-search-dialog-complex" outlined label="Complex" v-model.trim="complex" />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <app-map-suggestion-dialog
          ref="app-address-search-dialog-map-suggestion-dialog"
          :initial-position-latitude="initialCameraPosition.lat"
          :initial-position-longitude="initialCameraPosition.lng"
          :is-loading-app-new-address-form-dialog="isLoadingAppNewAddressFormDialog"
          :is-create-address-from-marker-button-visible="isCreateAddressFromMarkerButtonVisible"
          @createAddress="handleCreateAddress"
        ></app-map-suggestion-dialog>
      </div>
    </template>

    <template v-slot:actions>
      <v-btn class="pa-4" color="primaryText" ref="app-address-search-dialog-cancel-btn" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn v-if="!isHereMapVisible" :loading="isLoadingCreateAddressFromSearch" :disabled="isAddButtonDisabled" class="pa-4" color="primaryText" ref="app-address-search-dialog-confirm-btn" @click="confirm" text rounded ripple>Add</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import routingApi from "@/api/Routing"
import * as Sentry from "@sentry/vue"
import { errorSnackbar } from "@/utils/SnackbarBuilder"
import { mapActions } from "vuex"
import AppMapSuggestionDialog from "@/components/AppMapSuggestionDialog"
import { createCustomerAddressDto } from "@/api/dtos/JunkDtos"

export default {
  name: "AppAddressSearchDialog",
  components: { AppMapSuggestionDialog, BaseDialog },
  props: {
    initialCameraPosition: {
      type: Object,
      default: () => ({ lat: 0, lng: 0 })
    },
    isDialogVisible: {
      type: Boolean,
      required: true
    },
    isLoadingAppNewAddressFormDialog: {
      type: Boolean,
      default: false
    },
    isLoadingCreateAddressFromSearch: {
      type: Boolean,
      default: false
    },
    isCreateAddressFromMarkerButtonVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      address: undefined,
      validatedAddress: undefined,
      addressLine2: undefined,
      complex: null,
      search: null,
      isLoading: false,
      entries: [],
      isHereMapVisible: false
    }
  },
  methods: {
    ...mapActions(["addSnackbar"]),
    confirm() {
      let address = this.validatedAddress

      if (this.addressLine2) {
        address.addressLine2 = this.addressLine2.trim()
      }

      if (this.complex) {
        address.complex = this.complex.trim()
      }

      let houseNumber = address.houseNumber
      let street = address.street
      let addressLine1 = `${houseNumber} ${street}`.trim()
      let dto = createCustomerAddressDto(addressLine1, address.addressLine2, address.city, address.stateCode, address.postalCode, address.countryCode, address.latitude, address.longitude, false, address.complex)
      this.$emit("createAddressFromSearch", dto)
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    setIsHereMapVisible(isVisible) {
      this.isHereMapVisible = isVisible
    },
    handleCreateAddress(event) {
      this.$emit("createAddressFromForm", event)
    }
  },
  computed: {
    isAddButtonDisabled() {
      return this.address === undefined || this.address === null || this.address === "" || Object.keys(this.address).length === 0 || this.isLoadingCreateAddressFromSearch
    },
    items() {
      return this.entries
    }
  },

  watch: {
    async search(val) {
      if (val.length < 3) return
      this.isLoading = true
      await routingApi
        .fetchAutoSuggestedAddressesByQuery(val, this.initialCameraPosition.lat, this.initialCameraPosition.lng)
        .then(data => {
          if (Array.isArray(data)) {
            this.entries = data
          } else {
            this.entries = []
          }
          Promise.resolve(data)
        })
        .catch(err => {
          Sentry.captureException(err)
          Promise.reject(err)
        })
        .finally(() => (this.isLoading = false))
    },
    async address(newValue) {
      console.log("watcher newValue: ", newValue)

      if (newValue === null || newValue === "") {
        this.validatedAddress = undefined
        this.address = ""
        return
      }

      await routingApi
        .fetchAddressLookupById(newValue.id)
        .then(data => {
          console.log(JSON.stringify(data, null, 4))
          this.validatedAddress = data
        })
        .catch(err => {
          console.log(err)
          this.validatedAddress = undefined
          this.address = ""
          this.addSnackbar(errorSnackbar("Unable to validate address, please try again."))
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style scoped></style>
