let formatAddressText = function(item) {
  let addressLine2 = item?.addressLine2?.trim() || ""
  let complex = item?.complex?.trim() || ""

  let addressToReturn = `${item.addressLine1}, ${item.city}, ${item.stateOrProvince} ${item.zipCode}`
  if (addressLine2.length >= 1) {
    addressToReturn = `${item.addressLine1}, ${item?.addressLine2}, ${item.city}, ${item.stateOrProvince} ${item.zipCode}`
  }

  if (complex.length >= 1) {
    addressToReturn = addressToReturn += ` ${complex}`
  }

  return addressToReturn
}

let formatHereAddressText = function(address) {
  if (!address) return address
  return address?.addressLine2?.trim()
    ? `${address.addressLine1}, ${address.addressLine2}, ${address.city}, ${address.stateCode} ${address.postalCode}`
    : `${address.addressLine1}, ${address.city}, ${address.stateCode} ${address.postalCode}`
}

module.exports = {
  formatAddressText,
  formatHereAddressText
}
