export const Permissions = {
  READ_JOB: "read:job",
  WRITE_JOB: "write:job",
  WRITE_CLOSE_JOB: "close:job",
  READ_SETTINGS: "read:settings",
  WRITE_SETTINGS: "write:settings",
  WRITE_UNCOMPLETE_JOB: "write:uncomplete-job",
  WRITE_FRANCHISE_ONBOARDING: "write:franchise-onboarding",
  READ_QUEUE_NOTIFICATIONS: "read:queue-notifications"
}
