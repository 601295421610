/**
 * Used by the SnackbarBuilder to construct a base snackbar object to be displayed to the user.
 * @param id - Unique identifier.
 * @param text - Informative text that will be displayed within the body of the snackbar.
 * @param color - The desired color of the snackbar.
 * @param icon - Icon that will be prepended to the text that's displayed in the body of the snackbar.
 * @param iconColor - The color of the icon that will be prepended to the text that's displayed in the body of the snackbar.
 * @param timeout - The desired length of time, in milliseconds, the snackbar should be displayed before automatically dismissing.
 * @param negativeAction - An object containing attributes for a negative action (e.g. - dismiss action).
 * @param positiveAction - An object containing attributes for a positive action (e.g. - a retry event).
 * @constructor
 */
let snackbar = function(id, text, color, icon, iconColor, timeout, negativeAction, positiveAction) {
  this.id = id
  this.text = text
  this.color = color
  this.icon = icon
  this.iconColor = iconColor
  this.timeout = timeout
  this.negativeAction = negativeAction
  this.positiveAction = positiveAction
}

/**
 * Base Snack Builder leveraged by all snackbar methods, below, to generate a snackbar object.
 * All snackbars have a default timeout of `10,000 ms` and a negative action which will dismiss the snackbar when clicked.
 * @returns {{setIcon: (function(*): *), setTimeout: (function(*): *), setColor: (function(*): *), build: (function(): snackbar), setNegativeAction: (function(*, *): *), setIconColor: (function(*): *), setText: (function(*): *), setPositiveAction: (function(*, *, *): *)}}
 * @constructor
 */
let snackbarBuilder = function() {
  let _id = Math.floor(Math.random() * 1000000)
  let _text = ""
  let _color = "primary"
  let _icon = "mdi-blank"
  let _iconColor = "white"
  let _timeout = 10000
  let _negativeAction = {
    isEnabled: true,
    text: "Dismiss"
  }
  let _positiveAction = {
    isEnabled: false,
    text: "",
    event: undefined
  }

  return {
    setText: function(text) {
      _text = text ?? _text
      return this
    },
    setColor: function(color) {
      _color = color ?? _color
      return this
    },
    setIcon: function(icon) {
      _icon = icon ?? _icon
      return this
    },
    setIconColor: function(iconColor) {
      _iconColor = iconColor ?? _iconColor
      return this
    },
    setTimeout: function(timeout) {
      _timeout = timeout ?? _timeout
      return this
    },
    setNegativeAction: function(isEnabled, text) {
      _negativeAction = {
        isEnabled: isEnabled ?? _negativeAction.isEnabled,
        text: text ?? _negativeAction.text
      }
      return this
    },
    setPositiveAction: function(isEnabled, text, event) {
      _positiveAction = {
        isEnabled: isEnabled ?? _positiveAction.isEnabled,
        text: text ?? _positiveAction.text,
        event: event ?? _positiveAction.event
      }
      return this
    },
    build: function() {
      return new snackbar(_id, _text, _color, _icon, _iconColor, _timeout, _negativeAction, _positiveAction)
    }
  }
}

/**
 * Snackbar used for displaying an error event/ message. By default, this error snackbar does not require user interaction in order to be dismissed.
 * @param text - Informative text that will be displayed within the body of the snackbar.
 * @param timeout - Length of time, in milliseconds, the snackbar should be displayed before automatically dismissing. If a timeout is not supplied, the default timeout of `10_000 ms` will be used.
 * @returns {snackbar}
 */
let errorSnackbar = function(text, timeout = 10000) {
  return new snackbarBuilder()
    .setText(text)
    .setColor("error")
    .setTimeout(timeout)
    .setIcon("mdi-close-circle-outline")
    .build()
}

/**
 * Snackbar used for displaying an error event/ message with a custom action. By default, this error snackbar does not require user interaction in order to be dismissed.
 * @param text - Informative text that will be displayed within the body of the snackbar.
 * @param positiveActionText - Text displayed for the button that invokes the event passed.
 * @param event - Function that will be invoked on user interaction.
 * @param timeout - Length of time, in milliseconds, the snackbar should be displayed before automatically dismissing. If a timeout is not supplied, the default timeout of `10_000 ms` will be used.
 * @returns {snackbar}
 */
let errorSnackbarWithPositiveAction = function(text, positiveActionText, event, timeout = 10000) {
  return new snackbarBuilder()
    .setText(text)
    .setColor("error")
    .setTimeout(timeout)
    .setIcon("mdi-close-circle-outline")
    .setPositiveAction(true, positiveActionText, event)
    .build()
}

/**
 * Snackbar used for displaying an error event/ message, with a custom action. This snackbar requires user interaction in order to be dismissed.
 * @param text - Informative text displayed within the body of the snackbar.
 * @param positiveActionText - Text displayed for the button that invokes the event passed.
 * @param event - Function that will be invoked on user interaction.
 * @returns {snackbar}
 */
let persistentErrorSnackbarWithPositiveAction = function(text, positiveActionText, event) {
  return new snackbarBuilder()
    .setText(text)
    .setColor("error")
    .setTimeout(-1)
    .setIcon("mdi-close-circle-outline")
    .setPositiveAction(true, positiveActionText, event)
    .build()
}

/**
 * Snackbar used for displaying an informative event/ message. By default, this error snackbar does not require user interaction in order to be dismissed.
 * @param text - Informative text displayed within the body of the snackbar.
 * @param timeout - Length of time, in milliseconds, the snackbar should be displayed before automatically dismissing. If a timeout is not supplied, the default timeout of `10_000 ms` will be used.
 * @returns {snackbar}
 */
let infoSnackbar = function(text, timeout = 10000) {
  return new snackbarBuilder()
    .setText(text)
    .setColor("info")
    .setTimeout(timeout)
    .setIcon("mdi-information-outline")
    .build()
}

/**
 * Snackbar used for displaying a successful event/ message. Will return a snackbar object with the provided snackbarText and timeout.
 * @param text - Informative text displayed within the body of the snackbar.
 * @param timeout - Length of time, in milliseconds, the snackbar should be displayed before automatically dismissing. If a timeout is not supplied, the default timeout of `10_000 ms` will be used.
 * @returns {snackbar}
 */
let successSnackbar = function(text, timeout = 10000) {
  return new snackbarBuilder()
    .setText(text)
    .setColor("success")
    .setTimeout(timeout)
    .setIcon("mdi-check-circle-outline")
    .build()
}

/**
 * Snackbar used for displaying a warning event/ message. Will return a snackbar object with the provided snackbarText and timeout.
 * @param text - Informative text displayed within the body of the snackbar.
 * @param timeout - Length of time, in milliseconds, the snackbar should be displayed before automatically dismissing. If a timeout is not supplied, the default timeout of `10_000 ms` will be used.
 * @returns {snackbar}
 */
let warningSnackbar = function(text, timeout = 10000) {
  return new snackbarBuilder()
    .setText(text)
    .setColor("warning")
    .setTimeout(timeout)
    .setIcon("mdi-alert-outline")
    .build()
}

module.exports = {
  snackbarBuilder,
  errorSnackbar,
  infoSnackbar,
  successSnackbar,
  warningSnackbar,
  persistentErrorSnackbarWithPositiveAction,
  errorSnackbarWithPositiveAction
}
