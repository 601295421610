<template>
  <div id="appSignaturePad">
    <vue-signature-pad
      id="signature"
      width="100%"
      height="200px"
      ref="signaturePad"
      :options="{
        onBegin: () => {
          $refs.signaturePad.resizeCanvas()
        }
      }"
    />
    <div class="buttons align-end">
      <v-btn ref="clearButton" class="pa-4" color="error" @click="clear" elevation="4" rounded ripple>Clear</v-btn>
      <v-btn ref="saveButton" class="pa-4 ms-4" color="primary" @click="save" elevation="4" rounded ripple>Save</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "appSignaturePad",
  computed: {
    isSignatureBlank() {
      return this.$refs.signaturePad.isEmpty()
    }
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature()
      this.$emit("on-clear-signature-data")
    },
    save() {
      if (!this.isSignatureBlank) {
        // const { data } = this.$refs.signaturePad.saveSignature()
        this.$emit("on-save-signature-data", this.$refs.signaturePad.saveSignature())
      }
    }
  }
}
</script>

<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, var(--v-primary-base), var(--v-primary-base));
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.buttons {
  align-content: flex-start;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
  padding: 8px;
}
</style>
