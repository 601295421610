<template>
  <v-container class="mb-16" fluid>
    <v-card ref="job-management-card-root" class="mt-n1 px-4 mb-16 pb-10" elevation="4">
      <v-card-title class="headline ps-1 mb-2">Jobs</v-card-title>
      <v-card class="transparent my-n10" elevation="0">
        <v-card-text class="mt-6">
          <v-layout class="justify-center justify-space-around" row justify-center>
            <v-flex mx-sm-4 mx-md-2 my-2 class="flex-column d-flex">
              <v-card ref="job-management-scheduled-date-range-card" class="primary-solid-border flex d-flex flex-column justify-center mb-0" elevation="0">
                <v-card-title class="text-md-body-1 primary white--text">Scheduled Date Range</v-card-title>
                <v-card-text style="min-width: 350px" class="fill-height mb-0">
                  <app-date-range-menu-picker
                    text-field-class="me-2 mt-6 mb-n6 flex-fill"
                    :date-range="getSelectedDateRange"
                    text-field-date-format="MMMM DD, YYYY"
                    @updatedRange="setSelectedDateRange($event)"
                    :is-text-field-outlined="false"
                    :is-disabled="getIsJobManagementLoading"
                  ></app-date-range-menu-picker>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex mx-sm-4 mx-md-2 my-2 class="flex-column d-flex">
              <v-card ref="job-management-statuses-card" class="primary-solid-border flex d-flex flex-column justify-center flex-fill fill-height mb-0" elevation="0" :loading="getIsLoadingJobStatuses">
                <v-card-title class="text-md-body-1 primary white--text">Filters</v-card-title>
                <v-card-text class="fill-height">
                  <v-chip-group active-class="primary--text" class="mt-2" :value="getSelectedStatuses" @change="SET_SELECTED_STATUSES" column multiple>
                    <v-chip class="elevation-2" v-for="status in getJobStatusesSortedByName" :value="status.id" :key="status.id" :disabled="getIsJobManagementLoading" filter ripple>{{ status.name }}</v-chip>
                  </v-chip-group>
                  <v-checkbox
                    class="mt-2 mb-n5"
                    :input-value="getIncludeOnlyCustomerSelfScheduled"
                    @change="setIncludeOnlyCustomerSelfScheduled"
                    label="Only Customer Self-Scheduled"
                    color="primary"
                    :disabled="false"
                  />
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-row class="no-gutters">
            <v-col cols="auto" class="mt-4 flex-column d-flex flex-fill justify-lg-start d-inline-flex">
              <v-btn
                class="mt-1 fill-height"
                ref="job-management-pull-jobs-btn"
                ripple
                large
                rounded
                :disabled="isApplyFiltersButtonDisabled"
                color="primary"
                elevation="1"
                @click="applyFiltersOnClickHandler"
                data-cy="pull-jobs-btn"
                >Apply Filters</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="justify-end mt-2 px-2">
            <v-col class="justify-end col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <v-text-field
                id="job-search-field"
                ref="job-management-search-results-field"
                class="justify-center"
                v-model="searchText"
                clearable="clearable"
                clear-icon="mdi-trash-can-outline"
                append-icon="mdi-magnify"
                label="Search Results"
                :disabled="getIsJobManagementLoading"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row class="mt-4 mb-2">
            <v-responsive class="overflow-y-auto" max-height="550" min-height="200">
              <v-data-table
                class="flex-fill"
                ref="job-management-data-table"
                v-model="selectedJobs"
                :headers="jobManagementHeaders"
                :items="jobItems"
                item-key="jobId"
                show-select
                mobile-breakpoint="840"
                :loading="getIsJobManagementLoading"
                loading-text="🔎 Fetching Jobs 🔍"
                disable-pagination
                hide-default-footer
                :search="searchText"
              >
                <template v-slot:no-data>
                  <v-col class="ma-0 pa-0 align-self-center d-flex">
                    <app-not-found title="No Results" content="Please Specify Your Desired Parameters and Select 'Apply Filters'.">
                      <template v-slot:actions><div></div></template>
                    </app-not-found>
                  </v-col>
                </template>
                <template v-slot:item.jobNumber="{ item }">
                  <router-link class="text-md-body-1" :to="{ name: 'Job', params: { jobId: item.jobId } }">{{ item.jobNumber }}</router-link>
                  <v-icon class="ms-2" color="primary" @click="addNewJobTab(item)">
                    mdi-pin-outline
                  </v-icon>
                  <span v-if="item.jobCreationOriginId === JobCreationOrigin.CUSTOMER.id" class="ms-2">
                    <base-tool-tip
                      ref="job-management-self-scheduled-tool-tip"
                      tooltipColor="blue"
                      icon-color="blue"
                      dialog-title="Customer Self-Scheduled"
                      nudge-dialog="0"
                      tooltip-icon="mdi-account-tag-outline"
                      :is-bottom-dialog="true"
                    ></base-tool-tip>
                  </span>
                </template>
                <template v-slot:item.customerName="{ item }">
                  <div class="text-md-body-1">
                    {{ item.customerName }}
                  </div>
                </template>
                <template v-slot:item.businessName="{ item }">
                  <div class="text-md-body-1">{{ item.businessName }}</div>
                </template>
                <template v-slot:item.scheduledDate="{ item }">
                  <div class="text-md-body-1">{{ item.scheduledDate }}</div>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip :color="getColor(item.status)" :text-color="getColor(item.status)" outlined>
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:item.price="{ value }">
                  <app-number-formatter class="text-md-body-1" :amount="value" currency-sign="accounting" numberFormatStyle="currency" />
                </template>
                <template v-slot:item.capacity="{ item }">
                  <div class="text-md-body-1">{{ item.capacity }}</div>
                </template>
                <template v-slot:item.totalTruckHours="{ item }">
                  <app-decimal-formatter class="text-md-body-1" :amount="item.totalTruckHours"></app-decimal-formatter>
                </template>
<!--                <template v-slot:item.documentsSigned="{ item }">-->
<!--                  <v-avatar left>-->
<!--                    <v-icon v-if="item.documentsSigned === true" color="var(&#45;&#45;v-primary-darken1)">mdi-check-circle-outline </v-icon>-->
<!--                  </v-avatar>-->
<!--                </template>-->
              </v-data-table>
            </v-responsive>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="auto" class="col-lg-2 col-md-3">
              <v-row>
                <v-col class="col-6 col-lg-8 ps-0">
                  <v-select
                    ref="job-management-items-per-page-selection"
                    class="pt-4 cursor_pointer"
                    label="Items Per Page"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :items="getItemsPerPageOptions"
                    :value="getJobManagementPaginationOptions.pageSize"
                    :disabled="getIsJobManagementLoading"
                    @change="setPageSize"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="justify-center align-content-center text-center align-self-center mb-4">
              <v-row class="justify-center align-content-center text-center align-self-center">
                <div class="text-center">
                  <v-pagination
                    ref="job-management-pagination-btn"
                    :value="getJobManagementPaginationOptions.currentPage"
                    @input="triggerPagination"
                    :length="getJobManagementPaginationOptions.totalPages"
                    :disabled="getIsJobManagementLoading"
                  ></v-pagination>
                  <div v-if="isResultRangeTextVisible" ref="job-management-result-range-text" class="text-md-body-1">{{ getResultRangeText }}</div>
                </div>
              </v-row>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndUp" cols="auto" class="col-lg-2 col-md-3"></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
    <v-layout row class="mt-16">
      <app-footer class="ps-2" ref="job-management-footer" :is-expand-button-disabled="true" :is-loading="getIsJobManagementLoading">
        <template class="pa-0 ma-0 align-self-center justify-center text-center align-content-center" v-slot:content>
          <span class="mx-3 ms-4 align-start font-weight-bold" :style="{ color: 'white', 'font-size': 'xx-large', 'background-color': 'transparent' }">Selected: </span>
          <div class="font-weight-bold" :style="{ color: 'white', 'font-size': 'xx-large', 'background-color': 'transparent' }">{{ getNumberOfSelectedJobs }}</div>
          <div class="mx-2 font-weight-bold" :style="{ color: 'white', 'font-size': 'xx-large', 'background-color': 'transparent' }">of</div>
          <div class="font-weight-bold" :style="{ color: 'white', 'font-size': 'xx-large', 'background-color': 'transparent' }">{{ getNumberOfJobs }}</div>
        </template>
        <template v-slot:actions>
          <v-btn
            class="mt-0 ms-3 pa-4"
            ripple
            x-large
            rounded
            :disabled="getIsJobManagementLoading"
            color="primary"
            elevation="4"
            @click="closeSelectedJobs"
            data-cy="close-selected-jobs-btn"
            >Close Selected Jobs
          </v-btn>
        </template>
      </app-footer>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import { JobStatus } from "@/enums/JobStatus"
import AppNumberFormatter from "@/components/AppNumberFormatter"
import AppDateRangeMenuPicker from "@/components/AppDateRangeMenuPicker"
import AppFooter from "@/components/AppFooter"
import AppNotFound from "@/components/AppNotFound"
import { getJobStatusColorByStatusName } from "@/utils/JobStatusColors"
import AppDecimalFormatter from "@/components/AppDecimalFormatter"
import BaseToolTip from "@/components/BaseToolTip.vue"
import { JobCreationOrigin } from "@/enums/JobCreationOrigin"
import { JobTab } from "@/models/JobTab"

export default {
  name: "JobManagement",
  components: { BaseToolTip, AppDecimalFormatter, AppNotFound, AppFooter, AppDateRangeMenuPicker, AppNumberFormatter },
  data: () => ({
    searchText: "",
    selectedJobs: [],
    jobManagementHeaders: [
      { text: "Job Number", align: "start", value: "jobNumber" },
      { text: "Customer Name", align: "start", value: "customerName" },
      { text: "Business Name", align: "start", value: "businessName" },
      { text: "Scheduled Date", align: "center", value: "scheduledDate" },
      { text: "Status", align: "center", value: "status" },
      { text: "Price", align: "center", value: "price" },
      { text: "Capacity", align: "center", value: "capacity" },
      { text: "Total Truck Hours", align: "center", value: "totalTruckHours" }
      // { text: "Documents Signed", align: "center", value: "documentsSigned" }
    ]
  }),
  methods: {
    ...mapMutations("JobManagement", ["SET_SELECTED_STATUSES"]),
    ...mapActions("JobManagement", ["fetchJobManagementData", "resetJobManagementJobs", "setSelectedDateRange", "setIncludeOnlyCustomerSelfScheduled"]),
    ...mapActions(["addJobTab"]),
    async addNewJobTab(item) {
      let tab = new JobTab(item.jobId, item.jobNumber, item.customerId, item.customerLastName, item.businessName)
      await this.addJobTab(tab)
    },
    getColor(statusName) {
      return getJobStatusColorByStatusName(statusName)
    },
    isJobSelectable(status) {
      return status === JobStatus.COMPLETED.name
    },
    applyFiltersOnClickHandler() {
      let operatingUnitId = this.getSelectedOperatingUnitId
      console.log("applyFiltersOnClickHandler.operatingUnitId = ", operatingUnitId)
      this.pullJobs(operatingUnitId)
    },
    async pullJobs(operatingUnitId) {
      console.log("pullJobs.operatingUnitId = ", operatingUnitId)
      await this.fetchJobManagementData({
        operatingUnitId: operatingUnitId,
        fromDate: this.fromDate,
        toDate: this.toDate,
        pageNumber: this.getJobManagementPaginationOptions.currentPage,
        pageSize: this.getJobManagementPaginationOptions.pageSize
      })
    },
    async closeSelectedJobs() {
      console.log("Closing Selected Jobs!")
    },
    async triggerPagination(pageNumber) {
      await this.fetchJobManagementData({
        operatingUnitId: this.getSelectedOperatingUnitId,
        fromDate: this.fromDate,
        toDate: this.toDate,
        pageNumber: pageNumber,
        pageSize: this.getJobManagementPaginationOptions.pageSize
      })
    },
    async setPageSize(pageSize) {
      await this.fetchJobManagementData({
        operatingUnitId: this.getSelectedOperatingUnitId,
        fromDate: this.fromDate,
        toDate: this.toDate,
        pageNumber: 1,
        pageSize: pageSize
      })
    }
  },
  computed: {
    JobCreationOrigin() {
      return JobCreationOrigin
    },
    ...mapGetters(["getJobStatusesSortedByName", "getSelectedOperatingUnitId"]),
    ...mapGetters("JobManagement", [
      "getIsJobManagementLoading",
      "getJobs",
      "getIsLoadingJobStatuses",
      "getSelectedStatuses",
      "getJobManagementPaginationOptions",
      "getSelectedDateRange",
      "getItemsPerPageOptions",
      "getIncludeOnlyCustomerSelfScheduled"
    ]),
    jobItems() {
      return this.getJobs.slice().map(job => {
        return {
          ...job,
          isSelectable: this.isJobSelectable(job.status)
        }
      })
    },
    getNumberOfSelectedJobs() {
      return this.selectedJobs?.length?.toString() ?? "0"
    },
    getNumberOfJobs() {
      return this.getJobs?.length?.toString() ?? "0"
    },
    isResultRangeTextVisible() {
      return this.getJobs.length > 0
    },
    getResultRangeText() {
      return `Displaying ${this.lowerLimitText} - ${this.upperLimitText} of ${this.getJobManagementPaginationOptions.totalCount}`
    },
    lowerLimitText() {
      return (this.getJobManagementPaginationOptions.currentPage - 1) * this.getJobManagementPaginationOptions.pageSize + 1
    },
    upperLimitText() {
      let currentPageTimesPageSize = this.getJobManagementPaginationOptions.currentPage * this.getJobManagementPaginationOptions.pageSize
      return currentPageTimesPageSize > this.getJobManagementPaginationOptions.totalCount ? this.getJobManagementPaginationOptions.totalCount : currentPageTimesPageSize
    },
    fromDate() {
      return this.getSelectedDateRange[0]
    },
    toDate() {
      return this.getSelectedDateRange[1] ?? this.fromDate
    },
    isApplyFiltersButtonDisabled() {
      return this.getIsJobManagementLoading || this.getJobStatusesSortedByName.length <= 0
    }
  },
  async created() {
    this.$eventBus.$on("operating-unit-updated", (operatingUnitMetadataObj) => {
      console.log("JobManagement operating-unit-updated = ", JSON.stringify(operatingUnitMetadataObj, null, 4))
      this.resetJobManagementJobs()
      this.pullJobs(operatingUnitMetadataObj.id)
    })
  }
}
</script>

<style scoped></style>
