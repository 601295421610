import jwtDecode from "jwt-decode"
import { msalPluginInstance } from "@/plugins/MsalPlugin"

export default {
  async authHeader() {
    if (msalPluginInstance.getIsUserLoggedIn()) {
      let token = await msalPluginInstance.getTokenSilent().catch(e => {
        console.error("authHeader.error = ", e)
      })

      console.log("authHeader.tokenForHeader = ", jwtDecode(token))

      return `Bearer ${token}`
    } else {
      await msalPluginInstance.signOut()
      return null
    }
  },
  async routingHeader() {
    if (msalPluginInstance.getIsUserLoggedIn()) {
      let token = await msalPluginInstance.getRoutingTokenSilent().catch(e => {
        console.error("authHeader.error = ", e)
      })

      console.log("authHeader.tokenForHeader = ", jwtDecode(token))

      return `Bearer ${token}`
    } else {
      await msalPluginInstance.signOut()
      return null
    }
  },
  async documentHeader() {
    if (msalPluginInstance.getIsUserLoggedIn()) {
      let token = await msalPluginInstance.getDocumentServiceTokenSilent().catch(e => {
        console.error("documentHeader.authHeader.error = ", e)
      })

      console.log("documentHeader.authHeader.tokenForHeader = ", jwtDecode(token))

      return `Bearer ${token}`
    } else {
      await msalPluginInstance.signOut()
      return null
    }
  },
  async generateQueryParams(key, list) {
    let params = new URLSearchParams()
    list?.forEach(x => {
      params.append(key, x)
    })
    return params
  }
}
