<template>
  <v-container fluid>
    <v-row justify="center">
      <v-dialog
          persistent
          fullscreen
          hide-overlay
          :value="isDialogVisible">
        <v-card
            class="mb-8"
            :color="getCardBackgroundColor">
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Statement</v-toolbar-title>

            <v-spacer />

            <v-toolbar-items class="align-baseline">
              <v-select
                ref="job-receipt-full-screen-dialog-customer-representative-select"
                label="Customer Representative"
                class="cursor_pointer"
                item-text="name"
                item-value="customerRepresentativeId"
                dense
                outlined
                return-object
                :menu-props="{ bottom: true, offsetY: true }"
                :items="currentJobReceiptCustomerRepresentatives"
                :value="selectedCustomerRepresentative"
                @change="setSelectedCustomerRepresentative">
                <template v-slot:append-item>
                  <div class="text-center">
                    <v-btn color="primary" @click="addCustomerRepresentative"> <v-icon>mdi-plus</v-icon> ADD CUSTOMER REP </v-btn>
                  </div>
                </template>
              </v-select>
              <v-btn
                  ref="job-receipt-full-screen-dialog-driver-override-button"
                  id="menu-activator"
                  dark
                  text
                  rounded
                  :disabled="isDriverOverrideDisabled">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
              <v-menu activator="#menu-activator" :menu-props="{ bottom: true, offsetY: true }">
                <v-list>
                  <v-list-item
                      ref="job-receipt-full-screen-dialog-driver-override-action"
                      v-for="(item, index) in menuOptions"
                      :key="index"
                      :value="index"
                      @click="item.action">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar-items>
          </v-toolbar>

          <app-create-signature-profile-dialog
            ref="job-receipt-full-screen-dialog-create-signature-profile-dialog"
            v-if="isCreateSignatureProfileDialogVisible"
            :is-dialog-visible="isCreateSignatureProfileDialogVisible"
            :is-loading="getIsLoadingCustomerRepresentativeProfile"
            :customer-representatives="getCustomerRepresentativesWithSignatureProfileStatus"
            :current-customer-representative="selectedCustomerRepresentative"
            :is-new-customer-representative="isNewCustomerRepresentative"
            @on-cancel-signature-profile="handleCancelSignatureProfile"
            @on-save-signature-profile="handleSaveSignatureProfile" />

          <v-card
              v-if="isCarouselVisible"
              class="mx-auto mt-8"
              max-width="550">
            <v-window
                touchless
                v-model="carouselStep">
              <v-window-item v-for="n in carouselSteps" :key="n" :value="n">
                <v-card-text ref="job-receipt-carousel-documents-card" v-if="isReceiptDocumentsCardVisible" class="pa-0">
                  <job-receipt-phrase-step-carousel
                    ref="job-receipt-full-screen-dialog-phrase-step-carousel"
                    v-if="isPhraseStepVisible"
                    :is-phrase-step-dialog-visible="isPhraseStepVisible"
                    :has-driver-override="getHasDriverOverride"
                    :current-customer-representative="selectedCustomerRepresentative"
                    :phrase-customer-representative-id="getCurrentPhrase.customerRepresentativeId"
                    :phrase-employee-profile-id="getCurrentPhrase.employeeProfileId"
                    :job-document-phrases="getJobDocumentReceiptPhrases"
                    :is-phrase-intermission="getCurrentPhrase.isIntermission"
                    :phrase-step="phraseStep"
                    :phrase-actions-visible="!areActionsVisible"
                    :next-button-text="nextButtonText"
                    @on-update-phrase-complete="handleUpdatePhraseComplete"
                    @on-update-phrase-uncomplete="handleUpdatePhraseUncomplete"
                    @on-prev="pullStepper"
                    @on-next="pushStepper"
                  />
                </v-card-text>

                <v-card-text ref="job-receipt-carousel-documents-confirmation-card" v-else style="min-height: 100px">
                  <div ref="job-receipt-documents-confirmation-card" v-if="isReceiptDocumentsConfirmationCardVisible">
                    <v-card
                        flat
                        class="mx-auto"
                        max-width="640"
                        min-height="200">
                      <v-card-title>
                        <v-row class="pl-8 pr-8 text-center">
                          <v-col>
                              <span ref="job-document-intermission-phrase-text" class="text-h6 break-word">{{ getIntermissionPhraseText }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-slide-x-reverse-transition>
                            <v-img ref="job-receipt-intermission-image" :src="getIntermissionImage" min-width="50" min-height="50" class="image-fit" />
                          </v-slide-x-reverse-transition>
                        </v-row>
                      </v-card-title>
                    </v-card>
                  </div>

                  <job-receipt-job-detail-confirmation-card
                      ref="job-receipt-detail-confirmation-card"
                      v-if="isReceiptJobDetailsConfirmationCardVisible" />

                  <job-receipt-add-tip-card
                    ref="job-receipt-add-tip-card"
                    v-if="isJobReceiptAddTipCardVisible"
                    :customer-name="selectedCustomerRepresentative.firstName"
                    :is-tip-resolved="isTipResolved"
                    @update:tipState="updateTipStateWrapper" />

                  <job-receipt-payments-card
                      ref="job-receipt-payments-card"
                      v-if="isJobReceiptPaymentsCardVisible"
                      class="mt-0" />

                  <job-receipt-recipients-card
                    ref="job-receipt-recipients-card"
                    v-if="isJobReceiptRecipientsCardVisible"
                    button-text="Generate Receipt"
                    card-title="Send Statement to Contacts"
                    :is-send-button-visible="false"
                    :is-primary-customer-disabled="true"
                    :show-is-primary-contact-email-not-provided-disclaimer="true"
                    @generate="handleGenerateReceipt" />
                </v-card-text>
              </v-window-item>
            </v-window>

            <v-divider v-if="areActionsVisible" class="ml-0 mr-0" />

            <v-card-actions ref="job-receipt-actions-card" v-if="areActionsVisible">
              <v-btn
                  ref="job-receipt-carousel-back-button"
                  class="ms-2"
                  outlined
                  rounded
                  elevation="1"
                  @click="pullStepper">
                Back
              </v-btn>

              <v-spacer />

              <v-btn
                ref="job-receipt-carousel-next-button"
                v-if="isCarouselNextButtonVisible"
                class="me-2"
                elevation="1"
                outlined
                rounded
                :loading="isLoadingCarouselNextButton"
                :disabled="isCarouselNextButtonDisabled"
                @click="pushStepper">
                {{ nextButtonText }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import AppCreateSignatureProfileDialog from "@/components/AppCreateSignatureProfileDialog"
import JobReceiptPhraseStepCarousel from "@/components/JobReceiptPhraseStepCarousel"
import JobReceiptJobDetailConfirmationCard from "@/components/JobReceiptJobDetailConfirmationCard"
import JobReceiptAddTipCard from "@/components/JobReceiptAddTipCard"
import JobReceiptPaymentsCard from "@/components/JobReceiptPaymentsCard"
import JobReceiptRecipientsCard from "@/components/JobReceiptRecipientsCard"
import applicationColors from "@/utils/ApplicationColors"
import { OverrideReasons } from "@/enums/JobDocumentPhraseOverrideReasons"
import { mapActions, mapGetters } from "vuex"
import { v4 } from "uuid"
import { createCustomerRepresentativeDto, updateJobFranchiseDocumentPhraseDto } from "@/api/dtos/DocumentDtos"
import { putJunkJobEmployeeTipDto } from "@/api/dtos/JunkDtos"
import { consoleLog } from "@/utils/Logging"

export default {
  name: "JobReceiptFullScreenDialog",
  components: {
    JobReceiptPhraseStepCarousel,
    JobReceiptAddTipCard,
    JobReceiptJobDetailConfirmationCard,
    JobReceiptPaymentsCard,
    JobReceiptRecipientsCard,
    AppCreateSignatureProfileDialog
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      phraseStep: 1,
      phraseSteps: 1,
      carouselStep: 1,
      carouselSteps: 1,
      isCreateSignatureProfileDialogVisible: false,
      menuOptions: [
        {
          id: 1,
          title: "Driver Override",
          action: () => this.setHasDriverOverride()
        }
      ],
      selectedCustomerRepresentative: {},
      isNewCustomerRepresentative: false,
      currentTipAmount: 0.0,
      isTipResolved: false,
      isCarouselNextButtonDisabled: false,
      nextButtonText: "NEXT",
      currentJobReceiptCustomerRepresentatives: []
    }
  },
  computed: {
    ...mapGetters("Job", [
      "getJobDocumentReceiptPhrases",
      "getJobDocumentPhraseEmployeeProfile",
      "getCurrentJobReceiptCustomerRepresentatives",
      "getCurrentJob",
      "getEmployeesOnJob",
      "getIsLoadingEmployeeTips",
      "getIsLoadingCustomerRepresentativeProfile",
      "getSelectedEmailReceiptRecipients",
      "getJobReceiptPayments",
      "getTipConfiguration",
      "getCustomerRepresentativeProfiles",
      "getAllCustomerContacts"
    ]),
    areActionsVisible() {
      return (
        (this.carouselStep === 1 && this.phraseStep === 2) ||
        (this.carouselStep === 2 && this.phraseStep === 2) ||
        (this.carouselStep === 3 && this.phraseStep === 2) ||
        (this.carouselStep === 4 && this.phraseStep === 3) ||
        (this.carouselStep === 5 && this.phraseStep === 3) ||
        (this.carouselStep === 6 && this.phraseStep === 3)
      )
    },
    isCarouselVisible() {
      return this.carouselStep <= this.carouselSteps
    },
    isCarouselNextButtonVisible() {
      return this.carouselStep <= this.carouselSteps
    },
    isLoadingCarouselNextButton() {
      return this.getIsLoadingEmployeeTips
    },
    getCurrentPhrase() {
      return this.getJobDocumentReceiptPhrases.find(p => p.stepNumber === this.phraseStep)
    },
    getIntermissionPhraseText() {
      return "Thank you " + this.selectedCustomerRepresentative?.firstName + "! Please hand the tablet back to " + this.$msal.getCurrentUserGivenName() + "."
    },
    getHasDriverOverride() {
      return this.getCurrentPhrase.employeeProfileId !== null || false
    },
    isDriverOverrideDisabled() {
      return this.getCurrentPhrase?.customerRepresentativeId !== null || this.getCurrentPhrase.employeeProfileId !== null || this.getCurrentPhrase.isIntermission
    },
    isReceiptDocumentsCardVisible() {
      if (this.carouselStep === 2) {
        return this.phraseStep === 3
      }
      if (this.carouselStep === 3 && (this.phraseStep === 3 || this.phraseStep === 4)) {
        return true
      }
      return this.carouselStep === 1
    },
    isPhraseStepVisible() {
      return this.phraseStep <= this.phraseSteps
    },
    isReceiptDocumentsConfirmationCardVisible() {
      return this.carouselStep === 4
    },
    isReceiptJobDetailsConfirmationCardVisible() {
      return this.carouselStep === 2
    },
    isJobReceiptAddTipCardVisible() {
      return this.carouselStep === 3
    },
    isJobReceiptPaymentsCardVisible() {
      return this.carouselStep === 5
    },
    isJobReceiptRecipientsCardVisible() {
      return this.carouselStep === 6
    },
    getCustomerRepresentativesWithSignatureProfileStatus() {
      consoleLog("OHYEAH.getCustomerRepresentativesWithSignatureProfileStatus")
      return this.currentJobReceiptCustomerRepresentatives.map(customerRepresentative => {
        return {
          ...customerRepresentative,
          name: customerRepresentative.signature
            ? `${customerRepresentative.firstName} ${customerRepresentative.lastName} - Signature Saved`
            : `${customerRepresentative.firstName} ${customerRepresentative.lastName}`,
          hasSignatureProfile: customerRepresentative.signature
        }
      })
    },
    getIntermissionImage() {
      if (this.isDarkThemeEnabled) {
        return require("@/../public/tmjt-logo-dark.png")
      }
      return require("@/../public/tmjt-two-guys.jpeg")
    },
    isDarkThemeEnabled() {
      return this.$vuetify.theme.dark
    },
    getCardBackgroundColor() {
      return this.$vuetify.theme.dark ? "background" : applicationColors.vuetify.light
    },
    shouldDisplayTipCard() {
      return this.getTipConfiguration.isEnabled && this.getEmployeesOnJob.length > 0
    }
  },
  methods: {
    ...mapActions("Job", [
      "updateJobDocumentPhrase",
      "saveJobDocumentPhrase",
      "setPhraseCheckboxKey",
      "saveJobDocumentCustomerRepresentativeSignatureProfile",
      "clearCustomerRepresentativeProfiles",
      "updateEmployeeDistributedTips",
      "generateJobReceipt",
      "fetchJunkJobNotes",
      "setSelectedEmailReceiptRecipients",
      "fetchJunkJobEmployees"
    ]),
    setSelectedCustomerRepresentative(selectedCustomerRepresentative) {
      this.selectedCustomerRepresentative = selectedCustomerRepresentative
      if (!this.getCurrentPhrase.isSavedToServer) {
        this.handleUpdatePhraseUncomplete()
      }
      this.$refs["job-receipt-full-screen-dialog-customer-representative-select"].blur()
    },
    async setHasDriverOverride() {
      const employeeProfileId = this.getJobDocumentPhraseEmployeeProfile.employeeProfileId
      const overrideReasonId = OverrideReasons[0].id
      const newPhrase = {
        ...this.getCurrentPhrase,
        employeeProfileId: employeeProfileId,
        overrideReasonId: overrideReasonId,
        isSavedToServer: false
      }
      await this.updateJobDocumentPhrase(newPhrase)
    },
    setIsCreateSignatureProfileDialogVisible(isVisible) {
      this.isCreateSignatureProfileDialogVisible = isVisible
    },
    async addCustomerRepresentative() {
      this.isNewCustomerRepresentative = true
      this.setIsCreateSignatureProfileDialogVisible(true)
    },
    async handleUpdatePhraseComplete() {
      const customerRepresentativeId = this.selectedCustomerRepresentative.customerRepresentativeId
      const signatureData = this.selectedCustomerRepresentative.signature
      if (customerRepresentativeId && signatureData) {
        const newPhrase = {
          ...this.getCurrentPhrase,
          customerRepresentativeId: customerRepresentativeId,
          isSavedToServer: false
        }
        await this.updateJobDocumentPhrase(newPhrase)
      } else {
        this.setIsCreateSignatureProfileDialogVisible(true)
      }
    },
    async handleUpdatePhraseUncomplete() {
      const newPhrase = {
        ...this.getCurrentPhrase,
        customerRepresentativeId: null,
        employeeProfileId: null,
        overrideReasonId: null,
        isSavedToServer: false
      }
      await this.updateJobDocumentPhrase(newPhrase)
    },
    async handleSavePhrase() {
      if (!this.getCurrentPhrase.isSavedToServer && !this.getCurrentPhrase.isIntermission) {
        let currentPhrase = this.getCurrentPhrase
        let dto = updateJobFranchiseDocumentPhraseDto(
          currentPhrase.jobFranchiseDocumentPhraseId,
          this.$msal.getCurrentUserId(),
          currentPhrase.customerRepresentativeId,
          currentPhrase.employeeProfileId,
          currentPhrase.overrideReasonId
        )
        await this.saveJobDocumentPhrase(dto)
      }
    },
    createNonContactCustomerRepresentative(profile) {
      const newProfile = {
        contactId: null,
        customerRepresentativeId: profile.customerRepresentativeId,
        name: `${profile.firstName} ${profile.lastName}`,
        signature: profile.signature,
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: null,
        isPrimary: false,
        jobId: this.getCurrentJob.id // this is our problem
      }
      return newProfile
    },
    createContactCustomerRepresentative(profile) {
      const newProfile = {
        contactId: profile.contactId,
        customerRepresentativeId: profile.customerRepresentativeId, // this gets reset after merging these lists
        name: `${profile.firstName} ${profile.lastName}`,
        signature: profile.signature, // this gets set with existing signature data after merging these lists
        firstName: profile.firstName,
        lastName: profile.lastName,
        // email: contact.emailPrimary,
        // isPrimary: contact.isPrimary,
        jobId: this.getCurrentJob.id // this is our problem
      }
      return newProfile
    },
    async handleSaveSignatureProfile(firstName, lastName, signatureData, customerRepresentative) {
      let signatureId = this.isNewCustomerRepresentative ? v4() : customerRepresentative.customerRepresentativeId
      let contactId = this.isNewCustomerRepresentative ? null : customerRepresentative.contactId
      let dto = createCustomerRepresentativeDto(signatureId, contactId, this.getCurrentJob.id, signatureData, firstName, lastName, this.$msal.getCurrentUserId())

      await this.saveJobDocumentCustomerRepresentativeSignatureProfile(dto).then(async response => {
        let newProfile = null
        if (this.isNewCustomerRepresentative) {
          newProfile = this.createNonContactCustomerRepresentative(response)
          this.currentJobReceiptCustomerRepresentatives.push(newProfile)
        } else {
          let index = this.currentJobReceiptCustomerRepresentatives.findIndex(p => p.contactId === response.contactId)

          newProfile = this.createContactCustomerRepresentative(response)
          newProfile = {
            ...newProfile,
            email: this.currentJobReceiptCustomerRepresentatives[index].email,
            isPrimary: this.currentJobReceiptCustomerRepresentatives[index].isPrimary
          }
          this.currentJobReceiptCustomerRepresentatives.splice(index, 1, newProfile)
        }

        if (this.getCurrentPhrase.isSavedToServer && (this.getCurrentPhrase.customerRepresentativeId || this.getCurrentPhrase.employeeProfileId)) {
          this.isNewCustomerRepresentative = false
          this.setSelectedCustomerRepresentative(newProfile)
          this.setIsCreateSignatureProfileDialogVisible(false)
          return
        }

        const newPhrase = {
          ...this.getCurrentPhrase,
          customerRepresentativeId: response.customerRepresentativeId,
          isSavedToServer: false
        }
        this.setSelectedCustomerRepresentative(newProfile)
        await this.updateJobDocumentPhrase(newPhrase)
        this.setIsCreateSignatureProfileDialogVisible(false)
        this.isNewCustomerRepresentative = false
      })
    },
    async handleCancelSignatureProfile() {
      await this.handleUpdatePhraseUncomplete()
      await this.setPhraseCheckboxKey()
      this.isNewCustomerRepresentative = false
      this.setIsCreateSignatureProfileDialogVisible(false)
    },
    async handleGenerateReceipt() {
      const recipients = this.getSelectedEmailReceiptRecipients.map(recipient => {
        return {
          jobFranchiseDocumentRecipientId: v4(),
          firstName: recipient.firstName,
          lastName: recipient.lastName,
          emailAddress: recipient.email,
          isPrimaryContact: recipient.isPrimary
        }
      })
      const generateReceiptDto = {
        jobId: this.getCurrentJob.id,
        employeeId: this.$msal.getCurrentUserId(),
        jobFranchiseDocumentRecipientDtos: recipients
      }
      await this.generateJobReceipt(generateReceiptDto).then(async () => {
        await this.closeDialogAndFetchJobNotes()
      })
    },
    async closeDialogAndFetchJobNotes() {
      await this.setSelectedEmailReceiptRecipients([])
      await this.fetchJunkJobNotes(this.getCurrentJob.id)
      this.closeDialog()
    },
    closeDialog() {
      this.$emit("close-dialog")
    },
    updateTipStateWrapper(updatedTipState) {
      this.currentTipAmount = updatedTipState.tipAmount
      this.isTipResolved = updatedTipState.isTipResolved
      this.isCarouselNextButtonDisabled = !this.isTipResolved
      this.nextButtonText = updatedTipState.tipAmount > 0 ? `Add $${updatedTipState.tipAmount} Tip` : "Next: Signature"
    },
    async handleAddTip() { // here
      this.currentTipAmount = Number(parseFloat(this.currentTipAmount).toFixed(2))
      if (this.currentTipAmount !== this.getCurrentJob.totalTip) {
        const employeeDistributedTipsDto = putJunkJobEmployeeTipDto(this.currentTipAmount, this.$msal.getCurrentUserId())
        const jobId = this.getCurrentJob.id
        consoleLog("employeeDistributedTipsDto = ", employeeDistributedTipsDto)
        consoleLog("jobId = ", jobId)
        await this.updateEmployeeDistributedTips({ jobId: jobId, putEmployeeTipsDto: employeeDistributedTipsDto })
            .then(async () => {
              await this.fetchJunkJobEmployees(jobId)
            })
      }
    },
    initializeCustomerProfiles() {
      let customerContactProfiles =
        [
          ...this.getAllCustomerContacts?.slice().map(contact => {
            return {
              contactId: contact.id,
              customerRepresentativeId: v4(), // this gets reset after merging these lists
              name: `${contact.firstName} ${contact.lastName}`,
              signature: null, // this gets set with existing signature data after merging these lists
              firstName: contact.firstName,
              lastName: contact.lastName,
              email: contact.emailPrimary,
              isPrimary: contact.isPrimary,
              jobId: this.getCurrentJob.id // this is our problem
            }
          })
        ] ?? []

      const mergedResult = customerContactProfiles.map(rep => {
        let matchingElement = this.getCustomerRepresentativeProfiles.find(profile => rep.firstName === profile.firstName && rep.lastName === profile.lastName)

        if (matchingElement) {
          return {
            ...rep,
            ...matchingElement
          }
        } else {
          return rep
        }
      })

      // profiles that are not contacts
      this.getCustomerRepresentativeProfiles.forEach(profile => {
        const exists = mergedResult.some(item => item.firstName === profile.firstName && item.lastName === profile.lastName)
        if (!exists) {
          let customerProfileObject = this.createNonContactCustomerRepresentative(profile)
          mergedResult.push(customerProfileObject)
        }
      })

      this.currentJobReceiptCustomerRepresentatives.splice(0, this.currentJobReceiptCustomerRepresentatives?.length ?? 0, ...mergedResult)
    },
    async pushStepper() {
      if (this.carouselStep === 1) {
        if (this.phraseStep === 1) {
          await this.handleSavePhrase()
          this.phraseStep = 2
          this.nextButtonText = "Next: Job Details"
          return
        }
        if (this.phraseStep === 2) {
          this.carouselStep = 2
          if (this.shouldDisplayTipCard) {
            this.nextButtonText = "Next: Tip"
          } else {
            this.nextButtonText = "Next: Signature"
          }
        }
        return
      }

      if (this.carouselStep === 2) {
        this.carouselStep = 3
        if (this.phraseStep === 2) {
          if (this.shouldDisplayTipCard) {
            this.nextButtonText = "Next: Signature"
          } else {
            this.phraseStep = 3
            this.nextButtonText = "Next: Wrap Up"
          }
        }
        return
      }

      if (this.carouselStep === 3) {
        if (this.phraseStep === 2) {
          await this.handleAddTip()
              .finally(() => {
                this.phraseStep = 3
                this.nextButtonText = "Next: Wrap Up"
              })
          return
        }
        if (this.phraseStep === 3) {
          await this.handleSavePhrase()
          this.carouselStep = 4
          this.nextButtonText = "Next: Payments"
        }
        return
      }

      if (this.carouselStep === 4) {
        this.carouselStep = 5
        this.nextButtonText = "Next: Recipients"
        return
      }

      if (this.carouselStep === 5) {
        this.carouselStep = 6
        this.nextButtonText = "Generate Statement"
        return
      }

      if (this.carouselStep === 6) {
        await this.handleGenerateReceipt()
      }
    },
    pullStepper() {
      if (this.carouselStep === 6) {
        this.carouselStep = 5
        this.nextButtonText = "Next: Recipients"
        return
      }

      if (this.carouselStep === 5) {
        this.carouselStep = 4
        this.nextButtonText = "Next: Payments"
        return
      }

      if (this.carouselStep === 4) {
        this.carouselStep = 3
        this.nextButtonText = "Next: Wrap Up"
        return
      }

      if (this.carouselStep === 3) {
        if (this.phraseStep === 3) {
          this.phraseStep = 2
          this.nextButtonText = "Next: Signature"
          if (!this.shouldDisplayTipCard) {
            this.carouselStep = 2
          }
          return
        }
        if (this.phraseStep === 2) {
          this.carouselStep = 2
          this.isCarouselNextButtonDisabled = false
          this.nextButtonText = "Next: Tip"
          return
        }
      }

      if (this.carouselStep === 2) {
        if (this.phraseStep === 2) {
          this.carouselStep = 1
          this.nextButtonText = "Next: Job Details"
          return
        }
      }

      if (this.carouselStep === 1) {
        if (this.phraseStep === 2) {
          this.phraseStep = 1
          this.nextButtonText = "Next: Ready to Start"
        }
      }
    }
  },
  created() {
    consoleLog("OHYEAH.CREATED CALLED!")
    this.initializeCustomerProfiles()
    this.nextButtonText = "Next: Ready to Start"

    this.carouselStep = 1
    this.phraseStep = 1

    if (this.progress === 1) {
      this.carouselStep = 2
      this.phraseStep = 2
      if (this.shouldDisplayTipCard) {
        this.nextButtonText = "Next: Tip"
      } else {
        this.nextButtonText = "Next: Signature"
      }
    }

    if (this.getCurrentJob.totalTip > 0) {
      this.currentTipAmount = this.getCurrentJob.totalTip
      if (this.progress === 1) {
        this.carouselStep = 3
        this.phraseStep = 3
        this.nextButtonText = "Next: Wrap Up"
      }
    }

    if (this.progress === 2) {
      this.carouselStep = 5
      this.phraseStep = 3
      this.nextButtonText = "Next: Recipients"
    }

    if (this.getJobReceiptPayments === this.getCurrentJob.totalPrice) {
      this.carouselStep = 6
      this.phraseStep = 3
      this.nextButtonText = "Generate Statement"
    }

    this.phraseSteps = this.getJobDocumentReceiptPhrases.length
    this.carouselSteps = 6
    this.selectedCustomerRepresentative = this.currentJobReceiptCustomerRepresentatives.find(p => p.isPrimary) ?? {}
  },
  destroyed() {
    this.clearCustomerRepresentativeProfiles()
  }
}
</script>

<style>
.image-fit {
  object-fit: contain;
  max-width: 85%;
}
.break-word {
  word-break: break-word;
}
</style>
