<template>
  <base-dialog
    ref="data-fix-add-temporary-requester-dialog-base-dialog-root"
    :is-dialog-visible="isDialogVisible"
    max-dialog-width="900px"
    title="Add Temporary Requester"
    dialog-image="mdi-receipt-text-plus-outline"
  >
    <template v-slot:content>
      <v-form class="mb-8" ref="data-fix-add-temporary-requester-dialog-form" v-model="isFormValid">
        <v-row>
          <v-col>
            <v-text-field
              ref="data-fix-add-temporary-requester-dialog-first-name-text-input"
              label="First Name *"
              class="me-2"
              autocomplete="null"
              counter="50"
              clearable
              outlined
              v-model.trim="firstName"
              :rules="isRequiredRules"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              ref="data-fix-add-temporary-requester-dialog-last-name-text-input"
              label="Last Name *"
              class="me-2"
              autocomplete="null"
              counter="50"
              clearable
              outlined
              v-model.trim="lastName"
              :rules="isRequiredRules"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn ref="data-fix-add-temporary-requester-dialog-cancel-button" class="pa-4" color="primary" @click="handleCancelAddTemporaryRequester" text rounded ripple>Cancel </v-btn>
      <v-btn
        ref="data-fix-add-temporary-requester-dialog-add-button"
        class="pa-4"
        color="primary"
        @click="handleAddTemporaryRequester"
        text
        rounded
        ripple
        :disabled="isTemporaryRequesterAddButtonDisabled"
        >Add
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { v4 } from "uuid"

export default {
  name: "DataFixAddTemporaryRequesterDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isRequiredRules: [value => !!value || "Is required"],
      temporaryEmployeeId: v4(),
      firstName: "",
      lastName: "",
      isFormValid: false
    }
  },
  computed: {
    isTemporaryRequesterAddButtonDisabled() {
      return !(this.firstName && this.lastName)
    }
  },
  methods: {
    handleCancelAddTemporaryRequester() {
      this.$emit("on-cancel-add-temporary-requester")
    },
    handleAddTemporaryRequester() {
      if (this.$refs["data-fix-add-temporary-requester-dialog-form"].validate()) {
        const temporaryRequester = {
          employeeId: this.temporaryEmployeeId,
          firstName: this.firstName,
          lastName: this.lastName
        }
        this.$emit("on-add-temporary-requester", temporaryRequester)
      }
    }
  }
}
</script>
