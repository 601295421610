import { render, staticRenderFns } from "./AppTabNavDrawer.vue?vue&type=template&id=bbd8dd18&scoped=true"
import script from "./AppTabNavDrawer.vue?vue&type=script&lang=js"
export * from "./AppTabNavDrawer.vue?vue&type=script&lang=js"
import style0 from "./AppTabNavDrawer.vue?vue&type=style&index=0&id=bbd8dd18&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbd8dd18",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VContainer,VIcon,VNavigationDrawer,VTab,VTabs})
