<template>
  <v-container fluid>
    <base-dialog
      ref="app-over-allocation-confirmation-dialog-root"
      :is-dialog-visible="isDialogVisible"
      dialog-image="mdi-alert-outline"
      title="Warning: Over Slot Allocation"
      @close-dialog="closeDialog"
      icon-color="error"
      title-background-color="primary"
    >
      <template v-slot:content>
        <v-row class="mb-2">
          <div class="text-md-body-1 text-center" ref="app-over-allocation-confirmation-dialog-phrase-one">Scheduling this job will put you over the allocation amount for this job's <strong>Preferred Time of Day: <em>{{ timeSlotName }}</em></strong> and <strong>Scheduled Date: <em>{{ scheduledDate }}</em></strong>.</div>
        </v-row>

        <v-row>
          <v-col>
            <v-row class="text-h6 justify-center">First Slots</v-row>
            <v-row class="text-h6 justify-center" :class="{ 'red--text': isOverAllocationFirstSlot }">{{ overAllocation.firstSlotsRemaining }}</v-row>
          </v-col>
          <v-col>
            <v-row class="text-h6 justify-center">AM Slots</v-row>
            <v-row class="text-h6 justify-center" :class="{ 'red--text': isOverAllocationAmSlots }">{{ overAllocation.totalAmSlotsRemaining }}</v-row>
          </v-col>
          <v-col>
            <v-row class="text-h6 justify-center">PM Slots</v-row>
            <v-row class="text-h6 justify-center" :class="{ 'red--text': isOverAllocationPmSlots }">{{ overAllocation.totalPmSlotsRemaining }}</v-row>
          </v-col>
          <v-col>
            <v-row class="text-h6 justify-center">Total Slots</v-row>
            <v-row class="text-h6 justify-center" :class="{ 'red--text': isOverAllocationTotalSlots }">{{ overAllocation.totalSlotsRemaining }}</v-row>
          </v-col>
        </v-row>
        <v-row class="mt-8 justify-center">
          <span class="text-md-body-1" ref="app-over-allocation-confirmation-dialog-phrase-two">Do you still wish to continue?</span>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="app-over-allocation-confirmation-dialog-cancel-btn" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
        <v-btn class="pa-4" ref="app-over-allocation-confirmation-dialog-confirm-btn" color="primary" @click="confirm" text rounded ripple>Confirm</v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
export default {
  name: "AppOverAllocationConfirmationDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    timeSlotName: {
      type: String,
      default: ""
    },
    scheduledDate: {
      type: String,
      default: ""
    },
    overAllocation: {
      type: Object,
      default: () => {
        return {
          firstSlotsRemaining: 0,
          totalAmSlotsRemaining: 0,
          totalPmSlotsRemaining: 0,
          totalSlotsRemaining: 0
        }
      }
    }
  },
  methods: {
    confirm() {
      this.$emit("confirm")
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    isOverAllocationFirstSlot() {
      return this.overAllocation.firstSlotsRemaining < 0
    },
    isOverAllocationAmSlots() {
      return this.overAllocation.totalAmSlotsRemaining < 0
    },
    isOverAllocationPmSlots() {
      return this.overAllocation.totalPmSlotsRemaining < 0
    },
    isOverAllocationTotalSlots() {
      return this.overAllocation.totalSlotsRemaining < 0
    }
  }
}
</script>
