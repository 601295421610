import junkApi from "@/api/Junk"
import { errorSnackbar } from "@/utils/SnackbarBuilder"
import * as Sentry from "@sentry/vue"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { customerSearchDto } from "@/api/dtos/JunkDtos"
import { consoleLog } from "@/utils/Logging";

export const state = {
  _customers: [],
  _serverItemsLength: 0,
  _serverSideItemsPerPage: 10,
  _isLoadingCustomers: false,
  _isLoadingCreateCustomer: false,
  _isLoadingCreateJob: false,
  _itemsPerPageOptions: [10, 25, 50, 100],
  _customerPaginationOptions: {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false
  },
  _searchFirstName: "",
  _searchLastName: "",
  _searchPhone: "",
  _searchEmail: "",
  _searchBusinessName: "",
  _searchJobNumber: ""
}

export const getters = {
  getSearchParamFirstName(state) {
    return state._searchFirstName ?? ""
  },
  getSearchParamLastName(state) {
    return state._searchLastName ?? ""
  },
  getSearchParamPhone(state) {
    return state._searchPhone ?? ""
  },
  getSearchParamEmail(state) {
    return state._searchEmail ?? ""
  },
  getSearchParamBusinessName(state) {
    return state._searchBusinessName ?? ""
  },
  getSearchParamJobNumber(state) {
    return state._searchJobNumber ?? ""
  },
  getCustomers(state) {
    return state._customers?.slice() ?? []
  },
  getCustomerById: (state) => (customerId) => {
    return state._customers?.slice()?.find(c => c.id === customerId) ?? {}
  },
  getCustomerPaginationOptions(state) {
    return state._customerPaginationOptions
  },
  getItemsPerPageOptions(state) {
    return state._itemsPerPageOptions
  },
  getIsLoadingCustomers(state) {
    return state._isLoadingCustomers
  },
  getIsLoadingCreateJob(state) {
    return state._isLoadingCreateJob
  }
}

export const mutations = {
  SET_SEARCH_PARAM_FIRST_NAME(state, firstName) {
    console.log("SET_SEARCH_PARAM_FIRST_NAME = ", firstName)
    console.log("SET_SEARCH_PARAM_FIRST_NAME state.firstName currently = ", state._searchFirstName)
    state._searchFirstName = firstName
  },
  SET_SEARCH_PARAM_LAST_NAME(state, lastName) {
    state._searchLastName = lastName
  },
  SET_SEARCH_PARAM_PHONE(state, phone) {
    state._searchPhone = phone
  },
  SET_SEARCH_PARAM_EMAIL(state, lastName) {
    state._searchEmail = lastName
  },
  SET_SEARCH_PARAM_BUSINESS_NAME(state, businessName) {
    state._searchBusinessName = businessName
  },
  SET_SEARCH_PARAM_JOB_NUMBER(state, jobNumber) {
    state._searchJobNumber = jobNumber
  },
  SET_CUSTOMERS(state, customers) {
    customers = customers.map(c => {
      let result = {
        ...c
      }
      c.jobs?.forEach(j => j.customerId = c.id)
      return result
    })
    state._customers = customers
  },
  SET_CUSTOMER_PAGINATION_OPTIONS(state, options) {
    state._customerPaginationOptions = Object.assign({}, options)
  },
  SET_IS_LOADING_CUSTOMERS(state, isLoading) {
    state._isLoadingCustomers = isLoading
  },
  SET_IS_LOADING_CREATE_JOB(state, isLoading) {
    state._isLoadingCreateJob = isLoading
  }
}

export const actions = {
  async setSearchParamFirstName({ commit }, firstName) {
    commit("SET_SEARCH_PARAM_FIRST_NAME", firstName)
  },
  async setSearchParamLastName({ commit }, lastName) {
    commit("SET_SEARCH_PARAM_LAST_NAME", lastName)
  },
  async setSearchParamPhone({ commit }, phone) {
    commit("SET_SEARCH_PARAM_PHONE", phone)
  },
  async setSearchParamEmail({ commit }, email) {
    commit("SET_SEARCH_PARAM_EMAIL", email)
  },
  async setSearchParamBusinessName({ commit }, businessName) {
    commit("SET_SEARCH_PARAM_BUSINESS_NAME", businessName)
  },
  async setSearchParamJobNumber({ commit }, jobNumber) {
    commit("SET_SEARCH_PARAM_JOB_NUMBER", jobNumber)
  },
  async createCustomer({ commit }, createCustomerDto) {
    commit("SET_IS_LOADING_CUSTOMERS", true)
    return await junkApi
      .createCustomer(createCustomerDto)
      .then(data => {
        return Promise.resolve(data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(errorMessageHandler(error)), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_CUSTOMERS", false))
  },
  async createJunkJob({ commit }, createJobDto) {
    commit("SET_IS_LOADING_CREATE_JOB", true)
    return await junkApi
      .createJunkJob(createJobDto)
      .then(data => {
        return Promise.resolve(data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`Error Creating Job! ${error}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_CREATE_JOB", false))
  },
  async searchCustomers({ commit, getters, rootGetters }, { pageNumber, pageSize }) {
    commit("SET_IS_LOADING_CUSTOMERS", true)
    let dto = customerSearchDto(getters.getSearchParamFirstName, getters.getSearchParamLastName, getters.getSearchParamPhone, getters.getSearchParamEmail, getters.getSearchParamBusinessName, getters.getSearchParamJobNumber, pageNumber, pageSize, rootGetters.getUserOperatingUnitIds)
    consoleLog("searchCustomers.dto = ", dto)
    return await junkApi
      .fetchCustomers(dto)
      .then(data => {
        commit("SET_CUSTOMER_PAGINATION_OPTIONS", {
          currentPage: data.currentPage,
          totalPages: data.totalPages,
          pageSize: data.pageSize,
          totalCount: data.totalCount,
          hasPrevious: data.hasPrevious,
          hasNext: data.hasNext
        })

        commit("SET_CUSTOMERS", data.items)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(`Error Fetching Customers ${error}`), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_CUSTOMERS", false)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {}
}
