import { persistentErrorSnackbarWithPositiveAction } from "@/utils/SnackbarBuilder"
import * as Sentry from "@sentry/vue"
import junkApi from "@/api/Junk"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import dayjs from "dayjs"

export const state = {
  isJobManagementLoading: false,
  isLoadingJobStatuses: false,
  selectedStatuses: [1, 2, 3],
  selectedDateRange: [
    todayAsDate(),
    dayjs()
      .add(5, "day")
      .format("YYYY-MM-DD")
  ],
  itemsPerPageOptions: [10, 25, 50, 100],
  jobs: [],
  jobStatuses: [],
  includeOnlyCustomerSelfScheduled: false,
  jobManagementPaginationOptions: {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false
  }
}

export const getters = {
  getIncludeOnlyCustomerSelfScheduled(state) {
    return state.includeOnlyCustomerSelfScheduled
  },
  getSelectedDateRange(state) {
    return state.selectedDateRange
  },
  getItemsPerPageOptions(state) {
    return state.itemsPerPageOptions
  },
  getIsLoadingJobStatuses(state) {
    return state.isLoadingJobStatuses
  },
  getIsJobManagementLoading(state) {
    return state.isJobManagementLoading
  },
  getJobStatuses(state) {
    return state.jobStatuses.slice()
  },
  getSelectedStatuses(state) {
    return state.selectedStatuses
  },
  getJobs(state) {
    return state.jobs.slice()
  },
  getJobManagementPaginationOptions(state) {
    return state.jobManagementPaginationOptions
  }
}

export const mutations = {
  SET_IS_JOB_MANAGEMENT_LOADING(state, isLoading) {
    state.isJobManagementLoading = isLoading
  },
  SET_SELECTED_STATUSES(state, statuses) {
    state.selectedStatuses = statuses
  },
  SET_SELECTED_DATE_RANGE(state, dateRange) {
    state.selectedDateRange = dateRange
  },
  SET_JOBS(state, jobs) {
    state.jobs = jobs
  },
  SET_JOB_STATUSES(state, jobStatuses) {
    state.jobStatuses = jobStatuses
  },
  SET_IS_LOADING_JOB_STATUSES(state, isLoading) {
    state.isLoadingJobStatuses = isLoading
  },
  SET_JOB_MANAGEMENT_PAGINATION_OPTIONS(state, options) {
    state.jobManagementPaginationOptions = Object.assign({}, options)
  },
  SET_INCLUDE_ONLY_CUSTOMER_SELF_SCHEDULED(state, includeOnlyCustomerSelfScheduled) {
    state.includeOnlyCustomerSelfScheduled = includeOnlyCustomerSelfScheduled
  }
}

export const actions = {
  setIncludeOnlyCustomerSelfScheduled({ commit }, includeOnlyCustomerSelfScheduled) {
    commit("SET_INCLUDE_ONLY_CUSTOMER_SELF_SCHEDULED", includeOnlyCustomerSelfScheduled)
  },
  async setSelectedDateRange({ commit }, dateRange) {
    commit("SET_SELECTED_DATE_RANGE", dateRange)
  },
  async resetJobManagementJobs({ commit }) {
    commit("SET_JOBS", [])
  },
  async fetchJunkJobStatuses({ commit }) {
    commit("SET_IS_LOADING_JOB_STATUSES", true)
    return await junkApi
      .fetchJunkJobStatuses()
      .then(data => {
        commit("SET_JOB_STATUSES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Job Statuses! ${errorMessage}`, "Retry", () => this.dispatch("JobManagement/fetchJunkJobStatuses")),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_JOB_STATUSES", false))
  },
  async fetchJobManagementData({ commit, getters }, { operatingUnitId, fromDate, toDate, pageNumber, pageSize }) {
    commit("SET_IS_JOB_MANAGEMENT_LOADING", true)
    return await junkApi
      .fetchJobManagementData(operatingUnitId, getters.getSelectedStatuses, fromDate, toDate, pageNumber, pageSize, getters.getIncludeOnlyCustomerSelfScheduled)
      .then(data => {
        commit("SET_JOB_MANAGEMENT_PAGINATION_OPTIONS", {
          currentPage: data.currentPage,
          totalPages: data.totalPages,
          pageSize: data.pageSize,
          totalCount: data.totalCount,
          hasPrevious: data.hasPrevious,
          hasNext: data.hasNext
        })
        commit("SET_JOBS", data.items)
        return Promise.resolve(data.items)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit(
          "ADD_SNACKBAR",
          persistentErrorSnackbarWithPositiveAction(`Error Fetching Jobs! ${errorMessage}`, "Retry", () => this.dispatch("JobManagement/fetchJobManagementData", { operatingUnitId, fromDate, toDate, pageNumber, pageSize })),
          { root: true }
        )
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_JOB_MANAGEMENT_LOADING", false))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {}
}
