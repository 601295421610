<template>
  <v-card :loading="getIsLoadingDiscounts">
    <v-card-title>
      <v-col cols="auto">
        Discounts
      </v-col>
      <v-spacer />
      <v-col class="ma-0 pa-0 d-flex flex-fill ms-0 ms-sm-4 ms-md-8 ms-lg-12 ms-xl-6 col-lg-6 col-xl-5" cols="auto">
        <v-switch
          ref="administrator-discounts-card-inactive-switch"
          label="Include Inactive Discounts"
          class="ma-0 mx-2 pa-0 justify-end align-end float-end align-self-end text-break"
          v-model="isDisplayInactiveDiscountsEnabled"
          :disabled="isDisplayInactiveDiscountsToggleDisabled"
          @change="scrollToInactiveDiscountsCard"
        />
        <v-text-field
          ref="administrator-discounts-card-search-text-field"
          label="Search Discounts"
          class="ma-0 mx-4 ps-4 float-end d-flex align-self-end flex-fill"
          v-model="searchText"
          :disabled="isSearchTextFieldDisabled"
          clearable="clearable"
          clear-icon="mdi-trash-can-outline"
          append-icon="mdi-magnify"
        />
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-data-table
        ref="administrator-discounts-card-data-table"
        :headers="administratorDiscountHeaders"
        :search="searchText"
        :items="getFilteredDiscounts"
        item-key="nationalDiscountId"
        single-select
        show-expand
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.name="{ item }">
          <span class="me-1" ref="administrator-discounts-card-name">{{ item.name }}</span>
        </template>
        <template v-slot:item.amount="{ item }">
          <app-number-formatter
            ref="administrator-discounts-card-amount-number-formatter"
            :amount="item.amount"
            number-format-style="currency"
            currency-sign="accounting"
            :is-percentage="item.isPercentage"
          />
        </template>
        <template v-slot:item.promoCode="{ item }">
          <v-text-field
            class="mt-2 mb-n3"
            ref="administrator-discounts-card-promo-code-edit"
            v-if="isDiscountItemBeingEdited(item.nationalDiscountId)"
            :value="editedDiscountItem.promoCode"
            @input="setEditedDiscountItemPromoCode($event)"
            :rules="editablePromoCodeValidationRules"
            counter="25"
            outlined
            dense
          ></v-text-field>
          <span v-else ref="administrator-discounts-card-promo-code">{{ item.promoCode }}</span>
        </template>
        <template v-slot:item.isEligibleForBrandWebsite="{ item }">
          <v-icon :color="getIsEligibleForBrandWebsiteColor(item.isEligibleForBrandWebsite)" v-text="getIsEligibleForBrandWebsiteIcon(item.isEligibleForBrandWebsite)"></v-icon>
        </template>
        <template v-slot:item.beginDate="{ item }">
          <app-date-picker-field-selector
            ref="administrator-discounts-card-begin-date-picker"
            v-if="isDiscountItemBeingEdited(item.nationalDiscountId)"
            :text-field-disabled="isBeginDateDisabled(item)"
            :date-picker-value="formatAsDateOnly(editedDiscountItem.beginDate)"
            :minimum-date-value="todayAsDate()"
            :maximum-date-value="formatAsDateOnly(editedDiscountItem.endDate)"
            is-text-field-dense
            text-field-format="MMMM D, YYYY"
            @updatedPickerValue="setBeginDateTime($event)"
          />
          <app-date-time-locale-formatter ref="administrator-discounts-card-begin-date-formatter" v-else :date-time-value="item.beginDate" />
        </template>
        <template v-slot:item.endDate="{ item }">
          <app-date-picker-field-selector
            ref="administrator-discounts-card-end-date-picker"
            v-if="isDiscountItemBeingEdited(item.nationalDiscountId)"
            :date-picker-value="formatAsDateOnly(editedDiscountItem.endDate)"
            :minimum-date-value="editableItemEndDateMinimumValue"
            is-clear-enabled
            is-text-field-dense
            text-field-format="MMMM D, YYYY"
            @updatedPickerValue="setEndDateTime($event)"
          />
          <app-date-time-locale-formatter ref="administrator-discounts-card-end-date-formatter" v-else :date-time-value="item.endDate" />
        </template>
        <template v-slot:item.actions="{ item }">
          <div ref="administrator-discounts-card-actions-save-container" v-if="isDiscountItemBeingEdited(item.nationalDiscountId)">
            <v-btn ref="administrator-discounts-card-save-item-button" :disabled="isSaveDisabled(item)" @click="confirmSaveEditableDiscountItem" color="primary" icon>
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn ref="administrator-discounts-card-cancel-item-button" @click="resetEditableDiscountItem" icon>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </div>
          <div ref="administrator-discounts-card-actions-edit-container" v-else-if="isDiscountItemEditable(item)" class="justify-center">
            <v-btn ref="administrator-discounts-card-edit-item-button" @click="setEditDiscountItem(item)" color="primary" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
          <td ref="administrator-discounts-card-expand-cell" v-if="hasFranchiseElectedDiscounts(item)" class="text-start">
            <v-btn
              ref="administrator-discounts-card-expand-button"
              icon
              @click="expand(!isExpanded)"
              class="v-data-table__expand-icon"
              :class="{ 'v-data-table__expand-icon--active': isExpanded }"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </td>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td ref="administrator-discounts-card-expanded-cell" class="pa-0" :colspan="headers.length">
            <v-responsive class="overflow-y-auto" max-height="350">
              <v-data-table
                ref="administrator-discounts-card-expanded-data-table"
                :headers="subHeaders"
                :items="item.franchiseElectedDiscounts"
                fixed-header
                sort-by="franchiseNumberAndName"
                :item-class="itemRowBackground"
                item-key="franchiseElectedDiscountId"
                no-data-text="There are no franchises associated with this national discount."
                mobile-breakpoint="590"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item.franchiseNumberAndName="{ value }">
                  <span ref="administrator-discounts-card-franchise-name-expanded">{{ value }}</span>
                </template>
                <template v-slot:item.beginDate="{ value }">
                  <app-date-time-locale-formatter ref="administrator-discounts-card-begin-date-expanded" :date-time-value="value" />
                </template>
                <template v-slot:item.endDate="{ value }">
                  <app-date-time-locale-formatter ref="administrator-discounts-card-end-date-expanded" :date-time-value="value" />
                </template>
              </v-data-table>
            </v-responsive>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <base-fab ref="administrator-discounts-card-fab" :is-visible="true" :fab-options="fabOptions" />

    <administrator-create-discount-dialog
      ref="administrator-discounts-card-create-discount-dialog"
      v-if="isCreateDiscountDialogVisible"
      :is-loading="getIsLoadingDiscounts"
      :is-dialog-visible="isCreateDiscountDialogVisible"
      @confirm-national-discount="handleCreateDiscount($event)"
      @close-dialog="setIsCreateDiscountDialogVisible(false)"
    />
    <administrator-discounts-confirmation-dialog
      ref="administrator-discounts-card-confirm-save-edit-dialog"
      :is-dialog-visible="isConfirmSaveEditableDiscountDialogVisible"
      @saveEdit="saveEditableDiscountItem"
      @closeDialog="setIsConfirmSaveEditableDiscountDialogVisible(false)"
    />
  </v-card>
</template>

<script>
import AppNumberFormatter from "@/components/AppNumberFormatter"
import AppDateTimeLocaleFormatter from "@/components/AppDateTimeLocaleFormatter"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import BaseFab from "@/components/BaseFab"
import AdministratorDiscountsConfirmationDialog from "@/components/AdministratorDiscountsConfirmationDialog"
import AdministratorCreateDiscountDialog from "@/components/AdministratorCreateDiscountDialog"
import { mapActions, mapGetters } from "vuex"
import { formatAsDateOnly, todayAsDate } from "@/utils/DateTimeFormatters"
import { updateNationalDiscountDto } from "@/api/dtos/JunkDtos"
import { consoleLog } from "@/utils/Logging"

export default {
  name: "AdministratorDiscountsCard",
  components: {
    AdministratorDiscountsConfirmationDialog,
    AppNumberFormatter,
    AppDateTimeLocaleFormatter,
    AppDatePickerFieldSelector,
    BaseFab,
    AdministratorCreateDiscountDialog
  },
  data() {
    return {
      isDisplayInactiveDiscountsEnabled: false,
      searchText: "",
      editedDiscountItem: {},
      isCreateDiscountDialogVisible: false,
      isConfirmSaveEditableDiscountDialogVisible: false,
      editablePromoCodeValidationRules: [value => ((value ?? "").length > 4 && (value ?? "").length <= 25) || "Must be between 5 and 25 characters."]
    }
  },
  computed: {
    ...mapGetters("Administrator", ["getIsLoadingDiscounts", "getDiscounts", "getActiveDiscounts"]),
    subHeaders() {
      return [
        { text: "Franchise Name", align: "start", value: "franchiseNumberAndName", class: "secondaryDark white--text" },
        { text: "Begin Date", align: "center", value: "beginDate", class: "secondaryDark white--text" },
        { text: "End Date", align: "center", value: "endDate", class: "secondaryDark white--text" }
      ]
    },
    administratorDiscountHeaders() {
      return [
        { text: "Name", align: "start", value: "name" },
        { text: "Discount", align: "start", value: "amount" },
        { text: "Promo Code", align: "start", value: "promoCode" },
        { text: "Website Phrase", align: "start", value: "defaultWebsitePhrase" },
        { text: "Eligible for Brand Website", align: "center", value: "isEligibleForBrandWebsite" },
        { text: "Begin Date", align: "start", value: "beginDate" },
        { text: "End Date", align: "start", value: "endDate" },
        { text: "Actions", align: "center", value: "actions", sortable: false }
      ]
    },
    isDisplayInactiveDiscountsToggleDisabled() {
      return this.getDiscounts.length === 0
    },
    isSearchTextFieldDisabled() {
      return this.getDiscounts.length === 0
    },
    fabOptions() {
      return [
        {
          icon: "mdi-tag-plus",
          onClickAction: () => this.handleBaseFabClick(true),
          text: "Create Discount",
          isFabDisabled: false
        }
      ]
    },
    getFilteredDiscounts() {
      return this.isDisplayInactiveDiscountsEnabled ? this.getDiscounts : this.getActiveDiscounts
    },
    editableItemEndDateMinimumValue() {
      return this.editedDiscountItem.beginDate <= todayAsDate() ? todayAsDate() : formatAsDateOnly(this.editedDiscountItem.beginDate)
    }
  },
  methods: {
    ...mapActions("Administrator", ["fetchNationalDiscounts", "createNationalDiscount", "updateNationalDiscount"]),
    todayAsDate,
    formatAsDateOnly,
    getIsEligibleForBrandWebsiteIcon(isEligible) {
      return isEligible ? "mdi-checkbox-marked-circle-outline" : "mdi-close-circle-outline"
    },
    getIsEligibleForBrandWebsiteColor(isEligible) {
      return isEligible ? "green" : "red"
    },
    itemRowBackground() {
      if (!this.$vuetify.breakpoint.xs) return "backgroundDisabled"
    },
    setEditedDiscountItemPromoCode(event) {
      this.editedDiscountItem.promoCode = event.toUpperCase()
    },
    scrollToInactiveDiscountsCard() {
      if (this.isDisplayInactiveDiscountsEnabled) {
        this.$refs["administrator-discounts-data-table"].$el.scrollIntoView()
      }
    },
    hasFranchiseElectedDiscounts(item) {
      return item?.franchiseElectedDiscounts?.length > 0
    },
    async handleCreateDiscount(createdDiscountDto) {
      await this.createNationalDiscount(createdDiscountDto).then(() => {
        this.setIsCreateDiscountDialogVisible(false)
      })
    },
    setBeginDateTime(dateTime) {
      this.editedDiscountItem.beginDate = dateTime
    },
    setEndDateTime(dateTime) {
      this.editedDiscountItem.endDate = dateTime
    },
    isBeginDateDisabled(item) {
      return formatAsDateOnly(item.beginDate) <= todayAsDate()
    },
    isDiscountItemEditable(item) {
      return (formatAsDateOnly(item.beginDate) ?? todayAsDate()) >= todayAsDate() || (formatAsDateOnly(item.endDate) ?? todayAsDate()) >= todayAsDate()
    },
    isDiscountItemBeingEdited(itemDiscountId) {
      return itemDiscountId === this.editedDiscountItem?.nationalDiscountId
    },
    isSaveDisabled(item) {
      return this.isItemNotModified(item) || this.getIsLoadingDiscounts
    },
    isItemNotModified(item) {
      return (
        formatAsDateOnly(this.editedDiscountItem.beginDate) === formatAsDateOnly(item.beginDate) &&
        formatAsDateOnly(this.editedDiscountItem.endDate) === formatAsDateOnly(item.endDate) &&
        this.editedDiscountItem.promoCode === item.promoCode
      )
    },
    setEditDiscountItem(item) {
      this.editedDiscountItem = this.createEditableDiscountItem(item)
    },
    createEditableDiscountItem(item) {
      let newEditableItem = Object.assign({}, item)
      newEditableItem.beginDate = formatAsDateOnly(item.beginDate) ?? todayAsDate()
      newEditableItem.endDate = formatAsDateOnly(item.endDate) ?? null
      return newEditableItem
    },
    confirmSaveEditableDiscountItem() {
      if (this.$refs["administrator-discounts-card-promo-code-edit"].validate()) {
        this.setIsConfirmSaveEditableDiscountDialogVisible(true)
      }
    },
    setIsConfirmSaveEditableDiscountDialogVisible(isVisible) {
      this.isConfirmSaveEditableDiscountDialogVisible = isVisible
    },
    async saveEditableDiscountItem() {
      this.setIsConfirmSaveEditableDiscountDialogVisible(false)

      const updatedDiscountDto = new updateNationalDiscountDto(
        this.editedDiscountItem.nationalDiscountId,
        this.editedDiscountItem.beginDate,
        this.editedDiscountItem.endDate,
        this.$msal.getCurrentUserId(),
        this.editedDiscountItem.promoCode
      )
      consoleLog("saveEditableDiscountItem.updatedDiscountDto = ", updatedDiscountDto)
      await this.updateNationalDiscount(updatedDiscountDto).then(() => {
        this.resetEditableDiscountItem()
      })
    },
    handleBaseFabClick(isVisible) {
      this.resetEditableDiscountItem()
      this.setIsCreateDiscountDialogVisible(isVisible)
    },
    setIsCreateDiscountDialogVisible(isVisible) {
      this.isCreateDiscountDialogVisible = isVisible
    },
    resetEditableDiscountItem() {
      this.editedDiscountItem = Object.assign({}, {})
    }
  },
  async created() {
    await this.fetchNationalDiscounts()
  }
}
</script>
