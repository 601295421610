<template>
  <v-container fluid>
    <v-row>
      <v-col class="flex-fill">
        <administrator-operating-unit-tree />
      </v-col>
    </v-row>
    <base-fab ref="administrator-settings-fab" :is-visible="true" :fab-options="fabOptions" />
    <administrator-create-operating-unit-dialog
      ref="administrator-settings-create-operating-unit-dialog"
      v-if="isCreateOperatingUnitDialogVisible"
      :is-dialog-visible="isCreateOperatingUnitDialogVisible"
      @closeDialog="setIsCreateOperatingUnitDialogVisible(false)"
    />
    <administrator-enroll-franchise-dialog
      ref="administrator-settings-enroll-franchise-dialog"
      v-if="isEnrollFranchiseDialogVisible"
      :is-dialog-visible="isEnrollFranchiseDialogVisible"
      @closeDialog="setIsEnrollFranchiseDialogVisible(false)"
    />
    <administrator-create-satellite-office-dialog
      ref="administrator-settings-create-satellite-office-dialog"
      v-if="isCreateSatelliteOfficeDialogVisible"
      :is-dialog-visible="isCreateSatelliteOfficeDialogVisible"
      @closeDialog="setIsCreateSatelliteOfficeDialogVisible(false)"
    />
  </v-container>
</template>

<script>
import AdministratorOperatingUnitTree from "@/components/AdministratorOperatingUnitTree"
import BaseFab from "@/components/BaseFab"
import AdministratorCreateOperatingUnitDialog from "@/components/AdministratorCreateOperatingUnitDialog"
import AdministratorEnrollFranchiseDialog from "@/components/AdministratorEnrollFranchiseDialog"
import AdministratorCreateSatelliteOfficeDialog from "@/components/AdministratorCreateSatelliteOfficeDialog"

export default {
  name: "AdministratorSettings",
  components: {
    AdministratorOperatingUnitTree,
    BaseFab,
    AdministratorCreateOperatingUnitDialog,
    AdministratorEnrollFranchiseDialog,
    AdministratorCreateSatelliteOfficeDialog
  },
  data() {
    return {
      isCreateOperatingUnitDialogVisible: false,
      isEnrollFranchiseDialogVisible: false,
      isCreateSatelliteOfficeDialogVisible: false
    }
  },
  computed: {
    fabOptions() {
      return [
        {
          icon: "mdi-cash-plus",
          onClickAction: () => this.setIsCreateOperatingUnitDialogVisible(true),
          text: "Create Operating Unit",
          isFabDisabled: false
        },
        {
          icon: "mdi-domain-plus",
          onClickAction: () => this.setIsEnrollFranchiseDialogVisible(true),
          text: "Create and Enroll Franchise",
          isFabDisabled: false
        },
        {
          icon: "mdi-satellite-uplink",
          onClickAction: () => this.setIsCreateSatelliteOfficeDialogVisible(true),
          text: "Create Satellite Office",
          isFabDisabled: false
        }
      ]
    }
  },
  methods: {
    setIsCreateOperatingUnitDialogVisible(isVisible) {
      this.isCreateOperatingUnitDialogVisible = isVisible
    },
    setIsEnrollFranchiseDialogVisible(isVisible) {
      this.isEnrollFranchiseDialogVisible = isVisible
    },
    setIsCreateSatelliteOfficeDialogVisible(isVisible) {
      this.isCreateSatelliteOfficeDialogVisible = isVisible
    }
  }
}
</script>
