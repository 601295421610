<template>
  <v-container fluid>
    <base-dialog :is-dialog-visible="isDialogVisible" title="Create Satellite Office" dialog-image="mdi-account-plus-outline">
      <template class="flex-fill" v-slot:content>
        <v-form class="ma-0 pa-0" ref="admin-create-satellite-office-dialog-form" v-model="isCreateSatelliteOfficeFormValid">
          <v-row dense>
            <v-col>
              <v-autocomplete
                class="cursor_pointer"
                ref="admin-create-satellite-office-dialog-franchise-selector"
                label="Selected Franchise *"
                v-model="selectedFranchiseId"
                :items="getFranchisesAndSatellites"
                item-value="franchiseId"
                item-text="displayName"
                outlined
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field label="Satellite Office Name *" v-model="satelliteOfficeName" outlined counter="50" :rules="isRequiredRules"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-phone-input class="d-flex" ref="admin-create-satellite-office-dialog-phone-input" label="Primary Phone Number *" v-model="phonePrimary" :rules="isRequiredRules" outlined validate-on-blur />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-enroll-sat-office-google-review-url-text"
                label="Google Review URL"
                v-model.trim="googleReviewUrl"
                counter="256"
                outlined
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field class="cursor_pointer" label="Address *" readonly :value="formatAddress(satelliteOfficeAddress)" outlined @click="setIsAddressSearchDialogVisible(true)" :rules="isRequiredRules"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex">
              <app-date-picker-field-selector
                ref="administrator-enroll-franchise-dialog-enrollment-date-picker"
                :minimum-date-value="minimumDateValue"
                :date-picker-value="beginDateTime"
                @updatedPickerValue="setBeginDate($event)"
                text-field-label="Begin Date *"
                :text-field-disabled="false"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="admin-create-satellite-office-dialog-cancel-btn" color="primaryText" @click="closeDialog" :disabled="false" text rounded ripple>Cancel</v-btn>
        <v-btn class="pa-4" ref="admin-create-satellite-office-dialog-confirm-btn" color="primary" @click="save" :loading="getIsLoadingOperatingUnitFranchisesAndSatellites" :disabled="isSaveDisabled" text rounded ripple>Confirm</v-btn>
      </template>
    </base-dialog>
    <app-address-search-dialog
      v-if="isAddressSearchDialogVisible"
      :is-dialog-visible="isAddressSearchDialogVisible"
      :initial-camera-position="{ lat: 42.435712, lng: -84.331992 }"
      :is-create-address-from-marker-button-visible="false"
      @createAddressFromSearch="setSatelliteOfficeAddress($event)"
      @createAddressFromForm="setSatelliteOfficeAddress($event)"
      @closeDialog="setIsAddressSearchDialogVisible(false)"
    ></app-address-search-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { createSatelliteOfficeDto } from "@/api/dtos/JunkDtos"
import { mapActions, mapGetters } from "vuex"
import AppAddressSearchDialog from "@/components/AppAddressSearchDialog"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { formatHereAddressText } from "@/utils/FormatAddressText"

export default {
  name: "AdministratorCreateSatelliteOfficeDialog",
  components: { AppDatePickerFieldSelector, AppAddressSearchDialog, BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      minimumDateValue: todayAsDate(),
      isAddressSearchDialogVisible: false,
      selectedFranchiseId: undefined,
      satelliteOfficeName: "",
      phonePrimary: "",
      satelliteOfficeAddress: "",
      googleReviewUrl: "",
      beginDateTime: todayAsDate(),
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."],
      isCreateSatelliteOfficeFormValid: false
    }
  },
  computed: {
    ...mapGetters("Administrator", ["getFranchisesAndSatellites", "getIsLoadingOperatingUnitFranchisesAndSatellites"]),
    isSaveDisabled() {
      return this.selectedFranchiseId === undefined || this.getIsLoadingOperatingUnitFranchisesAndSatellites
    }
  },
  methods: {
    ...mapActions("Administrator", ["createSatelliteOffice"]),
    async save() {
      if (this.$refs["admin-create-satellite-office-dialog-form"].validate()) {
        let dto = createSatelliteOfficeDto(this.selectedFranchiseId, this.phonePrimary, this.satelliteOfficeName, this.beginDateTime, this.$msal.getCurrentUserId(), this.googleReviewUrl, this.satelliteOfficeAddress.addressLine1, this.satelliteOfficeAddress.addressLine2, this.satelliteOfficeAddress.city, this.satelliteOfficeAddress.stateCode, this.satelliteOfficeAddress.postalCode, this.satelliteOfficeAddress.countryCode, false, this.satelliteOfficeAddress.latitude, this.satelliteOfficeAddress.longitude)
        await this.createSatelliteOffice(dto).then(() => this.closeDialog())
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    formatAddress(address) {
      return formatHereAddressText(address)
    },
    setIsAddressSearchDialogVisible(isVisible) {
      this.isAddressSearchDialogVisible = isVisible
    },
    setSatelliteOfficeAddress(satelliteOfficeAddress) {
      this.satelliteOfficeAddress = satelliteOfficeAddress
      this.setIsAddressSearchDialogVisible(false)
    },
    setBeginDate(beginDateTime) {
      this.beginDateTime = beginDateTime
    }
  }
}
</script>

<style scoped></style>
