<template>
  <v-container fluid>
    <base-dialog
      ref="job-indirect-labor-dialog-root"
      :is-dialog-visible="isDialogVisible"
      dialog-image="mdi-account-hard-hat-outline"
      title="Add Indirect Labor"
      @close-dialog="closeDialog"
    >
      <template v-slot:content>
        <v-form ref="job-indirect-labor-dialog-form" v-model="isJobIndirectLaborDialogFormValid">
          <v-layout row>
            <v-autocomplete
              ref="job-indirect-labor-dialog-selected-employees"
              v-model="selectedEmployees"
              :items="getActiveEmployees"
              label="Selected Employees"
              item-value="id"
              no-data-text="No Employees Available"
              :item-text="formatEmployeeName"
              :rules="employeeValidationRules"
              outlined
              flat
              solo
              clearable
              multiple
              chips
              deletable-chips
            >
            </v-autocomplete>
          </v-layout>
<!--          <v-layout row>-->
<!--            <v-text-field-->
<!--              ref="job-indirect-labor-dialog-adjusted-hours"-->
<!--              :value="adjustedHours"-->
<!--              @change="setAdjustedHours"-->
<!--              label="Adjusted Hours"-->
<!--              type="number"-->
<!--              min="-24"-->
<!--              max="24"-->
<!--              :rules="adjustedHoursValidationRules"-->
<!--              outlined-->
<!--              validate-on-blur-->
<!--            ></v-text-field>-->
<!--          </v-layout>-->
          <v-layout row>
            <v-text-field
              ref="job-indirect-labor-dialog-tip-amount"
              :value="tipAmount"
              @change="setTipAmount"
              label="Tip Amount"
              prepend-inner-icon="mdi-currency-usd"
              type="number"
              min="-999999.99"
              max="999999.99"
              :rules="tipAmountValidationRules"
              outlined
              validate-on-blur
            ></v-text-field>
          </v-layout>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" color="primaryText" ref="job-indirect-labor-dialog-cancel-btn" @click="closeDialog" text rounded ripple>Cancel</v-btn>
        <v-btn class="pa-4" color="primary" ref="job-indirect-labor-dialog-confirm-btn" @click="confirmDialog" text rounded ripple :loading="getIsLoadingTrucksAndEmployees">Confirm</v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { mapActions, mapGetters } from "vuex"
import { formatDecimalValue } from "@/utils/FormatDecimalValue"
import { postJobEmployeeIndirectLaborDto } from "@/api/dtos/JunkDtos"

export default {
  name: "JobIndirectLaborDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedEmployees: undefined,
      // adjustedHours: undefined,
      tipAmount: undefined,
      isJobIndirectLaborDialogFormValid: false,
      employeeValidationRules: [value => value?.length > 0 || "At least one employee is required."],
      // adjustedHoursValidationRules: [value => !!value || "Adjusted Hours is Required."],
      tipAmountValidationRules: [value => !!value || "A Tip Amount is Required."]
    }
  },
  computed: {
    ...mapGetters("Job", ["getActiveEmployees", "getCurrentJob", "getIsLoadingTrucksAndEmployees"])
  },
  methods: {
    ...mapActions("Job", ["createJobEmployeeIndirectLabor", "fetchJunkJobByJobId"]),
    setTipAmount(value) {
      this.tipAmount = this.formatDecimalValueOrDefault(value)
    },
    // setAdjustedHours(value) {
    //   this.adjustedHours = this.formatDecimalValueOrDefault(value)
    // },
    formatDecimalValueOrDefault(value, defaultValue = 0, scale = 2) {
      let isValueNan = isNaN(parseFloat(value))
      return isValueNan ? formatDecimalValue(defaultValue, scale) : formatDecimalValue(value, scale)
    },
    formatEmployeeName(item) {
      return `${item.surname}, ${item.givenName}`
    },
    generateJobEmployeeIndirectLaborDtos(modifiedBy, jobId) {
      return this.selectedEmployees.map(jobEmployeeId => postJobEmployeeIndirectLaborDto(jobEmployeeId, jobId, this.tipAmount, modifiedBy))
    },
    async confirmDialog() {
      if (this.$refs["job-indirect-labor-dialog-form"].validate()) {
        const dtos = this.generateJobEmployeeIndirectLaborDtos(this.$msal.getCurrentUserId(), this.getCurrentJob.id)
        await this.createJobEmployeeIndirectLabor({ jobId: this.getCurrentJob.id, createIndirectLaborDtos: dtos }).then(() => {
          this.fetchJunkJobByJobId(this.getCurrentJob.id)
          this.closeDialog()
        })
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  }
}
</script>

<style scoped></style>
