import { AdRoles } from "@/enums/AdRoles"

const CORPORATE_ADMINISTRATOR = AdRoles.CORPORATE_ADMINISTRATOR
const CSR = AdRoles.CSR
const HAULER = AdRoles.HAULER
const FRANCHISE_OWNER = AdRoles.FRANCHISE_OWNER
const JUNK_MANAGER = AdRoles.JUNK_MANAGER
const SALES_SUPPORT = AdRoles.SALES_SUPPORT
const CUSTOMER = { id: 7, name: "Customer", adName: "" }

export const JobCreationOrigin = {
  CORPORATE_ADMINISTRATOR: CORPORATE_ADMINISTRATOR,
  CSR: CSR,
  HAULER: HAULER,
  FRANCHISE_OWNER: FRANCHISE_OWNER,
  JUNK_MANAGER: JUNK_MANAGER,
  SALES_SUPPORT: SALES_SUPPORT,
  CUSTOMER: CUSTOMER
}

export const JobCreationOrigins = [CORPORATE_ADMINISTRATOR, CSR, HAULER, FRANCHISE_OWNER, JUNK_MANAGER, SALES_SUPPORT, CUSTOMER]
