function errorMessageHandler(error) {
  if (typeof error?.response?.data === "object" || error?.response?.data.trim().length <= 0 || error?.response?.data === undefined || error?.response?.data === null) {
    return error
  } else {
    return error?.response?.data
  }
}

module.exports = {
  errorMessageHandler
}
