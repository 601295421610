import Vue from "vue"
import VueRouter from "vue-router"
import Job from "@/views/Job"
import JobManagement from "@/views/JobManagement"
import JobQueue from "@/views/JobQueue"
import AppNotFound from "@/components/AppNotFound"
import Settings from "@/views/Settings"
import Customer from "@/views/Customer"
import Dashboard from "@/views/Dashboard"
import Administrator from "@/views/Administrator"
import DataFix from "@/views/DataFix"
import { routeGuard } from "@/router/RouteGuard"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/dashboard"
    // beforeEnter: routeGuard
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: routeGuard,
    meta: {
      title: "Junk Drawer - Dashboard"
    }
  },
  {
    path: "/customer",
    name: "Customer",
    component: Customer,
    beforeEnter: routeGuard,
    meta: {
      title: "Junk Drawer - Customer"
    }
  },
  {
    path: "/jobmanagement",
    name: "JobManagement",
    component: JobManagement,
    beforeEnter: routeGuard,
    meta: {
      title: "Junk Drawer - Job Management"
    }
  },
  {
    path: "/job/:jobId?",
    name: "Job",
    component: Job,
    props: true,
    beforeEnter: routeGuard,
    meta: {
      title: "Junk Drawer - Job"
    }
  },
  {
    path: "/jobqueue",
    name: "JobQueue",
    component: JobQueue,
    beforeEnter: routeGuard,
    meta: {
      title: "Junk Drawer - Job Queue"
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: routeGuard,
    meta: {
      title: "Junk Drawer - Settings"
    }
  },
  {
    path: "/administrator",
    name: "AdministratorSettings",
    component: Administrator,
    beforeEnter: routeGuard,
    meta: {
      title: "Junk Drawer - Administrator Settings"
    }
  },
  {
    path: "/datafix",
    name: "DataFix",
    component: DataFix,
    beforeEnter: routeGuard,
    meta: {
      title: "Junk Drawer - Data Fix"
    }
  },
  {
    path: "/:catchAll(.*)",
    name: "AppNotFound",
    component: AppNotFound,
    beforeEnter: routeGuard,
    meta: {
      title: "Junk Drawer - Page Not Found"
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = "Junk Drawer"
router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to?.meta?.title ?? DEFAULT_TITLE
  })
})

export default router
