<template>
  <app-footer ref="job-footer-root" :is-loading="getIsLoadingAnyJobComponents" :is-expand-button-disabled="isExpandableButtonDisabled">
    <template v-slot:content>
      <v-row>
        <v-col>
          <span class="mx-2 align-start font-weight-bold" :style="{ color: 'white', 'font-size': 'xx-large', 'background-color': 'transparent' }">Total: </span>
          <app-number-formatter
            class="font-weight-bold wrap ms-0 ps-0 align-self-start me-16"
            ref="job-footer-total-price"
            style="color: white; font-size: xx-large; background-color: transparent"
            :amount="getTotalPriceWithTips"
            currency-sign="accounting"
            number-format-style="currency"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:additional-content>
      <v-layout row class="ps-2 mt-3 mb-2">
        <v-col>
          <v-list class="transparent">
            <v-list-item-group>
              <template v-for="(item, index) in getLineItemBreakdownTotals">
                <v-list-item :key="item.title" inactive disabled>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="float-start align-start white--text">
                        <span>{{ item.title }}</span>
                      </v-col>
                      <v-col class="text-end white--text">
                        <app-number-formatter :amount="item.value" :display-negative-as-red="item.name === 'Discount'" currencySign="accounting" numberFormatStyle="currency"></app-number-formatter>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mx-2 my-n2" :key="index" :style="{ background: 'var(--v-primaryLight-base)' }"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-layout>
    </template>
    <template v-slot:actions>
      <v-layout class="mt-2" row>
        <v-col class="ms-4">
          <v-layout row class="flex-fill">
          </v-layout>
          <v-layout row class="pb-1 justify-space-around">
            <v-col v-if="getIsJobStatusCreated" class="mb-n2 me-16">
              <v-btn ripple rounded large elevation="4" class="primary align-self-start me-2 mb-2" ref="job-footer-schedule-btn" @click="emitScheduleJob">
                <v-icon class="me-1" icon>mdi-calendar-check</v-icon>Schedule
              </v-btn>
              <v-btn ripple rounded large elevation="4" class="error" ref="job-footer-abandon-btn" @click="emitAbandonJob">
                <v-icon class="me-1" icon>mdi-close-outline</v-icon>Abandon
              </v-btn>
            </v-col>
            <v-col v-else-if="getIsJobStatusScheduled" class="mb-n2 me-16">
              <v-btn ripple rounded large class="primary me-2 mb-2" ref="job-footer-claim-btn" @click="emitClaimJob">
                <v-icon class="me-1" icon>mdi-lock-outline</v-icon>Claim
              </v-btn>
              <v-btn ripple rounded large class="warning me-16" ref="job-footer-cancel-btn" @click="emitCancelJob">
                <v-icon class="me-1" icon>mdi-cancel</v-icon>Cancel
              </v-btn>
            </v-col>
            <v-col v-else-if="getIsJobStatusClaimed" class="mb-n2 me-16">
              <v-btn ripple rounded large class="error me-2 mb-2" ref="job-footer-disclaim-btn" @click="emitDisclaimJob">
                <v-icon class="me-1" icon>mdi-undo</v-icon>Disclaim
              </v-btn>
              <v-btn :disabled="getIsLoadingJobDocuments" ripple rounded large class="primary me-2 mb-2" ref="job-footer-complete-btn" @click="emitCompleteJob">
                <v-icon class="me-1" icon>mdi-clipboard-check-outline</v-icon>Complete
              </v-btn>
              <v-btn ripple rounded large class="warning me-16" ref="job-footer-cancel-btn" @click="emitCancelJob">
                <v-icon class="me-1" icon>mdi-cancel</v-icon>Cancel
              </v-btn>
            </v-col>
            <v-col v-else-if="getIsJobStatusCompleted" class="mb-n2 me-16">
              <v-btn v-if="isUncompleteButtonVisible" ripple rounded large class="error me-2 mb-2" ref="job-footer-uncomplete-btn" @click="emitUncompleteJob">
                <v-icon class="me-1" icon>mdi-undo</v-icon>Uncomplete
              </v-btn>
              <v-btn v-if="isCloseButtonVisible" ripple rounded large class="primary me-2 mb-2" ref="job-footer-close-btn" @click="emitCloseJob">
                <v-icon class="me-1" icon>mdi-close-circle-outline</v-icon>Close
              </v-btn>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>
    </template>
  </app-footer>
</template>

<script>
import AppFooter from "@/components/AppFooter"
import AppNumberFormatter from "@/components/AppNumberFormatter"
import { mapGetters } from "vuex"

export default {
  name: "JobFooter",
  components: { AppNumberFormatter, AppFooter },
  props: {
    isExpandableButtonDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("Job", [
      "getCurrentJob",
      "getIsJobStatusCreated",
      "getIsJobStatusScheduled",
      "getIsJobStatusClaimed",
      "getIsJobStatusCompleted",
      "getIsJobStatusCanceled",
      "getIsJobStatusClosed",
      "getIsJobStatusAbandoned",
      "getIsLoadingAnyJobComponents",
      "getIsLoadingJobDocuments"
    ]),
    getTotalPriceWithTips() {
      return this.getCurrentJob?.totalPrice ?? 0
    },
    isCloseButtonVisible() {
      return this.$msal.getUserHasWriteCloseJobPermission()
    },
    isUncompleteButtonVisible() {
      return this.$msal.getUserHasWriteUncompleteJobPermission()
    },
    getLineItemBreakdownTotals() {
      return [
        {
          title: "Discount",
          value: this.getDiscountAmount
        },
        {
          title: "Tax",
          value: this.getCurrentJob?.totalTax ?? 0
        },
        {
          title: "Tips",
          value: this.getCurrentJob?.totalTip ?? 0
        }
      ]
    },
    getDiscountAmount() {
      return this.getCurrentJob?.discountAmount > 0 ? this.getCurrentJob?.discountAmount * -1 : this.getCurrentJob?.discountAmount ?? 0
    }
  },
  methods: {
    emitScheduleJob() {
      this.$emit("scheduleJob")
    },
    emitAbandonJob() {
      this.$emit("abandonJob")
    },
    emitClaimJob() {
      this.$emit("claimJob")
    },
    emitCancelJob() {
      this.$emit("cancelJob")
    },
    emitCompleteJob() {
      this.$emit("completeJob")
    },
    emitCloseJob() {
      this.$emit("closeJob")
    },
    emitDisclaimJob() {
      this.$emit("disclaimJob")
    },
    emitUncompleteJob() {
      this.$emit("uncompleteJob")
    }
  }
}
</script>
