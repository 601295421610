import dayjs from "dayjs"

/**
 * Given a date, will return a date formatted as `<Day-of-Week>, <Two-Digit Month> <Two-Digit Day>, <Four-Digit Year>`.
 * For example, 01-09-2022 will return Sunday, January 09, 2022.
 * @param dateToFormat
 * @returns {string}
 */
export function dateAsDayOfWeekMonthDayYear(dateToFormat) {
  return dateToFormat !== undefined && dateToFormat !== null ? new dayjs(dateToFormat).format("dddd, MMMM DD, YYYY") : dateToFormat
}


/**
 * Given a date, will return a date formatted as `<Day-of-Week>, <Two-Digit Month> <Two-Digit Day>, <Four-Digit Year>`.
 * For example, 01-09-2022 will return Sunday, January 09, 2022.
 * @param dateToFormat
 * @returns {string}
 */
export function dateAsShortenedDayOfWeekMonthAndYear(dateToFormat) {
  return dateToFormat !== undefined && dateToFormat !== null ? new dayjs(dateToFormat).format("ddd, MMM DD, YYYY") : dateToFormat
}

/**
 * Given an hour and minute, will return a 12-hour representation. Great for time pickers.
 * For example, hours = 13 and minutes = 45, will return 1:45 pm.
 * @param hours
 * @param minutes
 * @returns {string}
 */
export function hoursAndMinutesAsTwelveHour(hours, minutes) {
  return new dayjs()
    .startOf("day")
    .add(parseInt(hours), "hour")
    .add(parseInt(minutes), "minutes")
    .format("hh:mm a")
}

/**
 * Given a time value, will return a date object, in UTC, with the passed time value included.
 * For example, if the user is in Eastern and passed a date = "2022-06-28T08:00:00" and time = "11:45", would return 2022-06-28T15:45:00.000Z.
 * @param date
 * @param time
 * @returns {string}
 */
export function formatTimeAsDateUtc(date, time) {
  const [hours, minutes] = time.split(":")
  return new dayjs(date)
    .startOf("day")
    .add(parseInt(hours), "hour")
    .add(parseInt(minutes), "minutes")
    .toISOString()
}

/**
 * Given a dateTime value, will return a date formatted as `<Four-Digit Year>-<Two-Digit Month>-<Two-Digit Day>`, or, if the value is `null` or `undefined`, returns the passed dateTime param.
 * For example, if the dateTime param passed was '2022-03-01T00:00:00', will return '2022-03-01'.
 * @param dateTime
 * @returns {string|undefined|null}
 */
export function formatAsDateOnly(dateTime) {
  return dateTime ? new dayjs(dateTime).format("YYYY-MM-DD") : dateTime
}

/**
 * Returns the current date, formatted as `<Four-Digit Year>-<Two-Digit Month>-<Two-Digit Day>`.
 * For example, if today is March 1, 2022, returns 2022-03-01.
 * @returns {string}
 */
export function todayAsDate() {
  return new dayjs().format("YYYY-MM-DD")
}

/**
 * Returns the current date minus one week (7 days), formatted as `<Four-Digit Year>-<Two-Digit Month>-<Two-Digit Day>`.
 * For example, if today is March 8, 2022, returns 2022-03-01.
 * @returns {string}
 */
export function oneWeekAgoFromNowAsDate() {
  return new dayjs().add(-7, "day").format("YYYY-MM-DD")
}

/**
 * Returns the current date + 1, formatted as `<Four-Digit Year>-<Two-Digit Month>-<Two-Digit Day>`.
 * For example, if today is March 1, 2022, returns 2022-03-02.
 * @returns {string}
 */
export function tomorrowAsDate() {
  return new dayjs().add(1, "day").format("YYYY-MM-DD")
}

/**
 * Returns the current date + 1 month, formatted as `<Four-Digit Year>-<Two-Digit Month>-<Two-Digit Day>`.
 * For example, if today is March 1, 2022, returns 2022-04-01.
 * @returns {string}
 */
export function oneMonthFromNowAsDate() {
  return new dayjs().add(1, "month").format("YYYY-MM-DD")
}

/**
 * Returns the current date + 1 year, formatted as `<Four-Digit Year>-<Two-Digit Month>-<Two-Digit Day>`.
 * For example, if today is March 1, 2022, returns 2023-03-01.
 * @returns {string}
 */
export function oneYearFromNowAsDate() {
  return new dayjs().add(1, "year").format("YYYY-MM-DD")
}

/**
 * Returns the current date - 1, formatted as `<Four-Digit Year>-<Two-Digit Month>-<Two-Digit Day>`.
 * For example, if today is March 1, 2022, returns 2022-02-28.
 * @returns {string}
 */
export function yesterdayAsDate() {
  return new dayjs().add(-1, "day").format("YYYY-MM-DD")
}

/**
 * Returns the current date - 1 month, formatted as `<Four-Digit Year>-<Two-Digit Month>-<Two-Digit Day>`.
 * For example, if today is March 1, 2022, returns 2022-02-01.
 * @returns {string}
 */
export function oneMonthAgoFromNowAsDate() {
  return new dayjs().add(-1, "month").format("YYYY-MM-DD")
}

/**
 * Returns the current date - 1 year, formatted as `<Four-Digit Year>-<Two-Digit Month>-<Two-Digit Day>`.
 * For example, if today is March 1, 2022, returns 2021-03-01.
 * @returns {string}
 */
export function oneYearAgoFromNowAsDate() {
  return new dayjs().add(-1, "year").format("YYYY-MM-DD")
}

/**
 * Returns only the year and month from the provided dateTime. For example, if given "2022-07-20 00:00:00", will return "2022-07".
 * @param dateTime
 * @returns {string}
 */
export function getYearAndMonthFromDateTime(dateTime) {
  return new dayjs(dateTime).format("YYYY-MM")
}

/**
 * Returns the current date formatted as "YYYY-MM-DD" if the value supplied for yearAndMonth is the same as the value for comparator. Otherwise, will return the first of the month.
 * That is, if the yearAndMonth supplied is the same as the current yearAndMonth, will return the current date.
 * @param yearAndMonth {string} year and month to compare. If it does not match the value for comparator, will return the first of the month.
 * @param comparator {string} date to compare, defaults to today's date.
 * @returns {string}
 */
export function getMinimumDateOrFirstOfMonth(yearAndMonth, comparator = new dayjs().format("YYYY-MM-DD")) {
  return yearAndMonth === comparator.substring(0, 7) ? comparator : new dayjs(`${yearAndMonth}-01`).toISOString().substring(0, 10)
}

/**
 * Returns the last day of the month, formatted as YYYY-MM-DD, for the given yearAndMonth.
 * @param yearAndMonth {string} the desired year and month for which you wish to find the last day.
 * @returns {string}
 */
export function getLastDayOfMonthByYearAndMonth(yearAndMonth) {
  const daysInMonth = new dayjs(yearAndMonth).daysInMonth()
  return new dayjs(`${yearAndMonth}-${daysInMonth}`).toISOString().substring(0, 10)
}

/**
 * Given two dates, will return a bool indicating whether the first date supplied is before the second date.
 * @param firstDate {string} the first date to compare
 * @param secondDate {string} the second date to compare
 * @returns {boolean}
 */
export function getIsFirstDateBeforeSecondDate(firstDate, secondDate) {
  return new dayjs(firstDate).isBefore(new dayjs(secondDate))
}

/**
 * Given two dates, will return a bool indicating whether the first date supplied is on *or* before the second date.
 * @param firstDate {string} the first date to compare
 * @param secondDate {string} the second date to compare
 * @returns {boolean}
 */
export function getIsFirstDateOnOrBeforeSecondDate(firstDate, secondDate) {
  return new dayjs(firstDate).isBefore(new dayjs(secondDate)) || new dayjs(firstDate).isSame(new dayjs(secondDate))
}

/**
 * Given two dates, will return a bool indicating whether the first date supplied is after the second date.
 * @param firstDate {string} the first date to compare
 * @param secondDate {string} the second date to compare
 * @returns {boolean}
 */
export function getIsFirstDateAfterSecondDate(firstDate, secondDate) {
  return new dayjs(firstDate).isAfter(new dayjs(secondDate))
}

/**
 * Given a date, will return a bool indicating whether the date supplied is on or after today. If a null date is supplied, will return true.
 * @param date
 * @returns {boolean}
 */
export function isDateOnOrAfterToday(date) {
  if (!date) return true
  return new dayjs(new dayjs(date)).isAfter(todayAsDate()) || new dayjs(new dayjs(date)).isSame(todayAsDate())
}

/**
 * Given a date, will return a bool indicating whether the date supplied is after today. If a null date is supplied, will return true.
 * @param date
 * @returns {boolean}
 */
export function isDateAfterToday(date) {
  if (!date) return true
  return new dayjs(new dayjs(date)).isAfter(todayAsDate())
}
