let ONE_EIGHTH = { id: 1, name: "1/8" }
let ONE_QUARTER = { id: 2, name: "1/4" }
let THREE_EIGHTHS = { id: 3, name: "3/8" }
let ONE_HALF = { id: 4, name: "1/2" }
let FIVE_EIGHTHS = { id: 5, name: "5/8" }
let THREE_QUARTERS = { id: 6, name: "3/4" }
let SEVEN_EIGHTHS = { id: 7, name: "7/8" }
let FULL = { id: 8, name: "Full" }

export const TruckCapacities = {
    ONE_EIGHTH: ONE_EIGHTH,
    ONE_QUARTER: ONE_QUARTER,
    THREE_EIGHTHS: THREE_EIGHTHS,
    ONE_HALF: ONE_HALF,
    FIVE_EIGHTHS: FIVE_EIGHTHS,
    THREE_QUARTERS: THREE_QUARTERS,
    SEVEN_EIGHTHS: SEVEN_EIGHTHS,
    FULL: FULL
}

export const TruckCapacitiesList = [
    ONE_EIGHTH,
    ONE_QUARTER,
    THREE_EIGHTHS,
    ONE_HALF,
    FIVE_EIGHTHS,
    THREE_QUARTERS,
    SEVEN_EIGHTHS,
    FULL
]
