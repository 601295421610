import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import "@mdi/font/css/materialdesignicons.css"
import { darkTheme, lightTheme } from "../utils/Themes"

Vue.use(Vuetify)

// Vuetify.config.silent = true
export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: lightTheme,
      dark: darkTheme
    }
  }
})
