const SUNDAY = { id: 0, name: "Sunday" }
const MONDAY = { id: 1, name: "Monday" }
const TUESDAY = { id: 2, name: "Tuesday" }
const WEDNESDAY = { id: 3, name: "Wednesday" }
const THURSDAY = { id: 4, name: "Thursday" }
const FRIDAY = { id: 5, name: "Friday" }
const SATURDAY = { id: 6, name: "Saturday" }

export const DayOfWeek = {
  SUNDAY: SUNDAY,
  MONDAY: MONDAY,
  TUESDAY: TUESDAY,
  WEDNESDAY: WEDNESDAY,
  THURSDAY: THURSDAY,
  FRIDAY: FRIDAY,
  SATURDAY: SATURDAY
}

export const DaysOfTheWeek = [
  DayOfWeek.SUNDAY,
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
  DayOfWeek.SATURDAY,
]