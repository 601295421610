<template>
  <base-dialog :is-dialog-visible="isDialogVisible" title="Record Payment Details" dialog-image="mdi-cash-plus">
    <template v-slot:content>
      <v-form class="ma-0 pa-0" ref="job-receipt-add-payment-dialog-form" v-model="isValid" v-on:submit.prevent="handleApply">
        <v-row>
          <v-col>
            <span class="text-h6">Amount Due: ${{amountDue.toFixed(2)}}</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="align-center">
            <v-row class="mt-1" dense>
              <v-col>
                <v-text-field
                  ref="job-receipt-add-payment-dialog-amount-text-field"
                  label="Amount *"
                  prefix="$"
                  type="number"
                  autocomplete="null"
                  :value="getPaymentAmount"
                  @change="setPaymentAmount"
                  :rules="amountRules"
                  outlined
                  @focus="selectAllContent"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="isLastFourCardFieldVisible"
                  ref="job-receipt-add-payment-dialog-credit-card-number-text-field"
                  label="Last Four Credit Card *"
                  type="number"
                  autocomplete="null"
                  v-model="lastFourCardNumber"
                  :rules="lastFourCardNumberRules"
                  @keyup.enter="handleApply"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-if="isInvoiceFieldVisible"
                  ref="job-receipt-add-payment-dialog-invoice-number-text-field"
                  label="Invoice Number *"
                  type="number"
                  autocomplete="null"
                  v-model="invoiceNumber"
                  :rules="invoiceRules"
                  @keyup.enter="handleApply"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-if="isCheckFieldVisible"
                  ref="job-receipt-add-payment-dialog-check-number-text-field"
                  label="Check Number *"
                  type="number"
                  autocomplete="null"
                  v-model="checkNumber"
                  :rules="checkRules"
                  @keyup.enter="handleApply"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="ps-8">
            <v-radio-group v-model="selectedPaymentType" @change="reset">
              <template v-for="payment in paymentTypes">
                <v-radio :label="payment.name" :value="payment.id" :key="payment.id"></v-radio>
              </template>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" color="primary" @click="handleCancel" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" color="primary" @click="handleApply" :disabled="isApplyButtonDisabled" :loading="getIsLoadingAddPayment" text rounded ripple>Apply</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog.vue"
import { PaymentType, PaymentTypes } from "@/enums/PaymentTypes"
import { v4 } from "uuid"
import { mapGetters } from "vuex"
import { round } from "@/utils/Round"

export default {
  name: "JobReceiptAddPaymentDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    amountDue: {
      type: Number,
      default: 0.0
    }
  },
  data() {
    return {
      selectedPaymentType: PaymentType.CREDIT_CARD.id,
      paymentAmount: 0.0,
      lastFourCardNumberRules: [],
      invoiceRules: [],
      checkRules: [],
      amountRules: [],
      invoiceNumber: "",
      checkNumber: "",
      lastFourCardNumber: "",
      isValid: false
    }
  },
  methods: {
    async setValidationRules() {
      this.lastFourCardNumberRules = [value => (!!value && !!value?.trim() && (value?.trim()?.length === 4)) || "Must contain four digits."]
      this.invoiceRules = [
        value => (!!value && !!value.trim()) || "Is required.",
        value => value?.length <= 100 || "Must be less than 101 characters."
      ]
      this.checkRules = [
        value => (!!value && !!value.trim()) || "Is required.",
        value => value?.length <= 100 || "Must be less than 101 characters."
      ]
      this.amountRules = [
        value => (!!value) || "Amount is required.",
        value => value > 0 || "Amount must be positive.",
        value => value <= round(this.amountDue) || `Payment exceeds amount due of $${this.amountDue.toFixed(2)}.`
      ]
    },
    reset() {
      this.invoiceNumber = ""
      this.checkNumber = ""
      this.lastFourCardNumber = ""
      this.amountRules = []
      this.checkRules = []
      this.invoiceRules = []
      this.lastFourCardNumberRules = []
    },
    selectAllContent(event) {
      event.target.select()
    },
    handleCancel() {
      this.$emit("cancel-payment")
    },
    async handleApply() {
      await this.setValidationRules()

      if (this.$refs["job-receipt-add-payment-dialog-form"].validate()) {
        const payment = {
          jobFranchiseDocumentPaymentId: v4(),
          jobFranchiseDocumentId: this.getJobDocumentReceiptInfo.jobFranchiseDocumentId,
          paymentTypeId: this.selectedPaymentType,
          paymentTypeName: this.paymentTypes.find(pt => pt.id === this.selectedPaymentType).name,
          amount: this.paymentAmount,
          lastFourCardNumber: this.lastFourCardNumber,
          invoiceNumber: this.invoiceNumber,
          checkNumber: this.checkNumber,
          isCash: this.selectedPaymentType === PaymentType.CASH.id
        }

        this.$emit("save-payment", payment)
      }
    },
    setPaymentAmount(amount) {
      this.paymentAmount = round(amount, 2)
    }
  },
  computed: {
    ...mapGetters("Job", ["getIsLoadingAddPayment", "getJobDocumentReceiptInfo"]),
    isLastFourCardFieldVisible() {
      return this.selectedPaymentType === PaymentType.CREDIT_CARD.id
    },
    isInvoiceFieldVisible() {
      return this.selectedPaymentType === PaymentType.INVOICE.id
    },
    isCheckFieldVisible() {
      return this.selectedPaymentType === PaymentType.CHECK.id
    },
    isApplyButtonDisabled() {
      return this.paymentAmount <= 0 || this.getIsLoadingAddPayment || !PaymentTypes.some(paymentType => paymentType.id === this.selectedPaymentType)
    },
    paymentTypes() {
      return [PaymentType.CREDIT_CARD, PaymentType.CASH, PaymentType.CHECK, PaymentType.INVOICE]
    },
    getPaymentAmount() {
      return this.paymentAmount.toFixed(2)
    }
  }
}
</script>
