<template>
  <v-container fluid>
    <base-dialog ref="create-truck-dialog-root" :is-dialog-visible="isDialogVisible" dialog-image="mdi-truck" title="Create Truck" @close-dialog="closeDialog">
      <template v-slot:content>
        <v-form ref="form" v-model="isCreateTruckFieldsValid">
          <v-layout row>
            <v-text-field
              ref="create-truck-dialog-truck-name-text"
              label="Truck Name"
              v-model="truckName"
              :rules="truckNameValidationRules"
              :error-messages="recordAlreadyExistsError"
              :disabled="getJunkTrucksLoadingState"
              counter="50"
              outlined
              validate-on-blur
            />
          </v-layout>
          <v-layout row>
            <v-select
              ref="create-truck-dialog-dispatch-location-selector"
              label="Dispatch Location"
              v-model="selectedDispatchLocation"
              :items="getFranchisesInOperatingUnit"
              item-text="franchiseName"
              :disabled="getJunkTrucksLoadingState"
              item-value="addressId"
              :rules="dispatchLocationValidationRules"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              @change="setWeeklyAllocationDispatchLocationId"
            />
          </v-layout>
          <v-layout row>
            <v-select
              ref="create-truck-dialog-fuel-type-selector"
              label="Fuel"
              v-model="selectedVehicleFuelTypeId"
              :items="getVehicleFuelTypes"
              :disabled="getJunkTrucksLoadingState"
              item-text="name"
              item-value="id"
              :rules="vehicleFuelTypeValidationRules"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
            />
          </v-layout>
          <v-layout row>
            <v-text-field
              ref="create-truck-dialog-truck-depreciation-text"
              label="Truck Depreciation"
              prepend-inner-icon="mdi-currency-usd"
              :value="truckDepreciation"
              @change="setTruckDepreciation"
              :rules="truckDepreciationValidationRules"
              :disabled="getJunkTrucksLoadingState"
              min="0"
              max="999.99"
              counter="6"
              type="number"
              outlined
              validate-on-blur
            />
          </v-layout>
          <v-layout row>
            <app-date-picker-field-selector
              ref="create-truck-dialog-begin-date-selector"
              :minimum-date-value="minimumDate"
              :date-picker-value="beginDateTime"
              @updatedPickerValue="setBeginDateTime($event)"
              text-field-label="Begin Date"
              :text-field-disabled="getJunkTrucksLoadingState"
            />
          </v-layout>
          <v-layout row>
            <v-expansion-panels class="mt-4">
              <v-expansion-panel>
                <v-expansion-panel-header ref="create-truck-dialog-expansion-title">
                  Truck Slots
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table ref="create-truck-dialog-expansion-data-table" v-model="newTruckAllocationItems" disable-sort :items="newTruckAllocationItems" :headers="newTruckAllocationHeaders" hide-default-footer item-key="day">
                    <template v-slot:item.amSlots="{ item, index }">
                      <v-text-field ref="create-truck-dialog-expansion-data-table-am-slots" class="mt-4 mb-n2" :item-value="item.amSlots" :value="item.amSlots" @input="setNewTruckAmSlots(index, $event)" outlined dense/>
                    </template>
                    <template v-slot:item.pmSlots="{ item, index }">
                      <v-text-field class="mt-4 mb-n2" :item-value="item.pmSlots" :value="item.pmSlots" @input="setNewTruckPmSlots(index, $event)" outlined dense/>
                    </template>
                    <template v-slot:item.dispatchLocationId="{ item, index }">
                      <v-select class="mt-4 mb-n2" :value="item.dispatchLocationId" item-value="addressId" item-text="franchiseName" :items="getFranchisesInOperatingUnit" @input="setNewTruckDispatchLocation(index, $event)" outlined dense/>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-layout>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="create-truck-dialog-cancel-btn" color="secondary" @click="closeDialog" :disabled="getJunkTrucksLoadingState" text rounded ripple>Cancel</v-btn>
        <v-btn
          class="pa-4"
          ref="create-truck-dialog-confirm-btn"
          color="primary"
          @click="save"
          :loading="getJunkTrucksLoadingState"
          :disabled="getJunkTrucksLoadingState"
          text
          rounded
          ripple
          >Confirm</v-btn
        >
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import BaseDialog from "@/components/BaseDialog"
import { mapActions, mapGetters } from "vuex"
import { formatDecimalValue } from "@/utils/FormatDecimalValue"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import { DayOfWeek } from "@/enums/DayOfWeek";

export default {
  name: "SettingsCreateTruckDialog",
  components: { BaseDialog, AppDatePickerFieldSelector },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      minimumDate: todayAsDate(),
      isCreateTruckFieldsValid: false,
      truckName: "",
      selectedVehicleFuelTypeId: undefined,
      truckDepreciation: undefined,
      selectedDispatchLocation: undefined,
      beginDateTime: todayAsDate(),
      truckNameValidationRules: [value => (!!value && !!value.trim()) || "Name is required.", value => value.length <= 50 || "Name must be 50 characters or less."],
      vehicleFuelTypeValidationRules: [value => !!value || "Vehicle's fuel type is required."],
      dispatchLocationValidationRules: [value => !!value || "Dispatch Location is required."],
      truckDepreciationValidationRules: [
        value => !!value || "Depreciation is required.",
        value => value > 0 || "Depreciation must be positive.",
        value => value <= 999.99 || "Depreciation must be less than or equal to $999.99."
      ],
      newTruckAllocationItems: [
        { dayOfWeekId: DayOfWeek.SUNDAY.id, day: DayOfWeek.SUNDAY.name, amSlots: 2, pmSlots: 2, dispatchLocationId: undefined },
        { dayOfWeekId: DayOfWeek.MONDAY.id, day: DayOfWeek.MONDAY.name, amSlots: 2, pmSlots: 2, dispatchLocationId: undefined },
        { dayOfWeekId: DayOfWeek.TUESDAY.id, day: DayOfWeek.TUESDAY.name, amSlots: 2, pmSlots: 2, dispatchLocationId: undefined },
        { dayOfWeekId: DayOfWeek.WEDNESDAY.id, day: DayOfWeek.WEDNESDAY.name, amSlots: 2, pmSlots: 2, dispatchLocationId: undefined },
        { dayOfWeekId: DayOfWeek.THURSDAY.id, day: DayOfWeek.THURSDAY.name, amSlots: 2, pmSlots: 2, dispatchLocationId: undefined },
        { dayOfWeekId: DayOfWeek.FRIDAY.id, day: DayOfWeek.FRIDAY.name, amSlots: 2, pmSlots: 2, dispatchLocationId: undefined },
        { dayOfWeekId: DayOfWeek.SATURDAY.id, day: DayOfWeek.SATURDAY.name, amSlots: 2, pmSlots: 2, dispatchLocationId: undefined }
      ],
      newTruckAllocationHeaders: [
        { text: "Day", align: "start", value: "day", width: "10%" },
        { text: "AM Slots", align: "start", value: "amSlots", width: "15%" },
        { text: "PM Slots", align: "start", value: "pmSlots", width: "15%" },
        { text: "Dispatch Location", align: "start", value: "dispatchLocationId" },
      ]
    }
  },
  computed: {
    ...mapGetters(["getSelectedOperatingUnitId"]),
    ...mapGetters("Settings", [
      "getFranchisesInOperatingUnit",
      "getJunkTrucksLoadingState",
      "getSelectedFranchise",
      "getVehicleFuelTypes",
      "getActiveAndFutureTrucksInOperatingUnitSortedByName"
    ]),
    recordAlreadyExistsError() {
      return this.doesOverlappingRecordExist ? this.generateErrorMessage() : []
    },
    doesOverlappingRecordExist() {
      return this.findOverlappingRecords?.length > 0
    },
    findOverlappingRecords() {
      return this.getActiveAndFutureTrucksInOperatingUnitSortedByName?.slice().filter(trucks => trucks.name.trim().toLowerCase() === this.truckName.trim().toLowerCase())
    }
  },
  methods: {
    ...mapActions("Settings", ["createJunkTruck"]),
    generateErrorMessage() {
      const truckName = this.findOverlappingRecords[0].name
      return [`Sorry, a record already exists with the same name, '${truckName}'.`]
    },
    setTruckDepreciation(value) {
      this.truckDepreciation = formatDecimalValue(value)
    },
    setBeginDateTime(event) {
      this.beginDateTime = event
    },
    async save() {
      if (this.$refs.form.validate() && !this.doesOverlappingRecordExist) {
        let truckData = this.generateCreateTruckDto()
        console.log(JSON.stringify(truckData,null,4))
        await this.createJunkTruck(truckData).then(() => this.closeDialog())
      }
    },
    setWeeklyAllocationDispatchLocationId(id) {
      console.log("Selected id = ", id)
      this.newTruckAllocationItems.forEach(i => i.dispatchLocationId = id)
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    generateCreateTruckDto() {
      return {
        name: this.truckName.trim(),
        operatingUnitId: this.getSelectedOperatingUnitId,
        depreciationPerMile: this.truckDepreciation,
        dispatchLocationId: this.selectedDispatchLocation,
        beginDateTime: this.beginDateTime,
        truckFuelTypeId: this.selectedVehicleFuelTypeId,
        modifiedBy: this.$msal.getCurrentUserId(),
        junkTruckWeeklyAllocations: this.newTruckAllocationItems
      }
    },
    setNewTruckAmSlots(index, value){
      this.newTruckAllocationItems[index].amSlots = value
      console.log(`tagaroo am = ${index} ${value}`)
    },
    setNewTruckPmSlots(index, value) {
      this.newTruckAllocationItems[index].pmSlots = value

      console.log(`tagaroo pm = ${index} ${value}`)
    },
    setNewTruckDispatchLocation(index, value) {
      this.newTruckAllocationItems[index].dispatchLocationId = value
      console.log(`tagaroo dispatch = ${index} ${value}`)
    }
  },
  created() {
    this.selectedDispatchLocation = this.getFranchisesInOperatingUnit?.length === 1 ? this.getFranchisesInOperatingUnit[0]?.addressId : undefined
    this.setWeeklyAllocationDispatchLocationId(this.selectedDispatchLocation)
  }
}
</script>

<style scoped>
/deep/ tr.v-data-table__selected {
  background: var(--v-background-base) !important;
}
</style>
