<template>
  <v-card elevation="0" class="pb-4" ref="job-queue-slot-card-root">
    <v-card-text>
      <v-row>
        <v-responsive class="overflow-y-auto" max-height="550" min-height="200">
          <v-data-table
            class="flex-fill row-pointer"
            ref="job-queue-slot-card-data-table"
            :headers="getJobQueueHeaders"
            :loading="isLoading"
            :items="getFilteredJobQueue"
            :sort-by="['preferredTimeSlotId', 'totalDistance']"
            loading-text="🔎 Fetching Jobs 🔍"
            @click:row="emitClaimJobEvent"
            mobile-breakpoint="840"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:no-data>
              <v-col class="ma-0 pa-0 align-self-center d-flex">
                <app-not-found title="No Results" content="No Jobs Are Currently Scheduled for the Selected Date.">
                  <template v-slot:actions><div></div></template>
                </app-not-found>
              </v-col>
            </template>
            <template v-slot:item.jobNumber="{ item }">
              <span>
                <router-link class="text-md-body-1" :to="{ name: 'Job', params: { jobId: item.jobId } }">
                  {{ item.jobNumber }}
                </router-link>
                <v-icon color="primary" @click.stop="addNewJobTab(item)" class="ms-2">
                  mdi-pin-outline
                </v-icon>
                <span v-if="item.jobCreationOriginId === JobCreationOrigin.CUSTOMER.id" class="ms-2">
                  <base-tool-tip
                    ref="job-queue-self-scheduled-tool-tip"
                    tooltipColor="blue"
                    icon-color="blue"
                    dialog-title="Customer Self-Scheduled"
                    nudge-dialog="0"
                    tooltip-icon="mdi-account-tag-outline"
                    :is-bottom-dialog="true"
                  ></base-tool-tip>
                </span>
              </span>
            </template>
            <template v-slot:item.customerAndAddress="{ item }">
              <span class="text-md-body-1" style="white-space: pre-line">{{ customerAndAddress(item) }}
              </span>
              <base-tool-tip
                v-if="true"
                ref="settings-franchise-time-slots-card-edit-tool-tip"
                tooltipColor="red"
                icon-color="red"
                :dialog-title="getNumberOfAdditionalAddresses(item) + ' Additional customer address(es)'"
                nudge-dialog="0"
                :tooltip-icon="getAdditionalAddressesIcon(item)"
                :is-bottom-dialog="true"
              ></base-tool-tip>
            </template>
            <template v-slot:item.jobAddresses="{ item }">
              <v-list dense class="transparent">
                <v-list-item-group class="transparent">
                  <v-list-item class="transparent" v-for="(address, index) in item.jobAddresses" :key="index">
                    <v-list-item-content>
                      <div class="text-md-body-1 primaryText--text" disabled>{{ address.fullAddress }}</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
            <template v-slot:item.capacityName="{ item }">
              <span class="text-md-body-1">{{ item.capacityName }}</span>
            </template>
            <template v-slot:item.preferredTimeSlotId="{ item }">
              <v-row class="justify-center text-center align-center" dense>
                <v-col v-if="isPreferredTimeSlotTextVisible" cols="auto" class="d-flex">
                  <div>{{ item.preferredTimeSlotName }}</div>
                </v-col>
                <v-col cols="auto" class="d-flex mt-n1">
                  <v-icon>
                    {{ getPreferredTimeSlotIcon(item.preferredTimeSlotId) }}
                  </v-icon>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.statusName="{ item }">
              <v-chip :color="determineBackgroundColor(item.statusName)"
                      :text-color="determineBackgroundColor(item.statusName)"
                      outlined>
                {{ item.statusName }}
              </v-chip>
            </template>
            <template v-slot:item.claimedBy="{ item }">
              <v-list dense class="transparent">
                <v-list-item-group class="transparent">
                  <v-list-item class="transparent"
                               v-for="(truck, index) in item.jobTrucks"
                               :key="index" inactive disabled>
                    <v-list-item-content class="text-md-body-1 primaryText--text">{{ truck.name }}</v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
            <template v-slot:item.totalDistance="{ item }">
              <span class="text-md-body-1">{{ formatDistanceFromUser(item.totalDistance) }}</span>
            </template>
          </v-data-table>
        </v-responsive>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AppNotFound from "@/components/AppNotFound"
import { JobStatus } from "@/enums/JobStatus"
import { getJobStatusColorByStatusName } from "@/utils/JobStatusColors"
import { mapActions, mapGetters } from "vuex"
import BaseToolTip from "@/components/BaseToolTip"
import { JobCreationOrigin } from "@/enums/JobCreationOrigin"
import { JobTab } from "@/models/JobTab"
import { PreferredTimeSlot } from "@/enums/PreferredTimeSlot"

export default {
  name: "JobQueueSlotCard",
  components: { AppNotFound, BaseToolTip },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters("JobQueue", ["getJobQueueHeaders", "getFilteredJobQueue"]),
    JobCreationOrigin() {
      return JobCreationOrigin
    },
    isPreferredTimeSlotTextVisible() {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  methods: {
    ...mapActions(["addJobTab"]),
    async addNewJobTab(item) {
      let tab = new JobTab(item.jobId, item.jobNumber, item.customerId, item.customerLastName, item.businessName)
      await this.addJobTab(tab)
    },
    emitClaimJobEvent(job) {
      if (job.statusName === JobStatus.SCHEDULED.name) this.$emit("claimJob", job)
    },
    customerAndAddress(item) {
      let address = item.jobAddresses.find(ja => !ja.isFranchiseLocation)
      if (address) {
        return `${item.customerName}\n${address?.fullAddress ?? ""}`
      }
      return item.customerName
    },
    getPreferredTimeSlotIcon(preferredTimeSlotId) {
      switch (preferredTimeSlotId) {
        case PreferredTimeSlot.FIRST.id:
          return `mdi-numeric-1-circle`
        case PreferredTimeSlot.AM.id:
          return `mdi-sun-angle`
        case PreferredTimeSlot.PM.id:
          return `mdi-weather-night`
        case PreferredTimeSlot.NONE.id:
          return `mdi-theme-light-dark`
        default:
          return `mdi-blank`
      }
    },
    getAdditionalAddressesIcon(item) {
      let count = this.getNumberOfAdditionalAddresses(item)
      if (count) {
        return `mdi-numeric-${count}-circle`
      }
    },
    getNumberOfAdditionalAddresses(item) {
      let length = item.jobAddresses.slice().filter(ja => !ja.isFranchiseLocation).length - 1
      if (length > 0) {
        return length
      }
    },
    determineBackgroundColor(statusName) {
      return getJobStatusColorByStatusName(statusName)
    },
    formatDistanceFromUser(distance) {
      return distance >= 0 ? `${distance} Miles` : "No Address"
    }
  }
}
</script>
