<template>
  <v-container fluid>
    <h1 id="not-found-heading" ref="not-found-heading" data-cy="not-found-heading">
      <slot name="title">{{ title }}</slot>
    </h1>
    <h3 id="not-found-subheading" ref="not-found-subheading" data-cy="not-found-subheading">
      <slot name="content">{{ content }}</slot>
    </h3>
    <slot name="actions">
      <router-link ref="not-found-router-link" data-cy="not-found-router-link" :to="{ name: 'Dashboard' }">Back to the Dashboard screen</router-link>
    </slot>
  </v-container>
</template>

<script>
export default {
  name: "AppNotFound",
  props: {
    title: {
      type: String,
      default: "Oops! :("
    },
    content: {
      default: "The page you're looking for is not here!"
    }
  }
}
</script>

<style scoped></style>
