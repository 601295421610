import Vue from "vue"
import Vuex from "vuex"
import Administrator from "@/store/modules/Administrator"
import Customer from "@/store/modules/Customer"
import Dashboard from "@/store/modules/Dashboard"
import Job from "@/store/modules/Job"
import JobManagement from "@/store/modules/JobManagement"
import JobQueue from "@/store/modules/JobQueue"
import Settings from "@/store/modules/Settings"
import DataFix from "@/store/modules/DataFix"
import { CustomerTypes } from "@/enums/CustomerTypes"
import { PreferredMethodOfContacts } from "@/enums/PreferredMethodOfContacts"
import junkApi from "@/api/Junk"
import { errorSnackbar, successSnackbar } from "@/utils/SnackbarBuilder";
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import * as Sentry from "@sentry/vue"
import { JobStatuses } from "@/enums/JobStatus"
import getEnv from "@/utils/Env"
import { app, azureauth } from "../../appsettings.json"
import { consoleLog } from "@/utils/Logging"
import { JobTab } from "@/models/JobTab"
import { PreferredTimeSlots } from "@/enums/PreferredTimeSlot"
import { TruckCapacitiesList } from "@/enums/TruckCapacities"

Vue.use(Vuex)

const isStrictModeEnabled = getEnv("VUE_APP_VUEX_IS_STRICT_MODE_ENABLED") || app.VUE_APP_VUEX_IS_STRICT_MODE_ENABLED //process.env.NODE_ENV !== "production"

export const getters = {
  getAppEnvironment() {
    let toBeReturned = getEnv("VUE_APP_ENVIRONMENT") || app.VUE_APP_ENVIRONMENT
    consoleLog("getAppEnvironment = ", toBeReturned)
    return toBeReturned
  },
  getJobTabs(state){
    return state._jobTabs.slice()
  },
  getAzureTypeExtKey() {
    return getEnv("AZURE_DIRECTORY_TYPE_EXTENSION_KEY") || azureauth.VUE_APP_AZURE_DIRECTORY_TYPE_EXTENSION_KEY
  },
  getAzureOperatingUnitEnvironmentExtKey() {
    return getEnv("AZURE_DIRECTORY_ENVIRONMENT_EXTENSION_KEY") || azureauth.VUE_APP_AZURE_DIRECTORY_ENVIRONMENT_EXTENSION_KEY
  },
  getIsLoading(state) {
    return state.isLoading
  },
  getIsSignalrConnected(state) {
    return state._isSignalRConnected
  },
  getSnackbars(state) {
    return state.snackbars
  },
  getIsLoadingAppFullScreen(state) {
    return state.isLoadingAppFullScreen
  },
  getCustomerTypes(state) {
    return state._customerTypes.slice().sort((a, b) => a.name.localeCompare(b.name))
  },
  getOperatingUnitMetadataList(state) {
    return state._operatingUnitMetadataList.slice()
  },
  getUserOperatingUnitIds(state) {
    return state._operatingUnitMetadataList?.map(ou => ou.id)
  },
  getJobStatusesSortedByName(state) {
    return state._jobStatuses.slice().sort((a, b) => a.name.localeCompare(b.name))
  },
  getPreferredMethodOfContacts(state) {
    return state._preferredMethodOfContacts.slice().sort((a, b) => a.name.localeCompare(b.name))
  },
  getUserActiveFranchises(state) {
    return state._userActiveFranchises?.slice()?.sort((a, b) => a?.franchiseNameAndNumber?.localeCompare(b?.franchiseNameAndNumber)) ?? []
  },
  getFranchiseEmailByFranchiseId: state => franchiseId => {
    return state._userActiveFranchises?.slice().find(franchise => franchise.franchiseId === franchiseId)?.franchiseEmail ?? ""
  },
  getIsLoadingUserActiveFranchises(state) {
    return state._isLoadingUserActiveFranchises
  },
  getSelectedOperatingUnit(state) {
    let toBeReturned = state._selectedOperatingUnitMetadata //JSON.parse(sessionStorage.getItem("selectedOperatingUnit"))
    consoleLog("getSelectedOperatingUnit.toBeReturned = ", toBeReturned)
    return toBeReturned
  },
  getPreferredTimeSlots(state) {
    return state._preferredTimeSlots.slice().sort((a, b) => a.name.localeCompare(b.name))
  },
  getTruckCapacities(state) {
    return state._truckCapacities.slice()
  },
  getSelectedOperatingUnitId(state) {
    let toBeReturned = state._selectedOperatingUnitMetadata.id
    consoleLog("getSelectedOperatingUnitId.toBeReturned = ", toBeReturned)
    return toBeReturned
  },
  getIsSelectedOperatingUnitIdSet(state, getters) {
    return getters.getSelectedOperatingUnitId !== undefined && getters.getSelectedOperatingUnitId !== null
  },
  getIsLoadingValidateEin(state) {
    return state._isLoadingValidateEin
  }
}

export const state = {
  isLoading: false,
  isSidePanelVisible: false,
  snackbars: [],
  isLoadingAppFullScreen: { isLoading: false, loadingText: "" },
  _userActiveFranchises: [], //window.localStorage.getItem("junkUserFran"),
  _customerTypes: CustomerTypes,
  _preferredMethodOfContacts: PreferredMethodOfContacts,
  _preferredTimeSlots: PreferredTimeSlots,
  _truckCapacities: TruckCapacitiesList,
  _jobStatuses: JobStatuses,
  _isLoadingUserActiveFranchises: false,
  _isLoadingValidateEin: false,
  _operatingUnitMetadataList: [],
  _selectedOperatingUnitMetadata: {},
  _isSignalRConnected: false,
  _jobTabs: [],
  _userSignatureProfile: {},
}

export const mutations = {
  ADD_SNACKBAR(state, snackbar) {
    state.snackbars.push(snackbar)
  },
  ADD_JOB_TAB(state, tab) {
    let exists = state._jobTabs.findIndex(c => c.jobId === tab.jobId)
    if (~exists) {
      return // Job already in tab list.
    }
    state._jobTabs.push(tab)
  },
  REMOVE_JOB_TAB_BY_JOB_ID(state, jobId) {
    let idx = state._jobTabs.findIndex(c => c.jobId === jobId)
    if (~idx) {
      state._jobTabs.splice(idx,1)
    }
  },
  UPDATE_JOB_TAB_JOB_NUMBER_BY_JOB_ID(state, job) {
    let idx = state._jobTabs.findIndex(c => c.jobId === job.id)
    if (~idx) {
      let existingTab = state._jobTabs[idx]
      let tab = new JobTab(job.id, job.jobNumber, existingTab.customerId, existingTab.customerLastName, existingTab.businessName)
      state._jobTabs.splice(idx, 1, tab)
    }
  },
  UPDATE_JOB_TAB_CUSTOMER_BY_CUSTOMER_ID(state, customer) {
    let copy = [...state._jobTabs]
    copy.forEach((c, i) => {
      if (c.customerId === customer.customerId) {
        let tab = new JobTab(c.jobId, c.jobNumber, c.customerId, c.customerLastName, customer.businessName)
        state._jobTabs.splice(i, 1, tab)
      }
    })
  },
  UPDATE_JOB_TAB_PRIMARY_CONTACT_BY_CUSTOMER_ID(state, contact) {
    let copy = [...state._jobTabs]
    copy.forEach((c, i) => {
      if (c.customerId === contact.customerId) {
        let tab = new JobTab(c.jobId, c.jobNumber, c.customerId, contact.lastName, c.businessName)
        state._jobTabs.splice(i, 1, tab)
      }
    })
  },
  SET_IS_SIGNALR_CONNECTED(state, isConnected) {
    state._isSignalRConnected = isConnected
  },
  REMOVE_SNACKBAR(state, id) {
    state.snackbars = state.snackbars.filter(s => {
      return s.id !== id
    })
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  TOGGLE_SIDE_PANEL_VISIBILITY(state) {
    state.isSidePanelVisible = !state.isSidePanelVisible
  },
  SET_IS_LOADING_APP_FULL_SCREEN(state, { isLoading, loadingText }) {
    state.isLoadingAppFullScreen = { isLoading: isLoading, loadingText: loadingText }
  },
  SET_USER_ACTIVE_FRANCHISES(state, userActiveFranchises) {
    state._userActiveFranchises = userActiveFranchises
    // window.localStorage.setItem("junkUserFran", userActiveFranchises)
  },
  SET_IS_LOADING_USER_ACTIVE_FRANCHISES(state, isLoading) {
    state._isLoadingUserActiveFranchises = isLoading
  },
  SET_OPERATING_UNIT_METADATA_LIST(state, metadataList) {
    state._operatingUnitMetadataList = metadataList
  },
  SET_SELECTED_OPERATING_UNIT_METADATA(state, metadataObject) {
    state._selectedOperatingUnitMetadata = Object.assign({}, metadataObject)
  },
  SET_IS_LOADING_VALIDATE_EIN(state, isLoading) {
    state._isLoadingValidateEin = isLoading
  }
}

export const actions = {
  async deleteJobTab({commit}, jobId) {
      commit("REMOVE_JOB_TAB_BY_JOB_ID", jobId) // Job in tab list, remove.
  },
  async addJobTab({commit}, tab) {
    commit("ADD_JOB_TAB", tab)
  },
  async createOrUpdateEmployeeDetails({ commit }, upsertEmployeeDetailsDto) {
    consoleLog("createOrUpdateEmployeeDetails = ", upsertEmployeeDetailsDto)
    return await junkApi
      .upsertEmployeeDetails(upsertEmployeeDetailsDto)
      .then(data => {
        console.log("createOrUpdateEmployeeDetails.data = ", JSON.stringify(data, null, 4))
        return Promise.resolve(data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(errorMessageHandler(error)), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
  },
  async setSelectedOperatingUnitMetadata({ commit }, metadataObject) {
    consoleLog("setSelectedOperatingUnitMetadata.metadataObject = ", metadataObject)
    sessionStorage.setItem("selectedOperatingUnit", JSON.stringify(metadataObject))
    commit("SET_SELECTED_OPERATING_UNIT_METADATA", metadataObject)
  },
  async resetSelectedOperatingUnitMetadata({ commit }) {
    sessionStorage.setItem("selectedOperatingUnit", undefined)
    commit("SET_SELECTED_OPERATING_UNIT_METADATA", undefined)
  },
  async addSnackbar({ commit }, snackbar) {
    commit("ADD_SNACKBAR", snackbar)
  },
  async setHubConnectionState({commit}, isConnected) {
    commit("SET_IS_SIGNALR_CONNECTED", isConnected)
  },
  async setOperatingUnitMetadataList({ commit }, metadataList) {
    commit("SET_OPERATING_UNIT_METADATA_LIST", metadataList)
  },
  async removeSnackbar({ commit }, id) {
    commit("REMOVE_SNACKBAR", id)
  },
  async setIsLoadingAppFullScreen({ commit }, { isLoading, loadingText }) {
    commit("SET_IS_LOADING_APP_FULL_SCREEN", { isLoading, loadingText })
  },
  async resetIsLoadingAppFullScreen({ commit }) {
    commit("SET_IS_LOADING_APP_FULL_SCREEN", { isLoading: false, loadingText: "" })
  },
  async validateEmployerIdentificationNumber({ commit }, ein) {
    consoleLog("validateEmployerIdentificationNumber = ", ein)
    commit("SET_IS_LOADING_VALIDATE_EIN", true)
    return await junkApi
      .validateEmployerIdentificationNumber(ein)
      .then(isValid => {
        consoleLog("validateEmployerIdentificationNumber.isValid = ", isValid)
        isValid ? commit("ADD_SNACKBAR", successSnackbar("Charity is valid"), { root: true }) : commit("ADD_SNACKBAR", errorSnackbar("Charity is invalid"), { root: true })
        return Promise.resolve(isValid)
      })
      .catch(error => {
        const errorMessage = errorMessageHandler(error)
        commit("ADD_SNACKBAR", errorSnackbar(errorMessage), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => {
        commit("SET_IS_LOADING_VALIDATE_EIN", false)
      })
  },
  async fetchActiveFranchisesForUserByOperatingUnitIds({ commit, getters }) {
    commit("SET_IS_LOADING_USER_ACTIVE_FRANCHISES", true)
    consoleLog("getOperatingUnitMetadataList = ", getters.getUserOperatingUnitIds)
    return await junkApi
      .fetchActiveFranchisesByOperatingUnitIds(getters.getUserOperatingUnitIds)
      .then(data => {
        console.log("fetchActiveFranchisesForUserByOperatingUnitIds.data = ", JSON.stringify(data, null, 4))
        commit("SET_USER_ACTIVE_FRANCHISES", data)
        return Promise.resolve(data)
      })
      .catch(error => {
        commit("ADD_SNACKBAR", errorSnackbar(errorMessageHandler(error)), { root: true })
        Sentry.captureException(error)
        return Promise.reject(error)
      })
      .finally(() => commit("SET_IS_LOADING_USER_ACTIVE_FRANCHISES", false))
  }
}

export default new Vuex.Store({
  strict: isStrictModeEnabled,
  state,
  mutations,
  actions,
  getters,
  modules: {
    Administrator,
    Dashboard,
    Customer,
    Job,
    JobManagement,
    JobQueue,
    Settings,
    DataFix
  }
})
